import { VehicleOwnerType, VehicleType } from "../../../api";

// === VehicleType ===
export function vehicleTypeFromString(value: string): VehicleType {
    return Object.keys(VehicleType).find((item) => item.toString() === value) as VehicleType ?? VehicleType.OTHER;
}

export function vehicleTypeToString(value: VehicleType): string {
    return Object.keys(VehicleType).find((item) => item === value) ?? VehicleType.OTHER;
}

export function getVehicleTypeTranslationKey(value?: VehicleType): string {
    return value ? `vehicle_type_${vehicleTypeToString(value).toLowerCase()}` : 'global_no_data';
}

export const getVehicleTypeLabelKey = (type?: VehicleType): string => getVehicleTypeTranslationKey(type)


// === VehicleOwnerType ===
export function vehicleOwnerTypeFromString(value: string): VehicleOwnerType {
    return Object.keys(VehicleOwnerType).find((item) => item.toString() === value) as VehicleOwnerType ?? VehicleOwnerType.OTHER;
}

export function vehicleOwnerTypeToString(value: VehicleOwnerType): string {
    return Object.keys(VehicleOwnerType).find((item) => item === value) ?? VehicleOwnerType.OTHER;
}

export function getVehicleOwnerTypeTranslationKey(value?: VehicleOwnerType): string {
    return value ? `vehicle_owner_type_${vehicleOwnerTypeToString(value).toLowerCase()}` : 'global_no_data';
}

export const getVehicleOwnerTypeLabelKey = (type?: VehicleOwnerType): string => getVehicleOwnerTypeTranslationKey(type)
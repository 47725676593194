import { useEffect, useState } from "react";

import { Dialog } from "@mui/material";

import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  AddCourseDocumentQueryProps,
  CourseData,
  CourseDocumentBase,
} from "../../../../api";
import {
  addCourseDocument,
  deleteCourseDocument,
  downloadCourseDocument,
  getCourseDocumentsListHeaders,
} from "../clientCoursesPageSlice";
import CourseDocumentsListView from "./CourseDocumentsListView";
import CourseDocumentsPageAdd from "./CourseDocumentsPageAdd";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";

type Props = {
  translate: Function;
  course: CourseData | undefined;
  clientId: string | undefined;
};

export default function CourseDocumentsPage({
  clientId,
  course,
  translate,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  const documentsHeaders = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.selectedCourseDocuments
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.isLoading
  );

  // Add:
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);

  // Get the data:
  useEffect(() => {
    if (clientId && course?.id && !documentsHeaders) {
      dispatch(
        getCourseDocumentsListHeaders({ clientId, courseId: course.id })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, course]);

  const handleOnRowClicked = (header: CourseDocumentBase) => {
    if (clientId && course?.id && header.id) {
      dispatch(
        downloadCourseDocument({
          clientId,
          courseId: course.id,
          documentId: header.id,
        })
      );
    }
  };

  // ------------------- Delete Dialog -------------------
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [dataToDelete, setDataToDelete] = useState<CourseDocumentBase | null>(
    null
  );

  const handleDeleteConfirm = async () => {
    if (clientId && course?.id && dataToDelete?.id) {
      const result = await dispatch(
        deleteCourseDocument({
          clientId,
          courseId: course?.id,
          documentId: dataToDelete.id,
        })
      );

      if (result) {
        setOpenDeleteConfirm(false);
      }
    }
  };

  const handleOnDeleteClicked = (header: CourseDocumentBase) => {
    setOpenDeleteConfirm(true);
    setDataToDelete(header);
  };

  const handleOnAddNewClicked = () => {
    setIsAddEditDialogOpen(true);
  };

  const onAddDocument = async (
    file: File | null,
    docProps: AddCourseDocumentQueryProps
  ): Promise<string | null> => {
    if (clientId && course?.id) {
      return new Promise((resolve) => {
        dispatch(
          addCourseDocument({
            file,
            docProps,
            onComplete: (isSuccess: boolean) => {
              if (isSuccess) {
                resolve(null);
              } else {
                resolve(
                  `${translate("vehicle_documents_page_error_adding_document")}`
                );
              }
            },
          })
        );
      });
    }
    return null;
  };

  return (
    <>
      <CourseDocumentsListView
        translate={translate}
        isLoadingData={isLoading.documentsData}
        documentsHeaders={documentsHeaders || []}
        onRowClicked={handleOnRowClicked}
        titleKey="courses_page_tab_documents"
        onAddButtonClicked={handleOnAddNewClicked}
        onDeleteClicked={handleOnDeleteClicked}
      />

      <DeleteConfimDialog
        translate={translate}
        isOpen={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        onAction={handleDeleteConfirm}
        isLoading={isLoading.documentsDelete}
      />

      {/**  Add Dialog  */}
      {clientId && course?.id && (
        <Dialog
          open={isAddEditDialogOpen}
          onClose={() => setIsAddEditDialogOpen(false)}
        >
          <CourseDocumentsPageAdd
            translate={translate}
            courseId={course.id}
            clientId={clientId}
            onPreformAction={onAddDocument}
            onComplete={() => setIsAddEditDialogOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
}

import { useState, useEffect } from "react";

import { Button, CircularProgress, Stack } from "@mui/material";
import { Add, Download } from "@mui/icons-material";

import { CourseHeader, CourseStatus, CourseType } from "../../../../api";

import RichTableView, { RichTableStatusTab } from "../../common/views/richTableView/rich-table-view";
import { TableHeadItem } from "../../../components/table/TableHeadCustom";
import CoursesListViewRowItem from "./CoursesListViewRowItem";
import { RichTableFilters } from "../../common/views/richTableView/rich-table-filters-result";
import { fTimestamp } from "../../../utils/format/formatTime";

// ----------------------------------------------------------------------

export type CoursesListViewProps = {
  translate: Function;
  isLoadingData: boolean;
  isDownloading: boolean;
  titleKey?: string;

  coursesHeaders: CourseHeader[];

  onRowClicked: (_course: CourseHeader) => void;

  // Action Button:
  onAddButtonClicked?: () => void;
  onDownloadReportButtonClicked?: () => void;
};

export const getCourseStatusColor = (courseStatus: CourseStatus) => {
  switch (courseStatus) {
    case CourseStatus.NEW:
    case CourseStatus.ACTIVE:
      return "primary";
    case CourseStatus.COMPLETED:
      return "success";
    case CourseStatus.CANCELED:
      return "error";
    case CourseStatus.PENDING:
      return "warning";
    default:
      return "default";
  }
};

const applyFilter = (courses: CourseHeader[], filters: RichTableFilters, dateError: boolean): CourseHeader[] => {
  const { globalSearch, status, types, startDate, endDate } = filters;
  
  let filteredData: CourseHeader[] = [...courses];

  if (globalSearch !== "") {
    filteredData = courses.filter((itemObject: any) =>
      Object.keys(itemObject).some((key) => {
        const value = itemObject[key];
        return (
          value &&
          typeof value === "string" &&
          value.toLowerCase().includes(globalSearch.toLowerCase())
        );
      })
    );
  }

  if (status !== null) {
    filteredData = courses.filter((course) => course.status === status);
  }

  if (types.length > 0) {
    filteredData = courses.filter(
      (course) => course.courseType && types.includes(course.courseType)
    );
  }

  if (!dateError) {
    if (startDate && endDate) {
      filteredData = courses.filter(
        (course) =>
          fTimestamp(course.startedAt) >= fTimestamp(startDate)
      );
    }
  }
  return filteredData;
};


export default function CoursesListView({
  translate,
  isLoadingData,
  isDownloading,
  titleKey,
  coursesHeaders,
  onRowClicked,
  onAddButtonClicked,
  onDownloadReportButtonClicked
}: CoursesListViewProps) {
  const [filteredTableData, _setFilteredTableData] = useState(coursesHeaders);

  useEffect(() => {
    _setFilteredTableData(coursesHeaders);
  }, [coursesHeaders]);

  const handleOnTableRowClicked = (invoice: CourseHeader) => {
    onRowClicked(invoice);
  };

  const getCoursesLength = (status: CourseStatus) =>
    coursesHeaders.filter((item) => item.status === status).length;

  const statusTabs = [
    {
      value: null,
      label: translate("course_status_title_all"),
      color: "default",
      count: coursesHeaders.length,
    },
    ...Object.values(CourseStatus).map((status) => ({
      value: status,
      label: translate(`course_status_title_${status.toLowerCase()}`),
      color: getCourseStatusColor(status),
      count: getCoursesLength(status),
    })),
  ] as RichTableStatusTab[];

  const TABLE_HEAD: TableHeadItem[] = [
    {
      id: "name",
      label: `${translate("courses_table_head_title_name")}`,
    },
    {
      id: "courseType",
      label: `${translate("courses_table_head_title_type")}`,
    },
    {
      id: "status",
      label: `${translate("courses_table_head_title_status")}`,
    },
    {
      id: "startedAt",
      label: `${translate("courses_table_head_title_started_at")}`,
    },
    {
      id: "endedAt",
      label: `${translate("courses_table_head_title_ended_at")}`,
    }
  ];

  const typeFilterOptions = new Map(
    Object.values(CourseType).map((type) => [
      type,
      `${translate(`course_type_title_${type.toLowerCase()}`)}`,
    ])
  );

  const handleOnApplyFilters = (filters: RichTableFilters, dateError: boolean) => {
    _setFilteredTableData(applyFilter(coursesHeaders, filters, dateError));
  };

  return (
    <RichTableView
      translate={translate}
      isLoadingData={isLoadingData}
      titleKey={titleKey}
      filteredTableData={filteredTableData}
      tableHeads={TABLE_HEAD}
      typeFilterOptions={typeFilterOptions}
      showEndDate={false}
      statusTabs={statusTabs}
      onApplyFilters={handleOnApplyFilters}
      randerRow={(item: CourseHeader) => (
        <CoursesListViewRowItem
          translate={translate}
          key={item.id || ""}
          row={item}
          onViewRow={() => handleOnTableRowClicked(item)}
        />
      )}
      onActionButtonClicked={onAddButtonClicked}
      overrideActionButtons={
        <>
        <Stack spacing={1} direction={'row'}>
            <Button
              onClick={onAddButtonClicked}
              variant="contained"
              color="success"
              startIcon={<Add />}
            >
              {`${translate("courses_page_title_create_course")}`}
            </Button>

            <Button
              disabled={isDownloading}
              onClick={onDownloadReportButtonClicked}
              variant="contained"
              startIcon={!isDownloading ? (
                <Download />
              ) : (
                <CircularProgress color={'primary'} size={16} thickness={5}/>
              )}
            >
              {`${translate("courses_page_title_download_report")}`}
            </Button>
        </Stack>
      </>
      }
    />
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog } from "@mui/material";

import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { getIncidentsHeaders } from "./vehicleIncidentsPageSlice";
import { VehicleIncidentHeader } from "../../../../api";
import { PATH_DASHBOARD } from "../../../routes/paths";
import IncidentsListView from "./list/IncidentsListView";
import VehicleIncidentsAddEdit from "./addEdit/VehicleIncidentsAddEdit";

type Props = {
  clientId?: string;
  licenseNumber?: string;
  translate: Function;
};

export default function VehicleIncidentsPage({
  clientId,
  licenseNumber,
  translate,
}: Props) {
  const navigate = useNavigate();

  const dispatch = useDashboardAppDispatch();

  const incidentsHeadersData = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehicleIncidentsPageSlice.headers
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehicleIncidentsPageSlice.isLoading
  );

  // Add:
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);

  // Get the data:
  useEffect(() => {
    if (clientId && licenseNumber) {
      dispatch(getIncidentsHeaders({ clientId, licenseNumber }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, licenseNumber]);

  const handleOnRowClicked = (header: VehicleIncidentHeader) => {
    if (clientId && licenseNumber && header.id) {
      navigate(
        PATH_DASHBOARD.management.clients.vheicle.incident(
          clientId,
          licenseNumber,
          header.id
        )
      );
    }
  };

  const handleOnAddNewClicked = () => {
    setIsAddEditDialogOpen(true);
  };

  // const handleDownloadIncidentReportPdf = () => {
  //   console.log("handleDownloadIncidentReportPdf");
  // };

  return (
    <>
      <IncidentsListView
        translate={translate}
        isLoadingData={isLoading.details}
        // isDownloading={isLoading.download}
        incidentsHeaders={incidentsHeadersData || []}
        onRowClicked={handleOnRowClicked}
        titleKey="vehicle_page_incidents_title"
        onAddButtonClicked={handleOnAddNewClicked}
        // onDownloadReportButtonClicked={handleDownloadIncidentReportPdf}
      />

      {/**  Add Dialog  */}
      {clientId && licenseNumber && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={isAddEditDialogOpen}
          onClose={() => setIsAddEditDialogOpen(false)}
        >
          <VehicleIncidentsAddEdit
            clientId={clientId}
            vehicleId={licenseNumber}
            translate={translate}
            onCloseClicked={() => setIsAddEditDialogOpen(false)}
            onComplete={() => setIsAddEditDialogOpen(false)}
          />
        </Dialog>
      )}
    </>
  );
}

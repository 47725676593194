

export type AddressInputFieldProps = {
    name: string,
    lablel_key: string
}
export const getAddressInputFields: AddressInputFieldProps[] = [
    {name: 'street', lablel_key: 'form_input_street'},
    {name: 'houseNumber', lablel_key: 'form_input_house_number'},
    {name: 'city', lablel_key: 'form_input_city'},
    {name: 'zipCode', lablel_key: 'form_input_zip_code'},
]
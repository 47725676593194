import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateVehicleTachograph, DriverUser, UpdateVehicleTachograph, VehicleTachograph, VehicleTachographHeader } from '../../../../api';
import RepositoryVehicleTachographs, { CreateVehicleTachographRecordsProps, GetVehicleTachographRecordsProps, GetVehicleTachographsHeadersProps, UpdateVehicleTachographRecordProps } from '../../../repositories/vehicle/RepositoryVehicleTachographs';
import { downloadFile } from '../../../../global/repositories/RepositoryBase';


export type VehicleTachographPageState = {
  headers: VehicleTachographHeader[] | undefined;
  selectedTachograph: VehicleTachograph | undefined;
  drivers: DriverUser[] | undefined;
  selectedDriverId?: string;
  isLoading: {
    details: boolean;
    create: boolean;
    selected: boolean;
    drivers: boolean;
    delete: boolean;
    download: boolean;
  }
};

const initialState: VehicleTachographPageState = {
  headers: undefined,
  selectedTachograph: undefined,
  drivers: undefined,
  selectedDriverId: undefined,
  isLoading: {
    details: false,
    create: false,
    selected: false,
    drivers: false,
    delete: false,
    download: false,
  }
};



// getTachographsHeaders:
export const getTachographsHeaders = createAsyncThunk('vehicleTachographsPageSlice/getTachographsHeaders', async (
  props: GetVehicleTachographsHeadersProps
) => {
  const data = await RepositoryVehicleTachographs().getTachographRecordsHeaders(props);
  return data;
});


// getTachographRecord:
export const getTachographData = createAsyncThunk('vehicleTachographsPageSlice/getTachographData', async (
  props: GetVehicleTachographRecordsProps
) => {
  const data = await RepositoryVehicleTachographs().getTachographRecord(props);
  return data;
});


// addNewTachographRecord:
type CreateVehicleTachographParams = CreateVehicleTachographRecordsProps & {
  createObject: CreateVehicleTachograph,
  onCompleted?: (_isSuccess: boolean) => void;
}
export const addNewTachographRecord = createAsyncThunk('vehicleTachographsPageSlice/addNewTachographRecord', async (
  props: CreateVehicleTachographParams
) => {
  const data = await RepositoryVehicleTachographs().addNewTachographRecord(
    props.createObject,
    props
  );
  return data;
});


// updateTachographRecord: 
type UpdateVehicleTachographParams = UpdateVehicleTachographRecordProps & {
  updateTachographObject: UpdateVehicleTachograph,
  onCompleted?: (_isSuccess: boolean) => void;
}

export const updateTachographRecord = createAsyncThunk('vehicleTachographsPageSlice/updateTachographRecord', async (
  props: UpdateVehicleTachographParams
) => {
  const data = await RepositoryVehicleTachographs().updateTachographRecord(
    props.updateTachographObject,
    props
  );
  return data;
});

// deleteTachographRecord:
export const deleteTachographRecord = createAsyncThunk('vehicleTachographsPageSlice/deleteTachographRecord', async (
  props: GetVehicleTachographRecordsProps & {
    onCompleted?: (_isSuccess: boolean) => void;
  }
) => {
  const data = await RepositoryVehicleTachographs().deleteTachographRecord(props);
  return data;
});


// download sumery:
export const downloadTachographSummery = createAsyncThunk('vehicleTachographsPageSlice/downloadTachographSummery', async (
  props: GetVehicleTachographsHeadersProps & {
    onCompleted?: (_isSuccess: boolean) => void;
  }
) => {
  const data = await RepositoryVehicleTachographs().downloadTachographSummery(props);
  return data;
});


export const vehicleTachographsPageSlice = createSlice({
  name: 'vehicleTachographsPageSlice',
  initialState,
  reducers: {
    onResetCurrentSelectedTachograph: (state: VehicleTachographPageState) => {
      state.selectedTachograph = undefined;
    },
  },

  extraReducers: (builder) => {

    // getTachographsHeaders
    builder.addCase(getTachographsHeaders.pending, (state) => {
      state.isLoading.details = true;
    });

    builder.addCase(getTachographsHeaders.fulfilled, (state, action) => {
      state.headers = action.payload;
      state.isLoading.details = false;
    });

    builder.addCase(getTachographsHeaders.rejected, (state) => {
      state.isLoading.details = false;
    });

    // getTachographData
    builder.addCase(getTachographData.pending, (state) => {
      state.isLoading.selected = true;
    });

    builder.addCase(getTachographData.fulfilled, (state, action) => {
      state.selectedTachograph = action.payload;
      state.isLoading.selected = false;
    });

    builder.addCase(getTachographData.rejected, (state) => {
      state.isLoading.selected = false;
    });

    // createVehicleTachograph:
    builder.addCase(addNewTachographRecord.pending, (state) => {
      state.isLoading.create = true;
    });

    builder.addCase(addNewTachographRecord.fulfilled, (state, action) => {
      if (action.payload) {
        if (!state.headers) {
          state.headers = [];
        }
        state.headers.push(action.payload);
        action.meta.arg.onCompleted?.(true);
      }
      state.isLoading.create = false;
    });

    builder.addCase(addNewTachographRecord.rejected, (state, action) => {
      state.isLoading.create = false;
      action.meta.arg.onCompleted?.(false);
    });

    // updateVehicleTachograph:
    builder.addCase(updateTachographRecord.pending, (state) => {
      state.isLoading.create = true;
    });

    builder.addCase(updateTachographRecord.fulfilled, (state, action) => {
      if (action.payload) {
        // update the list of Tachographs headers:
        if (state.headers) {
          const index = state.headers.findIndex((header) => header.id === action.payload?.id);
          if (index >= 0) {
            state.headers[index] = {
              id: action.payload.id,
              startDateTime: action.payload.startDateTime,
              endDateTime: action.payload.endDateTime,
              relatedDriverName: action.payload.relatedDriver?.fullName || '',
              isOverspeeding: action.payload.isOverspeeding,
              isOvertime: action.payload.isOvertime,
              totalHours: action.payload.totalHours
            };
          }
          action.meta.arg.onCompleted?.(true);
        }
      }
      state.isLoading.create = false;
    });

    builder.addCase(updateTachographRecord.rejected, (state, action) => {
      state.isLoading.create = false;
      action.meta.arg.onCompleted?.(false);
    });


    // deleteTachographRecord:
    builder.addCase(deleteTachographRecord.pending, (state) => {
      state.isLoading.delete = true;
    });

    builder.addCase(deleteTachographRecord.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.headers) {
          state.headers = state.headers.filter((header) => header.id !== action.meta.arg.tachographId);
        }
        state.selectedTachograph = undefined;
      }
      action.meta.arg.onCompleted?.(true);
      state.isLoading.delete = false;
    });

    builder.addCase(deleteTachographRecord.rejected, (state, action) => {
      state.isLoading.delete = false;
      action.meta.arg.onCompleted?.(false);
    });


    // ==== download Summery ==== //
    builder.addCase(downloadTachographSummery.pending, (state, _action) => {
      state.isLoading.download = true;
    });

    builder.addCase(downloadTachographSummery.fulfilled, (state, action) => {
      downloadFile(action.payload, `${action.meta.arg.vehicleId}`, 'application/pdf')
      state.isLoading.download = false;
    });

    builder.addCase(downloadTachographSummery.rejected, (state, _action) => {
      state.isLoading.download = false;
    });

  },
});

// Export the actions and reducer
export default vehicleTachographsPageSlice.reducer;
export const {
  onResetCurrentSelectedTachograph,
} = vehicleTachographsPageSlice.actions;

import { useState, useCallback } from "react";

// @mui
import { useTheme, alpha } from "@mui/material/styles";

// components
import {
  Button,
  Typography,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  TableBody,
  TableContainer,
} from "@mui/material";

import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../../components/table";
// types

//
import Scrollbar from "../../../../../global/components/scrollbar";
import Label, { LabelColor } from "../../../../../global/components/label";
import LoadingView from "../../../../components/loading-view";
import { TableHeadItem } from "../../../../components/table/TableHeadCustom";
import RichTableFiltersResult, {
  RichTableFilters,
  RichTableFilterValue,
} from "./rich-table-filters-result";
import RichTableToolbar from "./rich-table-toolbar";

// ----------------------------------------------------------------------

const defaultFilters: RichTableFilters = {
  globalSearch: "",
  types: [],
  status: null,
  startDate: null,
  endDate: null,
};

export type RichTableStatusTab = {
  value: string | null;
  label: string;
  color: LabelColor;
  count: number;
};

export type RichTableViewProps<T> = {
  // Titles:
  translate: Function;
  isLoadingData: boolean;
  titleKey?: string;
  tableHeads: TableHeadItem[];
  analyticsView?: JSX.Element;

  // Data:
  filteredTableData: T[];

  // Filters:
  hideToolbar?: boolean;
  statusTabs?: RichTableStatusTab[];
  showStartDate?: boolean;
  showEndDate?: boolean;
  typeFilterOptions?: Map<string, string>;
  onApplyFilters?: (_filters: RichTableFilters, _dateError: boolean) => void;

  randerRow: (_item: T) => JSX.Element;
  hideNoDataView?: boolean;

  // Action Button:
  onActionButtonClicked?: () => void;
  actionButtonLabelKey?: string;
  actionButtonIcon?: JSX.Element;

  overrideActionButtons?: JSX.Element;
};

export default function RichTableView<T>({
  translate,
  isLoadingData,
  titleKey,
  filteredTableData,
  hideToolbar,
  statusTabs,
  showStartDate,
  showEndDate,
  onActionButtonClicked,
  onApplyFilters,
  actionButtonLabelKey,
  actionButtonIcon,
  tableHeads,
  typeFilterOptions,
  randerRow,
  hideNoDataView,
  analyticsView,
  overrideActionButtons,
}: RichTableViewProps<T>) {
  const theme = useTheme();
  const table = useTable({ defaultOrderBy: "createDate" });

  // Filters:
  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const handleFilters = useCallback(
    (name: string, value: RichTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      if (onApplyFilters) {
        onApplyFilters({ ...filters, [name]: value }, dateError);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table]
  );

  const handleFilterStatus = useCallback(
    (_event: React.SyntheticEvent, newValue: string) => {
      handleFilters("status", newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = () => {
    setFilters(defaultFilters);
    if (onApplyFilters) {
      onApplyFilters(defaultFilters, false);
    }
  };

  const canReset =
    !!filters.globalSearch ||
    !!filters.types.length ||
    filters.status !== null ||
    (!!filters.startDate && !!filters.endDate);

  const notFound =
    (!filteredTableData.length && canReset) || !filteredTableData.length;

  // Actions:
  const denseHeight = table.dense ? 56 : 76;
  table.rowsPerPage = 25;

  // Content:
  return (
    <Stack spacing={3} paddingTop={1}>
      <Stack
        justifyContent="space-between"
        direction='row'
      >
        {/* Title */}
        {titleKey ? (
          <Typography variant="h5">{`${translate(titleKey)}`}</Typography>
        ) : (
          <Typography variant="h5"> </Typography>
        )}

        {/* Action Button + Search Bar */}
        <Stack direction="row" alignItems="center" spacing={3}>
          {/* Action Button */}

          {overrideActionButtons === undefined &&
          onActionButtonClicked &&
          actionButtonLabelKey ? (
            <Button
              onClick={() => onActionButtonClicked()}
              variant="contained"
              startIcon={actionButtonIcon}
            >
              {`${translate(actionButtonLabelKey)}`}
            </Button>
          ) : (
            overrideActionButtons && overrideActionButtons
          )}
        </Stack>
      </Stack>

      <>
        {analyticsView && <> {analyticsView} </>}

        <Card>
          {!hideToolbar && statusTabs && (
            <Tabs
              value={filters.status}
              onChange={handleFilterStatus}
              sx={{
                px: 2.5,
                boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
              }}
            >
              {statusTabs?.map((tab) => (
                <Tab
                  key={tab.value}
                  value={tab.value}
                  label={tab.label}
                  iconPosition="end"
                  icon={
                    <Label
                      variant={
                        ((tab.value === null || tab.value === filters.status) &&
                          "filled") ||
                        "soft"
                      }
                      color={tab.color}
                    >
                      {tab.count}
                    </Label>
                  }
                />
              ))}
            </Tabs>
          )}

          {!hideToolbar && typeFilterOptions && (
            <RichTableToolbar
              translate={translate}
              filters={filters}
              onFilters={handleFilters}
              showStartDate={showStartDate}
              showEndDate={showEndDate}
              //
              dateError={dateError}
              typeFilterOptions={typeFilterOptions}
            />
          )}

          {!hideToolbar && canReset && typeFilterOptions && (
            <RichTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              typesMap={typeFilterOptions}
              statusTabs={statusTabs}
              //
              onResetFilters={handleResetFilters}
              //
              results={filteredTableData.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          {isLoadingData ? (
            <LoadingView />
          ) : (
            <TableContainer sx={{ position: "relative", overflow: "unset" }}>
              <Scrollbar>
                <Table
                  size={table.dense ? "small" : "medium"}
                  sx={{ minWidth: 800 }}
                >
                  <TableHeadCustom
                    order={table.order}
                    orderBy={table.orderBy}
                    headLabel={tableHeads}
                    rowCount={filteredTableData.length}
                    numSelected={table.selected.length}
                    onSort={table.onSort}
                  />

                  <TableBody>
                    {filteredTableData
                      .slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => randerRow(row))}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(
                        table.page,
                        table.rowsPerPage,
                        filteredTableData.length
                      )}
                    />
                    {!hideNoDataView && <TableNoData isNotFound={notFound} />}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>
          )}

          <TablePaginationCustom
            labelRowsPerPage={`${translate("global_rows_per_page")}`}
            count={filteredTableData.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
          />
        </Card>
      </>
    </Stack>
  );
}

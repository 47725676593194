import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryDashboard from '../../repositories/RepositoryDashboard';
import { DashboardData, ExpiredVehicleHeader } from '../../../api';

// Define a type for the slice state
export type DashoboardState = {
  isLoading: {
    data: boolean;
    expiredTestVehiclesHeaders: boolean;
  };
  dashboardData?: DashboardData | null;
  expiredReportVehiclesHeaders?: ExpiredVehicleHeader[] | null
};

// Define the initial state using that type
const initialState: DashoboardState = {
  isLoading: {
    data: false,
    expiredTestVehiclesHeaders: false,
  },
  dashboardData: undefined,
};

// Create a createAsyncThunk function to get the clients list from the repository
export const getDashboardData = createAsyncThunk('dashboardSlice/getDashboardData', async () => {
  const data = await RepositoryDashboard().getDashboardData();
  return data;
});

// getExpiredReportVehiclesHeaders:
export const getExpiredReportVehiclesHeaders = createAsyncThunk('dashboardSlice/getExpiredReportVehiclesHeaders', async () => {
  const data = await RepositoryDashboard().getExpiredReportVehiclesHeaders();
  return data;
});

// Define the clients list slice
export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    // Clear when logout:
    clear: () => initialState,
  },
  extraReducers: (builder) => {

    // Dashboard data:
    builder.addCase(getDashboardData.pending, (state: DashoboardState, _action) => {
      state.isLoading.data = true;
      state.dashboardData = null;
    });

    builder.addCase(getDashboardData.fulfilled, (state: DashoboardState, action) => {
      state.dashboardData = action.payload;
      state.isLoading.data = false;
    });

    builder.addCase(getDashboardData.rejected, (state: DashoboardState, _action) => {
      state.dashboardData = null;
      state.isLoading.data = false;
    });

    // Expired report vehicles headers:
    builder.addCase(getExpiredReportVehiclesHeaders.pending, (state: DashoboardState, _action) => {
      state.isLoading.expiredTestVehiclesHeaders = true;
      state.expiredReportVehiclesHeaders = null;
    });

    builder.addCase(getExpiredReportVehiclesHeaders.fulfilled, (state: DashoboardState, action) => {
      state.expiredReportVehiclesHeaders = action.payload?.sort((a, b) => a.licenseNumber.localeCompare(b.licenseNumber));;
      state.isLoading.expiredTestVehiclesHeaders = false;
    });

    builder.addCase(getExpiredReportVehiclesHeaders.rejected, (state: DashoboardState, _action) => {
      state.expiredReportVehiclesHeaders = null;
      state.isLoading.expiredTestVehiclesHeaders = false;
    });
  },
});

// Export the actions and reducer
export default dashboardSlice.reducer;
export const { clear } = dashboardSlice.actions;

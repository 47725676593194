import { KInvoice, KInvoiceStatus } from "src/api";

// @mui
import { useTheme } from "@mui/material/styles";

// components
import {
  Card,
  Stack,
  Divider,
} from "@mui/material";

//
import InvoiceAnalytic from "../invoice-analytic";
import Scrollbar from "../../../../global/components/scrollbar";


export function InvoiceStatisticsView({
    translate,
    data,
    getInvoiceLength,
  }: {
    translate: Function;
    data: KInvoice[];
    getInvoiceLength: (_status: KInvoiceStatus) => number;
  }) {
    const theme = useTheme();
  
    const getAllTotalAmount = () => {
      let total = 0;
      data.forEach((item) => {
        total += Number(item.totalAmount) || 0;
      });
      return total;
    };
  
    const getTotalAmount = (status: KInvoiceStatus) => {
      let total = 0;
      data
        .filter((item) => item.status === status)
        .forEach((item) => {
          total += Number(item.totalAmount) || 0;
        });
  
      return total;
    };
  
    const getPercentByStatus = (status: KInvoiceStatus) =>
      (getInvoiceLength(status) / data.length) * 100;
  
    return (
      <Card
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Scrollbar>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderStyle: "dashed" }}
              />
            }
            sx={{ py: 2 }}
          >
            <InvoiceAnalytic
              translate={translate}
              title={`${translate("invoice_analytics_title_total")}`}
              total={data.length}
              percent={100}
              price={getAllTotalAmount()}
              icon="solar:bill-list-bold-duotone"
              color={theme.palette.info.main}
            />
  
            <InvoiceAnalytic
              translate={translate}
              title={`${translate("invoice_analytics_title_paid")}`}
              total={getInvoiceLength(KInvoiceStatus.PAID)}
              percent={getPercentByStatus(KInvoiceStatus.PAID)}
              price={getTotalAmount(KInvoiceStatus.PAID)}
              icon="solar:file-check-bold-duotone"
              color={theme.palette.success.main}
            />
  
            <InvoiceAnalytic
              translate={translate}
              title={`${translate("invoice_analytics_title_unpaid")}`}
              total={getInvoiceLength(KInvoiceStatus.NOT_PAID)}
              percent={getPercentByStatus(KInvoiceStatus.NOT_PAID)}
              price={getTotalAmount(KInvoiceStatus.NOT_PAID)}
              icon="solar:bell-bing-bold-duotone"
              color={theme.palette.error.main}
            />
          </Stack>
        </Scrollbar>
      </Card>
    );
  }
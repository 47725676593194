import { useEffect, useState } from "react";

// @mui
import { Dialog, DialogProps } from "@mui/material";

import { PostAdd } from "@mui/icons-material";
import { KInvoice } from "src/api";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  addVehicleInvoice,
  clearSlice,
  downloadVehicleInvoice,
  getVehicleInvoices,
  updateVehicleInvoice,
} from "./vehiclePageInvoicesSlice";
import InvoiceListView from "../../invoices/view/invoice-list-view";
import InvoiceAddEditForm from "../../invoices/add/invoice-add-form";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  clientId?: string;
  licenseNumber?: string;
};

export default function VehiclePageInvoices({
  clientId,
  licenseNumber,
  translate,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  const vehicleInvoices = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageInvoicesSlice.data
  );

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageInvoicesSlice.isLoading
  );

  useEffect(() => {
    if (licenseNumber && clientId && vehicleInvoices.length === 0) {
      dispatch(getVehicleInvoices({ clientId, licenseNumber }));
    }

    return () => {
      dispatch(clearSlice())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseNumber, clientId]);

  // Add:
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [currentSlectedInvoice, setCurrentSlectedInvoice] =
    useState<KInvoice | null>(null);

  const handleClose: DialogProps["onClose"] = (_event, _reason) => {
    // if (reason && reason === "backdropClick") return;
    setIsAddEditDialogOpen(false);
  };

  const handleOnFileSelected = (file: File) => {
    setSelectedFile(file);
  };

  const onAddInvoice = async (
    invoiceData: KInvoice
  ): Promise<string | null> => {
    if (licenseNumber && clientId && invoiceData) {
      return new Promise((resolve) => {
        dispatch(
          addVehicleInvoice({
            params: {
              clientId,
              licenseNumber,
              invoiceData,
              file: selectedFile,
            },
            onRespose: (isSuccess: boolean) => {
              if (isSuccess) {
                // dispatch(getVehicleInvoices({ clientId, licenseNumber }));
                setIsAddEditDialogOpen(false);
                resolve(null);
              } else {
                resolve(`${translate("vehicle_invoices_page_error")}`);
              }
            },
          })
        );
      });
    }
    return null;
  };

  const handleInvoceUpdate = async (invoiceData: KInvoice): Promise<string | null> => {
    if (licenseNumber && clientId && invoiceData.id !== undefined) {
      return new Promise((resolve) => {
        dispatch(
          updateVehicleInvoice({
            params: {
              clientId,
              licenseNumber,
              invoiceId: invoiceData.id!,
              invoiceData,
            },
            onRespose: (isSuccess: boolean) => {
              if (isSuccess) {
                setIsAddEditDialogOpen(false);
                resolve(null);
              } else {
                resolve(`${translate("vehicle_invoices_page_error")}`);
              }
            },
          })
        );
      });
    }

    return null;
  };

  const handleOnAddNewClicked = () => {
    setCurrentSlectedInvoice(null);
    setIsAddEditDialogOpen(true);
  };

  const handleOnRowClicked = (vehicleInvoice: KInvoice) => {
    setCurrentSlectedInvoice(vehicleInvoice);
    setIsAddEditDialogOpen(true);
  };

  const onDownloadInvoice = (invoice: KInvoice) => {
    if (licenseNumber && clientId && invoice.id) {
      dispatch(
        downloadVehicleInvoice({
          params: { clientId, licenseNumber, invoiceId: invoice.id },
          onRespose: (responseVehicleInvoice: KInvoice) => {
            if (responseVehicleInvoice.fileUrl != null) {
              window.open(responseVehicleInvoice.fileUrl, "_blank");
            }
          },
        })
      );
    }
  };

  return (
    <>
      <InvoiceListView
        translate={translate}
        isLoadingData={isLoadingData.data}
        invoices={vehicleInvoices || []}
        onRowClicked={handleOnRowClicked}
        titleKey="vehicle_invoices_page_title_invoices_list"
        onActionButtonClicked={handleOnAddNewClicked}
        actionButtonLabelKey="vehicle_invoices_page_title_add_invoice"
        actionButtonIcon={<PostAdd />}
      />

      {/**  Add Dialog  */}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isAddEditDialogOpen}
        onClose={handleClose}
      >
        <InvoiceAddEditForm
          translate={translate}
          currentInvoice={currentSlectedInvoice}
          onAddInvoiceClicked={onAddInvoice}
          onFileSelected={handleOnFileSelected}
          onDownloadFileClicked={onDownloadInvoice}
          onUpdateInvoiceClicked={handleInvoceUpdate}
        />
      </Dialog>
    </>
  );
}

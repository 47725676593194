import { useCallback } from "react";
// @mui
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Iconify from "../../../../../global/components/iconify";
import {
  RichTableFilters,
  RichTableFilterValue,
} from "./rich-table-filters-result";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  filters: RichTableFilters;
  onFilters: (_name: string, _value: RichTableFilterValue) => void;
  //
  showStartDate?: boolean;
  showEndDate?: boolean;
  dateError: boolean;
  typeFilterOptions: Map<string, string>;
};

export default function RichTableToolbar({
  translate,
  filters,
  onFilters,
  //
  dateError,
  showStartDate,
  showEndDate,
  typeFilterOptions,
}: Props) {
  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters("globalSearch", event.target.value);
    },
    [onFilters]
  );

  const handleFilterService = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters(
        "types",
        typeof event.target.value === "string"
          ? event.target.value.split(",")
          : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterStartDate = useCallback(
    (newValue: Date | null) => {
      onFilters("startDate", newValue);
    },
    [onFilters]
  );

  const handleFilterEndDate = useCallback(
    (newValue: Date | null) => {
      onFilters("endDate", newValue);
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: "flex-end", md: "center" }}
        direction={{
          xs: "column",
          md: "row",
        }}
        sx={{
          p: 2.5,
        }}
      >
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 180 },
          }}
        >
          <InputLabel>{`${translate(`rich_table_filter_type_title`)}`}</InputLabel>

          <Select
            multiple
            value={filters.types}
            onChange={handleFilterService}
            input={
              <OutlinedInput
                label={`${translate(`rich_table_filter_type_title`)}`}
              />
            }
            renderValue={(selected) =>
              selected.map((value) => typeFilterOptions.get(value)).join(", ")
            }
            sx={{ textTransform: "capitalize" }}
          >
            {Array.from(typeFilterOptions).map(([type, label]) => (
              <MenuItem key={type} value={type}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={filters.types.includes(type)}
                />
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {showStartDate && (
          <DatePicker
            label={`${translate("rich_table_filter_start_date")}`}
            value={filters.startDate}
            onChange={handleFilterStartDate}
            slotProps={{ textField: { fullWidth: true } }}
            sx={{
              maxWidth: { md: 180 },
            }}
          />
        )}

        {showEndDate && (
          <DatePicker
            label={`${translate("rich_table_filter_end_date")}`}
            value={filters.endDate}
            onChange={handleFilterEndDate}
            slotProps={{
              textField: {
                fullWidth: true,
                error: dateError,
              },
            }}
            sx={{
              maxWidth: { md: 180 },
            }}
          />
        )}

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          flexGrow={1}
          sx={{ width: 1 }}
        >
          <TextField
            fullWidth
            value={filters.globalSearch}
            onChange={handleFilterName}
            placeholder={`${translate("rich_table_filter_title_search")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}

import { Address } from '../../../api';

// Format address to string while making sure that the address is not null or undefined:
export const formatAddress = (address?: Address): string | undefined => {
    if (!address) {
        return undefined;
    }
    
    const { city, street, houseNumber, zipCode } = address;
    
    let result = '';
        
    if (street) {
        result += `${street}`;
    }
    
    if (houseNumber) {
        result += ` ${houseNumber}`;
    }
    
    if (city) {
        result += `, ${city}`;
    }

    if (zipCode) {
        result += ` ${zipCode}`;
    }
    
    return result.length > 0 ? result : undefined;
}
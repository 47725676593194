import { Box, SxProps, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { formatLicensePlateNumber } from "../../../utils/models/vehicleUtils";

export type ViewLicensePlateProps = {
  size?: "tiny" | "small" | "medium" | "large";
  number: string;
  backgroundColor?: string;
  sx?: SxProps;
};

export default function ViewLicensePlate({
  size = "small",
  number,
  backgroundColor,
  sx,
}: ViewLicensePlateProps) {
  let variant: Variant = "h4";
  let px = 2;
  let py = 0.2;
  let borderWidth = 2;

  switch (size) {
    case "tiny":
      variant = "body1";
      borderWidth = 2;
      px = 1;
      py = 0;
      break;
    case "small":
      variant = "body1";
      borderWidth = 2;
      px = 1;
      break;
    case "medium":
      variant = "h4";
      borderWidth = 3;
      break;
    case "large":
      variant = "h2";
      borderWidth = 5;
      break;

    default:
      break;
  }

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: backgroundColor ?? "#FFCB09",
        borderColor: "#000000",
        borderStyle: "solid",
        borderWidth,
        borderRadius: 1,
        width: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant={variant} sx={{ px, py }}>
        {formatLicensePlateNumber(number)}
      </Typography>
    </Box>
  );
}

/* eslint-disable no-unused-vars */
// @mui
import { Theme } from "@mui/material/styles";
import {
  Box,
  SxProps,
  TablePagination,
  TablePaginationProps,
} from "@mui/material";
//

// ----------------------------------------------------------------------

type Props = {
  labelRowsPerPage?: string;
  dense?: boolean;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
};

export default function TablePaginationCustom({
  labelRowsPerPage,
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25, 50],
  sx,
  ...other
}: Props & TablePaginationProps) {
  return (
    <Box sx={{ position: "relative", ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
        labelRowsPerPage={labelRowsPerPage}
        slotProps={{
          actions: {
            nextButton: {
              sx: { rotate: "180deg" },
            },
            previousButton: {
              sx: { rotate: "180deg" },
            }
          },
        }}
      />
    </Box>
  );
}

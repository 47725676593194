import * as katzbatApi from '../../../api';
import { API_URL_BASE, genericAxiosPost, getAxionsInstance } from '../../../global/repositories/RepositoryBase';

import { DocumentUploadImgRequestBody, VehicleDocument, VehicleDocumentType } from '../../../api';


export type VehiclePageDocumentsGetProps = {
  clientId: string,
  licenseNumber: string,
  documentType: VehicleDocumentType,
  documentId: string
}

export interface IRepositoryVehicleDocuments {
  addVehicleDocument: (_params: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    expirationDate: string,
    title: string,
    docuemnt: File
  }) => Promise<boolean>;
  getVehicleDocuments: (_params: {
    clientId: string,
    licenseNumber: string
  }) => Promise<VehicleDocument[] | undefined>;
  getVehicleDocuemntById: (_params: VehiclePageDocumentsGetProps) => Promise<VehicleDocument | undefined>;
  deleteVehicleDocument: (_params: VehiclePageDocumentsGetProps) => Promise<boolean>;
  updateVehicleDocumentExpirationDate: (_params: VehiclePageDocumentsGetProps & {
    expirationDate: string
  }) => Promise<boolean>;
}

export default function RepositoryVehicleDocuments(): IRepositoryVehicleDocuments {

  const apiVehicleDocuments = (clientId?: string) => katzbatApi.VehicleDocumentsApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  const addVehicleDocumentFromPdf = async ({
    clientId,
    licenseNumber,
    documentType,
    expirationDate,
    title,
    docuemnt
  }: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    expirationDate: string,
    title: string,
    docuemnt: File
  }): Promise<boolean> => {
    const formData = new FormData();

    formData.append('file', docuemnt);

    const response = await genericAxiosPost<FormData>(
      '/vehicle/documents/upload_pdf',
      formData,
      {
        clientId,
        licenseNumber,
        documentType,
        expirationDate,
        title
      },
      'json',
      clientId
    );

    return response.status === 200;
  }

  const convertFileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    })

  const addVehicleDocumentFromImage = async ({
    clientId,
    licenseNumber,
    documentType,
    expirationDate,
    title,
    docuemnt
  }: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    expirationDate: string,
    title: string,
    docuemnt: File
  }): Promise<boolean> => {
    // convert image to base64:
    const fileAsBase64 = await convertFileToBase64(docuemnt);
    const prefixToRemove = 'data:image/png;base64,';
    const fileAsBase64WithoutPrefix = fileAsBase64.substring(prefixToRemove.length);

    const requestBody: DocumentUploadImgRequestBody = {
      pagesDataBase64: [fileAsBase64WithoutPrefix]
    }
    const response = await genericAxiosPost<DocumentUploadImgRequestBody>(
      '/vehicle/documents/upload_images',
      requestBody,
      {
        clientId,
        licenseNumber,
        documentType,
        expirationDate,
        title
      },
      'json',
      clientId
    );

    return response.status === 200;
  }


  // === POST: Add vehicle document ===
  const addVehicleDocument = async ({
    clientId,
    licenseNumber,
    documentType,
    expirationDate,
    title,
    docuemnt
  }: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    expirationDate: string,
    title: string,
    docuemnt: File
  }): Promise<boolean> => {
    // get the file extension:
    const fileExtension = docuemnt.name.split('.').pop();
    
    let result = false;
    if (fileExtension === 'pdf') {
      result = await addVehicleDocumentFromPdf({
        clientId,
        licenseNumber,
        documentType,
        expirationDate,
        title,
        docuemnt
      })
    } else {
      result = await addVehicleDocumentFromImage({
        clientId,
        licenseNumber,
        documentType,
        expirationDate,
        title,
        docuemnt
      })
    }

    return result;
  }

  // === GET: Get vehicle documents ===
  const getVehicleDocuments = async ({
    clientId,
    licenseNumber
  }: {
    clientId: string,
    licenseNumber: string
  }): Promise<VehicleDocument[] | undefined> => {
    const resutls = await apiVehicleDocuments(clientId).vehicleDocumentsGetAllGet(clientId, licenseNumber);
    return resutls.data;
  }

  // === GET: Get vehicle document by ID ===
  const getVehicleDocuemntById = async ({
    clientId,
    licenseNumber,
    documentType,
    documentId
  }: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    documentId: string
  }) => {
    const resutls = await apiVehicleDocuments(clientId).vehicleDocumentsGetGet(clientId, licenseNumber, documentType, documentId);
    return resutls.data;
  }

  // === DELETE: Remove vehicle document ===
  const deleteVehicleDocument = async ({
    clientId,
    licenseNumber,
    documentType,
    documentId
  }: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    documentId: string
  }) => {
    const resutls = await apiVehicleDocuments(clientId).vehicleDocumentsDeleteDelete(clientId, licenseNumber, documentType, documentId);
    return resutls.data;
  }

  const updateVehicleDocumentExpirationDate = async ({
    clientId,
    licenseNumber,
    documentType,
    documentId,
    expirationDate
  }: {
    clientId: string,
    licenseNumber: string,
    documentType: VehicleDocumentType,
    documentId: string,
    expirationDate: string
  }) => {
    const resutls = await apiVehicleDocuments(clientId).vehicleDocumentsUpdateExpirationDatePatch(clientId, licenseNumber, documentId, documentType, expirationDate);
    return resutls.data;
  }


  return {
    addVehicleDocument,
    getVehicleDocuments,
    getVehicleDocuemntById,
    deleteVehicleDocument,
    updateVehicleDocumentExpirationDate
  };
}

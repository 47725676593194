import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Folder, Pages } from "@mui/icons-material";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../hooks/useRedux";
import { useLocales } from "../../../global/locales";
import { PATH_DASHBOARD } from "../../routes/paths";

import { DashboardRootState } from "../../app/dashboardAppStore";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";

import CommonLayout from "../common/layout/CommonLayout";
import LoadingView from "../../components/loading-view/LoadingView";
import {
  CommonLayoutPageCover,
  CommonLayoutTab,
} from "../common/layout/types";
import { getCourseData, resetCourseData } from "./clientCoursesPageSlice";
import CourseDetailsPage from "./details/CourseDetailsPage";
import CourseDocumentsPage from "./documents/CourseDocumentsPage";
import { CLIENT_PAGE_TABS_IDS } from "../clientPage/main/ClientPage";

export const COURSE_PAGE_TABS_IDS = {
  info: "info",
  documents: "documents",
};

export default function ClientCoursePage() {
  const { clientId, courseId } = useParams<string>();
  const dispatch = useDashboardAppDispatch();

  const { translate } = useLocales();

  // const isLoadingData = useDashboardAppSelector(
  //   (state: DashboardRootState) => state.clientCoursesPageSlice.isLoading
  // );

  const courseData = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.selectedCourseData
  );

  // Get the data:
  const getData = async () => {
    if (courseId && clientId) {
      dispatch(getCourseData({ clientId, courseId }));
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(resetCourseData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  const TABS: CommonLayoutTab[] = [
    {
      value: COURSE_PAGE_TABS_IDS.info,
      label: `${translate("courses_page_tab_info")}`,
      icon: <Pages />,
      component: (
        <>
          <CourseDetailsPage
            translate={translate}
            course={courseData}
            clientId={clientId}
            onEditCourseComplete={getData}
          />
        </>
      ),
    },

    {
      value: COURSE_PAGE_TABS_IDS.documents,
      label: `${translate("courses_page_tab_documents")}`,
      icon: <Folder />,
      component: (
        <>
          <CourseDocumentsPage
            translate={translate}
            course={courseData}
            clientId={clientId}
          />
        </>
      ),
    },
  ];

  const coverData: CommonLayoutPageCover = {
    title: `${courseData?.title}`,
    subtitle: `${translate(`course_type_title_${courseData?.courseType.toLowerCase()}`)}`,
  };

  return courseData ? (
    <>
      <Helmet>
        <title>
          {`${translate("course_page_title")}`} | {`${courseData.title || ""}`}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("course_page_title")}`}
          links={[
            {
              name: `${translate("global_back_to_client")}`,
              href: `${PATH_DASHBOARD.management.clients.client(
                clientId || "",
                CLIENT_PAGE_TABS_IDS.profile
              )}`,
            },
            {
              name: `${translate("client_page_tab_courses")}`,
              href: `${PATH_DASHBOARD.management.clients.client(
                clientId || "",
                CLIENT_PAGE_TABS_IDS.courses
              )}`,
            },
            {
              name: `${courseData.title}`,
            },
          ]}
        />
        <CommonLayout tabs={TABS} converData={coverData} />
      </Container>
    </>
  ) : (
    <LoadingView showLogo />
  );
}

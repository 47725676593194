
function path(root: string, sublink: string) {
  return `${root}/${sublink}`;
}

// ----------------------------------------------------------------------
const ROOTS_DRIVER = '/driver';
export const PATH_DRIVER = {
  root: ROOTS_DRIVER,
  home: path(ROOTS_DRIVER, 'home'),
  login: path(ROOTS_DRIVER, 'login'),
  onboarding: path(ROOTS_DRIVER, 'onboarding'),
  courses: path(ROOTS_DRIVER, 'courses')
};
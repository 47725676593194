import * as katzbatApi from '../../../api';
import { API_URL_BASE, genericAxiosPost, getAxionsInstance } from '../../../global/repositories/RepositoryBase';

import { AddVehicleInvoiceReqBody, AddVehicleInvoiceReqBodyScannedDocs, KInvoice } from '../../../api';
import { convertFileToBase64 } from '../../utils/fileUtils';


export type GetVehicleInvoicesParams = {
  clientId: string;
  licenseNumber: string;
}

export type DownloadVehicleInvoicesParams = GetVehicleInvoicesParams & {
  invoiceId: string;
}

export type AddVehicleInvoiceParams = {
  clientId: string,
  licenseNumber: string,
  invoiceData: KInvoice,
  file?: File | null
}

export type UpdateVehicleInvoiceParams = GetVehicleInvoicesParams & {
  invoiceId: string;
  invoiceData: KInvoice;
};

export interface IRepositoryVehicleInvoices {
  addVehicleInvoice: (_params: AddVehicleInvoiceParams) => Promise<KInvoice | null>;
  getVehicleInvoices: (_params: GetVehicleInvoicesParams) => Promise<KInvoice[] | undefined>;
  getVehicleInvoiceById: (_params: DownloadVehicleInvoicesParams) => Promise<KInvoice | undefined>;
  updateVehicleInvoice: (_params: UpdateVehicleInvoiceParams) => Promise<KInvoice | undefined>;
}

export default function RepositoryVehicleInvoices(): IRepositoryVehicleInvoices {

  const apiVehicleInvoices = (clientId?: string) => katzbatApi.VehicleInvoicesApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  const addVehicleInvoiceFromPdf = async (params: AddVehicleInvoiceParams): Promise<KInvoice | null> => {
    const { clientId, licenseNumber, invoiceData, file } = params;

    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }

    const response = await genericAxiosPost<FormData>(
      '/vehicle/invoices/upload_pdf',
      formData,
      {
        clientId,
        licenseNumber,
        invoiceData
      },
      'json',
      clientId
    );
    
    return response.data;
  }

  const addVehicleInvoiceFromImage = async (params: AddVehicleInvoiceParams): Promise<KInvoice | null> => {
    const { clientId, licenseNumber, invoiceData, file } = params;

    // convert image to base64:
    const fileAsBase64 = file ? await convertFileToBase64(file) : undefined;
    const prefixToRemove = 'data:image/png;base64,';
    const fileAsBase64WithoutPrefix = fileAsBase64 ? fileAsBase64.substring(prefixToRemove.length) : undefined;

    let scannedDocs: katzbatApi.AddVehicleInvoiceReqBodyScannedDocs | undefined;
    if (fileAsBase64WithoutPrefix) {
      scannedDocs = {
        pagesDataBase64: [fileAsBase64WithoutPrefix]
      } as AddVehicleInvoiceReqBodyScannedDocs
    }

    const response = await apiVehicleInvoices(clientId).vehicleInvoicesUploadImagesPost(
      clientId,
      licenseNumber,
      {
        invoiceData,
        scannedDocs
      } as AddVehicleInvoiceReqBody
    );

    return response.data;
  }


  // === POST: Add vehicle invoice ===
  const addVehicleInvoice = async (params: AddVehicleInvoiceParams): Promise<KInvoice | null> => {
    // get the file extension:
    const fileExtension = params.file?.name.split('.').pop();
    let result = null;
    if (fileExtension === 'pdf') {
      result = await addVehicleInvoiceFromPdf(params)
    } else {
      result = await addVehicleInvoiceFromImage(params)
    }
    return result;
  }

  // === GET: Get vehicle invoices ===
  const getVehicleInvoices = async ({
    clientId,
    licenseNumber
  }: {
    clientId: string,
    licenseNumber: string
  }): Promise<KInvoice[] | undefined> => {
    const resutls = await apiVehicleInvoices(clientId).vehicleInvoicesGetAllGet(clientId, licenseNumber);
    return resutls.data;
  }

  // === GET: Get vehicle invoice by ID ===
  const getVehicleInvoiceById = async ({
    clientId,
    licenseNumber,
    invoiceId
  }: {
    clientId: string,
    licenseNumber: string,
    invoiceId: string
  }) => {
    const resutls = await apiVehicleInvoices(clientId).vehicleInvoicesGetGet(clientId, licenseNumber, invoiceId);
    return resutls.data;
  }

  // === PATCH: Update vehicle invoice ===
  const updateVehicleInvoice = async (params: UpdateVehicleInvoiceParams): Promise<KInvoice | undefined> => {
    const { clientId, licenseNumber, invoiceId, invoiceData } = params;
    const resutls = await apiVehicleInvoices(clientId).vehicleInvoicesUpdatePatch(clientId, licenseNumber, invoiceId, {
      invoiceData
    } as AddVehicleInvoiceReqBody);
    return resutls.data;
  }


  return {
    addVehicleInvoice,
    getVehicleInvoices,
    getVehicleInvoiceById,
    updateVehicleInvoice
  };
}

import * as Yup from "yup";
import { useMemo, useState, useEffect, ChangeEvent } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VehicleDocument, VehicleDocumentType } from "src/api";
import { LoadingButton } from "@mui/lab";
import { Button, Card, Stack, Typography } from "@mui/material";

import { parseISO } from "date-fns";
import { useSnackbar } from "../../../../global/components/snackbar";
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFDatePicker,
} from "../../../../global/components/hook-form";
import {
  genericStringSchema,
  genericRequiredStringSchema,
  genericRequiredDateSchema,
} from "../../../../global/utils/formValidators";

export type VehiclePageDocumentAddEditProps = {
  translate: Function;
  currentVehicleDocument?: VehicleDocument;
  isEdit?: boolean;
  onPreformAction?: (_documentData: VehicleDocument) => Promise<string | null>;
  onComplete?: () => void;
};

export default function VehiclePageDocumentAddEdit({
  translate,
  currentVehicleDocument,
  isEdit = false,
  onPreformAction,
  onComplete,
}: VehiclePageDocumentAddEditProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [listOfAllDocumentTypes, setListOfAllDocumentTypes] = useState<
    VehicleDocumentType[]
  >([]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showMissingFileErorr, setShowMissingFileError] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      setSelectedFile(fileInput.files[0]);
      setShowMissingFileError(false);
    }
  };

  useEffect(() => {
    if (listOfAllDocumentTypes.length > 0) return;
    setListOfAllDocumentTypes(Object.values(VehicleDocumentType));
  }, [listOfAllDocumentTypes]);

  const NewVehicleDocumentSchema = Yup.object().shape({
    title: genericStringSchema(),
    type: genericRequiredStringSchema(translate),
    expirationDate: genericRequiredDateSchema(translate),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentVehicleDocument?.title || "",
      type: currentVehicleDocument?.documentType || VehicleDocumentType.OTHER,
      expirationDate: currentVehicleDocument?.expirationDate ? parseISO(currentVehicleDocument?.expirationDate) : new Date(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVehicleDocument]
  );

  const methods = useForm({
    resolver: yupResolver(NewVehicleDocumentSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: any) => {
    if (!isEdit && selectedFile === null) {
      setShowMissingFileError(true);
      return;
    }

    try {
      if (onPreformAction) {
        const error = await onPreformAction({
          ...data,
          id: currentVehicleDocument?.id,
          documentFile: selectedFile,
          expirationDate: data.expirationDate || "",
        });
        if (!error && onComplete) {
          reset();
          onComplete();
        } else {
          enqueueSnackbar(
            `${translate("error_vehicle_document_add_title")}: ${error}`,
            {
              variant: "error",
            }
          );
        }
        reset();
      }
    } catch (error) {
      enqueueSnackbar(
        `${translate("error_vehicle_document_add_title")}: ${error}`,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, width: 550 }}>
        <Stack justifyContent="space-between">
          <Typography variant="h4">
            {isEdit
              ? `${translate("vehicle_documents_page_title_edit_document")}`
              : `${translate("vehicle_documents_page_title_add_document")}`}
          </Typography>
        </Stack>

        <Stack sx={{ marginTop: 3 }} spacing={3}>
          {/** Title */}
          <RHFTextField
            disabled={isEdit}
            name="title"
            label={`${translate(
              "vehicle_documents_page_title_document_title"
            )}`}
          />

          {/** Type */}
          <RHFSelect
            native
            disabled={isEdit}
            name="type"
            label={`${translate("vehicle_documents_page_title_document_type")}`}
          >
            {listOfAllDocumentTypes.map((type) => (
              <option key={type} value={type}>
                {`${translate(`vehicle_document_type_${type?.toLowerCase()}`)}`}
              </option>
            ))}
          </RHFSelect>

          {/** Expiration Date */}
          <RHFDatePicker
            name="expirationDate"
            label={`${translate(
              "vehicle_documents_page_title_expiration_date"
            )}`}
          />

          {/** File */}
          {!isEdit && (
            <>
              <input
                accept=".jpg,.jpeg,.png,.pdf"
                id="contained-button-file"
                type="file"
                name="pdfFile"
                hidden
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button fullWidth size="large" variant="contained" component="span">
                  {`${translate("vehicle_documents_page_title_upload_file")}`}
                </Button>
              </label>

              {selectedFile && (
                <Typography sx={{ mt: 2 }} textAlign='center'>
                  {`${translate("vehicle_documents_page_title_file_selected")}: ${
                    selectedFile.name
                  }`}
                </Typography>
              )}

              {showMissingFileErorr && (
                <Typography sx={{ mt: 2 }} color="error" textAlign='center'>
                  {`${translate("error_vehicle_document_add_no_file")}`}
                </Typography>
              )}
            </>
          )}
        </Stack>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="success"
            loading={isSubmitting}
          >
            {!isEdit
              ? `${translate("global_add_new")}`
              : `${translate("global_save")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

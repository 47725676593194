import * as katzbatApi from '../../api';
import { API_URL_BASE, genericAxiosPost, getAxionsInstance } from '../../global/repositories/RepositoryBase';
import {
    AddCourseDocumentQueryProps,
    CourseAtendee,
    CourseAtendeeStatus,
    CourseData,
    CourseDocumentBase,
    CourseHeader,
    CreateNewCourseBody,
    DocumentUploadImgRequestBody,
    GetCourseDocumentQueryProps,
    UpdateAttendeeStatusBody,
    UpdateCourseDataBody
} from '../../api';
import { convertFileToBase64 } from '../utils/fileUtils';

export interface IRepositoryCourses {
    // Courses:
    createNewCourse(_clientId: string, _course: CreateNewCourseBody): Promise<CourseData | undefined>;
    getCoursesHeaders(_clientId: string): Promise<CourseHeader[] | undefined>;
    getCourseData(_clientId: string, _courseId: string): Promise<CourseData | undefined>;
    updateCourseData(_clientId: string, _courseId: string, _course: UpdateCourseDataBody): Promise<boolean>;
    downloadCourseSummery(_clientId: string, _courseId: string): Promise<any>;
    downloadAllCoursesSummery(_clientId: string, _from?: string, _courseType?: string, _to?: string): Promise<any>;

    // Attendees:
    getAllClientAvilableAttendees(_clientId: string): Promise<CourseAtendee[] | undefined>;
    getAttendeeData(_clientId: string, _courseId: string, _attendeeId: string): Promise<CourseAtendee | undefined>;
    updateAttendeeStatus(_clientId: string, _courseId: string, _attendeeId: string, _status: CourseAtendeeStatus): Promise<boolean>;
    notifyAttendees(_clientId: string, _courseId: string): Promise<boolean | undefined>;

    // Documents:
    addCourseDocument: (_file: File | null, _docProps: AddCourseDocumentQueryProps) => Promise<CourseDocumentBase | null>;
    addCourseDocumentFromImages: (_file: File, _docProps: AddCourseDocumentQueryProps) => Promise<CourseDocumentBase | null>;
    addCourseDocumentFromPDF: (_file: File, _docProps: AddCourseDocumentQueryProps) => Promise<CourseDocumentBase | null>;
    getCourseDocumentsListHeaders: (_props: katzbatApi.GetCourseDocumentsQueryProps) => Promise<CourseDocumentBase[] | null>;
    getCourseDocumentUrl: (_props: GetCourseDocumentQueryProps) => Promise<string | null>;
    deleteCourseDocument: (_props: GetCourseDocumentQueryProps) => Promise<boolean>;
}

export default function RepositoryCourses(): IRepositoryCourses {
    const apiCourses = (clientId: string) => katzbatApi.CoursesApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

    // Courses:
    const createNewCourse = async (clientId: string, course: CreateNewCourseBody): Promise<CourseData | undefined> => {
        const results = await apiCourses(clientId).coursesCreatePost(clientId, course);
        return results.data;
    }

    const updateCourseData = async (clientId: string, courseId: string, course: UpdateCourseDataBody): Promise<boolean> => {
        const results = await apiCourses(clientId).coursesUpdatePatch(clientId, courseId, course);
        return results.data;
    }

    const getCoursesHeaders = async (clientId: string): Promise<CourseHeader[] | undefined> => {
        const results = await apiCourses(clientId).coursesGetAllHeadersGet(clientId);
        return results.data;
    }

    const getCourseData = async (clientId: string, courseId: string): Promise<CourseData | undefined> => {
        const results = await apiCourses(clientId).coursesGetDataGet(clientId, courseId);
        return results.data;
    }

    const downloadCourseSummery = async (clientId: string, courseId: string): Promise<any> => {
        const resutls = await apiCourses(clientId).coursesGetCourseSummeryDocumentPdfGet(
            clientId,
            courseId,
            {
                responseType: 'arraybuffer'
            });
        return resutls.data;
    }

    const downloadAllCoursesSummery = async (clientId: string, from: string, courseType?: string, to?: string): Promise<any> => {
        const resutls = await apiCourses(clientId).coursesGetAllCourseSummeryDocumentPdfGet(
            clientId,
            from,
            courseType,
            to,
            {
                responseType: 'arraybuffer'
            });
        return resutls.data;
    }


    // Attendees:
    const getAllClientAvilableAttendees = async (clientId: string): Promise<CourseAtendee[] | undefined> => {
        const results = await apiCourses(clientId).coursesGetAvailableAttendeesGet(clientId);
        return results.data;
    }

    const getAttendeeData = async (clientId: string, courseId: string, attendeeId: string): Promise<CourseAtendee | undefined> => {
        const results = await apiCourses(clientId).coursesGetAttendeeDataGet(clientId, courseId, attendeeId);
        return results.data;
    }

    const updateAttendeeStatus = async (clientId: string, courseId: string, attendeeId: string, status: CourseAtendeeStatus): Promise<boolean> => {
        const body = {
            status: status.toString(),
            isSingedByDriver: false,
        } as UpdateAttendeeStatusBody;
        const results = await apiCourses(clientId).coursesUpdateAttendeeStatusPatch(clientId, courseId, attendeeId, body);
        return results.data;
    }

    const notifyAttendees = async (clientId: string, courseId: string): Promise<boolean | undefined> => {
        const results = await apiCourses(clientId).coursesNotifyAttendeesGet(clientId, courseId);
        return results.data;
    }



    // Documents:

    const sendAddCourseDocumentRequest = async <T>(
        docProps: AddCourseDocumentQueryProps, 
        url: string,
        data: T
    ): Promise<CourseDocumentBase | null> => {

        const response = await genericAxiosPost<T>(
            url,
            data,
            docProps,
            'json',
            docProps.clientId
        );

        if (response.status !== 200) {
            return null;
        }

        return response.data;
    }


    const addCourseDocumentFromImages = async (file: File, props: AddCourseDocumentQueryProps): Promise<CourseDocumentBase | null> => {
        const fileAsBase64 = await convertFileToBase64(file);
        const prefixToRemove = 'data:image/png;base64,';
        const fileAsBase64WithoutPrefix = fileAsBase64.substring(prefixToRemove.length);
        const requestBody: DocumentUploadImgRequestBody = {
            pagesDataBase64: [fileAsBase64WithoutPrefix]
        }

        const result = await sendAddCourseDocumentRequest<DocumentUploadImgRequestBody>(
            props,
            '/courses/add_course_document_img',
            requestBody
        );

        return result;
    }

    const addCourseDocumentFromPDF = async (file: File, props: AddCourseDocumentQueryProps): Promise<CourseDocumentBase | null> => {
        const formData = new FormData();
        formData.append('file', file);

        const result = await sendAddCourseDocumentRequest<FormData>(
            props,
            '/courses/add_course_document_pdf',
            formData
        );

        return result;
    }


    const addCourseDocument = async (file: File | null, props: AddCourseDocumentQueryProps): Promise<CourseDocumentBase | null> => {
        if (!file) {
            const result = await sendAddCourseDocumentRequest<null>(
                props,
                '/courses/add_course_document_pdf',
                null
            );

            return result;
        }

        const fileExtension = file.name.split('.').pop();
        let result = null;
        if (fileExtension === 'pdf') {
            result = await addCourseDocumentFromPDF(file, props);
        } else {
            result = await addCourseDocumentFromImages(file, props);
        }

        return result;
    }


    const getCourseDocumentsListHeaders = async (props: katzbatApi.GetCourseDocumentsQueryProps): Promise<CourseDocumentBase[] | null> => {
        const results = await apiCourses(props.clientId).coursesGetCourseDocumentsHeadersGet(props.clientId, props.courseId, props.documentType);
        return results.data;
    }

    const getCourseDocumentUrl = async (props: GetCourseDocumentQueryProps): Promise<string | null> => {
        const results = await apiCourses(props.clientId).coursesGetCourseDocumentUrlGet(props.clientId, props.courseId, props.documentId);
        return results.data;
    }

    const deleteCourseDocument = async (props: GetCourseDocumentQueryProps): Promise<boolean> => {
        const results = await apiCourses(props.clientId).coursesDeleteCourseFileDelete(props.clientId, props.courseId, props.documentId);
        return results.data;
    }


    return {

        // Courses:
        createNewCourse,
        updateCourseData,
        getCoursesHeaders,
        getCourseData,
        downloadCourseSummery,
        downloadAllCoursesSummery,

        // Attendees:
        getAllClientAvilableAttendees,
        getAttendeeData,
        updateAttendeeStatus,
        notifyAttendees,

        // Documents:
        addCourseDocument,
        addCourseDocumentFromImages,
        addCourseDocumentFromPDF,
        getCourseDocumentsListHeaders,
        getCourseDocumentUrl,
        deleteCourseDocument
    }
}
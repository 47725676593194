import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { DashboardData, ExpiredVehicleHeader } from '../../api';

export interface IDashboardRepository {
  getDashboardData: () => Promise<DashboardData | undefined>;
  getClientsHeaders: () => Promise<any>;
  getExpiredReportVehiclesHeaders: () => Promise<ExpiredVehicleHeader[] | null>;
}

export default function RepositoryDashboard(): IDashboardRepository {

  const dashboardApi = katzbatApi.DashboardApiFactory(undefined, API_URL_BASE, getAxionsInstance(undefined, true));

  const getDashboardData = async (): Promise<DashboardData | undefined> => {
    const response = await dashboardApi.dashboardDataGet();
    return response.data;
  };

  const getClientsHeaders = async (): Promise<any> => {
    const response = await dashboardApi.dashboardClientsHeadersGet();
    return response.data;
  }

  const getExpiredReportVehiclesHeaders = async (): Promise<ExpiredVehicleHeader[] | null> => {
    const response = await dashboardApi.dashboardExpiredReportVehiclesHeadersGet();
    return response.data;
  }

  return {
    getDashboardData,
    getClientsHeaders,
    getExpiredReportVehiclesHeaders
  };
}

import * as Yup from "yup";
import { useEffect, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/material";

import { Add } from "@mui/icons-material";
import { DriverUser } from "../../../../api";

// utils
// routes
// components
import FormProvider, {
  RHFSelect,
} from "../../../../global/components/hook-form";
import RepositoryVehicle from "../../../repositories/vehicle/RepositoryVehicle";
import { useSnackbar } from "../../../../global/components/snackbar";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { genericStringSchema } from "../../../../global/utils/formValidators";
import LoadingView from "../../../components/loading-view";
import { CLIENT_PAGE_TABS_IDS } from "../../clientPage/main/ClientPage";

export type VehiclePageLinkDriverProps = {
  clientId: string;
  vehicleLicenseNumber: string;
  clientDrivers: DriverUser[];
  translate: Function;
  onComplete?: Function;
  isLoading?: boolean;
};

export default function VehicleDetailsPageLinkDriver({
  clientId,
  vehicleLicenseNumber,
  clientDrivers,
  translate,
  onComplete,
  isLoading,
}: VehiclePageLinkDriverProps) {
  const { enqueueSnackbar } = useSnackbar();

  const LinkDriverSchema = Yup.object().shape({
    driverId: genericStringSchema(),
  });

  const defaultValues = useMemo(
    () => ({
      driverId: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(LinkDriverSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (clientDrivers) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDrivers]);

  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      const { driverId } = data;
      const result = await RepositoryVehicle().linkDriverToVehicle(
        clientId,
        vehicleLicenseNumber,
        driverId
      );

      if (result) {
        enqueueSnackbar(`${translate("form_message_success_message")}`);

        // Wait for 0.5 second before closing the dialog
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (onComplete) {
          onComplete();
          reset();
        }
      } else {
        enqueueSnackbar(`${translate("error_global_title")}`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${translate("error_global_title")}: ${error}`, {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        {!isLoading ? (
          clientDrivers.length > 0 ? (
            <>
              <Stack justifyContent="space-between">
                <Typography variant="h4">{`${translate(
                  "vehicle_page_related_drivers_add"
                )}`}</Typography>
                <Stack sx={{ marginTop: 1 }}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{ marginBottom: 3 }}
                  >
                    {`${translate(
                      "vehicle_page_related_drivers_add_description"
                    )}`}
                  </Typography>

                  <Box>
                    <RHFSelect
                      native
                      name="driverId"
                      label={`${translate("client_page_driver_name")}`}
                    >
                      <option value="" />
                      {clientDrivers?.map((driver) => (
                        <option key={driver.id} value={driver.id}>
                          {`${driver.fullName} - ${driver.govId}`}
                        </option>
                      ))}
                    </RHFSelect>
                  </Box>
                </Stack>
              </Stack>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate("global_save")}`}
                </LoadingButton>
              </Stack>
            </>
          ) : (
            <Stack spacing={0} alignItems="center" sx={{ p: 1 }}>
              <Typography variant="h6">
                {`${translate("vehicle_page_related_drivers_empty")}`}
              </Typography>
              <Typography variant="body1">
                {`${translate("vehicle_page_related_drivers_empty_description")}`}
              </Typography>
              <Divider />
              <Button
                sx={{ marginTop: 2 }}
                component={RouterLink}
                to={`${PATH_DASHBOARD.management.clients.client(
                  clientId,
                  CLIENT_PAGE_TABS_IDS.drivers
                )}`}
                key={"add_driver"}
                color={"primary"}
                variant="contained"
                startIcon={<Add />}
              >
                {`${translate("client_page_driver_add")}`}
              </Button>
            </Stack>
          )
        ) : (
          <LoadingView />
        )}
      </Card>
    </FormProvider>
  );
}

import { Helmet } from "react-helmet-async";
import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../useAuthContext";
import { UserRole } from "../../../api";

import { useLocales } from "../../../global/locales";
import { StyledContent } from "../../../dashboard/layouts/login/styles";
import Logo from "../../../global/components/logo";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: UserRole[];
  children: React.ReactNode;
};

function AccessDiniedError({
  translate,
  handleLogout,
  handleContactUs,
  errorMessageKey,
}: {
  translate: Function;
  handleLogout: Function;
  handleContactUs: Function;
  errorMessageKey: string;
}) {
  return (
    <>
      <Helmet>
        <title>
          {`${translate("app_name")} | ${translate(
            "global_access_denied_title"
          )}`}
        </title>
      </Helmet>

      <StyledContent>
        <Stack spacing={3} alignItems="center">
          <Logo />
          <Typography
            textAlign={"center"}
            variant="h6"
            color="error"
            gutterBottom
          >
            {`${translate(errorMessageKey)}`}
          </Typography>
          <Button
            fullWidth
            color="primary"
            size="large"
            onClick={() => handleLogout()}
            variant="contained"
          >
            {`${translate("logout_title")}`}
          </Button>
          <Button
            fullWidth
            color="primary"
            size="large"
            onClick={() => handleContactUs()}
            variant="outlined"
          >
            {`${translate("global_contact_us")}`}
          </Button>
        </Stack>
      </StyledContent>
    </>
  );
}

export function RoleBasedGuard({
  hasContent = true,
  roles,
  children,
}: RoleBasedGuardProp) {
  const { user, logout } = useAuthContext();
  const { translate } = useLocales();
  const navigate = useNavigate();
  
  const handleLogout = async () => {
    try {
      logout();
      navigate("www.katzbat.co.il", { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  const handleContactUs = () => {
    try {
      navigate("https://katzbat.co.il/website/#contact", { replace: true });
    } catch (error) {
      console.error(error);
    }
  }

  const currentRole = user?.firebaseRole as UserRole;

  // Override roles if user is SUPER_USER:
  if (currentRole === UserRole.SUPER_USER) {
    return <> {children} </>;
  }

  if (typeof roles !== "undefined" && !roles.includes(currentRole)) {
    return hasContent ? (
      <AccessDiniedError
        errorMessageKey={"global_access_denied_subtitle"}
        translate={translate}
        handleLogout={handleLogout}
        handleContactUs={handleContactUs}
      />
    ) : null;
  }

  if (currentRole === UserRole.USER_MAIN || currentRole === UserRole.USER_MAIN_CHILD) {
    const stripeRole = user?.stripeRole;
    if (stripeRole === null || stripeRole === undefined) {
      // Redirect to checkout & payment page:
      // navigate(PATH_AUTH.login, { replace: true });
      return hasContent ? (
        <AccessDiniedError
          errorMessageKey={"global_access_denied_subtitle_missing_payments"}
          translate={translate}
          handleLogout={handleLogout}
          handleContactUs={handleContactUs}
        />
      ) : null;
    }
  }

  return <> {children} </>;
}

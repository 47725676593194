// @mui
import { Dialog, Typography, TableRow, TableCell, Card } from "@mui/material";

// _mock
import { useState, useEffect } from "react";
// components
import { Add } from "@mui/icons-material";
import ClientDriversAddEdit from "../ClientDriversAddEdit";
// components
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { getClientDriversFromApi } from "../../clientPage/main/clientPageSlice";
import GenericMessageView from "../../common/views/GenericMessageView";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../../common/views/tableView/ViewGenericDataTable";
import { DriverUser } from "../../../../api";
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";

// ----------------------------------------------------------------------

type DriversListRowProps = {
  row: DriverUser;
  onRowClick?: Function;
  // translate: Function;
};

function DriversListRow({
  row,
  onRowClick, // translate,
}: DriversListRowProps) {
  const { id, govId, fullName, licenseNumber } = row;

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(id);
    }
  };

  return (
    <>
      <TableRow hover onClick={handleRowClick} sx={{ cursor: "pointer" }}>
        {/* Name */}
        <TableCell>
          <Typography variant="subtitle2" noWrap color={"text"}>
            {fullName}
          </Typography>
        </TableCell>

        {/* govId */}
        <TableCell align="center">{govId}</TableCell>

        {/* License number */}
        <TableCell align="center">{licenseNumber}</TableCell>

        {/* Phone number */}
        <TableCell align="center">{row.phoneNumber}</TableCell>
      </TableRow>
    </>
  );
}

// ----------------------------------------------------------------------

type Props = {
  clientId?: string;
  translate: Function;
  navigate: Function;
};

export default function ClientDrivers({
  clientId,
  translate,
  navigate,
}: Props) {
  const dispatch = useDashboardAppDispatch();
  const { logEvent } = useAnalyticsContext();

  // const [searchDataValue, setSearchDataValue] = useState("");

  const driversData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.drivers
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.isLoading
  );

  // Get the data:
  useEffect(() => {
    if (clientId) {
      dispatch(getClientDriversFromApi(clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  const handleAddClicked = () => {
    setIsAddDialogOpen(true);
    logEvent("client_page_driver_add_clicked");
  };
  const handleClose = () => {
    setIsAddDialogOpen(false);
  };

  const onAddDriversComplete = () => {
    if (clientId) {
      dispatch(getClientDriversFromApi(clientId));
      handleClose();
    }
  };

  const handleRowClick = (driverId: string) => {
    if (clientId) {
      navigate(
        PATH_DASHBOARD.management.clients.driver.path(clientId, driverId)
      );
    }
  };

  const renderRow = (row: DriverUser) => (
    <DriversListRow
      key={row.id}
      row={row}
      onRowClick={handleRowClick}
      // translate={translate}
    />
  );

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "fullName",
      label: `${translate("client_page_driver_name")}`,
      align: "left",
    },
    {
      id: "govId",
      label: `${translate("global_id_number")}`,
      align: "center",
    },
    {
      id: "licenseNumber",
      label: `${translate("client_page_driver_license")}`,
      align: "center",
    },
    {
      id: "phoneNumber",
      label: `${translate("global_phone_number")}`,
      align: "center",
    },
  ];

  const driversListData: ViewGenericDataTableData<DriverUser> = {
    isLoading: isLoading.drivers,
    translate,
    titleKey: "client_page_tab_drivers",
    data: driversData,
    renderRow,
    tableHead: TABLE_HEAD,
    onActionButtonClicked: handleAddClicked,
    actionButtonLabelKey: "client_page_driver_add",
    actionButtonIcon: <Add />,
    searchable: true,
    searchLabelKey: "client_page_search_drivers",
    emptyStateComponent: (
      <GenericMessageView
        title={`${translate("error_drivers_list_empty_title")}`}
        subtitle={`${translate("error_drivers_list_empty_subtitle")}`}
        buttonTitle={`${translate("client_page_driver_add")}`}
        onAction={handleAddClicked}
      />
    ),
  };

  return (
    <>
      <Card sx={{ px: 3 }}>
        <ViewGenericDataTable {...driversListData} />
      </Card>

      {clientId && (
        <Dialog fullWidth maxWidth="lg" open={isAddDialogOpen} onClose={handleClose}>
          <ClientDriversAddEdit
            clientId={clientId}
            translate={translate}
            onComplete={onAddDriversComplete}
          />
        </Dialog>
      )}
    </>
  );
}

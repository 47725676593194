import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocales } from "../../../global/locales";
import Logo from "../../../global/components/logo";
import {
  useDriversAppDispatch,
  useDriversAppSelector,
} from "../../hooks/useRedux";
import {
  getDriverOnboarding,
} from "../../app/driverAppSlice";
import { DriversRootState } from "../../app/driversAppStore";
import { PATH_DRIVER } from "../../routes/paths";
import LoadingView from "../../../dashboard/components/loading-view";


export default function DriverMainPage() {
  const navigate = useNavigate();
  
  const { translate } = useLocales();
  const dispatch = useDriversAppDispatch();

  const driverUser = useDriversAppSelector(
    (state: DriversRootState) => state.driverAppSlice.driverOnboardingData
  );

  useEffect(() => {
    if (!driverUser) {
      dispatch(getDriverOnboarding());
    } else if (driverUser.isOnboardingCompleted === true) {
      navigate(PATH_DRIVER.courses, {
        replace: true,
      });
    } else {
      navigate(PATH_DRIVER.onboarding, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUser]);

  return (
    <>
      <Helmet>
        <title>
          {`${translate("app_name")} | ${translate("login_title")}`}
        </title>
      </Helmet>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        sx={{
          minHeight: "100vh",
          padding: 5,
        }}
      >
        {/** Logo */}
        <Logo disabledLink />

        {/** Contnet */}
        <LoadingView />

        {/** Footer */}
        <Stack
          spacing={1}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            {`${translate("driver_page_login_copyright_text")}`}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

import { useEffect, useState } from "react";

import { Dialog } from "@mui/material";

import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  addNewTachographRecord,
  deleteTachographRecord,
  downloadTachographSummery,
  getTachographData,
  getTachographsHeaders,
  onResetCurrentSelectedTachograph,
  updateTachographRecord,
} from "./vehicleTachographsPageSlice";
import {
  CreateVehicleTachograph,
  UpdateVehicleTachograph,
  VehicleTachographHeader,
} from "../../../../api";
import VehicleTachographsTable from "./VehicleTachographsTable";
import VehicleTachographAddEdit from "./VehicleTachographAddEdit";

type Props = {
  clientId?: string;
  licenseNumber?: string;
  translate: Function;
};

const todayDate = new Date();

export default function VehicleTachographsPage({
  clientId,
  licenseNumber,
  translate,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  const incidentsTachographsData = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehicleTachographsPageSlice.headers
  );

  const selectedTachographData = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.vehicleTachographsPageSlice.selectedTachograph
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehicleTachographsPageSlice.isLoading
  );

  // Selected driver id filter:
  const driversData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.drivers
  );

  const [selectedDriverId, setSelectedDriverId] = useState<string | undefined>(
    ""
  );

  // Add:
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);

  // Date states:
  const [currentSelectedDate, setCurrentSelectedDate] =
    useState<Date>(todayDate);
  const [preSelectedDate, setPreSelectedDate] = useState<Date>(todayDate);

  // Get the data:
  useEffect(() => {
    if (clientId && licenseNumber) {
      dispatch(
        getTachographsHeaders({
          clientId,
          vehicleId: licenseNumber,
          fromDate: new Date(currentSelectedDate).toDateString(),
          toDate: new Date(currentSelectedDate).toDateString(),
          relatedDriverId: selectedDriverId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, licenseNumber, currentSelectedDate, selectedDriverId]);

  // Clicks handlers:
  const handleOnTachographClicked = (header: VehicleTachographHeader) => {
    if (clientId && licenseNumber && header.id) {
      dispatch(
        getTachographData({
          clientId,
          vehicleId: licenseNumber,
          date: header.startDateTime,
          tachographId: header.id,
        })
      );
      setIsAddEditDialogOpen(true);
    }
  };

  const handleOnAddNewClicked = () => {
    dispatch(onResetCurrentSelectedTachograph());
    setIsAddEditDialogOpen(true);
  };

  const handleOnDownloadClicked = () => {
    if (clientId && licenseNumber) {
      dispatch(
        downloadTachographSummery({
          clientId,
          vehicleId: licenseNumber,
          fromDate: new Date(currentSelectedDate).toDateString(),
          toDate: new Date(currentSelectedDate).toDateString(),
          relatedDriverId: selectedDriverId,
        })
      );
    }
  };

  const handleOnCalendarDayClicked = (date: Date) => {
    dispatch(onResetCurrentSelectedTachograph());
    setPreSelectedDate(date);
    setIsAddEditDialogOpen(true);
  };

  const handleOnMonthSkip = (isNext: boolean) => {
    const newDate = new Date(currentSelectedDate);
    newDate.setMonth(
      isNext
        ? currentSelectedDate.getMonth() + 1
        : currentSelectedDate.getMonth() - 1
    );
    setCurrentSelectedDate(newDate);
  };

  // Data change handlers:
  const handleCreateNewTachographRecord = (
    createObject: CreateVehicleTachograph,
    relatedDriverId: string
  ) => {
    if (clientId && licenseNumber) {
      dispatch(
        addNewTachographRecord({
          createObject,
          clientId,
          vehicleId: licenseNumber,
          relatedDriverId,
          onCompleted: (isSuccess: boolean) => {
            if (isSuccess) {
              setIsAddEditDialogOpen(false);
            }
          },
        })
      );
    }
  };

  const handleUpdateTachographRecord = (
    updateObject: UpdateVehicleTachograph,
    relatedDriverId?: string
  ) => {
    if (selectedTachographData && clientId && licenseNumber) {
      dispatch(
        updateTachographRecord({
          updateTachographObject: updateObject,
          clientId,
          vehicleId: licenseNumber,
          date: selectedTachographData.startDateTime,
          tachographId: selectedTachographData.id,
          relatedDriverId:
            relatedDriverId || selectedTachographData.relatedDriverId,
          onCompleted: (isSuccess: boolean) => {
            if (isSuccess) {
              setIsAddEditDialogOpen(false);
            }
          },
        })
      );
    }
  };

  const handleDeleteTachographRecord = () => {
    if (selectedTachographData && clientId && licenseNumber) {
      dispatch(
        deleteTachographRecord({
          clientId,
          vehicleId: licenseNumber,
          date: selectedTachographData.startDateTime,
          tachographId: selectedTachographData.id,
          onCompleted: (isSuccess: boolean) => {
            if (isSuccess) {
              setIsAddEditDialogOpen(false);
            }
          },
        })
      );
    }
  };

  return (
    <>
      <VehicleTachographsTable
        translate={translate}
        tachographsHeaders={incidentsTachographsData}
        isLoadingData={isLoading.details}
        isLoadingCreate={isLoading.create}
        isLoadingDownload={isLoading.download}
        selectedDate={currentSelectedDate}
        driversList={driversData}
        selectedDriverId={selectedDriverId}
        handleOnDateChanged={setCurrentSelectedDate}
        setSelectedDriverId={setSelectedDriverId}
        onTachographClicked={handleOnTachographClicked}
        handleOnCalendarDayClicked={handleOnCalendarDayClicked}
        handleOnAddNewClicked={handleOnAddNewClicked}
        handleOnDownloadClicked={handleOnDownloadClicked}
        handleOnMonthSkip={handleOnMonthSkip}
      />

      {/**  Add Dialog  */}
      {clientId && licenseNumber && (
        <Dialog
          maxWidth="lg"
          open={isAddEditDialogOpen}
          onClose={() => setIsAddEditDialogOpen(false)}
        >
          <VehicleTachographAddEdit
            translate={translate}
            isLoadingCreate={isLoading.create}
            isLoadingSelected={isLoading.selected}
            isLoadingDelete={isLoading.delete}
            currentTachographData={selectedTachographData}
            driversList={driversData}
            preSelectedDate={preSelectedDate}
            onCreateNewTachographRecord={handleCreateNewTachographRecord}
            onUpdateTachographRecord={handleUpdateTachographRecord}
            onDeleteClicked={handleDeleteTachographRecord}
          />
        </Dialog>
      )}
    </>
  );
}

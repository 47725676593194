import * as katzbatApi from '../../../api';
import { API_URL_BASE, getAxionsInstance } from '../../../global/repositories/RepositoryBase';

import { VehicleTachographHeader, CreateVehicleTachograph, VehicleTachograph, UpdateVehicleTachograph } from '../../../api';

export interface IRepositoryVehicleTachographs {
  addNewTachographRecord: (_createObject: CreateVehicleTachograph, _props: CreateVehicleTachographRecordsProps) => Promise<VehicleTachographHeader | undefined>;
  updateTachographRecord: (_updateObject: UpdateVehicleTachograph, _props: UpdateVehicleTachographRecordProps) => Promise<VehicleTachograph | undefined>;
  getTachographRecord: (_props: GetVehicleTachographRecordsProps) => Promise<VehicleTachograph | undefined>;
  getTachographRecordsHeaders: (_props: GetVehicleTachographsHeadersProps) => Promise<VehicleTachographHeader[] | undefined>;
  deleteTachographRecord: (_props: GetVehicleTachographRecordsProps) => Promise<boolean | undefined>;
  downloadTachographSummery: (_props: GetVehicleTachographsHeadersProps) => Promise<any>;
}

export default function RepositoryVehicleTachographs(): IRepositoryVehicleTachographs {

  const apiVehicleTachographs = (clientId?: string) => katzbatApi.VehicleTachographApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  const addNewTachographRecord = async (createObject: CreateVehicleTachograph, props: CreateVehicleTachographRecordsProps): Promise<VehicleTachographHeader | undefined> => {
    console.log('addNewTachographRecord', props);
    const results = await apiVehicleTachographs(props.clientId).vehicleTachographCreateRecordPost(
      props.clientId, 
      props.vehicleId, 
      props.relatedDriverId, 
      createObject
    );
    return results.data;
  }

  const updateTachographRecord = async (updateObject: UpdateVehicleTachograph, props: UpdateVehicleTachographRecordProps): Promise<VehicleTachograph | undefined> => {
    const results = await apiVehicleTachographs(props.clientId).vehicleTachographUpdateRecordPatch(
      props.clientId, 
      props.vehicleId, 
      props.date,
      props.tachographId,
      props.relatedDriverId,
      updateObject
    );
    return results.data;
  }

  const getTachographRecord = async (props: GetVehicleTachographRecordsProps): Promise<VehicleTachograph | undefined> => {
    const results = await apiVehicleTachographs(props.clientId).vehicleTachographGetDataGet(
      props.clientId, 
      props.vehicleId, 
      props.date,
      props.tachographId
    );
    return results.data;
  }

  const getTachographRecordsHeaders = async (props: GetVehicleTachographsHeadersProps): Promise<VehicleTachographHeader[] | undefined> => {
    const results = await apiVehicleTachographs(props.clientId).vehicleTachographGetHeadersGet(
      props.clientId, 
      props.vehicleId, 
      props.fromDate,
      props.toDate,
      props.relatedDriverId
    );
    return results.data;
  }

  const deleteTachographRecord = async (props: GetVehicleTachographRecordsProps): Promise<boolean | undefined> => {
    const results = await apiVehicleTachographs(props.clientId).vehicleTachographDeleteDelete(
      props.clientId, 
      props.vehicleId, 
      props.date,
      props.tachographId
    );
    return results.status === 200;
  }
  
  const downloadTachographSummery = async (props: GetVehicleTachographsHeadersProps): Promise<any> => {
    const resutls = await apiVehicleTachographs(props.clientId).vehicleTachographDownloadSummeryPdfGet(
        props.clientId,
        props.vehicleId,
        props.fromDate,
        props.toDate,
        props.relatedDriverId,
        {
            responseType: 'arraybuffer'
        });
    return resutls.data;
}


  return {
    addNewTachographRecord,
    updateTachographRecord,
    getTachographRecord,
    getTachographRecordsHeaders,
    deleteTachographRecord,
    downloadTachographSummery
    
  };
}

export type CreateVehicleTachographRecordsProps = {
  clientId: string,
  vehicleId: string,
  relatedDriverId: string
}

export type GetVehicleTachographRecordsProps = {
  clientId: string,
  vehicleId: string,
  date: string,
  tachographId: string,
}

export type UpdateVehicleTachographRecordProps = GetVehicleTachographRecordsProps & {
  relatedDriverId?: string
};

export type GetVehicleTachographsHeadersProps = {
  clientId: string,
  vehicleId: string,
  fromDate: string,
  toDate: string,
  relatedDriverId?: string
}

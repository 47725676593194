// @mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

import { VehicleIncidentHeader } from "../../../../../api";

import { fDate } from "../../../../utils/format/formatTime";
import { formatAddress } from "../../../../utils/format/formatAddress";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  row: VehicleIncidentHeader;
  onViewRow: VoidFunction;
};

export default function IncidentsListViewRowItem({
  translate,
  row,
  onViewRow,
}: Props) {
  const { title, dateTime, address, relatedDriver } =
    row;

  const globalNoData = `${translate("global_no_data")}`;

  return (
    <>
      <TableRow hover onClick={onViewRow} sx={{ cursor: "pointer" }}>
        {/** title */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {title}
              </Typography>
            }
          />
        </TableCell>


        {/** dateTime */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {dateTime ? fDate(dateTime) : globalNoData}
              </Typography>
            }
          />
        </TableCell>

        {/** address */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                { formatAddress(address) ||  globalNoData }
              </Typography>
            }
          />
        </TableCell>

        {/** relatedDriver */}
        <TableCell>
        <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {relatedDriver || globalNoData}
              </Typography>
            }
          />
        </TableCell>

      </TableRow>
    </>
  );
}

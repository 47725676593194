import { useState } from "react";

// @mui
import { Edit } from "@mui/icons-material";
import {
  Dialog,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from "@mui/material";

import { DriverDetails } from "../../../drivers/details/DriverDetailsPage";
import { VehicleIncident } from "../../../../../api";
import FullWithDataView from "../../../common/views/FullWithDataView";
import { fDateTime } from "../../../../utils/format/formatTime";
import VehicleIncidentsAddEdit from "../addEdit/VehicleIncidentsAddEdit";
import { formatAddress } from "../../../../utils/format/formatAddress";

// ----------------------------------------------------------------------
export const IncidentDetailEntry = ({
  translate,
  titleKey,
  value,
}: {
  translate: Function;
  titleKey: string;
  value: string | undefined;
}) => {
  const globalNoData = `${translate("global_no_data")}`;
  return (
    <Stack spacing={1}>
      <Typography variant="h6">{translate(`${titleKey}`)}</Typography>
      <Typography variant="body1">{value || globalNoData}</Typography>
    </Stack>
  );
};

type Props = {
  clientId: string | undefined;
  licenseNumber: string | undefined;
  translate: Function;
  incidentData: VehicleIncident | undefined;
};

export default function IncidentDetailsPage({
  clientId,
  licenseNumber,
  translate,
  incidentData,
}: Props) {
  // const dispatch = useDashboardAppDispatch();

  const globalNoData = `${translate("global_no_data")}`;

  // const isLoading = useDashboardAppSelector(
  //   (state: DashboardRootState) => state.vehicleIncidentsPageSlice.isLoading
  // );

  // Add:
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  const IncidentDetails = () => {
    if (!incidentData) {
      return <></>;
    }

    const data = [
      {
        title: `${translate("incident_details_page_title_name")}`,
        value: incidentData.title ?? globalNoData,
      },
      {
        title: `${translate("incident_details_page_title_date_time")}`,
        value: incidentData.dateTime
          ? fDateTime(incidentData.dateTime)
          : globalNoData,
      },
      {
        title: `${translate("incident_details_page_title_address")}`,
        value: incidentData.address
          ? formatAddress(incidentData.address)
          : globalNoData,
      },
    ];

    // const handleDownloadCoursePdf = () => {
    //   // dispatch(
    //   // );
    // };

    return (
      <Stack spacing={3}>
        {/** Incident Data */}
        <FullWithDataView
          title={`${translate("incident_details_page_title")}`}
          itemsPerRow={3}
          data={data}
          translate={translate}
          actions={[
            {
              title: `${translate("incident_details_page_edit_data")}`,
              icon: <Edit />,
              color: "success",
              onClick: () => {
                setIsEditDialogOpen(true);
              },
            },
            // {
            //   title: `${translate("incident_details_page_download_pdf")}`,
            //   icon: !isLoading.download ? (
            //     <Download />
            //   ) : (
            //     <CircularProgress color={"primary"} size={16} thickness={5} />
            //   ),
            //   onClick: handleDownloadCoursePdf,
            //   isDisabled: isLoading.download,
            // },
          ]}
        />

        {/** Driver Data */}
        {incidentData?.relatedDriver && (
          <DriverDetails
            driver={incidentData?.relatedDriver}
            translate={translate}
          />
        )}

        {/** Incident Details and Notes */}
        <FullWithDataView
          title={`${translate(`incident_details_page_title_details`)}`}
          itemsPerRow={1}
          translate={translate}
          overrideContent={
            <Stack spacing={3}>
              {/** Incident description */}
              <IncidentDetailEntry
                titleKey={"incident_details_page_title_description"}
                value={incidentData.description}
                translate={translate}
              />

              {/** Incident reason */}
              <IncidentDetailEntry
                titleKey={"incident_details_page_title_reason"}
                value={incidentData.reason}
                translate={translate}
              />

              {/** Incident conclusion */}
              <IncidentDetailEntry
                titleKey={"incident_details_page_title_conclusion"}
                value={incidentData.conclusion}
                translate={translate}
              />

              {/** Incident policeNotes */}
              <IncidentDetailEntry
                titleKey={"incident_details_page_title_policeNotes"}
                value={incidentData.policeNotes}
                translate={translate}
              />

              {/** Incident notes */}
              <IncidentDetailEntry
                titleKey={"incident_details_page_title_notes"}
                value={incidentData.notes}
                translate={translate}
              />
            </Stack>
          }
        />

        {/** Incident Images */}
        {incidentData.photosUrls && incidentData.photosUrls.length > 0 && (
          <FullWithDataView
            title={`${translate(`incident_details_page_title_images`)}`}
            itemsPerRow={1}
            translate={translate}
            overrideContent={
              <ImageList
                // sx={{ width: 500, height: 450 }}
                gap={20}
                cols={3}
              >
                {incidentData.photosUrls.map((imgUrl) => (
                  <ImageListItem key={imgUrl}>
                    <a href={`${imgUrl}`} target="_blank" rel="noreferrer">
                      <img
                        srcSet={imgUrl}
                        src={imgUrl}
                        alt={imgUrl}
                        loading="lazy"
                        style={{ borderRadius: "15px" }}
                      />
                    </a>
                  </ImageListItem>
                ))}
              </ImageList>
            }
          />
        )}
      </Stack>
    );
  };

  return incidentData ? (
    <>
      <Stack spacing={3}>
        {/** Vehicle details */}
        <IncidentDetails />
      </Stack>

      {/**  Edit Dialog  */}
      {clientId && licenseNumber && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
        >
          <VehicleIncidentsAddEdit
            clientId={clientId}
            vehicleId={licenseNumber}
            translate={translate}
            currentIncidentData={incidentData}
            onCloseClicked={() => setIsEditDialogOpen(false)}
            onComplete={() => setIsEditDialogOpen(false)}
          />
        </Dialog>
      )}
    </>
  ) : (
    <></>
  );
}

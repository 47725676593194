



export const formatKmString = (value: number | undefined, translate: Function): string => {
    if (value === undefined) {
        return translate('global_no_data');
    }

    return `${value} ${translate('global_unit_km')}`;
}
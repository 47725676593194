import { useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import { Container, Stack, Typography } from "@mui/material";
//
import { KInvoice, KInvoiceItem } from "src/api";
import {
  genericAnySchema,
  genericRequiredDateSchema,
  genericRequiredStringSchema,
  genericStringSchema,
  numberWithDecimalOnlySchema,
  phoneNumberSchema,
} from "../../../../global/utils/formValidators";
import FormProvider from "../../../../global/components/hook-form";
import InvoiceAddAddress from "./invoice-add-address";
import InvoiceAddStatusDate from "./invoice-add-status-date";
import InvoiceAddDetails from "./invoice-add-details";

// ----------------------------------------------------------------------

type InvoiceAddFormProps = {
  translate: Function;
  currentInvoice?: KInvoice | null;
  onAddInvoiceClicked: (_invoice: KInvoice) => Promise<string | null>;
  onUpdateInvoiceClicked: (_invoice: KInvoice) => Promise<string | null>;
  onFileSelected: (_file: File) => void;
  onDownloadFileClicked: (_invoice: KInvoice) => void;
};

export default function InvoiceAddEditForm({
  translate,
  currentInvoice,
  onAddInvoiceClicked,
  onUpdateInvoiceClicked,
  onFileSelected,
  onDownloadFileClicked,
}: InvoiceAddFormProps) {

  const [isViewEditMode, setIsViewEditMode] = useState<boolean>(currentInvoice !== undefined && currentInvoice !== null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const NewInvoiceSchema = Yup.object().shape({
    invoiceNumber: genericRequiredStringSchema(translate),
    issuedDate: genericRequiredDateSchema(translate),
    dueDate: genericRequiredDateSchema(translate),

    // not required
    notes: genericStringSchema(),
    status: Yup.string(),
    type: Yup.string(),
    taxes: numberWithDecimalOnlySchema(translate),
    discount: numberWithDecimalOnlySchema(translate),
    shipping: numberWithDecimalOnlySchema(translate),
    subTotal: numberWithDecimalOnlySchema(translate),
    totalAmount: numberWithDecimalOnlySchema(translate),
    invoiceFromName: genericStringSchema(),
    invoiceFromAddress: genericAnySchema(),
    invoiceFromPhone: phoneNumberSchema(),
    items: Yup.mixed(),
  });

  const defaultValues = {
    invoiceNumber: currentInvoice?.invoiceNumber || "",
    issuedDate: new Date(),
    dueDate: new Date(),

    // not required
    notes: currentInvoice?.notes || "",
    taxes: currentInvoice?.taxes,
    status: currentInvoice?.status || "",
    type: currentInvoice?.type || "",
    discount: currentInvoice?.discount,
    shipping: currentInvoice?.shipping,
    subTotal: currentInvoice?.subTotal,
    totalAmount: currentInvoice?.totalAmount,
    invoiceFromName: currentInvoice?.invoiceFromName || "",
    invoiceFromAddress: currentInvoice?.invoiceFromAddress || "",
    invoiceFromPhone: currentInvoice?.invoiceFromPhone || "",
    items: currentInvoice?.items || [],
  };

  const methods = useForm({
    resolver: yupResolver(NewInvoiceSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleCreateOrUpdate = handleSubmit(async (data) => {
    try {
      const newInvoiceData = {
        id: currentInvoice?.id,
        invoiceNumber: data.invoiceNumber,
        notes: data.notes,
        issuedDate: data.issuedDate.toISOString(),
        dueDate: data.dueDate.toISOString(),
        taxes: data.taxes as number,
        status: data.status,
        type: data.type,
        shipping: data.shipping as number,
        discount: data.discount as number,
        subTotal: data.subTotal as number,
        totalAmount: data.totalAmount as number,
        items: data.items?.map((item: KInvoiceItem) => ({
          ...item,
          price: item.price as number
        })),
        invoiceFromName: data.invoiceFromName,
        invoiceFromAddress: data.invoiceFromAddress,
        invoiceFromPhone: data.invoiceFromPhone,
      } as KInvoice;
      
      let uploadError = null;
      if (isViewEditMode) {
        uploadError = await onUpdateInvoiceClicked(newInvoiceData);
      } else {
        uploadError = await onAddInvoiceClicked(newInvoiceData);
      }

      if (!uploadError) {
        setIsViewEditMode(true);
      }

      setErrorMessage(uploadError);
    } catch (error) {
      console.error(error);
      setErrorMessage(error);
    }
    
  });

  const handleDownloadFileClicked = () => {
    if (currentInvoice) {
      onDownloadFileClicked(currentInvoice);
    }
  }

  return (
    <Container sx={{ py: 4 }}>
      <FormProvider methods={methods}>
        <Stack spacing={2}>
          <InvoiceAddAddress
            translate={translate}
            viewEditMode={isViewEditMode}
            hasScannedFile={currentInvoice?.includedScan === true}
            onFileSelected={onFileSelected}
            onDownloadFileClicked={handleDownloadFileClicked}
          />

          <InvoiceAddStatusDate
            translate={translate}
            viewEditMode={isViewEditMode}
          />

          <InvoiceAddDetails
            translate={translate}
            viewEditMode={isViewEditMode}
          />
        </Stack>

        {errorMessage && (
          <Typography
            variant="body2"
            sx={{ color: "error.main", mt: 2, textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
        )}

        <Stack
          justifyContent="flex-end"
          direction="row"
          spacing={2}
          sx={{ mt: 3 }}
        >
          <LoadingButton
            size="large"
            variant="contained"
            loading={isSubmitting}
            onClick={handleCreateOrUpdate}
          >
            {isViewEditMode ? translate("inovice_add_update") : translate("invoice_add_title")}
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Container>
  );
}

import { useEffect, useMemo, useState } from "react";

import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Card, Stack, Box, Typography, Grid } from "@mui/material";
import { useSnackbar } from "../../../global/components/snackbar";

import FormProvider, {
  RHFDatePicker,
  RHFTextField,
  RHFAutocomplete,
  RHFCheckbox,
} from "../../../global/components/hook-form";
import {
  CourseAtendee,
  CourseData,
  CourseStatus,
  CourseType,
  CreateNewCourseBody,
  UpdateCourseDataBody,
} from "../../../api/api";
import {
  genericAnySchema,
  genericDateSchema,
  genericRequiredDateSchema,
  genericRequiredStringSchema,
} from "../../../global/utils/formValidators";
import RepositoryCourses from "../../repositories/RepositoryCourses";
import { AtendeesListRow } from "./details/AtendeesListRow";
import { CustomTableHeadProps } from "../common/views/tableView/SearchableTable";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../common/views/tableView/ViewGenericDataTable";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../hooks/useRedux";
import { DashboardRootState } from "../../app/dashboardAppStore";
import { getAllClientAvilableAttendees } from "./clientCoursesPageSlice";
import { getCourseStatusColor } from "./list/CoursesListView";
import Label from "../../../global/components/label";

function ClientCoursesAddForm({
  isEditMode,
  isLoading,
  translate,
  isSubmitting,
  allCoursesAtendees,
  selectedAtendeesIds,
  onAttendeeSelected,
  isAllSelected,
}: {
  isEditMode: boolean;
  isLoading: boolean;
  translate: Function;
  isSubmitting: boolean;
  allCoursesAtendees: CourseAtendee[] | undefined;
  selectedAtendeesIds: string[] | undefined;
  onAttendeeSelected(_attendee: CourseAtendee): void;
  isAllSelected: boolean;
}) {
  const renderRow = (row: CourseAtendee) => (
    <AtendeesListRow
      showCheckbox
      key={row.id}
      row={row}
      onRowClick={onAttendeeSelected}
      translate={translate}
      isSelected={selectedAtendeesIds?.includes(row.id)}
      disabled={isAllSelected}
    />
  );

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "checkbox",
      label: `${translate("global_select")}`,
      align: "center",
    },
    {
      id: "fullName",
      label: `${translate("course_details_page_atendee_name")}`,
      align: "left",
    },
    {
      id: "govId",
      label: `${translate("global_id_number")}`,
      align: "center",
    },
    {
      id: "phoneNumber",
      label: `${translate("global_phone_number")}`,
      align: "center",
    },
  ];

  const atendeesListData: ViewGenericDataTableData<CourseAtendee> = {
    isLoading,
    translate,
    titleKey: "client_page_course_ateendees_title",
    data: allCoursesAtendees || [],
    renderRow,
    tableHead: TABLE_HEAD,
    searchable: true,
    searchLabelKey: "course_details_page_atendee_search",
  };

  return (
    <Stack>
      <Stack direction={"row"} justifyContent="space-between">
        <Stack>
          <Typography variant="h4">{`${translate(isEditMode ? "client_page_course_edit" : "client_page_course_add")}`}</Typography>
          <Typography variant="body2">{`${translate("client_page_course_subtitle")}`}</Typography>
        </Stack>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ minWidth: 120 }}
        >
          {`${translate(isEditMode ? "global_save" : "global_add_new")}`}
        </LoadingButton>
      </Stack>

      <Grid container spacing={3}>
        <Grid item xs>
          <Stack>
            <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
              {`${translate("client_page_course_details")}`}
            </Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(2, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFTextField
                name="courseName"
                label={`${translate("client_page_course_name")}`}
              />

              <RHFAutocomplete
                name="courseType"
                label={`${translate("client_page_course_type")}`}
                options={Object.values(CourseType)}
                placeholder={`${translate("client_page_course_type")}`}
                getOptionLabel={(option) =>
                  `${translate(`course_type_title_${option.toLowerCase()}`)}`
                }
                isOptionEqualToValue={(option, value) => option === value}
                renderOption={(props, option) => (
                  <li {...props}>
                    {`${translate(`course_type_title_${option.toLowerCase()}`)}`}
                  </li>
                )}
              />

              <RHFDatePicker
                name="startedAt"
                label={`${translate("client_page_course_startedAt")}`}
              />

              <RHFDatePicker
                name="endedAt"
                label={`${translate("client_page_course_endedAt")}`}
              />

              {isEditMode && (
                <RHFAutocomplete
                  name="courseStatus"
                  label={`${translate("courses_table_head_title_status")}`}
                  options={Object.values(CourseStatus)}
                  placeholder={`${translate("courses_table_head_title_status")}`}
                  getOptionLabel={(option) =>
                    `${translate(`course_status_title_${option.toLowerCase()}`)}`
                  }
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Label
                        variant="soft"
                        color={getCourseStatusColor(option)}
                      >
                        {`${translate(`course_status_title_${option.toLowerCase()}`)}`}
                      </Label>
                    </li>
                  )}
                />
              )}

              {!isEditMode && (
                <RHFCheckbox
                  sx={{ marginTop: 0 }}
                  name="includeAllAtendees"
                  label={`${translate("client_page_course_add_all_ateendees")}`}
                />
              )}
            </Box>
          </Stack>
        </Grid>


        <Grid item xs>
          <Box>
            <ViewGenericDataTable {...atendeesListData} />
          </Box>
        </Grid>

      </Grid>
    </Stack>
  );
}

export type ClientCoursesAddEditProps = {
  currentCourseData?: CourseData;
  clientId: string;
  translate: Function;
  onComplete?: Function;
};

export default function ClientCoursesAddEdit({
  currentCourseData,
  clientId,
  translate,
  onComplete,
}: ClientCoursesAddEditProps) {
  const isEditMode = Boolean(currentCourseData);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDashboardAppDispatch();

  const allClientAvilableAttendees = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.allClientAvilableAttendees
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientCoursesPageSlice.isLoading.getAllClientAvilableAttendees
  );

  const [selectedAtendeesIds, setSelectedAtendeesIds] = useState<string[]>(
    currentCourseData?.atendees?.map((attendee) => attendee.id) || []
  );

  // Get the data:
  useEffect(() => {
    if (clientId) {
      dispatch(getAllClientAvilableAttendees(clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const NewCourseSchema = Yup.object().shape({
    courseName: genericRequiredStringSchema(translate),
    courseType: genericRequiredStringSchema(translate),
    startedAt: genericRequiredDateSchema(translate),
    endedAt: genericDateSchema(),
    includeAllAtendees: genericAnySchema(),
    courseStatus: genericAnySchema(),
  });

  const defaultValues = useMemo(
    () => ({
      courseName: currentCourseData?.title || "",
      courseType: currentCourseData?.courseType || CourseType.OTHER,
      startedAt: currentCourseData?.startedAt
        ? new Date(currentCourseData?.startedAt)
        : new Date(),
      endedAt: currentCourseData?.endedAt
        ? new Date(currentCourseData?.endedAt)
        : undefined,
      includeAllAtendees: false,
      courseStatus: currentCourseData?.status || CourseStatus.NEW,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewCourseSchema),
    defaultValues,
  });

  const {
    // reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const includeAllAtendees = methods.watch("includeAllAtendees");

  const onSubmit = async (data: any) => {
    try {
      let result;
      if (!isEditMode) {
        const courseData: CreateNewCourseBody = {
          title: data.courseName,
          courseType: data.courseType,
          startedAt: data.startedAt.toISOString(),
          endedAt: data.endedAt?.toISOString() || null,
          includeAllAtendees: data.includeAllAtendees,
          atendeesIds: selectedAtendeesIds,
        };

        result = await RepositoryCourses().createNewCourse(
          clientId,
          courseData
        );
      } else {
        // Edit mode:
        const updateCourseData: UpdateCourseDataBody = {
          title: data.courseName,
          courseType: data.courseType,
          startedAt: data.startedAt.toISOString(),
          endedAt: data.endedAt?.toISOString() || null,
          atendeesIds: selectedAtendeesIds,
          status: data.courseStatus,
        };

        result = await RepositoryCourses().updateCourseData(
          clientId,
          currentCourseData?.id || "",
          updateCourseData
        );
      }

      if (result) {
        enqueueSnackbar(`${translate("form_message_success_message")}`);

        // Wait for 1 second before closing the dialog
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (onComplete) {
          onComplete();
        }
      } else {
        enqueueSnackbar(`${translate("error_course_add_title")}`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${translate("error_course_add_title")}`, {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <ClientCoursesAddForm
          isEditMode={isEditMode}
          isLoading={isLoading}
          isAllSelected={includeAllAtendees === true}
          translate={translate}
          isSubmitting={isSubmitting}
          allCoursesAtendees={allClientAvilableAttendees}
          selectedAtendeesIds={selectedAtendeesIds}
          onAttendeeSelected={(attendee: CourseAtendee) => {
            setSelectedAtendeesIds((prev) => {
              const index = prev.indexOf(attendee.id);
              if (index === -1) {
                return [...prev, attendee.id];
              }
              return prev.filter((_, i) => i !== index);
            });
          }}
        />
      </Card>
    </FormProvider>
  );
}

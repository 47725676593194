import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { useSnackbar } from "../../../../global/components/snackbar";
import {
  VehicleManufacturer,
  Vehicle,
  VehicleType,
  DriverUser,
  VehicleOwnerType,
} from "../../../../api/api";
import {
  getVehicleOwnerTypeLabelKey,
  getVehicleTypeLabelKey,
} from "../../../utils/models/typesUtils";
import { useDashboardAppSelector } from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";

// utils
// routes
// components
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "../../../../global/components/hook-form";
import RepositoryVehicle from "../../../repositories/vehicle/RepositoryVehicle";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";
import {
  genericRequiredStringSchema,
  genericStringSchema,
  numberOnlySchema,
  numberOnlyStringSchemaRequired,
} from "../../../../global/utils/formValidators";
import {
  getManufacturersBasedOnVehicleType,
  getModelsBasedOnManufacturer,
} from "../../../utils/models/vehicleUtils";

export type ClientVehiclesAddEditProps = {
  clientId: string;
  isEdit?: boolean;
  currentVehicle?: Vehicle;
  drivers?: DriverUser[];
  translate: Function;
  onComplete?: Function;
};

export default function ClientVehiclesAddEdit({
  clientId,
  isEdit = false,
  currentVehicle,
  drivers,
  translate,
  onComplete,
}: ClientVehiclesAddEditProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = useAnalyticsContext();

  const vehicleConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig.vehicleConfig
  );

  const [showDriverInfo, setShowDriverInfo] = useState(
    (currentVehicle?.relatedDrivers?.length || 0) > 0
  );

  const handleHasDriverInfo = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setShowDriverInfo(checked);
  };

  const defaultValues = useMemo(
    () => ({
      licenseNumber: currentVehicle?.licenseNumber || "",
      makeId: currentVehicle?.makeId || "",
      modelId: currentVehicle?.modelId || "",
      type: currentVehicle?.type || VehicleType.CAR,
      buildYear: currentVehicle?.buildYear?.toString() || "",
      vin: currentVehicle?.vin || "",
      lastOdometer: currentVehicle?.lastOdometer || 0,
      color: currentVehicle?.color || "",
      internalId: currentVehicle?.internalId || "",
      ownerType: currentVehicle?.ownerType || VehicleOwnerType.OTHER,
      permenentDriverId: currentVehicle?.relatedDrivers?.[0] || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentVehicle]
  );

  const NewVehicleSchema = Yup.object().shape({
    licenseNumber: numberOnlyStringSchemaRequired(translate),
    makeId: genericRequiredStringSchema(translate),
    modelId: genericRequiredStringSchema(translate),
    type: genericRequiredStringSchema(translate),
    buildYear: numberOnlyStringSchemaRequired(translate),
    vin: genericStringSchema(),
    lastOdometer: numberOnlySchema(translate),
    color: genericStringSchema(),
    internalId: genericStringSchema(),
    ownerType: genericRequiredStringSchema(translate),
  });

  const methods = useForm({
    resolver: yupResolver(NewVehicleSchema),
    defaultValues,
  });

  // List of manufacturers:
  const [manufacturers, setManufacturers] = useState<VehicleManufacturer[]>([]);
  const [models, setModels] = useState<VehicleManufacturer[]>([]);
  const make = methods.watch("makeId");

  // All VehicleType:
  const selectedVehicleType = methods.watch("type");
  useEffect(() => {
    if (selectedVehicleType && vehicleConfig) {
      methods.setValue("makeId", "");
      methods.setValue("modelId", "");
      setModels([]);
      setManufacturers(
        getManufacturersBasedOnVehicleType(vehicleConfig, selectedVehicleType)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicleType]);

  useEffect(() => {
    if (make && vehicleConfig) {
      setModels(
        getModelsBasedOnManufacturer(vehicleConfig, selectedVehicleType, make)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [make]);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentVehicle) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentVehicle]);

  // Submit
  const onSubmit = async (data: any) => {
    try {
      const vehicle: Vehicle = {
        licenseNumber: data.licenseNumber,
        makeId: data.makeId,
        modelId: data.modelId,
        type: data.type,
        buildYear: parseInt(data.buildYear, 10),
        vin: data.vin,
        lastOdometer: data.lastOdometer,
        color: data.color,
        internalId: data.internalId,
        ownerType: data.ownerType
      };

      if (!isEdit && showDriverInfo && data.permenentDriverId) {
        vehicle.relatedDrivers = [data.permenentDriverId];
      } else {
        vehicle.relatedDrivers = currentVehicle?.relatedDrivers;
      }

      let result;
      if (!isEdit) {
        result = await RepositoryVehicle().addVehicle(clientId, vehicle);
      } else {
        result = await RepositoryVehicle().updateVehicle(clientId, vehicle);
      }

      if (result) {
        logEvent("client_page_vehicle_add_success", {
          item_id: vehicle.licenseNumber,
          isEdit,
        });

        enqueueSnackbar(
          !isEdit
            ? `${translate("form_message_success_message")}`
            : `${translate("form_message_update_success_message")}`
        );

        // Wait for 1 second before closing the dialog
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (onComplete) {
          onComplete();
          reset();
        }
      } else {
        enqueueSnackbar(`${translate("error_vehicle_add_title")}`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${translate("error_vehicle_add_title")}: ${error}`, {
        variant: "error",
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Stack justifyContent="space-between">
          <Typography variant="h4">
            {isEdit
              ? `${translate("client_page_vehicle_edit")}`
              : `${translate("client_page_vehicle_add")}`}
          </Typography>
          <Stack sx={{ marginTop: 3 }}>
            <Typography variant="h6" gutterBottom sx={{ marginBottom: 3 }}>
              {`${translate("client_page_vehicle_details")}`}
            </Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFTextField
                disabled={isEdit}
                name="licenseNumber"
                label={`${translate("client_page_vehicle_id")}`}
                type="number"
              />
              <RHFTextField
                name="buildYear"
                label={`${translate("client_page_vehicle_year")}`}
                type="number"
              />

              <RHFTextField
                name="vin"
                label={`${translate("client_page_vehicle_vin")}`}
              />

              <RHFSelect
                native
                name="type"
                label={`${translate("client_page_vehicle_type")}`}
              >
                {Object.values(VehicleType).map(
                  (type) =>
                    type && (
                      <option key={type} value={type}>
                        {`${translate(getVehicleTypeLabelKey(type))}`}
                      </option>
                    )
                )}
              </RHFSelect>

              <RHFSelect
                native
                name="ownerType"
                label={`${translate("client_page_vehicle_owner_type")}`}
              >
                {Object.values(VehicleOwnerType).map((type) => (
                  <option key={type} value={type}>
                    {`${translate(getVehicleOwnerTypeLabelKey(type))}`}
                  </option>
                ))}
              </RHFSelect>

              <RHFSelect
                native
                name="makeId"
                label={`${translate("client_page_vehicle_make")}`}
              >
                <option value="" />
                {manufacturers.map((car) => (
                  <option key={car.value} value={car.value}>
                    {car.text}
                  </option>
                ))}
              </RHFSelect>

              {selectedVehicleType === VehicleType.CAR ||
              selectedVehicleType === VehicleType.TAXI ||
              selectedVehicleType === VehicleType.COMMERCIAL ? (
                <RHFSelect
                  native
                  name="modelId"
                  label={`${translate("client_page_vehicle_model")}`}
                >
                  <option value="" />
                  {models.map((model) => (
                    <option key={model.value} value={model.value}>
                      {model.text}
                    </option>
                  ))}
                </RHFSelect>
              ) : (
                <RHFTextField
                  name="modelId"
                  label={`${translate("client_page_vehicle_model")}`}
                />
              )}

              <RHFTextField
                name="lastOdometer"
                label={`${translate("client_page_vehicle_last_odometer")}`}
                type="number"
              />

              <RHFTextField
                name="color"
                label={`${translate("client_page_vehicle_color")}`}
              />

              <RHFTextField
                name="internalId"
                label={`${translate("client_page_vehicle_internal_id")}`}
              />

            </Box>

            {/* Driver details if needed: */}
            {!isEdit && (
              <Stack
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      name="hasDriverInfo"
                      onChange={handleHasDriverInfo}
                      checked={showDriverInfo}
                    />
                  }
                  label={`${translate("form_input_has_permenent_driver")}`}
                  sx={{ marginTop: 3, marginBottom: 3 }}
                />

                {showDriverInfo && (
                  <>
                    {drivers && drivers.length > 0 ? (
                      <RHFSelect
                        native
                        name="permenentDriverId"
                        label={`${translate(
                          "form_input_has_permenent_driver_select"
                        )}`}
                      >
                        <option value="" />
                        {drivers.map((driver) => (
                          <option key={driver.id} value={driver.id}>
                            {`${driver.fullName} - ${driver.govId}`}
                          </option>
                        ))}
                      </RHFSelect>
                    ) : (
                      <Typography variant="body2">
                        {`${translate("error_no_drivers_found_title")}`}
                      </Typography>
                    )}
                  </>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {!isEdit
              ? `${translate("global_add_new")}`
              : `${translate("global_save")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryVehicleDocuments from '../../../repositories/vehicle/RepositoryVehicleDocuments';
import { VehicleDocument, VehicleDocumentType } from '../../../../api';

export type VehiclePageDocumentsState = {
  data: VehicleDocument[];
  isLoading : {
    data: boolean;
    documentsUpdate: boolean;
  }
};

const initialState: VehiclePageDocumentsState = {
  data: [],
  isLoading: {
    data: false,
    documentsUpdate: false
  }
};

// Vehicle documents:
type getVehicleDocumentsParams = {
  clientId: string;
  licenseNumber: string;
}

export const getVehicleDocuments = createAsyncThunk('vehiclePageSlice/getVehicleDocuments', async ({ clientId, licenseNumber }: getVehicleDocumentsParams) => {
  const data = await RepositoryVehicleDocuments().getVehicleDocuments({
    clientId,
    licenseNumber
  });
  return data;
});

type downloadVehicleDocumentsParams = getVehicleDocumentsParams & {
  documentType: VehicleDocumentType;
  documentId: string;
  onRespose: (_data: VehicleDocument) => void;
}

export const downloadVehicleDocument = createAsyncThunk('vehiclePageSlice/downloadVehicleDocument', async ({ clientId, licenseNumber, documentType, documentId }: downloadVehicleDocumentsParams) => {
  const data = await RepositoryVehicleDocuments().getVehicleDocuemntById({
    clientId,
    licenseNumber,
    documentType,
    documentId
  });

  return data;
});


type deleteVehicleDocumentsParams = getVehicleDocumentsParams & {
  documentType: VehicleDocumentType;
  documentId: string;
  onRespose: (_isSuccess: boolean) => void;
};
export const deleteVehicleDocument = createAsyncThunk('vehiclePageSlice/deleteVehicleDocument', async ({ clientId, licenseNumber, documentType, documentId, onRespose }: deleteVehicleDocumentsParams) => {
  const data = await RepositoryVehicleDocuments().deleteVehicleDocument({
    clientId,
    licenseNumber,
    documentType,
    documentId
  });
  onRespose(data);
});

type updateVehicleDocumentExpirationDateParams = getVehicleDocumentsParams & {
  documentType: VehicleDocumentType;
  documentId: string;
  expirationDate: string;
  onRespose: (_isSuccess: boolean) => void;
};

export const updateVehicleDocumentExpirationDate = createAsyncThunk('vehiclePageSlice/updateVehicleDocumentExpirationDate', async ({ 
  clientId, 
  licenseNumber, 
  documentType, 
  documentId, 
  expirationDate, 
  onRespose 
}: updateVehicleDocumentExpirationDateParams) => {
  const data = await RepositoryVehicleDocuments().updateVehicleDocumentExpirationDate({
    clientId,
    licenseNumber,
    documentType,
    documentId,
    expirationDate
  });
  onRespose(data);
});


export type AddVehicleDocumentParams = getVehicleDocumentsParams & {
  documentType: VehicleDocumentType;
  title: string;
  expirationDate: string;
  file: File;
  onRespose: (_isSuccess: boolean) => void;
};

export const addVehicleDocument = createAsyncThunk('vehiclePageSlice/addVehicleDocument', async ({ clientId, licenseNumber, documentType, title, expirationDate, file, onRespose }: AddVehicleDocumentParams) => {
  try {
    const isSuccess = await RepositoryVehicleDocuments().addVehicleDocument({
      clientId,
      licenseNumber,
      documentType,
      expirationDate,
      title,
      docuemnt: file
    });
    onRespose(isSuccess);
  } catch (error) {
    onRespose(false);
  }
});



export const vehiclePageDocumentsSlice = createSlice({
  name: 'vehiclePageDocumentsSlice',
  initialState,
  reducers: {
    resetVehicleDocuments: (state: VehiclePageDocumentsState) => {
      state.data = [];
      state.isLoading = {
        data: false,
        documentsUpdate: false
      };
    }
  },
  extraReducers: (builder) => {


    // vehicle documents:
    builder.addCase(getVehicleDocuments.pending, (state: VehiclePageDocumentsState, _action) => {
      state.isLoading.data = true;
    });

    builder.addCase(getVehicleDocuments.fulfilled, (state: VehiclePageDocumentsState, action) => {
      if (action.payload) {
        // Arragne the documents by date:
        action.payload.sort((a, b) => {
          if (a.expirationDate && b.expirationDate) {
            return new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime();
          }
          return 0;
        });
        state.data = action.payload;
      }
      state.isLoading.data = false;
    });

    builder.addCase(getVehicleDocuments.rejected, (state: VehiclePageDocumentsState, _action) => {
      state.data = [];
      state.isLoading.data = false;
    });

    // update vehicle document expiration date:
    builder.addCase(updateVehicleDocumentExpirationDate.pending, (state: VehiclePageDocumentsState, _action) => {
      state.isLoading.documentsUpdate = true;
    });

    builder.addCase(updateVehicleDocumentExpirationDate.fulfilled, (state: VehiclePageDocumentsState, _action) => {
      state.isLoading.documentsUpdate = false;
    });

    builder.addCase(updateVehicleDocumentExpirationDate.rejected, (state: VehiclePageDocumentsState, _action) => {
      state.isLoading.documentsUpdate = false;
    });

    // downloadVehicleDocument:
    builder.addCase(downloadVehicleDocument.pending, (_state: VehiclePageDocumentsState, _action) => {
      // state.isLoading.data = true;
    });

    builder.addCase(downloadVehicleDocument.fulfilled, (state: VehiclePageDocumentsState, action) => {
      if (action.payload != null) {
        action.meta.arg.onRespose(action.payload);
      }
      // state.isLoading.data = false;
    });

    builder.addCase(downloadVehicleDocument.rejected, (_state: VehiclePageDocumentsState, _action) => {
      // state.isLoading.data = false;
    });
  },
});

// Export the actions and reducer
export default vehiclePageDocumentsSlice.reducer;
export const { resetVehicleDocuments } = vehiclePageDocumentsSlice.actions;
import { useState } from "react";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";

import { Add } from "@mui/icons-material";
import {
  Address,
  DriverDocumentCreateReq,
  DriverDocumentFineData,
  DriverDocumentsTypes,
  DriverUser,
  VehicleHeader,
} from "../../../../api";
import FullWithDataView, {
  DataViewEntry,
} from "../../common/views/FullWithDataView";
import { KDatePicker } from "../../../../global/components/hook-form/RHFDatePicker";
import { fDateIsoString } from "../../../utils/format/formatTime";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";

type DriverDocumentsSelectItem = {
  title: string;
  value: string;
};

type Props = {
  clientId?: string;
  driver?: DriverUser | null;
  clientVehicles?: VehicleHeader[] | null;
  documentTypes?: string[] | null;
  translate: Function;
  handleGenerateDoc: (
    _driverDocumentCreateReq: DriverDocumentCreateReq
  ) => void;
};

export default function AddDriverDocuments({
  clientId,
  driver,
  clientVehicles,
  documentTypes,
  translate,
  handleGenerateDoc,
}: Props) {
  const [userSignature, setUserSignature] = useState(
    driver?.isOnboardingCompleted
  );

  const { logEvent } = useAnalyticsContext();

  const VEHILCE_TYPE_OTHER = "OTHER";

  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [documentDate, setDocumentDate] = useState<string>("");
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null);
  const [vehicleIdToUse, setVehicleIdToUse] = useState<string | null>(null);
  const [fineNumber, setFineNumber] = useState<string | undefined>();
  const [fineReceivedDate, setfineReceivedDate] = useState<string | undefined>();
  const [fineViolationDate, setFineViolationDate] = useState<string | undefined>();
  const [judgeName, setJudgeName] = useState<string | undefined>();
  const [courtLocation, setCourtLocation] = useState<string | undefined>();
  const [sendToAddress, setSendToAddress] = useState<Address>({
    street: "",
    city: "",
    zipCode: "",
    houseNumber: "",
    additionalAddress: "",
  });

  const DriverDocumentsSelect = ({
    id,
    labelKey,
    currentValue,
    options,
    showOtherOption,
    onSelect,
  }: {
    id: string;
    labelKey: string;
    currentValue: string;
    showOtherOption?: boolean;
    options: DriverDocumentsSelectItem[];
    onSelect: (_value: string) => void;
  }) => (
    <FormControl fullWidth>
      <InputLabel id={labelKey}>{`${translate(labelKey)}`}</InputLabel>
      <Select
        labelId={labelKey}
        id={id}
        value={currentValue}
        label={`${translate(labelKey)}`}
        onChange={(event) => {
          onSelect(event.target.value as string);
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}

        {/** Other option */}
        {showOtherOption && (
          <MenuItem value={VEHILCE_TYPE_OTHER}>{`${translate(
            "global_other"
          )}`}</MenuItem>
        )}
      </Select>
    </FormControl>
  );

  const [sendToName, setSendToName] = useState<string>("");
  const [sendToAddressDetails, setSendToAddressDetails] =
    useState<boolean>(false);

  const data: DataViewEntry[] = [
    // TYPE:
    {
      overrideElement: (
        <DriverDocumentsSelect
          id="driver_documents_generate_title_type"
          labelKey="driver_documents_generate_title_type"
          currentValue={selectedType || ""}
          options={
            documentTypes?.map((type) => ({
              title: `${translate(
                `driver_document_title_${type.toLowerCase()}`
              )}`,
              value: type,
            })) || []
          }
          onSelect={setSelectedType}
        />
      ),
    },

    // DATE:
    {
      overrideElement: (
        <KDatePicker
          sx={{ width: "100%" }}
          label={`${translate("driver_documents_generate_date")}`}
          value={documentDate || ""}
          onValueChanged={(newValue) => {
            setDocumentDate(newValue);
          }}
        />
      ),
    },
  ];

  if (selectedType !== DriverDocumentsTypes.LAW_168) {
    data.push({
      overrideElement: (
        <DriverDocumentsSelect
          id="driver_documents_generate_title_vehicle"
          labelKey="driver_documents_generate_title_vehicle"
          currentValue={selectedVehicle || ""}
          options={
            clientVehicles?.map((vehicle) => ({
              title: `${vehicle.licenseNumber}`,
              value: vehicle.licenseNumber,
            })) || []
          }
          onSelect={(value) => {
            setSelectedVehicle(value);
            if (value !== VEHILCE_TYPE_OTHER) {
              setVehicleIdToUse(value);
            }
          }}
          showOtherOption
        />
      ),
    });
  }

  if (selectedVehicle === VEHILCE_TYPE_OTHER) {
    // Vehicle license number:
    data.push({
      overrideElement: (
        <TextField
          id="car_id"
          label={`${translate("report_page_title_car_id")}`}
          value={vehicleIdToUse || ""}
          onChange={(event) => {
            setVehicleIdToUse(event.target.value);
          }}
          fullWidth
        />
      ),
    });
  }

  if (
    selectedType?.includes("FINE_CHANGE") ||
    selectedType === DriverDocumentsTypes.DRIVER_DECLARATION
  ) {
    // Fine number:
    data.push({
      overrideElement: (
        <TextField
          id="fineNumber"
          label={`${translate("driver_documents_generate_fine_number")}`}
          value={fineNumber}
          onChange={(event) => {
            setFineNumber(event.target.value);
          }}
          fullWidth
        />
      ),
    });

    // Fine recived date:
    data.push({
      overrideElement: (
        <KDatePicker
          sx={{ width: "100%" }}
          label={`${translate("driver_documents_generate_fine_recived_date")}`}
          value={fineReceivedDate || ""}
          onValueChanged={(newValue) => {
            setfineReceivedDate(newValue);
          }}
        />
      ),
    });

    // Violation date:
    data.push({
      overrideElement: (
        <KDatePicker
          sx={{ width: "100%" }}
          label={`${translate("driver_documents_generate_violation_date")}`}
          value={fineViolationDate || ""}
          onValueChanged={(newValue) => {
            setFineViolationDate(newValue);
          }}
        />
      ),
    });
  }

  if (selectedType === DriverDocumentsTypes.FINE_CHANGE_COURT) {
    // Judge name:
    data.push({
      overrideElement: (
        <TextField
          id="judgeName"
          label={`${translate("driver_documents_generate_judge_name")}`}
          value={judgeName}
          onChange={(event) => {
            setJudgeName(event.target.value);
          }}
          fullWidth
        />
      ),
    });

    // Court Location:
    data.push({
      overrideElement: (
        <TextField
          id="courtLocation"
          label={`${translate("driver_documents_generate_court_location")}`}
          value={courtLocation}
          onChange={(event) => {
            setCourtLocation(event.target.value);
          }}
          fullWidth
        />
      ),
    });
  }

  // User signature:
  if (driver?.isOnboardingCompleted) {
    data.push({
      overrideElement: (
        <FormControlLabel
          control={
            <Checkbox
              checked={userSignature}
              onChange={() => {
                setUserSignature(!userSignature);
              }}
              name="useDriverSignature"
              color="primary"
            />
          }
          label={`${translate(
            "driver_documents_generate_use_driver_signature"
          )}`}
        />
      ),
    });
  }

  // Toggle Address details:
  if (selectedType !== DriverDocumentsTypes.LAW_168) {
    data.push({
      overrideElement: (
        <FormControlLabel
          sx={{ m: 1 }}
          control={
            <Switch
              size="medium"
              onChange={(event) => {
                setSendToAddressDetails(event.target.checked);
              }}
              name="sendToAddressDetails"
              color="primary"
            />
          }
          label={`${translate("driver_documents_generate_send_to_address")}`}
        />
      ),
    });
  }

  // Address details:
  const dataSendTo: DataViewEntry[] = [
    // Name:
    {
      overrideElement: (
        <TextField
          id="sendToName"
          label={`${translate("driver_documents_generate_send_to_name")}`}
          value={sendToName}
          onChange={(event) => {
            setSendToName(event.target.value);
          }}
          fullWidth
        />
      ),
    },

    // Street:
    {
      overrideElement: (
        <TextField
          id="sendToAddress_street"
          label={`${translate(
            "driver_documents_generate_send_to_address_street"
          )}`}
          value={sendToAddress.street}
          onChange={(event) => {
            setSendToAddress({
              ...sendToAddress,
              street: event.target.value,
            });
          }}
          fullWidth
        />
      ),
    },

    // House number:
    {
      overrideElement: (
        <TextField
          id="sendToAddress_houseNumber"
          label={`${translate(
            "driver_documents_generate_send_to_address_houseNumber"
          )}`}
          value={sendToAddress.houseNumber}
          onChange={(event) => {
            setSendToAddress({
              ...sendToAddress,
              houseNumber: event.target.value,
            });
          }}
          fullWidth
        />
      ),
    },

    // Additional address:
    {
      overrideElement: (
        <TextField
          id="sendToAddress_additionalAddress"
          label={`${translate(
            "driver_documents_generate_send_to_address_additionalAddress"
          )}`}
          value={sendToAddress.additionalAddress}
          onChange={(event) => {
            setSendToAddress({
              ...sendToAddress,
              additionalAddress: event.target.value,
            });
          }}
          fullWidth
        />
      ),
    },

    // City:
    {
      overrideElement: (
        <TextField
          id="sendToAddress_city"
          label={`${translate(
            "driver_documents_generate_send_to_address_city"
          )}`}
          value={sendToAddress.city}
          onChange={(event) => {
            setSendToAddress({ ...sendToAddress, city: event.target.value });
          }}
          fullWidth
        />
      ),
    },

    // Zip code:
    {
      overrideElement: (
        <TextField
          id="sendToAddress_zipCode"
          label={`${translate(
            "driver_documents_generate_send_to_address_zipCode"
          )}`}
          value={sendToAddress.zipCode}
          onChange={(event) => {
            setSendToAddress({
              ...sendToAddress,
              zipCode: event.target.value,
            });
          }}
          fullWidth
        />
      ),
    },
  ];

  const onGenerateClicked = () => {
    handleGenerateDoc({
      driverId: driver?.id,
      relatedClientId: clientId,
      relatedVehicleLicenseNumber: vehicleIdToUse,
      documentType: selectedType,
      includeDigitalSignature: userSignature,
      documentDate,
      extraFineData: {
        fineNumber,
        fineReceivedDate: (fineReceivedDate && fineReceivedDate.length > 0)
          ? fDateIsoString(fineReceivedDate)
          : null,
        fineViolationDate: (fineViolationDate && fineViolationDate.length > 0)
          ? fDateIsoString(fineViolationDate)
          : null,
        judgeName,
        courtLocation,
        sendToName,
      } as DriverDocumentFineData,
      sendToAddress,
    } as DriverDocumentCreateReq);

    logEvent("driver_document_generate", {
      documentType: selectedType,
      vehicleLicenseNumber: vehicleIdToUse,
    });
  };

  return (
    <>
      {driver && (
        <Stack spacing={3}>
          <FullWithDataView
            title={`${translate("driver_documents_generate_title")}`}
            subheader={`${translate(
              driver?.isOnboardingCompleted
                ? "driver_documents_subtitle_completed"
                : "driver_documents_subtitle_incomplete"
            )}`}
            itemsPerRow={3}
            data={data}
            translate={translate}
            actions={[
              {
                title: `${translate("driver_documents_generate_new_document")}`,
                onClick: onGenerateClicked,
                color: "success",
                icon: <Add />,
                isDisabled: !selectedType,
              },
            ]}
          />
          {sendToAddressDetails && (
            <FullWithDataView
              title={`${translate("driver_documents_generate_ֿֿsend_to_title")}`}
              subheader={`${translate("driver_documents_generate_ֿֿsend_to_subtitle")}`}
              itemsPerRow={3}
              data={dataSendTo}
              translate={translate}
            />
          )}
        </Stack>
      )}
    </>
  );
}

import { useEffect, useState } from "react";

// @mui
import {
  Dialog,
  Card,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Stack,
  Box,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { AlertTypeEmailContact, AlertType, Client } from "../../../../api";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
  ViewGenericDataTableFilterData,
} from "../../common/views/tableView/ViewGenericDataTable";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import GenericMessageView from "../../common/views/GenericMessageView";
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import ClientPageSettingsAddEditConactEmail from "./ClientPageSettingsAddEditConactEmail";
import {
  addAlertEmailContact,
  deleteAlertEmailContact,
  updateWebAppAlertsConfig,
} from "../main/clientPageSlice";
import {
  ClientWebAppConfig,
  ClientWebAppConfigAlertConfigType,
} from "../../../../api/api";
import { KSwitch } from "../../../../global/components/hook-form/RHFSwitch";
import { useSnackbar } from "../../../../global/components/snackbar";

type ClientContactEmailItem = {
  type: AlertType;
  contact: AlertTypeEmailContact;
};

// ----------------------------------------------------------------------

type AlertTypeEmailRowProps = {
  translate: Function;
  data: ClientContactEmailItem;
  onDeleteClicked: (_itemData: ClientContactEmailItem) => void;
};

function AlertTypeEmailListRow({
  translate,
  data,
  onDeleteClicked,
}: AlertTypeEmailRowProps) {
  const { contact } = data;
  return (
    <>
      <TableRow id={contact.email} hover sx={{ cursor: "pointer" }}>
        {/* Name */}
        <TableCell>
          <Typography variant="subtitle2" noWrap color={"text"}>
            {contact.name}
          </Typography>
        </TableCell>

        {/* Email */}
        <TableCell align="center">{contact.email}</TableCell>

        {/* Type */}
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap color={"text"}>
            {`${translate(`client_settings_alert_type_${data.type?.toLowerCase()}`)}`}
          </Typography>
        </TableCell>

        {/* Delete Icon */}
        <TableCell align="center">
          <IconButton
            size="small"
            onClick={() => {
              onDeleteClicked(data);
            }}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  data: Client | null;
  clientWebAppConfig: ClientWebAppConfig | null;
  onAddContactEmail?: (
    _alertType: AlertType,
    _contactInfo: AlertTypeEmailContact
  ) => void;
};

export default function ClientPageSettings({
  translate,
  data,
  clientWebAppConfig,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDashboardAppDispatch();

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.isLoading
  );

  const [dataToShow, setDataToShow] = useState<ClientContactEmailItem[]>([]);
  const [dataToDelete, setDataToDelete] =
    useState<ClientContactEmailItem | null>(null);

  const [
    listOfViewGenericDataTableFilterData,
    setListOfViewGenericDataTableFilterData,
  ] = useState<ViewGenericDataTableFilterData[] | null>(null);

  const [currentSelectedFilterKey, setCurrentSelectedFilterKey] = useState<
    string | undefined
  >(undefined);

  const handleFilterChange = (newFilter: string) => {
    setCurrentSelectedFilterKey(newFilter);
  };

  const pushAllContacts = () => {
    const allContacts: ClientContactEmailItem[] = [];
    Object.keys(AlertType).forEach((key) => {
      const contacts = clientWebAppConfig?.alertEmailsByType?.[key];
      if (contacts) {
        contacts.forEach((contact) => {
          allContacts.push({
            type: key as AlertType,
            contact,
          });
        });
      }
    });
    setDataToShow(allContacts);
  };

  useEffect(() => {
    if (listOfViewGenericDataTableFilterData === null) {
      setListOfViewGenericDataTableFilterData(
        Object.values(AlertType).map((type) => ({
          label: `${translate(`client_settings_alert_type_${type?.toLowerCase()}`)}`,
          key: type,
          payloadObject: type,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWebAppConfig, listOfViewGenericDataTableFilterData]);

  useEffect(() => {
    if (clientWebAppConfig && currentSelectedFilterKey !== undefined) {
      const contactForSelectedAlertType =
        clientWebAppConfig?.alertEmailsByType?.[currentSelectedFilterKey];
      if (contactForSelectedAlertType) {
        const filteredData: ClientContactEmailItem[] =
          contactForSelectedAlertType.map((contact: AlertTypeEmailContact) => ({
            type: Object.values(AlertType).find(
              (type) => type === currentSelectedFilterKey
            ) as AlertType,
            contact,
          }));
        setDataToShow(filteredData);
      } else {
        setDataToShow([]);
      }
    } else {
      pushAllContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientWebAppConfig, currentSelectedFilterKey]);

  const [selectedAlertType, _setSelectedAlertType] = useState<AlertType | null>(
    null
  );
  const [selectedContact, _setSelectedContactEmail] =
    useState<AlertTypeEmailContact | null>(null);

  // ------------------- Delete Client Dialog -------------------
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const handleOpenDeleteConfirm = (itemData: ClientContactEmailItem) => {
    setOpenDeleteConfirm(true);
    setDataToDelete(itemData);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    if (data && dataToDelete) {
      dispatch(
        deleteAlertEmailContact({
          clientId: data.id,
          alertType: dataToDelete.type,
          email: dataToDelete.contact.email,
          _onResponse: (success: boolean) => {
            if (success) {
              pushAllContacts();
              setDataToDelete(null);
              handleCloseDeleteConfirm();
            }
          },
        })
      );
    }
  };

  // ------------------- Add Dialog -------------------
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const handleAddClose = () => {
    setIsAddDialogOpen(false);
  };
  const handleAddClicked = () => {
    setIsAddDialogOpen(true);
  };

  const handleAddNewContact = (
    alertType: AlertType,
    contact: AlertTypeEmailContact
  ) => {
    if (data) {
      dispatch(
        addAlertEmailContact({
          clientId: data.id,
          alertType,
          contactInfo: contact,
          _onResponse: (success: boolean) => {
            if (success) {
              setIsAddDialogOpen(false);
            }
          },
        })
      );
    }
  };

  // ------------------- Global Settings -------------------
  const [alertsSettings, setAlertsSettings] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (clientWebAppConfig?.alertsConfig) {
      setAlertsSettings(clientWebAppConfig?.alertsConfig);
    }
  }, [clientWebAppConfig]);

  const onSettingChanged = (key: string, checked: boolean) => {
    if (data) {
      const newAlertsSettings = { ...alertsSettings, [key]: checked };
      setAlertsSettings(newAlertsSettings);
      dispatch(
        updateWebAppAlertsConfig({
          clientId: data.id,
          config: newAlertsSettings,
          onCompleted: (success: boolean) => {
            if (success) {
              enqueueSnackbar(`${translate("form_message_success_message")}`);
            } else {
              enqueueSnackbar(`${translate("form_message_error_message")}`);
            }
          },
        })
      );
    }
  };

  const SettingsSwitch = (props: {
    type: ClientWebAppConfigAlertConfigType;
    labelKey: string;
  }) => (
    <KSwitch
      name={props.type}
      label={`${translate(props.labelKey)}`}
      onSwitch={(checked) => onSettingChanged(props.type, checked)}
      isChecked={alertsSettings[props.type] || false}
      disabled={
        (props.type !== ClientWebAppConfigAlertConfigType.DISABLE_ALERTS &&
          alertsSettings[ClientWebAppConfigAlertConfigType.DISABLE_ALERTS]) ||
        false
      }
    />
  );

  const renderGlobalSettings = (
    <Card sx={{ px: 3, py: 3 }}>
      <Stack spacing={2}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 2fr)",
          }}
        >
          {/** Disable alerts */}
          <SettingsSwitch
            type={ClientWebAppConfigAlertConfigType.DISABLE_ALERTS}
            labelKey="client_page_settings_send_alert_disable"
          />

          {/** Vehicle docs to linked drivers */}
          <SettingsSwitch
            type={
              ClientWebAppConfigAlertConfigType.VEHICLE_DOCS_TO_LINKED_DRIVERS
            }
            labelKey="client_page_settings_send_alert_vehicle_to_drivers"
          />

          {/** Driver docs */}
          <SettingsSwitch
            type={ClientWebAppConfigAlertConfigType.DRIVER_DOCS_TO_DRIVERS}
            labelKey="client_page_settings_send_alert_driver_docs"
          />

          {/** Alert client */}
          <SettingsSwitch
            type={ClientWebAppConfigAlertConfigType.ALERT_CLIENT}
            labelKey="client_page_settings_send_alert_client"
          />
        </Box>

        {isLoading.alertConfig && (
          <Typography variant="subtitle2" color="textSecondary">
            {`${translate("global_saving_changes")}`}
          </Typography>
        )}
      </Stack>
    </Card>
  );

  // ------------------- Emails List -------------------
  const renderRow = (row: ClientContactEmailItem) => (
    <AlertTypeEmailListRow
      translate={translate}
      key={row.contact.email}
      data={row}
      onDeleteClicked={handleOpenDeleteConfirm}
    />
  );

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "fullName",
      label: `${translate("global_name")}`,
      align: "left",
    },
    {
      id: "email",
      label: `${translate("global_email")}`,
      align: "center",
    },
    {
      id: "type",
      label: `${translate("client_settings_alert_type_title")}`,
      align: "center",
    },
    {
      id: "actions",
      label: `${translate("global_actions")}`,
      align: "center",
    },
  ];

  const genericDataTableViewProps: ViewGenericDataTableData<ClientContactEmailItem> =
    {
      isLoading: isLoading.settings,
      translate,
      titleKey: "client_page_settings_alert_emails",
      subtitleKey: "client_page_settings_alert_emails_description",
      data: dataToShow,
      renderRow,
      tableHead: TABLE_HEAD,
      onActionButtonClicked: handleAddClicked,
      actionButtonLabelKey: "client_page_settings_add",
      actionButtonIcon: <Add />,
      searchable: true,
      searchLabelKey: "client_page_settings_search_email",
      emptyStateComponent: (
        <GenericMessageView
          title={`${translate("error_client_settings_list_empty_title")}`}
          subtitle={`${translate("error_client_settings_list_empty_subtitle")}`}
          buttonTitle={`${translate("client_page_settings_add_address")}`}
          onAction={() => setIsAddDialogOpen(true)}
        />
      ),
      listOfFilters: listOfViewGenericDataTableFilterData,
      currentSelectedFilterKey,
      onFilterChange: handleFilterChange,
    };

  return (
    <>
      <Stack spacing={3}>
        <Card sx={{ px: 3 }}>
          <ViewGenericDataTable {...genericDataTableViewProps} />
        </Card>
        {renderGlobalSettings}
      </Stack>

      <DeleteConfimDialog
        translate={translate}
        isOpen={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        onAction={handleDeleteClick}
        isLoading={isLoading.settings}
      />

      <Dialog open={isAddDialogOpen} onClose={handleAddClose}>
        <ClientPageSettingsAddEditConactEmail
          translate={translate}
          currentAlertTypeEmailContact={selectedContact}
          currentAlertType={selectedAlertType}
          isEdit={false}
          isLoading={isLoading.settings}
          onPreformAction={handleAddNewContact}
        />
      </Dialog>
    </>
  );
}

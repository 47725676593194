import { Navigate } from "react-router-dom";

// config
import { PATH_DASHBOARD } from "./paths";
import SettingsSignaturePage from "../features/settings/user/SettingsPageSignature";
import SettingsPageReportConfig from "../features/settings/config/SettingsPageReportConfig";
//
import {
  // Dashboard: General
  GeneralAppPage,
  ClientsListPage,
  ClientPage,
  VehiclePage,
  VehicleReportPage,
  PageError,
  DriverPage,
} from "./elements";

import DashboardApp from "../app/DashboardApp";
import ClientCoursePage from "../features/courses/ClientCoursePage";
import IncidentPage from "../features/vehicles/incidants/details/IncidentPage";

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  // Dashboard
  {
    path: PATH_DASHBOARD.root,
    element: <DashboardApp />,
    children: [
      // GENERAL:
      {
        element: <Navigate to={PATH_DASHBOARD.general.app.path} replace />,
        index: true,
      },
      { path: PATH_DASHBOARD.general.app.path, element: <GeneralAppPage /> },

      // MANAGEMENT:
      {
        path: PATH_DASHBOARD.management.clients.path,
        element: <ClientsListPage />,
      },
      {
        path: PATH_DASHBOARD.management.clients.client(":clientId"),
        element: <ClientPage />,
      },
      {
        path: PATH_DASHBOARD.management.clients.vheicle.path(
          ":clientId",
          ":licenseNumber"
        ),
        element: <VehiclePage />,
      },
      {
        path: PATH_DASHBOARD.management.clients.vheicle.incident(
          ":clientId",
          ":licenseNumber",
          ":incidentId"
        ),
        element: <IncidentPage />,
      },
      {
        path: PATH_DASHBOARD.management.clients.report.path(
          ":clientId",
          ":licenseNumber",
          ":reportId"
        ),
        element: <VehicleReportPage />,
      },
      {
        path: PATH_DASHBOARD.management.clients.driver.path(
          ":clientId",
          ":driverId"
        ),
        element: <DriverPage />,
      },
      {
        path: PATH_DASHBOARD.management.clients.course.path(
          ":clientId",
          ":courseId"
        ),
        element: <ClientCoursePage />,
      },

      // SYSTEM:
      {
        path: PATH_DASHBOARD.system.settings_vehicle_report.path,
        element: <SettingsPageReportConfig />,
      },
      {
        path: PATH_DASHBOARD.system.settings_signature.path,
        element: <SettingsSignaturePage />,
      },
    ],
  },

  {
    element: <></>,
    children: [{ path: "error", element: <PageError /> }],
  },
];

import { Alert, AlertSeverity } from "../../../api";
import { AlertDriverDocumentPayload, AlertDriverLicenseExpiredPayload, AlertVehicleDocumentPayload, AlertVehicleExpiredTestPayload } from '../../../api/api';
import { formatLicensePlateNumber } from "./vehicleUtils";

export function getAlertTitle(alert: Alert, translate: Function): string {
    return translate(`alert_type_${alert.type.toLowerCase()}`);
}

export function getAlertVehicleDocumentDescription(alertVehicleDocument: AlertVehicleDocumentPayload | undefined, translate: Function): string | undefined {
    return `
    ${translate(`alert_vehicle_document_expired_title`)} 
    ${translate(`vehicle_document_type_${alertVehicleDocument?.vehicleDocument?.documentType?.toLowerCase() ?? "unknown"}`)} 
    ${translate(`alert_vehicle_document_expired_for_vehicle`)} 
    ${formatLicensePlateNumber(alertVehicleDocument?.vehicleId ?? "0")}
    `;
}

export function getAlertVehicleTestExpiredDescription(alertVehicleDocument: AlertVehicleExpiredTestPayload | undefined, translate: Function): string | undefined {
    return `
    ${translate(`alert_vehicle_test_expired_title`)}
    ${formatLicensePlateNumber(alertVehicleDocument?.vehicleId ?? "0")}
    `;
}

export function getAlertDriverDocumentDescription(driverPayload: AlertDriverDocumentPayload | undefined, translate: Function): string | undefined {
    return `
    ${translate(`alert_driver_document_expired_title`)} 
    ${translate(`driver_document_upload_title_${driverPayload?.driverDocument?.documentType?.toLowerCase() ?? "unknown"}`)} 
    ${translate(`alert_driver_document_expired_for_driver`)} 
    ${driverPayload?.driverName ?? `${translate(`global_unknown`)}`}
    `;
}

export function getAlertDriverLicenseDescription(driverPayload: AlertDriverLicenseExpiredPayload | undefined, translate: Function): string | undefined {
    return `
    ${translate(`alert_driver_license_expired_title`)} 
    ${driverPayload?.driverName ?? `${translate(`global_unknown`)}`}
    `;
}

export function getAlertColor(alertSeverity: AlertSeverity): string {
    switch (alertSeverity) {
        case AlertSeverity.CRITICAL:
            return "red";
        case AlertSeverity.HIGH:
            return "red";
        case AlertSeverity.MEDIUM:
            return "orange";
        case AlertSeverity.LOW:
            return "green";
        default:
            return "black";
    }
}


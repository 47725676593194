import sum from "lodash/sum";
import { useCallback, useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import { KInvoiceItem } from "src/api";
import { fCurrency } from "../../../utils/format/formatNumber";
import { RHFTextField } from "../../../../global/components/hook-form";
import Iconify from "../../../../global/components/iconify";

// ----------------------------------------------------------------------

export type InvoiceAddDetailsProps = {
  translate: Function;
  viewEditMode: boolean;
};

export default function InvoiceAddDetails({
  translate,
  viewEditMode,
}: InvoiceAddDetailsProps) {
  const { control, setValue, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const values = watch();

  const totalOnRow = values.items?.map((item: KInvoiceItem) => item.price || 0);

  const subTotal = sum(totalOnRow);
  const totalBeforeTaxes =
    Number(subTotal ?? 0) - Number(values.discount ?? 0) + Number(values.shipping ?? 0);
  const taxes = totalBeforeTaxes * (Number(values.taxes) / 100);
  const totalAmount = totalBeforeTaxes + taxes;

  useEffect(() => {
    setValue("totalAmount", totalAmount);
    setValue("subTotal", subTotal);
  }, [setValue, totalAmount, subTotal]);

  useEffect(
    () => {
      if (!values.items || values.items.length === 0) {
        append({
          title: "",
          description: "",
          price: 0,
        });
        setValue("taxes", 18);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleAdd = () => {
    append({
      title: "",
      description: "",
      price: 0,
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const handleChangePrice = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      index: number
    ) => {
      setValue(`items[${index}].price`, Number(event.target.value));
    },
    [setValue]
  );

  const renderTotal = (
    <Stack
      spacing={2}
      alignItems="flex-end"
      sx={{ mt: 3, textAlign: "right", typography: "body2" }}
    >
      <Stack direction="row">
        <Box
          sx={{ color: "text.secondary" }}
        >{`${translate("invoice_details_title_subtotal")}`}</Box>
        <Box sx={{ width: 160, typography: "subtitle2" }}>
          {fCurrency(subTotal) || "-"}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box
          sx={{ color: "text.secondary" }}
        >{`${translate("invoice_details_title_shipping")}`}</Box>
        <Box
          sx={{
            width: 160,
            ...values.shipping,
          }}
        >
          {values.shipping ? `${fCurrency(values.shipping)}` : "-"}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box
          sx={{ color: "text.secondary" }}
        >{`${translate("invoice_details_title_discount")}`}</Box>
        <Box
          sx={{
            width: 160,
            ...(values.discount && { color: "error.main" }),
          }}
        >
          {values.discount ? `- ${fCurrency(values.discount)}` : "-"}
        </Box>
      </Stack>

      <Stack direction="row">
        <Box
          sx={{ color: "text.secondary" }}
        >{`${translate("invoice_details_title_tax")}`}</Box>
        <Box sx={{ width: 160 }}>{taxes ? fCurrency(taxes) : "-"}</Box>
      </Stack>

      <Stack direction="row" sx={{ typography: "subtitle1" }}>
        <Box>{`${translate("invoice_details_title_total")}`}</Box>
        <Box sx={{ width: 160 }}>{fCurrency(totalAmount) || "-"}</Box>
      </Stack>
    </Stack>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: "text.disabled", mb: 3 }}>
        {`${translate("invoice_details_title")}`}
      </Typography>

      <Stack
        divider={<Divider flexItem sx={{ borderStyle: "dashed" }} />}
        spacing={3}
      >
        {fields.map((item, index) => (
          <Stack key={item.id} alignItems="flex-end" spacing={1.5}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ width: 1 }}
            >
              <RHFTextField
                disabled={viewEditMode}
                size="small"
                name={`items[${index}].title`}
                label={`${translate("invoice_add_item_title")}`}
                InputLabelProps={{ shrink: true }}
              />

              <RHFTextField
                disabled={viewEditMode}
                size="small"
                name={`items[${index}].description`}
                label={`${translate("invoice_add_item_description")}`}
                InputLabelProps={{ shrink: true }}
              />

              <RHFTextField
                disabled={viewEditMode}
                size="small"
                type="number"
                name={`items[${index}].price`}
                label={`${translate("invoice_add_item_price")}`}
                placeholder="0.00"
                onChange={(event) => handleChangePrice(event, index)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{ typography: "subtitle2", color: "text.disabled" }}
                      >
                        ₪
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{ maxWidth: { md: 140 } }}
              />
            </Stack>

            {!viewEditMode && (
              <Button
                disabled={viewEditMode}
                size="small"
                color="error"
                startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                onClick={() => handleRemove(index)}
              >
                {`${translate("global_remove")}`}
              </Button>
            )}
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: "dashed" }} />

      <Stack
        spacing={3}
        direction={{ xs: "column", md: "row" }}
        alignItems={{ xs: "flex-end", md: "center" }}
      >
        {!viewEditMode && (
          <Button
            size="large"
            color="primary"
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={handleAdd}
            sx={{ flexShrink: 0 }}
          >
            {`${translate("invoice_add_item")}`}
          </Button>
        )}

        <Stack
          spacing={2}
          justifyContent="flex-end"
          direction={{ xs: "column", md: "row" }}
          sx={{ width: 1 }}
        >
          <RHFTextField
            disabled={viewEditMode}
            size="small"
            label={`${translate("invoice_details_title_shipping")}(₪)`}
            name="shipping"
            type="number"
            sx={{ maxWidth: { md: 120 } }}
          />

          <RHFTextField
            disabled={viewEditMode}
            size="small"
            label={`${translate("invoice_details_title_discount")}(₪)`}
            name="discount"
            type="number"
            sx={{ maxWidth: { md: 120 } }}
          />

          <RHFTextField
            disabled={viewEditMode}
            size="small"
            label={`${translate("invoice_details_title_tax")}(%)`}
            name="taxes"
            type="number"
            sx={{ maxWidth: { md: 120 } }}
          />
        </Stack>
      </Stack>

      {renderTotal}
    </Box>
  );
}

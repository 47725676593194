import { Helmet } from "react-helmet-async";
import {
  Button,
  Card,
  Checkbox,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { Fragment, useEffect, useState } from "react";
import { setDaysBeforeAlerts } from "../../../app/dashboardAppStateSlice";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";

import { useLocales } from "../../../../global/locales";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  useDashboardAppSelector,
  useDashboardAppDispatch,
} from "../../../hooks/useRedux";

import LoadingView from "../../../components/loading-view/LoadingView";
import FullWithDataView from "../../common/views/FullWithDataView";
import {
  DocumentConfigOption,
  DocumentInputConfig,
  VehicleType,
  VehicleReportType,
} from "../../../../api";
import {
  getStaticDocumentConfig,
  getStaticDocumentInputs,
  getUserVehicleTestConfig,
  getVehicleAvilableReportTypes,
  onDocumentInputChanged,
  onDocumentInputMandetoryChanged,
  onDocumentQuestionChanged,
  onResetCurrentReportConfig,
  onSelectedReportType,
  onSelectedVehicleType,
  updateUserVehicleTestConfig,
} from "./settingsReportConfigSlice";

import GenericSelectOptions, {
  GenericSelectOptionItem,
} from "../../common/views/GenericSelectOptions";
import { getVehicleTypeTranslationKey } from "../../../utils/models/typesUtils";
import { useSnackbar } from "../../../../global/components/snackbar";

export default function SettingsPageReportConfig() {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();
  const dispatch = useDashboardAppDispatch();

  const langCode = `${translate("language_code")}`;

  const appStateSlice = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice
  );

  const isLoadingAppConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig.isLoading
  );

  const staticDocumentCategoryQuestions = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.settingsReportConfigSlice.staticDocumentCategoryQuestions
  );

  const staticDocumnetInputs = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.settingsReportConfigSlice.staticDocumentInputs
  );

  const selectReportState = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.settingsReportConfigSlice.selectReportState
  );

  const avilableReportTypes = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.settingsReportConfigSlice.avilableReportTypes
  );

  const isLoadingReportConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsReportConfigSlice.isLoading
  );

  useEffect(() => {
    if (!staticDocumnetInputs) {
      dispatch(getStaticDocumentInputs({ langCode }));
    }

    if (selectReportState.vehicleType && !staticDocumentCategoryQuestions) {
      dispatch(
        getVehicleAvilableReportTypes({
          langCode,
          vehicleType: selectReportState.vehicleType,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticDocumnetInputs, staticDocumentCategoryQuestions]);

  const handleVehicleTypeSelected = (value: VehicleType) => {
    dispatch(onResetCurrentReportConfig());
    dispatch(onSelectedVehicleType(value));
    dispatch(
      getVehicleAvilableReportTypes({
        langCode,
        vehicleType: value,
      })
    );
  };

  const handleReportTypeSelected = (value: VehicleReportType) => {
    dispatch(onSelectedReportType(value));
    dispatch(getStaticDocumentConfig({ reportType: value }));
    dispatch(getUserVehicleTestConfig({ reportType: value }));
  };

  const handleSaveChangesClicked = () => {
    if (
      selectReportState?.reportType !== undefined &&
      selectReportState?.userConfig !== undefined
    ) {
      dispatch(
        updateUserVehicleTestConfig({
          reportType: selectReportState.reportType,
          config: selectReportState.userConfig,
        })
      );
    }
  };

  const ReportSelection = () => {
    const data = [
      // Vehicle Type:
      {
        overrideElement: (
          <GenericSelectOptions
            filedTitle={`${translate("settings_vehicle_type_selection")}`}
            currentValue={selectReportState.vehicleType}
            options={
              appStateSlice.appConfig.vehicleConfig?.vehicleTypes?.map(
                (vehicleType: VehicleType) =>
                  ({
                    key: vehicleType.toString(),
                    title: `${translate(
                      getVehicleTypeTranslationKey(vehicleType)
                    )}`,
                    value: vehicleType,
                  }) as GenericSelectOptionItem<VehicleType>
              ) as GenericSelectOptionItem<VehicleType>[]
            }
            onSelect={handleVehicleTypeSelected}
          />
        ),
      },

      // Report Type:
      {
        overrideElement: (
          <GenericSelectOptions
            filedTitle={`${translate(
              "settings_vehicle_type_report_selection"
            )}`}
            currentValue={selectReportState.reportType}
            options={
              avilableReportTypes.map(
                (configOption: DocumentConfigOption) =>
                  ({
                    key: configOption.type,
                    title: configOption.label,
                    value: configOption.type,
                  }) as GenericSelectOptionItem<VehicleReportType>
              ) as GenericSelectOptionItem<VehicleReportType>[]
            }
            onSelect={handleReportTypeSelected}
          />
        ),
      },
    ];

    const actions = selectReportState.reportType
      ? [
          {
            title: `${translate("global_save_changes")}`,
            onClick: handleSaveChangesClicked,
          },
        ]
      : [];

    return (
      <FullWithDataView
        translate={translate}
        title={`${translate("settings_vehicle_report_selection")}`}
        itemsPerRow={data.length}
        data={data}
        showBorder={false}
        actions={actions}
      />
    );
  };

  const getConfigByDbKey = (dbKey: string): DocumentInputConfig | undefined => {
    try {
      if (
        selectReportState?.userConfig?.enabled_test_inputs !== undefined &&
        selectReportState?.userConfig?.enabled_test_inputs.length <= 0
      ) {
        return undefined;
      }

      return selectReportState?.userConfig?.enabled_test_inputs.find(
        (input: DocumentInputConfig) => input.dbKey === dbKey
      );
    } catch (error) {
      console.log(error);
      return undefined;
    }
  };

  const isDocumentConfigContainsDocInput = (dbKey: string) =>
    getConfigByDbKey(dbKey) !== undefined;

  const isDocumentConfigContainsDocInputMandatory = (dbKey: string) =>
    getConfigByDbKey(dbKey)?.isMandatory;

  const isCategoryQuestionIsIncluded = (id: string) =>
    selectReportState.userConfig?.enabled_test_questions &&
    selectReportState.userConfig?.enabled_test_questions?.includes(id);

  const handleDocQuestionIsIncludeChange = (id: string, checked: boolean) => {
    dispatch(onDocumentQuestionChanged({ id, checked }));
  };

  const handleSelectAll = () => {
    if (staticDocumentCategoryQuestions) {
      staticDocumentCategoryQuestions.forEach((category) => {
        category.children.forEach((docItem) => {
          handleDocQuestionIsIncludeChange(docItem.id, true);
        });
      });
    }
  };

  const ReportDocumentCategoryQuestions = () =>
    selectReportState.reportType && staticDocumentCategoryQuestions ? (
      <>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            {`${translate("settings_vehicle_report_question_title")}`}
          </Typography>

          {/* Select All: */}
          <Button
            color={"primary"}
            onClick={handleSelectAll}
            variant="contained"
          >
            {`${translate("global_select_all")}`}
          </Button>
        </Stack>

        <Card sx={{ padding: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              {staticDocumentCategoryQuestions?.map((categoty, index) => (
                <Fragment key={categoty.id}>
                  {index === 0 ? (
                    <TableHead>
                      <TableRow>
                        {/* >> Title << */}
                        <TableCell>{`${translate(
                          "settings_vehicle_report_doc_input_table_field"
                        )}`}</TableCell>
                        {/* >> Is Included << */}
                        <TableCell align="center">{`${translate(
                          "settings_vehicle_report_doc_input_table_included"
                        )}`}</TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    <></>
                  )}

                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                          {categoty.title}
                        </Typography>
                      </TableCell>
                    </TableRow>

                    {categoty.children.map((docItem, docItemIndex) => (
                      <TableRow
                        key={docItem.id}
                        sx={{
                          border: 0.5,
                          borderColor: "grey.300",
                          backgroundColor:
                            docItemIndex % 2 === 0 ? "grey.100" : "white",
                        }}
                      >
                        {/* >> Title << */}
                        <TableCell component="th" scope="row">
                          {docItem.title}
                        </TableCell>

                        {/* >> Is Included << */}
                        <TableCell align="center">
                          <Checkbox
                            checked={isCategoryQuestionIsIncluded(docItem.id)}
                            onChange={(event) => {
                              handleDocQuestionIsIncludeChange(
                                docItem.id,
                                event.target.checked
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Fragment>
              ))}
            </Table>
          </TableContainer>
        </Card>
      </>
    ) : (
      <></>
    );

  const handleDocInputIsIncludeChange = (dbKey: string, checked: boolean) => {
    dispatch(onDocumentInputChanged({ dbKey, checked }));
  };

  const handleDocInputIsMandatoryChange = (dbKey: string, checked: boolean) => {
    dispatch(onDocumentInputMandetoryChanged({ dbKey, checked }));
  };

  const ReportDocumentInputEditView = () =>
    selectReportState.reportType && staticDocumnetInputs ? (
      <>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          {`${translate("settings_vehicle_report_doc_input_title")}`}
        </Typography>
        <Card sx={{ padding: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* >> Title << */}
                  <TableCell>{`${translate(
                    "settings_vehicle_report_doc_input_table_field"
                  )}`}</TableCell>
                  {/* >> Is Included << */}
                  <TableCell align="center">{`${translate(
                    "settings_vehicle_report_doc_input_table_included"
                  )}`}</TableCell>
                  {/* >> Mendatory << */}
                  <TableCell align="center">{`${translate(
                    "settings_vehicle_report_doc_input_table_mendatory"
                  )}`}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {staticDocumnetInputs.map((row, rowIndex) => (
                  <TableRow
                    key={row.dbKey}
                    sx={{
                      border: 0.5,
                      borderColor: "grey.300",
                      backgroundColor:
                        rowIndex % 2 === 0 ? "white" : "grey.100",
                    }}
                  >
                    {/* >> Title << */}
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>

                    {/* >> Is Included << */}
                    <TableCell align="center">
                      <Checkbox
                        checked={isDocumentConfigContainsDocInput(row.dbKey)}
                        onChange={(event) => {
                          handleDocInputIsIncludeChange(
                            row.dbKey,
                            event.target.checked
                          );
                        }}
                      />
                    </TableCell>

                    {/* >> Mendatory << */}
                    <TableCell align="center">
                      <Checkbox
                        checked={isDocumentConfigContainsDocInputMandatory(
                          row.dbKey
                        )}
                        onChange={(event) => {
                          handleDocInputIsMandatoryChange(
                            row.dbKey,
                            event.target.checked
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </>
    ) : (
      <></>
    );

  const SaveChangesButton = () => (
    <Button
      color={"primary"}
      onClick={handleSaveChangesClicked}
      variant="contained"
    >
      {`${translate("global_save_changes")}`}
    </Button>
  );

  const [daysBeforeAlert, setDaysBeforeAlert] = useState(
    appStateSlice.appConfig.daysBeforeAlerts ?? 0
  );
  const handleSaveDaysBeforeAlert = () => {
    dispatch(
      setDaysBeforeAlerts({
        days: daysBeforeAlert,
        onComplete: (isSuccess: boolean) => {
          if (isSuccess) {
            enqueueSnackbar(`${translate("form_message_success_message")}`);
          } else {
            enqueueSnackbar(`${translate("form_message_error_message")}`);
          }
        },
      })
    );
  };

  useEffect(() => {
    setDaysBeforeAlert(appStateSlice.appConfig.daysBeforeAlerts ?? 0);
  }, [appStateSlice.appConfig.daysBeforeAlerts]);

  const dataDaysBeforeAlertConfig = [
    // Days Before Alert Input:
    {
      overrideElement: (
        <TextField
          id="daysBeforeAlert"
          label={`${translate("settings_days_before_alert_label")}`}
          value={daysBeforeAlert}
          type="number"
          onChange={(event) => {
            setDaysBeforeAlert(parseInt(event.target.value, 10));
          }}
          fullWidth
        />
      ),
    },
  ];

  const actionsDaysBeforeAlertConfig = [
    {
      title: `${translate("global_save_changes")}`,
      onClick: handleSaveDaysBeforeAlert,
    },
  ];

  const randerDaysBeforeAlertConfig = (
    <FullWithDataView
      translate={translate}
      title={`${translate("settings_days_before_alert_title")}`}
      itemsPerRow={dataDaysBeforeAlertConfig.length}
      data={dataDaysBeforeAlertConfig}
      showBorder={false}
      actions={actionsDaysBeforeAlertConfig}
      isLoading={isLoadingAppConfig.daysBefore || isLoadingAppConfig.data}
    />
  );

  return (
    <>
      <Helmet>
        <title>{`${translate("nav_subheader_system")}`}</title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("nav_subheader_system")}`}
          links={[
            {
              name: `${translate(
                PATH_DASHBOARD.system.settings_vehicle_report.label_key
              )}`,
            },
          ]}
        />

        {!isLoadingAppConfig.data && appStateSlice.appConfig.vehicleConfig ? (
          <Stack spacing={3}>
            {randerDaysBeforeAlertConfig}
            <ReportSelection />

            {!isLoadingReportConfig ? (
              <>
                <ReportDocumentCategoryQuestions />
                <ReportDocumentInputEditView />
                {selectReportState.reportType && <SaveChangesButton />}
              </>
            ) : (
              <LoadingView />
            )}
          </Stack>
        ) : (
          <LoadingView />
        )}
      </Container>
    </>
  );
}

//  Flow:
// > Select vehicle type > (query for types and show) > Select type > (query for current config)
// > if empty show empty state > show config.

import { Typography, SxProps } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import {
  VehicleType,
  VehicleModel,
  VehiclesDataModel,
  VehicleConfig,
} from "../../../../api";
import { useDashboardAppSelector } from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";

type ManufacturerLabelProps = {
  labelType: "manufacturer" | "model";
  variant?: Variant;
  sx?: SxProps;
  makeId?: string;
  modelId?: string;
  type?: VehicleType;
};

export function getManufacturerOrModelLabel({
  labelType,
  makeId,
  modelId,
  type,
  vehicleConfig,
}: ManufacturerLabelProps & {
  vehicleConfig?: VehicleConfig;
}): string {
  let label = "";

  const getLabel = (options?: any[]): string => {
    try {
      if (labelType === "manufacturer") {
        return (
          options?.find((option: VehicleModel) => option.value === makeId)
            ?.text || ""
        );
      }
      return (
        options
          ?.find(
            (dataModel: VehiclesDataModel) =>
              dataModel.manufacturer_id === makeId
          )
          ?.models?.find((option: VehicleModel) => option.value === modelId)
          ?.text || modelId
      );
    } catch (error) {
      return labelType === "manufacturer" ? makeId || "" : modelId || "";
    }
  };

  switch (type) {
    case VehicleType.TAXI:
    case VehicleType.CAR:
      if (labelType === "manufacturer") {
        label = getLabel(vehicleConfig?.vehicleManufacturers.cars);
      } else {
        label = getLabel(vehicleConfig?.vehicleModels.cars);
      }
      break;

    case VehicleType.COMMERCIAL:
    case VehicleType.MOTOR_HOME:
      if (labelType === "manufacturer") {
        label = getLabel(vehicleConfig?.vehicleManufacturers.commercial);
      } else {
        label = getLabel(vehicleConfig?.vehicleModels.commercial);
      }
      break;

    case VehicleType.TEAYOULIT:
    case VehicleType.TRUCK:
      if (labelType === "manufacturer") {
        label = getLabel(vehicleConfig?.vehicleManufacturers.trucks);
      } else {
        label = `${modelId}`;
      }
      break;

    case VehicleType.TRUCK_SMALL:
      if (labelType === "manufacturer") {
        label = getLabel(vehicleConfig?.vehicleManufacturers.trucks);
      } else {
        label = `${modelId}`;
      }
      break;

    case VehicleType.BUS:
      if (labelType === "manufacturer") {
        label = getLabel(vehicleConfig?.vehicleManufacturers.bus);
      } else {
        label = `${modelId}`;
      }
      break;

    case VehicleType.MINIBUS:
      if (labelType === "manufacturer") {
        label = getLabel(vehicleConfig?.vehicleManufacturers.bus);
      } else {
        label = `${modelId}`;
      }
      break;

    case VehicleType.OTHER:
    case VehicleType.FORK_LIFT:
    case VehicleType.TRAILER:
      if (labelType === "manufacturer") {
        label = vehicleConfig?.vehicleManufacturers.other.text || `${makeId}`;
      } else {
        label = `${modelId}`;
      }
      break;

    default:
      break;
  }

  return label;
}

export default function ManufacturerOrModelLabel({
  labelType,
  variant,
  sx,
  makeId,
  modelId,
  type,
}: ManufacturerLabelProps) {
  const vehicleConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig.vehicleConfig
  );

  const label = getManufacturerOrModelLabel({
    labelType,
    makeId,
    modelId,
    type,
    vehicleConfig,
  });

  return (
    <Typography variant={variant || "body2"} noWrap sx={sx}>
      {label}
    </Typography>
  );
}

import * as Yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Add, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  Dialog,
} from "@mui/material";
import { parseISO } from "date-fns";
import { useSnackbar } from "../../../../../global/components/snackbar";
import { getAddressInputFields } from "../../../../utils/formsUtils";
import {
  Address,
  AddVehicleIncidentImgsRequestBody,
  CreateOrUpdateVehicleIncident,
  DriverUser,
  IncidentInvolvedParty,
  VehicleIncident,
} from "../../../../../api";

import FormProvider, {
  RHFTextField,
  RHFSelect,
  RHFDateTimePicker,
  RHFUpload,
} from "../../../../../global/components/hook-form";
import {
  arraySchema,
  genericAnySchema,
  genericRequiredDateSchema,
  genericRequiredStringSchema,
} from "../../../../../global/utils/formValidators";
import RepositoryDriver from "../../../../repositories/RepositoryDriver";
import InvolvedPartiesPage from "../details/involvedParties/InvolvedPartiesPage";
import IncidentInvolvedPartyAdd from "./IncidentInvolvedPartyAdd";
import { TableHeadItem } from "../../../../components/table/TableHeadCustom";
import {
  createVehicleIncident,
  updateVehicleIncident,
  uploadIncidentImages,
} from "../vehicleIncidentsPageSlice";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../../hooks/useRedux";
import {
  convertFileToBase64,
  reduceImageSize,
} from "../../../../utils/fileUtils";
import { DashboardRootState } from "../../../../app/dashboardAppStore";

export type VehicleIncidentsAddEditProps = {
  clientId: string;
  vehicleId: string;
  currentIncidentData?: VehicleIncident;
  translate: Function;
  onComplete?: Function;
  onCloseClicked?: Function;
};

const DetailsTextField = ({
  isEdit,
  name,
  labelId,
  translate,
}: {
  isEdit?: boolean;
  name: string;
  labelId: string;
  translate: Function;
}) => {
  const editTextRows = 5;

  const [isShowTextInput, setShowTextInput] = useState(false);

  return isShowTextInput ? (
    <Box position="relative">
      <IconButton
        onClick={() => setShowTextInput(false)}
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
        }}
      >
        <Close />
      </IconButton>
      <RHFTextField
        type="text"
        multiline
        rows={editTextRows}
        name={name}
        label={`${translate(labelId)}`}
      />
    </Box>
  ) : (
    <Button variant="outlined" onClick={() => setShowTextInput(true)}>
      {isEdit
        ? `${translate("vehicle_incident_add_edit_details_edit")} ${translate(labelId)}`
        : `${translate("vehicle_incident_add_edit_details_add")} ${translate(labelId)}`}
    </Button>
  );
};

export default function VehicleIncidentsAddEdit({
  clientId,
  vehicleId,
  currentIncidentData,
  translate,
  onComplete,
  onCloseClicked,
}: VehicleIncidentsAddEditProps) {
  const dispatch = useDashboardAppDispatch();

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehicleIncidentsPageSlice.isLoading
  );

  const { enqueueSnackbar } = useSnackbar();

  const [driversData, setDriversData] = useState<DriverUser[] | undefined>([]);

  const [currentSelectedInvolvedParty, setCurrentSelectedInvolvedParty] =
    useState<IncidentInvolvedParty | undefined>(undefined);

  const [isAddInvolvedPartyDialogOpen, setAddInvolvedPartyDialogOpen] =
    useState(false);

  const [involvedParties, setInvolvedParties] = useState<
    IncidentInvolvedParty[] | undefined
  >(currentIncidentData?.involvedParties);

  const handleInvolvedListItemClicked = (data: IncidentInvolvedParty) => {
    setCurrentSelectedInvolvedParty(data);
    setAddInvolvedPartyDialogOpen(true);
  };

  const handleAddInvolvedPartyDialogClose = () => {
    setAddInvolvedPartyDialogOpen(false);
    setCurrentSelectedInvolvedParty(undefined);
  };

  const handleOnAddInvolvedPartyButtonClicked = () => {
    setCurrentSelectedInvolvedParty(undefined);
    setAddInvolvedPartyDialogOpen(true);
  };

  const handleOnAddEditInvolvedParty = (
    involvedParty: IncidentInvolvedParty
  ) => {
    const index = involvedParties?.findIndex(
      (item) => item.personGovId === involvedParty.personGovId
    );

    if (
      index !== undefined &&
      index !== -1 &&
      involvedParties &&
      involvedParties.length > 0
    ) {
      const updatedData = [...involvedParties];
      updatedData[index] = involvedParty;
      setInvolvedParties(updatedData);
    } else {
      setInvolvedParties([...(involvedParties || []), involvedParty]);
    }

    setAddInvolvedPartyDialogOpen(false);
  };

  useEffect(() => {
    if (clientId && (driversData === undefined || driversData?.length === 0)) {
      RepositoryDriver()
        .getDrivers(clientId)
        .then((data) => {
          setDriversData(data);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const NewIncidentSchema = Yup.object().shape({
    title: genericRequiredStringSchema(translate),
    dateTime: genericRequiredDateSchema(translate),
    street: genericRequiredStringSchema(translate),
    houseNumber: genericRequiredStringSchema(translate),
    city: genericRequiredStringSchema(translate),
    zipCode: genericAnySchema(),
    relatedDriverId: genericRequiredStringSchema(translate),
    description: genericAnySchema(),
    reason: genericAnySchema(),
    conclusion: genericAnySchema(),
    policeNotes: genericAnySchema(),
    notes: genericAnySchema(),
    images: arraySchema(),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentIncidentData?.title || "",
      dateTime: currentIncidentData?.dateTime
        ? parseISO(currentIncidentData.dateTime)
        : new Date(),
      street: currentIncidentData?.address?.street || "",
      houseNumber: currentIncidentData?.address?.houseNumber || "",
      city: currentIncidentData?.address?.city || "",
      zipCode: currentIncidentData?.address?.zipCode || "",

      description: currentIncidentData?.description || "",
      reason: currentIncidentData?.reason || "",
      conclusion: currentIncidentData?.conclusion || "",
      policeNotes: currentIncidentData?.policeNotes || "",
      notes: currentIncidentData?.notes || "",
      relatedDriverId: currentIncidentData?.relatedDriver?.id || "",
      images: currentIncidentData?.photosUrls || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentIncidentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewIncidentSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentIncidentData) {
      reset(defaultValues);
    }
  }, [currentIncidentData, defaultValues, reset]);

  const onSubmit = async (data: any) => {
    try {
      const createOrUpdateData: CreateOrUpdateVehicleIncident = {
        title: data.title,
        dateTime: data.dateTime,
        address:
          data.street || data.houseNumber || data.city || data.zipCode
            ? ({
                street: data.street,
                houseNumber: data.houseNumber,
                city: data.city,
                zipCode: data.zipCode,
              } as Address)
            : undefined,
        description: data.description,
        reason: data.reason,
        conclusion: data.conclusion,
        policeNotes: data.policeNotes,
        notes: data.notes,
        involvedParties,
      };

      let result;
      if (!currentIncidentData) {
        result = await dispatch(
          createVehicleIncident({
            clientId,
            licenseNumber: vehicleId,
            relatedDriverId: data.relatedDriverId,
            createIncidentObject: createOrUpdateData,
          })
        );
      } else if (currentIncidentData?.id) {
        result = await dispatch(
          updateVehicleIncident({
            clientId,
            licenseNumber: vehicleId,
            incidentId: currentIncidentData.id,
            updateIncidentObject: createOrUpdateData,
          })
        );
      } else {
        result = undefined;
      }

      if (result?.payload) {
        const imgsToUploadBase64 = await Promise.all(
          data.images?.map(async (localFile: File | string) => {
            if (typeof localFile === "string") {
              return undefined;
            }
            
            const reducedFile = await reduceImageSize(localFile, 1080);
            const fileAsBase64 = reducedFile
              ? await convertFileToBase64(reducedFile)
              : undefined;
            const prefixToRemove = "data:image/jpeg;base64,";
            const fileAsBase64WithoutPrefix = fileAsBase64
              ? fileAsBase64.substring(prefixToRemove.length)
              : undefined;
            return fileAsBase64WithoutPrefix;
          })
        );

        // clear undefined values:
        const imgsToUploadBase64Cleared = imgsToUploadBase64.filter(
          (img) => img !== undefined
        ) as string[];

        // Upload image
        const incidentId = result.payload.id;
        if (imgsToUploadBase64Cleared.length > 0) {
          // Upload images
          const uploadRequest = {
            imagesDataBase64: imgsToUploadBase64Cleared,
          } as AddVehicleIncidentImgsRequestBody;
          const imgsUploadResult = await dispatch(
            uploadIncidentImages({
              clientId,
              licenseNumber: vehicleId,
              incidentId,
              request: uploadRequest,
            })
          );

          if (imgsUploadResult?.payload) {
            enqueueSnackbar(`${translate("form_message_success_message")}`);
            if (onComplete) {
              onComplete();
            }
          } else {
            enqueueSnackbar(`${translate("error_incident_add_title")}`, {
              variant: "error",
            });
          }
        } else {
          enqueueSnackbar(
            !currentIncidentData
              ? `${translate("form_message_success_message")}`
              : `${translate("form_message_update_success_message")}`
          );

          if (onComplete) {
            onComplete();
          }
        }
      } else {
        enqueueSnackbar(`${translate("error_incident_add_title")}`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`${translate("error_incident_add_title")}`, {
        variant: "error",
      });
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          key: file.name,
          preview: URL.createObjectURL(file),
        })
      );

      setValue("images", [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const filtered =
        values.images && values.images?.filter((file) => file !== inputFile);
      setValue("images", filtered);
    },
    [setValue, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    setValue("images", []);
  }, [setValue]);

  const involvedPartiesTableHead: TableHeadItem[] = [
    {
      id: "personName",
      label: `${translate("incident_details_involved_parties_table_head_person_name")}`,
    },
    {
      id: "personGovId",
      label: `${translate("incident_details_involved_parties_table_head_person_gov_id")}`,
    },
    {
      id: "type",
      label: `${translate("incident_details_involved_parties_table_head_type")}`,
    },
    {
      id: "actions",
      label: `${translate("global_actions")}`,
    },
  ];

  const renderTitleAndActions = (
    <Stack direction={"row"} justifyContent="space-between">
      <Stack>
        <Typography variant="h4">{`${translate(
          currentIncidentData ? "vehicle_incident_edit" : "vehicle_incident_add"
        )}`}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={1}>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting || isLoading.upload}
          sx={{ minWidth: 120 }}
        >
          {!currentIncidentData
            ? `${translate("global_add_new")}`
            : `${translate("global_save")}`}
        </LoadingButton>

        <Button
          onClick={() => {
            if (onCloseClicked) {
              onCloseClicked();
            }
          }}
          variant="outlined"
          color="error"
        >
          {`${translate("global_cancel_and_close")}`}
        </Button>
      </Stack>
    </Stack>
  );

  const renderDetails = (
    <Stack>
      {/** Details */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_details")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <RHFTextField
          name="title"
          label={`${translate("vehicle_incident_add_edit_title")}`}
        />

        <RHFDateTimePicker
          name="dateTime"
          label={`${translate("vehicle_incident_add_edit_date_time")}`}
        />

        <RHFSelect
          native
          name="relatedDriverId"
          label={`${translate("vehicle_incident_add_edit_related_driver_id")}`}
        >
          <option />
          {driversData?.map(
            (driverData) =>
              driverData && (
                <option key={driverData.govId} value={driverData.id}>
                  {`${driverData.fullName} | ${driverData.govId}`}
                </option>
              )
          )}
        </RHFSelect>
      </Box>
    </Stack>
  );

  const renderAddress = (
    <Stack>
      {/** Address */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_address")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        {getAddressInputFields.map((field) => (
          <RHFTextField
            key={field.name}
            name={field.name}
            label={`${translate(field.lablel_key)}`}
          />
        ))}
      </Box>
    </Stack>
  );

  const renderInvolvedParties = (
    <InvolvedPartiesPage
      translate={translate}
      involvedParties={involvedParties}
      onEditClicked={handleInvolvedListItemClicked}
      tableHeadOverride={involvedPartiesTableHead}
      onActionButtonClicked={handleOnAddInvolvedPartyButtonClicked}
      actionButtonLabelKey="vehicle_incident_add_edit_involved_parties_add"
      actionButtonIcon={<Add />}
      hideNoDataView
    />
  );

  const renderExtraDetails = (
    <Stack sx={{ paddingTop: 3 }} spacing={2}>
      <Typography variant="h6">
        {`${translate("vehicle_incident_add_edit_more_details")}`}
      </Typography>
      <Stack spacing={3}>
        <RHFTextField
          name="description"
          type="text"
          multiline
          rows={5}
          label={`${translate("vehicle_incident_add_edit_description")}`}
        />

        <DetailsTextField
          name="reason"
          labelId="vehicle_incident_add_edit_reason"
          isEdit={currentIncidentData?.reason !== undefined}
          translate={translate}
        />

        <DetailsTextField
          name="conclusion"
          labelId="vehicle_incident_add_edit_conclusion"
          isEdit={currentIncidentData?.conclusion !== undefined}
          translate={translate}
        />

        <DetailsTextField
          name="policeNotes"
          labelId="vehicle_incident_add_edit_police_notes"
          isEdit={currentIncidentData?.policeNotes !== undefined}
          translate={translate}
        />

        <DetailsTextField
          name="notes"
          labelId="vehicle_incident_add_edit_notes"
          isEdit={currentIncidentData?.notes !== undefined}
          translate={translate}
        />
      </Stack>
    </Stack>
  );

  const renderImagesSelect = (
    <Stack spacing={2} sx={{ paddingTop: 3 }}>
      <Typography variant="h6">
        {`${translate("vehicle_incident_add_edit_photos")}`}
      </Typography>
      <RHFUpload
        multiple
        thumbnail
        name="images"
        maxSize={10 * 1024 * 1024} // 10MB
        onDrop={handleDrop}
        onRemove={handleRemoveFile}
        onRemoveAll={handleRemoveAllFiles}
      />
    </Stack>
  );

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack padding={4}>
          {renderTitleAndActions}

          {/* Content */}
          <Grid container spacing={3}>
            <Grid item xs>
              <Stack spacing={1}>{renderDetails}</Stack>
            </Grid>
            <Grid item xs>
              {renderAddress}
            </Grid>
          </Grid>

          {renderInvolvedParties}

          {renderExtraDetails}

          {renderImagesSelect}

          {/** Bottom save button */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minWidth="100vh"
          >
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting || isLoading.upload}
              sx={{ minWidth: 240, marginTop: 4 }}
            >
              {!currentIncidentData
                ? `${translate("global_add_new")}`
                : `${translate("global_save")}`}
            </LoadingButton>
          </Box>
        </Stack>
      </FormProvider>

      <Dialog
        fullWidth
        maxWidth="xl"
        open={isAddInvolvedPartyDialogOpen}
        onClose={handleAddInvolvedPartyDialogClose}
      >
        <IncidentInvolvedPartyAdd
          translate={translate}
          currentData={currentSelectedInvolvedParty}
          onAddEditInvolvedParty={handleOnAddEditInvolvedParty}
          onCloseClicked={handleAddInvolvedPartyDialogClose}
        />
      </Dialog>
    </>
  );
}

import { useState, useEffect } from "react";

import { Button, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";

import { VehicleIncidentHeader } from "../../../../../api";

import RichTableView from "../../../common/views/richTableView/rich-table-view";
import { TableHeadItem } from "../../../../components/table/TableHeadCustom";
import { RichTableFilters } from "../../../common/views/richTableView/rich-table-filters-result";
import { fTimestamp } from "../../../../utils/format/formatTime";
import IncidentsListViewRowItem from "./IncidentsListViewRowItem";

// ----------------------------------------------------------------------

export type IncidentsListViewProps = {
  translate: Function;
  isLoadingData: boolean;
  // isDownloading: boolean;
  titleKey?: string;

  incidentsHeaders: VehicleIncidentHeader[];

  onRowClicked: (_incident: VehicleIncidentHeader) => void;

  // Action Button:
  onAddButtonClicked?: () => void;
  // onDownloadReportButtonClicked?: () => void;
};

const applyFilter = (incidents: VehicleIncidentHeader[], filters: RichTableFilters, dateError: boolean): VehicleIncidentHeader[] => {
  const { globalSearch, startDate } = filters;
  
  let filteredData: VehicleIncidentHeader[] = [...incidents];

  if (globalSearch !== "") {
    filteredData = incidents.filter((itemObject: any) =>
      Object.keys(itemObject).some((key) => {
        const value = itemObject[key];
        return (
          value &&
          typeof value === "string" &&
          value.toLowerCase().includes(globalSearch.toLowerCase())
        );
      })
    );
  }

  if (!dateError) {
    if (startDate) {
      filteredData = incidents.filter(
        (incident) =>
          incident.dateTime && fTimestamp(incident.dateTime) >= fTimestamp(startDate)
      );
    }
  }
  return filteredData;
};


export default function IncidentsListView({
  translate,
  isLoadingData,
  titleKey,
  incidentsHeaders,
  onRowClicked,
  onAddButtonClicked
}: IncidentsListViewProps) {
  const [filteredTableData, _setFilteredTableData] = useState(incidentsHeaders);

  useEffect(() => {
    _setFilteredTableData(incidentsHeaders);
  }, [incidentsHeaders]);

  const handleOnTableRowClicked = (invoice: VehicleIncidentHeader) => {
    onRowClicked(invoice);
  };

  const TABLE_HEAD: TableHeadItem[] = [
    {
      id: "title",
      label: `${translate("vehicle_page_incidents_table_head_title")}`,
    },
    {
      id: "dateTime",
      label: `${translate("vehicle_page_incidents_table_head_title_date_time")}`,
    },
    {
      id: "address",
      label: `${translate("vehicle_page_incidents_table_head_title_address")}`,
    },
    {
      id: "relatedDriver",
      label: `${translate("vehicle_page_incidents_table_head_title_related_driver")}`,
    }
  ];


  const handleOnApplyFilters = (filters: RichTableFilters, dateError: boolean) => {
    _setFilteredTableData(applyFilter(incidentsHeaders, filters, dateError));
  };

  return (
    <RichTableView
      translate={translate}
      isLoadingData={isLoadingData}
      titleKey={titleKey}
      filteredTableData={filteredTableData}
      tableHeads={TABLE_HEAD}
      showEndDate={false}
      onApplyFilters={handleOnApplyFilters}
      randerRow={(item: VehicleIncidentHeader) => (
        <IncidentsListViewRowItem
          translate={translate}
          key={item.id || ""}
          row={item}
          onViewRow={() => handleOnTableRowClicked(item)}
        />
      )}
      onActionButtonClicked={onAddButtonClicked}
      overrideActionButtons={
        <>
        <Stack spacing={1} direction={'row'}>
            <Button
              onClick={onAddButtonClicked}
              variant="contained"
              color="success"
              startIcon={<Add />}
            >
              {`${translate("vehicle_page_incidents_title_create_new")}`}
            </Button>

            {/* <Button
              disabled={isDownloading}
              onClick={onDownloadReportButtonClicked}
              variant="contained"
              startIcon={!isDownloading ? (
                <Download />
              ) : (
                <CircularProgress color={'primary'} size={16} thickness={5}/>
              )}
            >
              {`${translate("vehicle_page_incidents_list_title_download_report")}`}
            </Button> */}
        </Stack>
      </>
      }
    />
  );
}

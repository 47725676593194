import { useEffect, useRef, useState } from "react";
import Calendar from "@fullcalendar/react";

import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import heLocale from "@fullcalendar/core/locales/he";
import { DateSelectArg, EventClickArg } from "@fullcalendar/core";

import { Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Add, ArrowBackIos, ArrowForwardIos, Download } from "@mui/icons-material";

import { DriverUser, VehicleTachographHeader } from "../../../../api";
// import { StyledCalendar } from "../../../components/calendar/styles";
import LoadingView from "../../../components/loading-view";
import VehicleTachographsPageToolbar from "./VehicleTachographsPageToolbar";
import { fDate } from "../../../utils/format/formatTime";
import { StyledCalendar } from "./styles";

export type VehicleTachographsTableProps = {
  translate: Function;
  tachographsHeaders: VehicleTachographHeader[] | undefined;
  isLoadingData: boolean;
  isLoadingCreate: boolean;
  isLoadingDownload: boolean;
  selectedDate: Date;
  driversList?: DriverUser[];
  selectedDriverId?: string;
  handleOnDateChanged: (_newDate: Date) => void;
  setSelectedDriverId: (_driverId: string) => void;
  onTachographClicked: (_header: VehicleTachographHeader) => void;
  handleOnCalendarDayClicked: (_date: Date) => void;
  handleOnAddNewClicked: () => void;
  handleOnDownloadClicked: () => void;
  handleOnMonthSkip: (_isNext: boolean) => void;
};

export default function VehicleTachographsTable({
  translate,
  tachographsHeaders,
  isLoadingData,
  isLoadingCreate,
  isLoadingDownload,
  onTachographClicked,
  selectedDate,
  driversList,
  selectedDriverId,
  handleOnDateChanged,
  setSelectedDriverId,
  handleOnCalendarDayClicked,
  handleOnAddNewClicked,
  handleOnDownloadClicked,
  handleOnMonthSkip,
}: VehicleTachographsTableProps) {
  const theme = useTheme();

  const calendarRef = useRef<Calendar>(null);

  const [calendarEventsData, setCalendarEventsData] = useState<
    Partial<ICalendarEvent>[]
  >([]);

  const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase();

  const getColorHexByName = (name: string) => {
    if (["A", "N", "H", "L", "Q"].includes(getCharAtName(name)))
      return theme.palette.primary.main;
    if (["F", "G", "T", "I", "J"].includes(getCharAtName(name)))
      return theme.palette.info.main;
    if (["K", "D", "Y", "B", "O"].includes(getCharAtName(name)))
      return theme.palette.success.main;
    if (["P", "E", "R", "S", "U"].includes(getCharAtName(name)))
      return theme.palette.warning.main;
    if (["V", "W", "X", "M", "Z"].includes(getCharAtName(name)))
      return theme.palette.error.main;
    if (["א", "ב", "ג", "ד", "ה"].includes(getCharAtName(name)))
      return theme.palette.primary.main;
    if (["ו", "ז", "ח", "ט", "י"].includes(getCharAtName(name)))
      return theme.palette.info.main;
    if (["כ", "ל", "מ", "נ", "ס"].includes(getCharAtName(name)))
      return theme.palette.success.main;
    if (["ע", "פ", "צ", "ק", "ר"].includes(getCharAtName(name)))
      return theme.palette.warning.main;
    if (["ש", "ת", "ן", "ך", "ם"].includes(getCharAtName(name)))
      return theme.palette.error.main;
    return theme.palette.primary.main;
  };

  useEffect(() => {
    if (tachographsHeaders) {
      const eventsData: Partial<ICalendarEvent>[] = tachographsHeaders.map(
        (header) =>
          ({
            id: header.id,
            title: `${header.relatedDriverName}${header.isOverspeeding ? " 🚀" : ""}${header.isOvertime ? " ⏱️" : ""}`,
            start: header.startDateTime,
            end: header.endDateTime,
            headerData: header,
            backgroundColor: getColorHexByName(header.relatedDriverName),
          }) as Partial<ICalendarEvent>
      );
      setCalendarEventsData(eventsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tachographsHeaders]);

  const onClickEvent = (arg: EventClickArg) => {
    const header = arg.event.extendedProps.headerData;
    if (header) {
      onTachographClicked(header);
    }
  };

  const onSelectDay = (arg: DateSelectArg) => {
    const clickedDayDate = arg.start;
    if (clickedDayDate) {
      handleOnCalendarDayClicked(clickedDayDate);
    }
  };

  return (
    <>
      <Stack spacing={3} paddingTop={4}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          <Typography variant="h5">{`${translate("vehicle_tachograph_page_title")}`}</Typography>

          <Stack direction="row" spacing={2}>
            <LoadingButton
              loading={isLoadingDownload}
              variant="contained"
              color="success"
              startIcon={<Download />}
              onClick={handleOnDownloadClicked}
            >
              {`${translate("vehicle_tachograph_page_title_download_summary")}`}
            </LoadingButton>

            <LoadingButton
              loading={isLoadingCreate}
              variant="contained"
              startIcon={<Add />}
              onClick={handleOnAddNewClicked}
            >
              {`${translate("vehicle_tachograph_page_title_add_new")}`}
            </LoadingButton>
          </Stack>
        </Stack>

        <VehicleTachographsPageToolbar
          translate={translate}
          selectedDate={selectedDate}
          driversList={driversList}
          selectedDriverId={selectedDriverId}
          handleOnDateChanged={handleOnDateChanged}
          setSelectedDriverId={setSelectedDriverId}
        />

        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={() => handleOnMonthSkip(false)}>
            <ArrowForwardIos />
          </IconButton>
          <Typography variant="h4">{`${fDate(selectedDate, "he", "MMMM yyyy")}`}</Typography>
          <IconButton onClick={() => handleOnMonthSkip(true)}>
            <ArrowBackIos />
          </IconButton>
        </Stack>

        <Card>
          {isLoadingData ? (
            <LoadingView />
          ) : (
            <StyledCalendar>
              <Calendar
                locale={heLocale}
                direction="rtl"
                selectable
                ref={calendarRef}
                initialDate={selectedDate}
                initialView="dayGridMonth"
                dayMaxEventRows={5}
                eventDisplay="block"
                events={calendarEventsData}
                headerToolbar={false}
                select={onSelectDay}
                eventClick={onClickEvent}
                height={"auto"}
                plugins={[interactionPlugin, dayGridPlugin]}
              />
            </StyledCalendar>
          )}
        </Card>
      </Stack>
    </>
  );
}

export type ICalendarDate = string | number;
export type ICalendarEvent = {
  id: string;
  backgroundColor: string;
  title: string;
  end: ICalendarDate;
  start: ICalendarDate;
  headerData: VehicleTachographHeader;
};

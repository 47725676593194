import * as Yup from "yup";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

// components
import { useSnackbar } from "../../../global/components/snackbar";
import { Client } from "../../../api";
import FormProvider, {
  RHFTextField,
} from "../../../global/components/hook-form";
import RepositoryClient from "../../repositories/RepositoryClient";
import { getAddressInputFields } from "../../utils/formsUtils";
import {
  emailSchema,
  genericRequiredStringSchema,
  genericStringSchema,
  numberOnlyStringSchema,
  phoneNumberSchema,
  textOnlySchema,
  textOnlySchemaRequired,
} from "../../../global/utils/formValidators";
import signEample from "../../assets/images/sign_example.jpg";
import Image from "../../../global/components/image";

// ----------------------------------------------------------------------

type Props = {
  isEdit?: boolean;
  currentClient?: Client;
  translate: Function;
  onComplete?: Function;
};

export default function ClientsListNewEditForm({
  isEdit = false,
  currentClient,
  translate,
  onComplete,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [currentSignFile, setCurrentSignFile] = useState<string | undefined>(
    signEample
  );

  const [showContactInfo, setShowContactInfo] = useState(
    (currentClient?.contacts?.length || 0) > 0
  );
  const handlehasContactInfo = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setShowContactInfo(checked);
  };

  const NewUserSchema = Yup.object().shape({
    companyName: genericRequiredStringSchema(translate),
    id: genericRequiredStringSchema(translate),
    email: emailSchema(translate),
    phoneNumber: phoneNumberSchema(),
    street: textOnlySchema(translate),
    houseNumber: genericStringSchema(),
    city: textOnlySchemaRequired(translate),
    zipCode: numberOnlyStringSchema(translate),
    signatureBase64: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      id: currentClient?.id || "",
      companyName: currentClient?.companyName || "",
      street: currentClient?.address?.street || "",
      houseNumber: currentClient?.address?.houseNumber || "",
      city: currentClient?.address?.city || "",
      zipCode: currentClient?.address?.zipCode || "",
      phoneNumber: currentClient?.phoneNumber || "",
      email: currentClient?.email || "",
      signatureBase64: undefined,

      contactName: currentClient?.contacts?.at(0)?.name || "",
      contactEmail: currentClient?.contacts?.at(0)?.email || "",
      contactPhoneNumber: currentClient?.contacts?.at(0)?.phone || "",
      contactStreet: currentClient?.contacts?.at(0)?.address?.street || "",
      contactHouseNumber:
        currentClient?.contacts?.at(0)?.address?.houseNumber || "",
      contactCity: currentClient?.contacts?.at(0)?.address?.city || "",
      contactZipCode: currentClient?.contacts?.at(0)?.address?.zipCode || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentClient]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentClient) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentClient]);

  const handleUploadClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result) {
          const base64 = reader.result.toString();
          setCurrentSignFile(base64);
          setValue("signatureBase64", base64.split(",")[1]);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  // Submit: Add new client
  const onSubmit = async (data: any) => {
    try {
      const client: Client = {
        id: data.id,
        companyName: data.companyName,
        address: {
          street: data.street,
          houseNumber: data.houseNumber,
          city: data.city,
          zipCode: data.zipCode,
        },
        phoneNumber: data.phoneNumber,
        email: data.email,
        singatureBase64: data.signatureBase64,
        relatedUserId: currentClient?.relatedUserId,
      };

      if (showContactInfo) {
        client.contacts = [
          {
            name: data.contactName,
            email: data.contactEmail,
            phone: data.contactPhoneNumber,
          },
        ];
      }

      let result;
      if (isEdit) {
        result = await RepositoryClient().updateClientData(client);
      } else {
        result = await RepositoryClient().addClient(client);
      }

      if (result) {
        reset();
        enqueueSnackbar(
          !isEdit
            ? `${translate("form_message_success_message")}`
            : `${translate("form_message_update_success_message")}`
        );

        // Wait for 1 second before closing the dialog
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (onComplete) {
          onComplete();
        }
      } else {
        enqueueSnackbar(`${translate("error_client_add_title")}`, {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(`${translate("error_client_add_title")}: ${error}`, {
        variant: "error",
      });
    }
  };

  const renderSignature = (
    <Stack spacing={2} sx={{ p: 3, maxWidth: "45%" }}>
      <Typography variant="h6">{`${translate("client_singature")}`}</Typography>
      <Stack>
        <Typography variant="body2">
          {`${translate("client_singature_upload_description")}`}
        </Typography>
        <Box sx={{ height: "10px" }} />
        <Image
          sx={currentClient?.signaturePicUrl ? {} : { width: "100%", height: 240 }}
          src={currentClient?.signaturePicUrl || currentSignFile}
          alt="sign example"
        />
      </Stack>

      <Box sx={{ height: "10px" }} />

      <Button variant="contained" component="label">
        {`${translate("client_singature_upload")}`}
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleUploadClick}
        />
      </Button>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h4">
          {isEdit
            ? `${translate("clients_edit_title")}`
            : `${translate("clients_add_client")}`}
        </Typography>

        <Stack direction={"row"} justifyContent="space-between">
          {/** Form and details */}
          <Stack justifyContent="space-between">
            {/* Company Details */}
            <Stack sx={{ marginTop: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ marginBottom: 3 }}>
                {`${translate("form_input_title_company_info")}`}
              </Typography>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                }}
              >
                <RHFTextField
                  name="companyName"
                  label={`${translate("form_input_company_name")}`}
                />
                <RHFTextField
                  disabled={isEdit}
                  name="id"
                  label={`${translate("form_input_company_id")}`}
                  type="number"
                />
                <RHFTextField
                  name="email"
                  label={`${translate("form_input_email")}`}
                />
                <RHFTextField
                  name="phoneNumber"
                  label={`${translate("form_input_phone_number")}`}
                  type="number"
                />

                {getAddressInputFields.map((field) => (
                  <RHFTextField
                    key={field.name}
                    name={field.name}
                    label={`${translate(field.lablel_key)}`}
                  />
                ))}
              </Box>

              <FormControlLabel
                control={
                  <Switch
                    onChange={handlehasContactInfo}
                    checked={showContactInfo}
                  />
                }
                label={`${translate("form_input_has_contact_info")}`}
                sx={{ marginTop: 3, marginBottom: 3 }}
              />
            </Stack>

            {/* Contact Details */}
            {showContactInfo && (
              <Stack sx={{ marginBottom: 4 }}>
                <Typography variant="h6" gutterBottom sx={{ marginBottom: 3 }}>
                  {`${translate("form_input_title_contact_info")}`}
                </Typography>
                <Box
                  rowGap={3}
                  columnGap={2}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <RHFTextField
                    name="contactName"
                    label={`${translate("form_input_name")}`}
                  />
                  <RHFTextField
                    name="contactEmail"
                    label={`${translate("form_input_email")}`}
                  />
                  <RHFTextField
                    name="contactPhoneNumber"
                    label={`${translate("form_input_phone_number")}`}
                  />
                </Box>
              </Stack>
            )}
          </Stack>

          {/** Signature */}
          {renderSignature}
        </Stack>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {!isEdit
              ? `${translate("form_input_create_client")}`
              : `${translate("form_input_save_changes")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

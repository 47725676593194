import { useEffect, useState } from "react";

// @mui
import {
  Button,
  Card,
  Dialog,
  TableRow as MuiTableRow,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";

import { Add, Delete, Download, Update } from "@mui/icons-material";

// components
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
  ViewGenericDataTableFilterData,
} from "../../common/views/tableView/ViewGenericDataTable";
import { VehicleDocument, VehicleDocumentType } from "../../../../api";
import GenericMessageView from "../../common/views/GenericMessageView";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";
import { fDate } from "../../../utils/format/formatTime";
import VehiclePageDocumentAddEdit from "./VehiclePageDocumentAddEdit";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  addVehicleDocument,
  deleteVehicleDocument,
  downloadVehicleDocument,
  getVehicleDocuments,
  resetVehicleDocuments,
  updateVehicleDocumentExpirationDate,
} from "./vehiclePageDocumentsSlice";

// ----------------------------------------------------------------------

type VehiclePageDocumentRowProps = {
  vehicleDocument: VehicleDocument;
  translate: Function;
  onRowClick?: (_vehicleDocument: VehicleDocument) => void;
  onDelete?: (_vehicleDocument: VehicleDocument) => void;
  onUpdateDate?: (_vehicleDocument: VehicleDocument) => void;
};

function VehiclePageDocumentRow({
  vehicleDocument,
  translate,
  onRowClick,
  onDelete,
  onUpdateDate,
}: VehiclePageDocumentRowProps) {
  const { documentType, title, expirationDate, isExpired } = vehicleDocument;

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(vehicleDocument);
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(vehicleDocument);
    }
  };

  const handleUpdateDate = () => {
    if (onUpdateDate) {
      onUpdateDate(vehicleDocument);
    }
  };

  return (
    <>
      <MuiTableRow>
        {/* title */}
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {title ?? translate("vehicle_documents_page_title_no_title")}
          </Typography>
        </TableCell>

        {/* type */}
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {translate(`vehicle_document_type_${documentType?.toLowerCase()}`)}
          </Typography>
        </TableCell>

        {/* expirationDate in red color if expired */}
        <TableCell align="center">
          <Typography
            variant="subtitle2"
            sx={{
              color: isExpired ? "error.main" : "text.primary",
            }}
          >
            {`${fDate(expirationDate)} ${
              isExpired
                ? `(${translate("vehicle_documents_page_title_is_expired")})`
                : ""
            }`}
          </Typography>
        </TableCell>

        {/* actions */}
        <TableCell align="left">
          <Stack direction="row" spacing={2}>
            <Button
              onClick={handleRowClick}
              key={"download_document"}
              color={"primary"}
              variant="contained"
              startIcon={<Download />}
            >
              {`${translate("vehicle_documents_page_title_download")}`}
            </Button>

            <Button
              onClick={handleDelete}
              key={"delete_document"}
              color={"error"}
              variant="contained"
              startIcon={<Delete />}
            >
              {`${translate("global_delete")}`}
            </Button>

            <Button
              onClick={handleUpdateDate}
              key={"update_date"}
              color={"warning"}
              variant="contained"
              startIcon={<Update />}
            >
              {`${translate("vehicle_documents_page_title_update_date")}`}
            </Button>
          </Stack>
        </TableCell>
      </MuiTableRow>
    </>
  );
}

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  navigate: Function;
  clientId?: string;
  licenseNumber?: string;
};

export default function VehiclePageDocuments({
  clientId,
  licenseNumber,
  translate,
}: Props) {
  const dispatch = useDashboardAppDispatch();

  // Delete:
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [currentSelectedDoc, setCurrentSelectedDoc] = useState<
    VehicleDocument | undefined
  >(undefined);

  // Add:
  const [isAddEditDialogOpen, setIsAddEditDialogOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleAddClicked = () => {
    setIsEditMode(false);
    setCurrentSelectedDoc(undefined);
    setIsAddEditDialogOpen(true);
  };

  const onAddDocument = async (documentData: any): Promise<string | null> => {
    if (licenseNumber && clientId && documentData.documentFile) {
      return new Promise((resolve) => {
        dispatch(
          addVehicleDocument({
            clientId,
            licenseNumber,
            documentType: documentData.type,
            expirationDate: documentData.expirationDate,
            title:
              documentData.title ??
              `${translate(
                `vehicle_document_type_${documentData.type.toLowerCase()}`
              )}`,
            file: documentData.documentFile,
            onRespose: (isSuccess: boolean) => {
              if (isSuccess) {
                dispatch(getVehicleDocuments({ clientId, licenseNumber }));
                resolve(null);
              } else {
                resolve(
                  `${translate("vehicle_documents_page_error_adding_document")}`
                );
              }
            },
          })
        );
      });
    }
    return null;
  };

  const onUpdateDocumentExpirationDate = async (
    vehicleDocument: any
  ): Promise<string | null> => {
    if (
      licenseNumber &&
      clientId &&
      vehicleDocument.expirationDate &&
      vehicleDocument.id &&
      vehicleDocument.type
    ) {
      return new Promise((resolve) => {
        dispatch(
          updateVehicleDocumentExpirationDate({
            clientId,
            licenseNumber,
            documentType: vehicleDocument.type,
            documentId: vehicleDocument.id,
            expirationDate: vehicleDocument.expirationDate,
            onRespose: (isSuccess: boolean) => {
              if (isSuccess) {
                dispatch(getVehicleDocuments({ clientId, licenseNumber }));
                resolve(null);
              } else {
                resolve(
                  `${translate(
                    "vehicle_documents_page_error_updating_expiration_date"
                  )}`
                );
              }
            },
          })
        );
      });
    }
    return null;
  };

  const onDownloadDocument = (vehicleDocument: VehicleDocument) => {
    if (licenseNumber && clientId && vehicleDocument.documentType) {
      dispatch(
        downloadVehicleDocument({
          clientId,
          licenseNumber,
          documentType: vehicleDocument.documentType,
          documentId: vehicleDocument.id,
          onRespose: (responseVehicleDocument: VehicleDocument) => {
            if (responseVehicleDocument.fileUrl != null) {
              window.open(responseVehicleDocument.fileUrl, "_blank");
            }
          },
        })
      );
    }
  };

  const onDeleteDocument = (vehicleDocument: VehicleDocument) => {
    if (licenseNumber && clientId && vehicleDocument.documentType) {
      dispatch(
        deleteVehicleDocument({
          clientId,
          licenseNumber,
          documentType: vehicleDocument.documentType,
          documentId: vehicleDocument.id,
          onRespose: (isSuccess: boolean) => {
            if (isSuccess) {
              dispatch(getVehicleDocuments({ clientId, licenseNumber }));
            }
          },
        })
      );
    }
  };

  const vehicleDocuments = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageDocumentsSlice.data
  );

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageDocumentsSlice.isLoading
  );

  const [filteredData, setFilteredData] = useState(vehicleDocuments);

  useEffect(() => {
    if (licenseNumber && clientId && vehicleDocuments.length === 0) {
      dispatch(getVehicleDocuments({ clientId, licenseNumber }));
    }

    return () => {
      dispatch(resetVehicleDocuments());
      setFilteredData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseNumber, clientId]);

  const [
    listOfViewGenericDataTableFilterData,
    setListOfViewGenericDataTableFilterData,
  ] = useState<Array<ViewGenericDataTableFilterData> | null>(null);
  const [currentSelectedFilterKey, setCurrentSelectedFilterKey] = useState<
    string | undefined
  >(undefined);

  const handleFilterChange = (newFilter: string) => {
    setCurrentSelectedFilterKey(newFilter);
  };

  useEffect(() => {
    if (listOfViewGenericDataTableFilterData === null) {
      setListOfViewGenericDataTableFilterData(
        Object.values(VehicleDocumentType).map((type) => ({
          label: `${translate(`vehicle_document_type_${type?.toLowerCase()}`)}`,
          key: type,
          payloadObject: type,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDocuments, listOfViewGenericDataTableFilterData]);

  // Filter:
  useEffect(() => {
    if (vehicleDocuments && currentSelectedFilterKey) {
      setFilteredData(
        vehicleDocuments.filter(
          (vehicle: VehicleDocument) =>
            vehicle.documentType === currentSelectedFilterKey
        )
      );
    } else {
      setFilteredData(vehicleDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDocuments, currentSelectedFilterKey]);

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "docuemntTitle",
      label: `${translate("vehicle_documents_page_title_document_title")}`,
      align: "left",
    },
    {
      id: "documentType",
      label: `${translate("vehicle_documents_page_title_document_type")}`,
      align: "left",
    },
    {
      id: "expirationDate",
      label: `${translate("vehicle_documents_page_title_expiration_date")}`,
      align: "center",
    },
    {
      id: "actions",
      label: `${translate("vehicle_documents_page_title_actions")}`,
      align: "left",
    },
  ];

  const handleRowClick = (vehicleDocument: VehicleDocument) => {
    onDownloadDocument(vehicleDocument);
  };

  const handleDeleteDocumentClicked = (vehicleDocument: VehicleDocument) => {
    setOpenDeleteConfirm(true);
    setCurrentSelectedDoc(vehicleDocument);
  };

  const handleUpdateDateClicked = (vehicleDocument: VehicleDocument) => {
    setIsEditMode(true);
    setCurrentSelectedDoc(vehicleDocument);
    setIsAddEditDialogOpen(true);
  };

  const preformDocumentDelete = () => {
    if (currentSelectedDoc) {
      onDeleteDocument(currentSelectedDoc);
      setCurrentSelectedDoc(undefined);
    }
    setOpenDeleteConfirm(false);
  };

  const renderRow = (vehicleDocument: VehicleDocument) => (
    <VehiclePageDocumentRow
      key={vehicleDocument.id}
      vehicleDocument={vehicleDocument}
      translate={translate}
      onRowClick={handleRowClick}
      onDelete={handleDeleteDocumentClicked}
      onUpdateDate={handleUpdateDateClicked}
    />
  );

  // const mapVehicleDocumentToIFile = (
  //   vehicleDocument: VehicleDocument[]
  // ): IFile[] =>
  //   vehicleDocument.map((doc) => ({
  //     id: doc.id,
  //     name: doc.title ?? "No title",
  //     size: 0,
  //     type: "pdf",
  //     url: doc.fileUrl ?? "",
  //     tags: [],
  //     isFavorited: false,
  //     shared: null,
  //     createdAt: doc.expirationDate,
  //     modifiedAt: doc.expirationDate,
  //   }));

  const genericDataTableViewProps: ViewGenericDataTableData<VehicleDocument> = {
    isLoading: isLoadingData.data,
    translate,
    titleKey: "vehicle_documents_page_title_documents_list",
    data: filteredData,
    renderRow,
    tableHead: TABLE_HEAD,
    searchable: true,
    searchLabelKey: "vehicle_documents_page_title_search_documents",
    onActionButtonClicked: handleAddClicked,
    actionButtonLabelKey: "vehicle_documents_page_title_add_document",
    actionButtonIcon: <Add />,
    listOfFilters: listOfViewGenericDataTableFilterData,
    currentSelectedFilterKey,
    onFilterChange: handleFilterChange,
    emptyStateComponent: (
      <GenericMessageView
        title={`${translate("error_vehicle_documents_page_title_empty")}`}
        subtitle={`${translate("error_vehicle_documents_page_subtitle_empty")}`}
        buttonTitle={`${translate(
          "vehicle_documents_page_title_add_document"
        )}`}
        onAction={handleAddClicked}
      />
    ),
  };

  return (
    <>
      <Card sx={{ px: 3 }}>
        <ViewGenericDataTable {...genericDataTableViewProps} />
      </Card>

      <DeleteConfimDialog
        translate={translate}
        isOpen={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        onAction={preformDocumentDelete}
      />

      <Dialog
        open={isAddEditDialogOpen}
        onClose={() => {
          setIsAddEditDialogOpen(false);
        }}
      >
        <VehiclePageDocumentAddEdit
          translate={translate}
          isEdit={isEditMode}
          currentVehicleDocument={currentSelectedDoc}
          onPreformAction={
            isEditMode ? onUpdateDocumentExpirationDate : onAddDocument
          }
          onComplete={() => {
            setIsAddEditDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
}

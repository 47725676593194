import { Theme, SxProps } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
//
import { fileData, fileFormat, fileThumb } from "./utils";
import DownloadButton from "./download-button";

// ----------------------------------------------------------------------

type FileIconProps = {
  file: File | string;
  tooltip?: boolean;
  imageView?: boolean;
  onDownload?: VoidFunction;
  sx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
};

export default function FileThumbnail({
  file,
  tooltip,
  imageView,
  onDownload,
  sx,
  imgSx,
}: FileIconProps) {
  const { name = "", preview = "" } = fileData(file);
  const format = fileFormat(name);

  const renderContent =
    format === "image" && imageView ? (
      <Box
        component="img"
        src={preview}
        sx={{
          width: 1,
          height: 1,
          flexShrink: 0,
          objectFit: "cover",
          ...imgSx,
        }}
      />
    ) : (
      <Box
        component="img"
        src={fileThumb(format)}
        sx={{
          width: 32,
          height: 32,
          flexShrink: 0,
          ...sx,
        }}
      />
    );

  if (tooltip) {
    return (
      <Stack
        flexShrink={0}
        component="span"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "fit-content",
          height: "inherit",
        }}
      >
        {renderContent}
        {onDownload && <DownloadButton onDownload={onDownload} />}
      </Stack>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadButton onDownload={onDownload} />}
    </>
  );
}

import { VehicleConfig, VehiclesDataModel, VehicleType } from "../../../api";

// A function to reformat the license plate number:
export function formatLicensePlateNumber(number: string) {
    if (number.length === 7) {
        return `${number.slice(0, 2)}-${number.slice(2, 5)}-${number.slice(5, 7)}`;
    }
    if (number.length === 8) {
        return `${number.slice(0, 3)}-${number.slice(3, 5)}-${number.slice(5, 8)}`;
    }
    return number;
}

export function getManufacturersBasedOnVehicleType(vehicleConfig: VehicleConfig, vehicleType: VehicleType | string) {
    switch (vehicleType) {
        case VehicleType.CAR:
        case VehicleType.TAXI:
            return vehicleConfig.vehicleManufacturers.cars;
        case VehicleType.COMMERCIAL:
            return vehicleConfig.vehicleManufacturers.commercial;
        case VehicleType.TRUCK:
        case VehicleType.TRUCK_SMALL:
        case VehicleType.TEAYOULIT:
            return vehicleConfig.vehicleManufacturers.trucks;
        case VehicleType.BUS:
        case VehicleType.MINIBUS:
            return vehicleConfig.vehicleManufacturers.bus;
        default:
            return [vehicleConfig.vehicleManufacturers.other];
    }
}

export function getModelsBasedOnManufacturer(vehicleConfig: VehicleConfig, vehicleType: VehicleType | string, manufacturer: string) {
    let modelsToUse: VehiclesDataModel[] = [];
    switch (vehicleType) {
        case VehicleType.CAR:
            modelsToUse = vehicleConfig.vehicleModels.cars;
            break;
        case VehicleType.TAXI:
            modelsToUse = vehicleConfig.vehicleModels.cars;
            break;
        case VehicleType.COMMERCIAL:
            modelsToUse = vehicleConfig.vehicleModels.commercial;
            break;
        default:
            modelsToUse = [];
            break;
    }

    return modelsToUse.find(
        (modelData: VehiclesDataModel) => modelData.manufacturer_id === manufacturer
    )?.models || [];
}
// @mui
import { Dialog, Stack } from "@mui/material";
// import { Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Edit, Delete, Check, Warning } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { DriverUser } from "../../../../api";
import FullWithDataView, {
  DataViewEntry,
  FullWithDataViewAction,
} from "../../common/views/FullWithDataView";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";
import ClientDriversAddEdit from "../ClientDriversAddEdit";
import { fDate } from "../../../utils/format/formatTime";
import { formatAddress } from "../../../utils/format/formatAddress";
import LicenseFrame from "../../common/views/LicenseFrame";
import { DriverLicenseRes, UploadDriverLicenseReq } from "../../../../api/api";
import GenericMessageView from "../../common/views/GenericMessageView";
import { useDashboardAppDispatch } from "../../../hooks/useRedux";
import {
  downloadDriverLicense,
  sendDriverOnboardingNotification,
} from "../driverPageSlice";
import { useSnackbar } from "../../../../global/components/snackbar";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";

type Props = {
  clientId: string;
  driver?: DriverUser | null;
  driverLicenseRes?: DriverLicenseRes | null;
  translate: Function;
  onDataChanged?: Function;
  onDeleteDriver?: Function;
  uploadDriverData?: (_req: UploadDriverLicenseReq) => void;
};

export const DriverDetails = ({
  driver,
  translate,
  menuActions,
}: {
  driver?: DriverUser | null;
  translate: Function;
  menuActions?: FullWithDataViewAction[];
}) => {
  if (!driver) {
    return <></>;
  }

  const data: DataViewEntry[] = [
    {
      title: `${translate("client_page_driver_name")}`,
      value: driver.fullName,
    },
    {
      title: `${translate("global_id_number")}`,
      value: driver.govId,
    },
    {
      title: `${translate("client_page_driver_birthday")}`,
      value: `${fDate(driver.birthday || "")}`,
    },
    {
      title: `${translate("global_phone_number")}`,
      value: `${driver.phoneCountryCode}${driver.phoneNumber}+`,
    },
    {
      title: `${translate("global_email")}`,
      value: driver.email,
    },
    {
      title: `${translate("global_address")}`,
      value: formatAddress(driver.address),
    },
  ];

  return (
    <FullWithDataView
      title={`${translate("driver_page_details_title")}`}
      itemsPerRow={3}
      data={data}
      translate={translate}
      actions={menuActions}
    />
  );
};

export default function DriverDetailsPage({
  clientId,
  driver,
  driverLicenseRes,
  translate,
  onDataChanged,
  onDeleteDriver,
  uploadDriverData,
}: Props) {
  const dispatch = useDashboardAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = useAnalyticsContext();

  const [notifyDriverInProgress, setNotifyDriverInProgress] = useState(false);
  const handleNotifyDriver = () => {
    if (driver?.id) {
      setNotifyDriverInProgress(true);
      dispatch(
        sendDriverOnboardingNotification({
          driverId: driver?.id,
          onComplete: (isSuccess) => {
            setNotifyDriverInProgress(false);
            if (isSuccess) {
              enqueueSnackbar(
                `${translate("client_page_driver_notify_success")}`,
                {
                  variant: "success",
                }
              );
            } else {
              enqueueSnackbar(
                `${translate("client_page_driver_notify_failed")}`,
                {
                  variant: "error",
                }
              );
            }

            logEvent("driver_notified", {
              driverId: driver?.id,
              isSuccess,
            });
          },
        })
      );

      logEvent("notify_driver_clicked", {
        driverId: driver?.id,
      });
    }
  };

  // ------------------- Delete Driver Dialog -------------------
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
    logEvent("open_delete_driver_confirm_dialog");
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    if (onDeleteDriver) {
      onDeleteDriver();
      logEvent("driver_deleted", {
        driverId: driver?.id,
      });
    }
    handleCloseDeleteConfirm();
  };

  // ------------------- Edit vehicle Dialog -------------------
  const [isEditDriverDialogOpen, setIsEditDriverDialogOpen] = useState(false);
  const handleEditDriverClicked = () => {
    setIsEditDriverDialogOpen(true);
  };

  const handleEditDriverClose = () => {
    setIsEditDriverDialogOpen(false);
  };

  const handleOnEditDriver = () => {
    if (onDataChanged) {
      onDataChanged();
      logEvent("driver_edited", {
        driverId: driver?.id,
      });
    }

    handleEditDriverClose();
  };

  const isDateExpired = (date: string | undefined) => {
    if (!date) {
      return false;
    }
    const today = new Date();
    const expirationDate = new Date(date);
    return today > expirationDate;
  };

  const DriverWorkDetails = () => {
    if (!driver) {
      return <></>;
    }

    const data: DataViewEntry[] = [
      {
        title: `${translate("client_page_driver_work_start_date")}`,
        value: `${fDate(driver.workStartDate || "")}`,
      },
      {
        title: `${translate("client_page_driver_work_branch")}`,
        value: driver.workBranch,
      },
      {
        title: `${translate("client_page_driver_work_vehicle_type")}`,
        value: driver.workVehicleType,
      },
      {
        title: `${translate("client_page_driver_work_job_title")}`,
        value: driver.workJobTitle,
      },
      {
        title: `${translate("client_page_driver_work_job_devision")}`,
        value: driver.workJobDevision,
      },
    ];

    return (
      <FullWithDataView
        title={`${translate("client_page_driver_details_work")}`}
        itemsPerRow={3}
        data={data}
        translate={translate}
      />
    );
  };

  // ------------------- Driver License -------------------
  const [frontLicenseImgUrl, setFrontLicenseImgUrl] = useState<
    string | undefined
  >(driverLicenseRes?.frontLicenseImageUrl);
  const [backLicenseImgUrl, setBackLicenseImgUrl] = useState<
    string | undefined
  >(driverLicenseRes?.backLicenseImageUrl);

  useEffect(() => {
    if (driverLicenseRes) {
      setFrontLicenseImgUrl(driverLicenseRes.frontLicenseImageUrl);
      setBackLicenseImgUrl(driverLicenseRes.backLicenseImageUrl);
    }

    return () => {
      setFrontLicenseImgUrl(undefined);
      setBackLicenseImgUrl(undefined);
    };
  }, [driverLicenseRes]);

  const handleDownloadLienceClicked = () => {
    if (driver?.id) {
      dispatch(downloadDriverLicense({ driverId: driver.id }));
      logEvent("driver_page_details_download_click", {
        driverId: driver.id,
      });
    }
  };

  const handleTakeLicensePhoto = (isFront: boolean) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.capture = "environment";
    fileInput.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];

      // Convert the file to base64 string
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        const prefixToRemoveRange = base64String.indexOf(",") + 1;
        const base64StringWithoutPrefix =
          base64String.substring(prefixToRemoveRange);
        if (uploadDriverData && driver?.id) {
          uploadDriverData({
            driverId: driver.id,
            fileBase64: base64StringWithoutPrefix,
            isFront,
          });

          logEvent("driver_license_photo_uploaded", {
            driverId: driver.id,
            isFront,
          });
        }
      };

      // Convert the file to a URL
      const url = URL.createObjectURL(file);
      if (isFront) {
        setFrontLicenseImgUrl(url);
      } else {
        setBackLicenseImgUrl(url);
      }
    };
    fileInput.click();
  };

  const DriverLicense = () => {
    if (!driver) {
      return <></>;
    }

    const data: DataViewEntry[] = [
      {
        title: `${translate("client_page_driver_license")}`,
        value: driver.licenseNumber,
      },
      {
        title: `${translate("client_page_driver_license_issue_date")}`,
        value: `${fDate(driver.licenseIssueDate || "")}`,
      },
      {
        title: `${translate("client_page_driver_license_expiration")}`,
        value: `${fDate(driver.licenseExpireDate || "")}`,
        textColor: isDateExpired(driver.licenseExpireDate) ? "red" : "black",
      },
      {
        title: `${translate("driver_page_driver_license_levels")}`,
        value: `${driver.licenseLevels?.map((level) => ` ${translate(`driver_page_driver_license_levels_${level.toLowerCase()}`)}`) || ""}`,
      },
      {
        title: `${translate("driver_page_driver_license_certificates")}`,
        value: `${driver.licenseCertificates?.map((cert) => ` ${translate(`driver_page_driver_license_certificate_${cert.toLowerCase()}`)}`) || ""}`,
      },
      {
        overrideElement: <div style={{ height: 16 }} />,
      },

      // Front license image:
      {
        title: `${translate("driver_page_details_driving_license_copy_front")}`,
        overrideElement: (
          <LicenseFrame
            isFront
            currentImgUrl={frontLicenseImgUrl}
            translate={translate}
            onClicked={() => {
              handleTakeLicensePhoto(true);
            }}
          />
        ),
      },

      // Back license image:
      {
        title: `${translate("driver_page_details_driving_license_copy_back")}`,
        overrideElement: (
          <LicenseFrame
            isFront={false}
            currentImgUrl={backLicenseImgUrl}
            translate={translate}
            onClicked={() => {
              handleTakeLicensePhoto(false);
            }}
          />
        ),
      },
    ];

    return (
      <FullWithDataView
        title={`${translate("driver_page_details_driving_license")}`}
        itemsPerRow={2}
        data={data}
        translate={translate}
        actions={
          driverLicenseRes &&
          (driverLicenseRes.backLicenseImageUrl ||
            driverLicenseRes.frontLicenseImageUrl)
            ? [
                {
                  title: `${translate("driver_page_details_driving_license_download")}`,
                  onClick: handleDownloadLienceClicked,
                },
              ]
            : []
        }
      />
    );
  };

  return driver ? (
    <>
      <Stack spacing={3}>
        {/** Driver details */}
        <DriverDetails
          driver={driver}
          translate={translate}
          menuActions={[
            {
              title: `${
                driver.isOnboardingCompleted
                  ? translate("driver_page_details_onboarding_completed")
                  : translate("driver_page_details_onboarding_not_completed")
              }`,
              icon: driver.isOnboardingCompleted ? <Check /> : <Warning />,
              color: driver.isOnboardingCompleted ? "success" : "warning",
            },
            {
              title: `${translate("global_edit")}`,
              onClick: handleEditDriverClicked,
              icon: <Edit />,
            },
            {
              title: `${translate("global_delete")}`,
              onClick: handleOpenDeleteConfirm,
              icon: <Delete />,
              color: "error",
            },
          ]}
        />

        {/** Driver work details */}
        <DriverWorkDetails />

        {/** Driver License Info */}
        <DriverLicense />

        {/** Notify driver */}
        {!driver.isOnboardingCompleted && (
          <LoadingButton
            loading={notifyDriverInProgress}
            size="large"
            variant="contained"
            sx={{ mt: 3 }}
            onClick={handleNotifyDriver}
          >
            {`${translate("client_page_driver_notify")}`}
          </LoadingButton>
        )}
      </Stack>

      {/* Delete comfirm dialog: */}
      <DeleteConfimDialog
        translate={translate}
        titleKey={"global_delete"}
        descriptionKey={"message_are_you_sure_delete"}
        actionLabelKey={"global_delete"}
        isOpen={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        onAction={handleDeleteClick}
      />

      {/* Edit driver dialog: */}
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isEditDriverDialogOpen}
        onClose={handleEditDriverClose}
      >
        <ClientDriversAddEdit
          isEdit
          clientId={clientId}
          currentDriver={driver}
          translate={translate}
          onComplete={handleOnEditDriver}
        />
      </Dialog>
    </>
  ) : (
    <GenericMessageView
      title={`${translate("error_global_empty")}`}
      subtitle={""}
    />
  );
}

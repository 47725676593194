import { useState, ChangeEvent } from "react";

// @mui
import { Button, Typography, Stack, Box } from "@mui/material";

// components
import { Download } from "@mui/icons-material";
import { RHFTextField } from "../../../../global/components/hook-form";
// ----------------------------------------------------------------------
export type InvoiceAddAddressProps = {
  translate: Function;
  viewEditMode: boolean;
  hasScannedFile: boolean;
  onFileSelected: (_file: File) => void;
  onDownloadFileClicked: () => void;
};

export default function InvoiceAddAddress({
  translate,
  viewEditMode,
  hasScannedFile,
  onFileSelected,
  onDownloadFileClicked,
}: InvoiceAddAddressProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showMissingFileErorr, setShowMissingFileError] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      setSelectedFile(fileInput.files[0]);
      onFileSelected(fileInput.files[0]);
      setShowMissingFileError(false);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Stack sx={{ width: 1 }} spacing={2}>
          <Typography variant="h4" sx={{ color: "text", flexGrow: 1 }}>
            {`${translate("invoice_add_title")}`}
          </Typography>

          <Stack spacing={1}>
            {/** issuedBy */}
            <RHFTextField
              disabled={viewEditMode}
              name="invoiceFromName"
              label={`${translate("invoice_page_create_title_issuedBy")}`}
            />

            {/** address */}
            <RHFTextField
              disabled={viewEditMode}
              name="invoiceFromAddress"
              label={`${translate("invoice_page_create_title_address")}`}
            />

            {/** phone */}
            <RHFTextField
              disabled={viewEditMode}
              name="invoiceFromPhone"
              label={`${translate("invoice_page_create_title_phone")}`}
            />
          </Stack>
        </Stack>
        {viewEditMode ? (
          <>
            {hasScannedFile ? (
              <Box sx={{ width: 1, px: 12 }}>
                <Button
                  fullWidth
                  size="large"
                  variant="outlined"
                  component="span"
                  onClick={onDownloadFileClicked}
                  startIcon={<Download />}
                >
                  {`${translate("vehicle_invoices_page_title_download")}`}
                </Button>
              </Box>
            ) : (
              <Typography 
                sx={{ width: 1, mt: 2 }} 
                textAlign="center"
              >
                {`${translate("vehicle_invoices_page_title_no_file")}`}
              </Typography>
            )}
          </>
        ) : (
          <Box sx={{ width: 1, px: 12 }}>
            <input
              accept=".jpg,.jpeg,.png,.pdf"
              id="contained-button-file"
              type="file"
              name="pdfFile"
              hidden
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button
                disabled={viewEditMode}
                fullWidth
                size="large"
                variant="outlined"
                component="span"
              >
                {`${translate("vehicle_invoices_page_title_upload_file")}`}
              </Button>
            </label>

            {selectedFile && (
              <Typography sx={{ mt: 2 }} textAlign="center">
                {`${translate("vehicle_invoices_page_title_file_selected")}: ${
                  selectedFile.name
                }`}
              </Typography>
            )}

            {showMissingFileErorr && (
              <Typography sx={{ mt: 2 }} color="error" textAlign="center">
                {`${translate("error_vehicle_document_add_no_file")}`}
              </Typography>
            )}
          </Box>
        )}
      </Stack>
    </>
  );
}

// @mui
import { Card, Stack, Typography, Divider } from "@mui/material";
// utils
import { fNumber } from "../../../utils/format/formatNumber";

// ----------------------------------------------------------------------
export type ProfileVheicleDriversSummeryProps = {
  translate: Function;
  vehicles: number;
  drivers: number;
};
export default function ProfileVheicleDriversSummery({
  translate,
  vehicles,
  drivers,
}: ProfileVheicleDriversSummeryProps) {
  return (
    <Card sx={{ py: 3 }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(vehicles)}</Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {`${translate("client_page_tab_vehicles")}`}
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h4">{fNumber(drivers)}</Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            {`${translate("client_page_tab_drivers")}`}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

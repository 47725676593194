import {
  Speed,
  PeopleAlt,
  BorderColor,
  FormatListBulleted,
  Person,
} from "@mui/icons-material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { UserRole, WebAppFeature } from "../../../../api/api";

export const getNavConfig = (
  disabledFeatures?: WebAppFeature[],
  userRole?: UserRole
) => {
  const navigationConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader_key: PATH_DASHBOARD.general.label_key,
      items: [
        {
          title_key: PATH_DASHBOARD.general.app.label_key,
          path: PATH_DASHBOARD.general.app.path,
          icon: <Speed />,
        },
      ],
    },
  ];

  // MANAGEMENT
  if (disabledFeatures === undefined || !disabledFeatures?.includes(WebAppFeature.MULTI_CLIENTS) || userRole === UserRole.USER_MAIN_CHILD) {
    navigationConfig.push(
      {
        subheader_key: PATH_DASHBOARD.management.label_key,
        items: [
          // CLIENTS
          {
            title_key: PATH_DASHBOARD.management.clients.label_key,
            path: PATH_DASHBOARD.management.clients.path,
            icon: <PeopleAlt />,
          },
        ],
      }
    );
  } else {
    navigationConfig.push(
      {
        subheader_key: PATH_DASHBOARD.management.label_key,
        items: [
          // CLIENT
          {
            title_key: "client_page_title",
            path: PATH_DASHBOARD.management.clients.client('-'),
            icon: <Person />,
          },
        ],
      }
    );
  }

  // SYSTEM
  navigationConfig.push(
    {
      subheader_key: PATH_DASHBOARD.system.label_key,
      items: [
        // settings_signature
        {
          title_key: PATH_DASHBOARD.system.settings_signature.label_key,
          path: PATH_DASHBOARD.system.settings_signature.path,
          icon: <BorderColor />,
        },
        // settings_vehicle_report
        {
          title_key: PATH_DASHBOARD.system.settings_vehicle_report.label_key,
          path: PATH_DASHBOARD.system.settings_vehicle_report.path,
          icon: <FormatListBulleted />,
        },
      ],
    }
  );

  return navigationConfig;
};

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Chip,
  Grid,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";

export type DataViewEntry = {
  title?: string;
  value?: string;
  textColor?: string;
  overrideElement?: JSX.Element;
};

export type FullWithDataViewAction = {
  title: string;
  onClick?: () => void;
  color?: "secondary" | "success" | "error" | "info" | "warning";
  icon?: JSX.Element;
  overrideElement?: JSX.Element;
  isDisabled?: boolean;
};

export type VehicleReportFullWithDataViewProps = {
  sx?: SxProps;
  title?: string;
  subheader?: string;
  footer?: string;
  itemsPerRow: number;
  data?: DataViewEntry[];
  actions?: FullWithDataViewAction[];
  overrideContent?: any;
  showBorder?: boolean;
  translate: Function;
  isLoading?: boolean;
  elevation?: number;
};

export default function FullWithDataView({
  sx,
  title,
  subheader,
  footer,
  itemsPerRow,
  data,
  actions,
  overrideContent,
  showBorder,
  translate,
  isLoading,
  elevation,
  ...other
}: VehicleReportFullWithDataViewProps) {
  return (
    <Card elevation={elevation || 1} {...other} sx={{ ...sx, padding: 3 }}>
      {(title || subheader) && (
        <Stack spacing={0}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ paddingBottom: 3 }}
          >
            {/* Title and subheader */}
            {title && (
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {title}
              </Typography>
            )}

            {/* Action buttons */}
            <Stack direction="row" spacing={1}>
              {actions?.map((action) =>
                action.overrideElement ? (
                  action.overrideElement
                ) : action.onClick ? (
                  <LoadingButton
                    key={action.title}
                    color={action.color || "primary"}
                    onClick={action.onClick}
                    variant="contained"
                    startIcon={action.icon}
                    disabled={action.isDisabled}
                    loading={isLoading}
                  >
                    {action.title}
                  </LoadingButton>
                ) : (
                  <Chip
                    key={action.title}
                    color={action.color || "primary"}
                    icon={action.icon}
                    label={action.title}
                  />
                )
              )}
            </Stack>
          </Stack>

          {subheader && (
            <Typography
              variant="body1"
              sx={{ color: "text.secondary", pr: 5, pb: 3 }}
            >
              {subheader}
            </Typography>
          )}
        </Stack>
      )}

      <Box sx={{ flexGrow: 1 }}>
        {!overrideContent ? (
          <Grid container spacing={2}>
            {data?.map((entry, index) => (
              <Grid item xs={12 / itemsPerRow} key={index}>
                <Paper
                  variant="outlined"
                  sx={{ py: showBorder ? 1 : 0, textAlign: "center" }}
                >
                  <>
                    {(entry.overrideElement !== undefined) ? (
                      entry.overrideElement
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "bold",
                          color: entry.textColor
                            ? entry.textColor
                            : "text.primary",
                        }}
                      >
                        {entry.value
                          ? entry.value
                          : translate("global_no_data")}
                      </Typography>
                    )}

                    {entry.title && (
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        {entry.title}
                      </Typography>
                    )}
                  </>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          overrideContent
        )}
      </Box>

      {footer && (
        <Box sx={{ paddingTop: 3 }}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            {footer}
          </Typography>
        </Box>
      )}
    </Card>
  );
}

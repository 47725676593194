import { useContext } from 'react';
//
import { AnalyticsContext } from './FirebaseContext';

// ----------------------------------------------------------------------

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  if (!context) throw new Error('useAnalyticsContext context must be use inside AnalyticsProvider');

  return context;
};

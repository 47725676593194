import { LoadingButton } from "@mui/lab";

import ConfirmDialog from "../../../components/confirm-dialog/ConfirmDialog";

type Props = {
  translate: Function;
  titleKey?: string;
  descriptionKey?: string;
  isOpen: boolean;
  onClose: VoidFunction;
  actionLabelKey?: string;
  onAction: Function;
  isLoading?: boolean;
};

export default function DeleteConfimDialog({
  translate,
  titleKey = "global_delete",
  descriptionKey = "message_are_you_sure_delete",
  isOpen,
  onClose,
  actionLabelKey = "global_delete",
  onAction,
  isLoading = false,
}: Props) {
  return (
    <ConfirmDialog
      open={isOpen}
      onClose={onClose}
      title={`${translate(titleKey)}`}
      cancelText={`${translate("global_cancel")}`}
      content={`${translate(descriptionKey)}`}
      action={
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="error"
          onClick={() => onAction()}
        >
          {`${translate(actionLabelKey)}`}
        </LoadingButton>
      }
    />
  );
}

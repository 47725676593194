import { Provider as ReduxProvider } from "react-redux";

// theme
import ThemeProvider from "../../global/theme";
import ThemeLocalization from "../../global/locales";

// components
import { dashboardAppStore } from "./dashboardAppStore";
import SnackbarProvider from "../../global/components/snackbar/SnackbarProvider";
import { UserRole } from "../../api";
import { AuthGuard, RoleBasedGuard } from "../../app/firebase/guards";

import DashboardLayout from "../layouts/dashboard";
import RTL from "../../global/theme/options/right-to-left";

export default function DashboardApp() {

  return (
    <ReduxProvider store={dashboardAppStore}>
      <ThemeProvider>
        <ThemeLocalization>
          <RTL themeDirection={'rtl'}>
            <SnackbarProvider>
              <AuthGuard loginRole={UserRole.USER_MAIN}>
                <RoleBasedGuard roles={[UserRole.USER_MAIN, UserRole.USER_MAIN_CHILD]}>
                  <DashboardLayout />
                </RoleBasedGuard>
              </AuthGuard>
            </SnackbarProvider>
          </RTL>
        </ThemeLocalization>
      </ThemeProvider>
    </ReduxProvider>
  );
}

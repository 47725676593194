import * as Yup from "yup";
import { useMemo, useState, useEffect, ChangeEvent } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddCourseDocumentQueryProps, CourseDocumentType } from "src/api";
import { LoadingButton } from "@mui/lab";
import { Button, Card, Stack, Typography } from "@mui/material";

import { useSnackbar } from "../../../../global/components/snackbar";
import FormProvider, {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from "../../../../global/components/hook-form";
import {
  genericStringSchemaNullable,
  genericRequiredStringSchema,
  requiredBooleanSchema,
} from "../../../../global/utils/formValidators";

export type CourseDocumentsPageAddProps = {
  translate: Function;
  courseId: string;
  clientId: string;
  onPreformAction?: (
    _file: File | null,
    _docProps: AddCourseDocumentQueryProps
  ) => Promise<string | null>;
  onComplete?: () => void;
};

export default function CourseDocumentsPageAdd({
  translate,
  clientId,
  courseId,
  onPreformAction,
  onComplete,
}: CourseDocumentsPageAddProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [listOfAllDocumentTypes, setListOfAllDocumentTypes] = useState<
    CourseDocumentType[]
  >([]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showMissingFileErorr, setShowMissingFileError] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      setSelectedFile(fileInput.files[0]);
      setShowMissingFileError(false);
    }
  };

  useEffect(() => {
    if (listOfAllDocumentTypes.length > 0) return;
    setListOfAllDocumentTypes(Object.values(CourseDocumentType));
  }, [listOfAllDocumentTypes]);

  const NewDocumentSchema = Yup.object().shape({
    title: genericRequiredStringSchema(translate),
    documentType: genericRequiredStringSchema(translate),
    description: genericStringSchemaNullable(),
    showToAttendees: requiredBooleanSchema(translate),
    externalUrl: genericStringSchemaNullable(),
  });

  const defaultValues = useMemo(
    () => ({
      title: "",
      documentType: CourseDocumentType.OTHER,
      description: null,
      showToAttendees: false,
      externalUrl: null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewDocumentSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const selectedDocumentType = watch("documentType");

  const onSubmit = async (data: any) => {
    if (
      (selectedDocumentType !== CourseDocumentType.EXTERNAL_MATERIAL &&
        selectedFile === null) ||
      (selectedDocumentType === CourseDocumentType.EXTERNAL_MATERIAL &&
        !data.externalUrl)
    ) {
      setShowMissingFileError(true);
      return;
    }

    if (onPreformAction) {
      const error = await onPreformAction(selectedFile, {
        clientId,
        title: data.title,
        courseId,
        documentType: data.documentType,
        description: data.description,
        showToAttendees: data.showToAttendees,
        externalUrl: data.externalUrl,
      } as AddCourseDocumentQueryProps);

      if (!error && onComplete) {
        reset();
        onComplete();
      } else {
        enqueueSnackbar(
          `${translate("courses_documents_add_dialog_error")}: ${error}`,
          {
            variant: "error",
          }
        );
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, width: 550 }}>
        <Stack justifyContent="space-between">
          <Typography variant="h4">
            {`${translate("courses_documents_add_dialog_title")}`}
          </Typography>
        </Stack>

        <Stack sx={{ marginTop: 3 }} spacing={3}>
          {/** Title */}
          <RHFTextField
            name="title"
            label={`${translate("courses_documents_add_field_title")}`}
          />

          {/** Type */}
          <RHFSelect
            native
            name="documentType"
            label={`${translate("courses_documents_add_field_type")}`}
          >
            {listOfAllDocumentTypes.map((type) => (
              <option key={type} value={type}>
                {`${translate(`courses_documents_type_${type?.toLowerCase()}`)}`}
              </option>
            ))}
          </RHFSelect>

          {/** Description */}
          <RHFTextField
            name="description"
            label={`${translate("courses_documents_add_field_description")}`}
          />

          {/** Show to attendees */}
          <RHFSwitch
            name="showToAttendees"
            label={`${translate(
              "courses_documents_add_field_show_to_attendees"
            )}`}
          />

          {/** File */}
          {selectedDocumentType !== CourseDocumentType.EXTERNAL_MATERIAL ? (
            <>
              <input
                accept=".jpg,.jpeg,.png,.pdf"
                id="contained-button-file"
                type="file"
                name="pdfFile"
                hidden
                onChange={handleFileChange}
              />
              <label htmlFor="contained-button-file">
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  component="span"
                >
                  {`${translate("courses_documents_add_title_upload_file")}`}
                </Button>
              </label>

              {selectedFile && (
                <Typography sx={{ mt: 2 }} textAlign="center">
                  {`${translate("courses_documents_add_title_file_selected")}: ${
                    selectedFile.name
                  }`}
                </Typography>
              )}
            </>
          ) : (
            <Stack>
              <RHFTextField
                name="externalUrl"
                label={`${translate("courses_documents_add_field_external_url")}`}
              />
              {/** External URL Disclamer */}
              <Typography variant="subtitle2" sx={{ mt: 1 }}>
                {`${translate("courses_documents_add_external_url_disclaimer")}`}
              </Typography>
            </Stack>
          )}

          {showMissingFileErorr && (
            <Typography sx={{ mt: 2 }} color="error" textAlign="center">
              {`${translate("courses_documents_add_no_file_error")}`}
            </Typography>
          )}
        </Stack>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="success"
            loading={isSubmitting}
          >
            {`${translate("global_save")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

// @mui
import {
  Stack,
  Typography,
  IconButton,
  MenuItem,
  Box,
  Avatar,
  SxProps,
} from "@mui/material";

// _mock
import { useState } from "react";
// components
import {
  Place,
  MoreVert,
  DeleteOutline,
  LocalPhone,
  Email,
  DriveEta,
  EventBusy,
  Event,
} from "@mui/icons-material";

// components
import { DriverUser } from "../../../../api";
import MenuPopover from "../../../components/menu-popover/MenuPopover";
import { fDate } from "../../../utils/format/formatTime";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";

type ClientDriversListItemProps = {
  driver: DriverUser;
  translate: Function;
  onDeleteClicked?: Function;
  onDriverItemClicked: (_driverId: string) => void;
  sx?: SxProps;
};

export function ClientDriversListItem({
  driver,
  translate,
  onDeleteClicked,
  onDriverItemClicked,
  sx,
}: ClientDriversListItemProps) {
  // Delete and comfirm dialog:
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const handleOpenConfirm = () => {
    setOpenDeleteConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    if (onDeleteClicked) {
      onDeleteClicked(driver.id);
      handleCloseConfirm();
    }
  };

  const handleOnClicked = () => {
    if (driver?.id) {
      onDriverItemClicked(driver.id);
    }
  };

  // Menu Popover:
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  // View:
  type DataPointViewProps = {
    label?: string;
    value: string;
    Icon: any;
  };
  const DataPointView = ({ label, value, Icon }: DataPointViewProps) => (
    <Stack spacing={0.5} direction="row" alignItems="center">
      <Icon sx={{ fontSize: 20 }} />

      {label && <Typography variant="subtitle2">{`${label}: `}</Typography>}

      <Typography variant="body2" noWrap>
        {value}
      </Typography>
    </Stack>
  );

  return (
    <Stack direction="row" justifyContent="space-between" sx={sx}>
      <div
        onClick={handleOnClicked}
        onKeyDown={handleOnClicked}
        role="button"
        tabIndex={0}
        style={{ cursor: "pointer" }}
      >
        <Stack direction="row" spacing={1.5}>
          <Avatar alt={driver.fullName} sx={{ width: 48, height: 48 }} />

          <Stack spacing={0.5} sx={{ mt: 1 }} alignItems="start">
            <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
              {`${driver.fullName} | ${translate("global_id_number_short")} ${
                driver.govId
              }`}
            </Typography>

            {driver.address &&
              (driver.address.street ||
                driver.address.houseNumber ||
                driver.address.city) && (
                <DataPointView
                  label={`${translate("global_address")}`}
                  value={`${driver.address.street} ${driver.address.houseNumber}, ${driver.address.city} ${driver.address.zipCode}`}
                  Icon={Place}
                />
              )}
            <DataPointView
              label={`${translate("form_input_phone_number")}`}
              value={driver.phoneNumber}
              Icon={LocalPhone}
            />

            {driver.email && (
              <DataPointView
                label={`${translate("form_input_email")}`}
                value={driver.email}
                Icon={Email}
              />
            )}

            <DataPointView
              label={`${translate("client_page_driver_license")}`}
              value={driver.licenseNumber}
              Icon={DriveEta}
            />
            {driver.licenseExpireDate && (
              <DataPointView
                label={`${translate("client_page_driver_license_expiration")}`}
                value={fDate(driver.licenseExpireDate) || translate("global_no_data")}
                Icon={EventBusy}
              />
            )}
            {driver.licenseIssueDate && (
              <DataPointView
                label={`${translate("client_page_driver_license_issue_date")}`}
                value={fDate(driver.licenseIssueDate) || translate("global_no_data")}
                Icon={Event}
              />
            )}
          </Stack>
        </Stack>
      </div>

      <Box sx={{ mt: -0.5 }}>
        <IconButton
          color={openPopover ? "inherit" : "default"}
          onClick={handleOpenPopover}
        >
          <MoreVert />
        </IconButton>
      </Box>

      <MenuPopover
        disabledArrow
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: "error.main" }}
        >
          <DeleteOutline />
          {`${translate("global_remove")}`}
        </MenuItem>
      </MenuPopover>

      <DeleteConfimDialog
        translate={translate}
        isOpen={openDeleteConfirm}
        onClose={handleCloseConfirm}
        onAction={handleDeleteClick}
      />
    </Stack>
  );
}

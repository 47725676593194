import { format, getTime, formatDistanceStrict } from 'date-fns';
import { he, enUS } from 'date-fns/esm/locale'

// ----------------------------------------------------------------------

type InputValue = Date | string | number | null;

export function fDate(date?: InputValue, currentLangCode: string = 'he', newFormat?: string) {
  const locale = currentLangCode === 'he' ? he : enUS;
  const fm = newFormat || 'dd MMMM yyyy';

  if (date === undefined) {
    return undefined;
  }

  return date ? format(new Date(date), fm, { locale }) : '';
}

export function fDateIsoString(date: string): string {
  return new Date(date).toISOString();
}

export function fDateTime(date: InputValue, currentLangCode: string = 'he', newFormat?: string) {
  const locale = currentLangCode === 'he' ? he : enUS;
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm, { locale }) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceStrict(
      new Date(date).setHours(0, 0, 0, 0),
      new Date().setHours(0, 0, 0, 0),
      {
        addSuffix: true,
        locale: he,
        unit: 'day',
        roundingMethod: 'floor',
      })
    : '';
}

export function fDateFromSeconds(seconds: number): string {
  return fDate(new Date(seconds * 1000).toISOString()) || 'Unknown';
}

// A function to get the time difference between today and a given time in seconds:
export function fDaysPassedOrUntil(seconds: number, until: boolean = false): number {
  const now = new Date();
  const then = new Date(seconds * 1000);
  const diff = then.getTime() - now.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  return until ? days : -days;
}

import * as katzbatApi from '../../api';
import { AlertTypeEmailContact, Client, ClientWebAppConfig } from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

export interface IClientRepository {
  addClient: (_client: Client) => Promise<Client | undefined>;
  getData: (_clientId: string) => Promise<Client | undefined>;
  getSingleClientData: () => Promise<Client | undefined>;
  updateClientData: (_client: Client) => Promise<Client | undefined>;
  addAlertEmailContact: (_clientId: string, _alertType: string, _contactInfo: AlertTypeEmailContact) => Promise<any>;
  deleteAlertEmailContact: (_clientId: string, _alertType: string, _email: string) => Promise<any>;
  getWebAppConfig: (_clientId?: string) => Promise<ClientWebAppConfig>;
  updateWebAppAlertsConfig: (_clientId: string, _config: {[key: string]: boolean}) => Promise<ClientWebAppConfig>;
}


export default function RepositoryClient(): IClientRepository {

  function getClientApi(clientId?: string): any {
    return katzbatApi.ClientApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));
  }
  
  // === POST: Add Client ===
  const addClient = async (client: Client): Promise<Client | undefined> => {
    const resutls = await getClientApi(client.id).clientPost(client);
    return resutls.data;
  }

  // === GET: Get client data:
  const getData = async (clientId: string): Promise<Client | undefined> => {
    const resutls = await getClientApi(clientId).clientGet(clientId);
    return resutls.data;
  };
  
  const getSingleClientData = async (): Promise<Client | undefined> => {
    const resutls = await getClientApi().clientGetDefaultSingleClientGet();
    return resutls.data;
  }

  // === PATCH: Update client data ===
  const updateClientData = async (client: Client): Promise<Client | undefined> => {
    const resutls = await getClientApi(client.id).clientPatch(client);
    return resutls.data;
  }

  // === POST: Add Alert Email Contact ===
  const addAlertEmailContact = async (clientId: string, alertType: string, contactInfo: AlertTypeEmailContact): Promise<any> => {
    const resutls = await getClientApi(clientId).clientAddAlertEmailContactPost({
      clientId,
      alertType,
      contactInfo
    });
    return resutls.data;
  }

  // === DELETE: Delete Alert Email Contact ===
  const deleteAlertEmailContact = async (clientId: string, alertType: string, email: string): Promise<any> => {
    const resutls = await getClientApi(clientId).clientDeleteAlertEmailContactDelete(clientId, alertType, email);
    return resutls.data;
  }

  const getWebAppConfig = async (clientId?: string): Promise<ClientWebAppConfig> => {
    const response = await getClientApi(clientId).clientGetClientWebConfigGet(clientId);
    return response.data;
  }

  const updateWebAppAlertsConfig = async (clientId: string, config: {[key: string]: boolean}): Promise<ClientWebAppConfig> => {
    const response = await getClientApi(clientId).clientUpdateClientWebConfigPatch(clientId, config);
    return response.data
  }


  return {
    addClient,
    getData,
    getSingleClientData,
    updateClientData,
    addAlertEmailContact,
    deleteAlertEmailContact,
    getWebAppConfig,
    updateWebAppAlertsConfig
  };
}

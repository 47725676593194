import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  LocalShipping,
  AccountBox,
  Speed,
  Settings,
  School,
} from "@mui/icons-material";

import { WebAppFeature } from "src/api";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../hooks/useRedux";
import { useLocales } from "../../../../global/locales";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {
  clearClientData,
  getAlertsFromApi,
  getClientDataFromApi,
  getClientWebAppConfig,
  getExpiredTestVehicles,
  getSingleClientDataFromApi,
  markAlertAsRead,
} from "./clientPageSlice";
import ClientPageProfile from "../profile/ClientPageProfile";
import ClientVehicles from "../../vehicles/list/ClientVehicles";
import ClientDrivers from "../../drivers/list/ClientDrivers";
import CommonLayout from "../../common/layout/CommonLayout";
import LoadingView from "../../../components/loading-view/LoadingView";
import { useAnalyticsContext } from "../../../../app/firebase/useAnalyticsContext";
import {
  CommonLayoutPageCover,
  CommonLayoutTab,
} from "../../common/layout/types";
import DashboardAppFeaturesGuard from "../../../app/featuresConfig/DashboardAppFeaturesGuard";
import { getWebAppConfig } from "../../../app/dashboardAppStateSlice";
import ClientPageSettings from "../settings/ClientPageSettings";
import ClientCourses from "../../courses/list/ClientCoursesListPage";

// repository

export const CLIENT_PAGE_TABS_IDS = {
  profile: "profile",
  vehicles: "vehicles",
  drivers: "drivers",
  courses: "courses",
  settings: "settings",
};

export default function ClientPage() {
  const navigate = useNavigate();
  const { clientId } = useParams<string>();
  const dispatch = useDashboardAppDispatch();
  const { logEvent } = useAnalyticsContext();

  const { translate } = useLocales();

  const userData = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsPageSlice.user
  );

  const clientData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.data
  );

  const clientWebAppConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.clientWebAppConfig
  );

  const alertsData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.alerts
  );

  const expiredTestVehicleHeaders = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.clientPageSlice.expiredTestVehicleHeaders
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.isLoading
  );

  const handleMarkAlertAsRead = (alertId: string) => {
    if (clientId) {
      dispatch(markAlertAsRead({ clientId, alertId }));
      logEvent("client_page_alert_marked_as_read", {
        clientId,
        alertId,
      });
    }
  };

  const getData = async () => {
    if (clientId && clientId !== "-") {
      dispatch(getWebAppConfig({ clientId }));
      dispatch(getClientWebAppConfig(clientId));
      dispatch(getClientDataFromApi(clientId));
      dispatch(getExpiredTestVehicles(clientId));
    } else if (clientId === "-") {
      dispatch(getWebAppConfig({}));
      dispatch(getClientWebAppConfig(userData?.companyId || ""));
      dispatch(getSingleClientDataFromApi());
      dispatch(getExpiredTestVehicles(userData?.companyId || ""));
    }
  };

  const handleClientDeleted = () => {};

  const handleClientDataChanged = () => {
    getData();
  };

  // Get the data:
  useEffect(() => {
    getData();

    return () => {
      // cleanup
      if (clientId !== "-" || clientData?.id !== undefined) {
        dispatch(clearClientData());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  // Get the data:
  useEffect(() => {
    if (clientData) {
      if (clientId === "-") {
        navigate(`${PATH_DASHBOARD.management.clients.client(clientData.id, CLIENT_PAGE_TABS_IDS.profile)}`, {
          replace: true,
        });
        return;
      }

      if (!alertsData) {
        dispatch(getAlertsFromApi(clientData.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const TABS: CommonLayoutTab[] = [
    {
      value: CLIENT_PAGE_TABS_IDS.profile,
      label: `${translate("client_page_tab_profile")}`,
      icon: <AccountBox />,
      component: (
        <ClientPageProfile
          key={"client_page_profile"}
          translate={translate}
          data={clientData}
          alerts={alertsData || []}
          isLoadingAlerts={isLoading.alerts}
          expiredTestVehicleHeaders={expiredTestVehicleHeaders || []}
          isLoadingExpiredTests={isLoading.expiredVehicles}
          onDataChanged={handleClientDataChanged}
          onDeleteClicked={handleClientDeleted}
          markAlertAsRead={handleMarkAlertAsRead}
        />
      ),
    },
    {
      value: CLIENT_PAGE_TABS_IDS.vehicles,
      label: `${translate("client_page_tab_vehicles")}`,
      icon: <LocalShipping />,
      component: (
        <ClientVehicles
          key={"client_page_vehicles"}
          clientId={clientId}
          translate={translate}
          navigate={navigate}
        />
      ),
    },
    {
      value: CLIENT_PAGE_TABS_IDS.drivers,
      label: `${translate("client_page_tab_drivers")}`,
      icon: <Speed />,
      component: (
        <DashboardAppFeaturesGuard
          showError
          appFeature={WebAppFeature.DRIVERS_MANAGEMENT}
        >
          <ClientDrivers
            key={"client_page_drivers"}
            clientId={clientId}
            translate={translate}
            navigate={navigate}
          />
        </DashboardAppFeaturesGuard>
      ),
    },
    {
      value: CLIENT_PAGE_TABS_IDS.courses,
      label: `${translate("client_page_tab_courses")}`,
      icon: <School />,
      component: (
        <DashboardAppFeaturesGuard showError appFeature={WebAppFeature.COURSES}>
          <ClientCourses
            key={"client_page_drivers"}
            clientId={clientId}
            translate={translate}
          />
        </DashboardAppFeaturesGuard>
      ),
    },
    {
      value: CLIENT_PAGE_TABS_IDS.settings,
      label: `${translate("client_page_tab_notifications_settings")}`,
      icon: <Settings />,
      component: (
        <ClientPageSettings
          translate={translate}
          data={clientData}
          clientWebAppConfig={clientWebAppConfig}
        />
      ),
    },
  ];

  const coverData: CommonLayoutPageCover = {
    title: clientData?.companyName || "",
    subtitle: clientData?.id || "",
  };

  return clientData ? (
    <>
      <Helmet>
        <title>
          {`${translate("client_page_title")}`} |{" "}
          {`${clientData.companyName || ""}`}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("client_page_title")}`}
          links={[{ name: `${clientData.companyName}` }]}
        />

        <CommonLayout tabs={TABS} converData={coverData} />
      </Container>
    </>
  ) : (
    <LoadingView showLogo />
  );
}

import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
// components

import { KInvoiceStatus, KInvoiceType } from "src/api";
import {
  RHFTextField,
  RHFSelect,
  RHFDatePicker,
} from "../../../../global/components/hook-form";

// ----------------------------------------------------------------------

export type InvoiceAddStatusDateProps = {
  translate: Function;
  viewEditMode: boolean;
};

export default function InvoiceAddStatusDate({
  translate,
  viewEditMode,
}: InvoiceAddStatusDateProps) {
  return (
    <Stack
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      sx={{ p: 3, bgcolor: "background.neutral" }}
    >
      {/** invoiceNumber */}
      <RHFTextField
        disabled={viewEditMode}
        name="invoiceNumber"
        label={`${translate("invoice_page_create_title_invoice_number")}`}
      />

      {/** status */}
      <RHFSelect
        fullWidth
        name="status"
        label={`${translate("invoice_status_title")}`}
        InputLabelProps={{ shrink: true }}
      >
        {Object.values(KInvoiceStatus).map((status) => (
          <MenuItem key={status} value={status}>
            {translate(`invoice_status_title_${status.toLowerCase()}`)}
          </MenuItem>
        ))}
      </RHFSelect>

      {/** type */}
      <RHFSelect
        disabled={viewEditMode}
        fullWidth
        name="type"
        label={`${translate("invoice_type_title")}`}
        InputLabelProps={{ shrink: true }}
      >
        {Object.values(KInvoiceType).map((type) => (
          <MenuItem key={type} value={type}>
            {`${translate(`invoice_type_title_${type.toLowerCase()}`)}`}
          </MenuItem>
        ))}
      </RHFSelect>

      {/** issueDate */}
      <RHFDatePicker
        disabled={viewEditMode}
        name="issuedDate"
        label={`${translate("vehicle_invoices_page_title_invoice_issueDate")}`}
      />

      {/** dueDate */}
      <RHFDatePicker
        disabled={viewEditMode}
        name="dueDate"
        label={`${translate("vehicle_invoices_page_title_due_date")}`}
      />
    </Stack>
  );
}

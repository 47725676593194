import * as Yup from "yup";
import { useMemo, useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AlertType, AlertTypeEmailContact } from "src/api";
import { LoadingButton } from "@mui/lab";
import { Card, Stack, Typography } from "@mui/material";

import { useSnackbar } from "../../../../global/components/snackbar";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "../../../../global/components/hook-form";
import {
  genericRequiredStringSchema,
  emailSchemaRequired,
} from "../../../../global/utils/formValidators";

export type ClientPageSettingsAddEditConactEmailProps = {
  translate: Function;
  currentAlertTypeEmailContact: AlertTypeEmailContact | null;
  currentAlertType: AlertType | null;
  isEdit?: boolean;
  isLoading?: boolean;
  onPreformAction?: (
    _alertType: AlertType,
    _contactInfo: AlertTypeEmailContact
  ) => void;
};

export default function ClientPageSettingsAddEditConactEmail({
  translate,
  currentAlertTypeEmailContact,
  currentAlertType,
  isEdit = false,
  isLoading,
  onPreformAction
}: ClientPageSettingsAddEditConactEmailProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [listOfAllAlertType, setListOfAllAlertType] = useState<AlertType[]>([]);

  useEffect(() => {
    if (listOfAllAlertType.length > 0) return;
    setListOfAllAlertType(Object.values(AlertType));
  }, [listOfAllAlertType]);

  const NewAlertTypeEmailContactSchema = Yup.object().shape({
    name: genericRequiredStringSchema(translate),
    email: emailSchemaRequired(translate),
    type: genericRequiredStringSchema(translate),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentAlertTypeEmailContact?.name || "",
      email: currentAlertTypeEmailContact?.email || "",
      type: currentAlertType || AlertType.VEHICLE_DOCUMENT_EXPIRED,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAlertTypeEmailContact]
  );

  const methods = useForm({
    resolver: yupResolver(NewAlertTypeEmailContactSchema),
    defaultValues,
  });

  const {
    handleSubmit
  } = methods;

  const onSubmit = async (data: any) => {
    try {
      if (onPreformAction) {
        onPreformAction(data.type, {
          name: data.name,
          email: data.email,
        });
      }
    } catch (error) {
      enqueueSnackbar(
        `${translate("error_client_settings_alert_add_title")}: ${error}`,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3, width: 550 }}>
        <Stack justifyContent="space-between">
          <Typography variant="h4">
            {isEdit
              ? `${translate("client_settings_page_title_edit_alert")}`
              : `${translate("client_settings_page_title_add_alert")}`}
          </Typography>
        </Stack>

        <Stack sx={{ marginTop: 3 }} spacing={3}>

          {/** Type */}
          <RHFSelect
            native
            name="type"
            label={`${translate("vehicle_documents_page_title_document_type")}`}
          >
            {listOfAllAlertType.map((type) => (
              <option key={type} value={type}>
                {`${translate(`client_settings_alert_type_${type?.toLowerCase()}`)}`}
              </option>
            ))}
          </RHFSelect>

          {/** Name */}
          <RHFTextField
            name="name"
            label={`${translate(
              "global_full_name"
            )}`}
          />

          {/** Email */}
          <RHFTextField
            name="email"
            label={`${translate(
              "global_email"
            )}`}
          />
        </Stack>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="success"
            loading={isLoading}
          >
            {!isEdit
              ? `${translate("global_add_new")}`
              : `${translate("global_save")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}

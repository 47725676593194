// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { DateTimePicker } from "@mui/x-date-pickers";

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// ----------------------------------------------------------------------

interface Props {
  name: string;
  disabled?: boolean;
  label?: string;
}

export default function RHFDateTimePicker({ name, disabled, label }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          disabled={disabled === true}
          label={label}
          value={field.value || null}
          onChange={(newValue: any) => {
            field.onChange(newValue);
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            },
          }}
          slots={{
            leftArrowIcon: ChevronRightIcon,
            rightArrowIcon: ChevronLeftIcon,
          }}
        />
      )}
    />
  );
}

import { useState, useEffect } from "react";

import { KInvoice, KInvoiceStatus, KInvoiceType } from "src/api";

import InvoiceTableRow from "./invoice-table-row";
import { LabelColor } from "../../../../global/components/label";
import RichTableView, {
  RichTableStatusTab,
} from "../../common/views/richTableView/rich-table-view";
import { TableHeadItem } from "../../../components/table/TableHeadCustom";
import { RichTableFilters } from "../../common/views/richTableView/rich-table-filters-result";
import { fTimestamp } from "../../../utils/format/formatTime";
import { InvoiceStatisticsView } from "./invoce-stats-view";

// ----------------------------------------------------------------------

export type InvoiceListStatusTab = {
  value: KInvoiceStatus | null;
  label: string;
  color: LabelColor;
  count: number;
};

export type InvoiceListViewProps = {
  translate: Function;
  isLoadingData: boolean;
  titleKey?: string;

  invoices: KInvoice[];

  onRowClicked: (_invoice: KInvoice) => void;

  // Action Button:
  onActionButtonClicked?: () => void;
  actionButtonLabelKey?: string;
  actionButtonIcon?: JSX.Element;
};

export const getColorByStatus = (status: KInvoiceStatus) => {
  switch (status) {
    case KInvoiceStatus.PAID:
      return "success";
    case KInvoiceStatus.NOT_PAID:
      return "error";
    case KInvoiceStatus.REFUNDED:
      return "warning";
    default:
      return "default";
  }
};

const applyFilter = (
  invoices: KInvoice[],
  filters: RichTableFilters,
  dateError: boolean
): KInvoice[] => {
  const { globalSearch, status, types, startDate, endDate } = filters;

  let filteredData: KInvoice[] = [...invoices];

  if (globalSearch !== "") {
    filteredData = invoices.filter((itemObject: any) =>
      Object.keys(itemObject).some((key) => {
        const value = itemObject[key];
        return (
          value &&
          typeof value === "string" &&
          value.toLowerCase().includes(globalSearch.toLowerCase())
        );
      })
    );
  }

  if (status !== null) {
    filteredData = invoices.filter((invoice) => invoice.status === status);
  }

  if (types.length > 0) {
    filteredData = invoices.filter(
      (invoice) => invoice.type && types.includes(invoice.type)
    );
  }

  if (!dateError) {
    if (startDate && endDate) {
      filteredData = invoices.filter(
        (invoice) =>
          invoice.issuedDate !== undefined &&
          fTimestamp(invoice.issuedDate) >= fTimestamp(startDate) &&
          fTimestamp(invoice.issuedDate) <= fTimestamp(endDate)
      );
    }
  }
  return filteredData;
};

export default function InvoiceListView({
  translate,
  isLoadingData,
  titleKey,
  invoices,
  onRowClicked,
  onActionButtonClicked,
  actionButtonLabelKey,
  actionButtonIcon,
}: InvoiceListViewProps) {
  const [filteredTableData, _setFilteredTableData] = useState(invoices);

  useEffect(() => {
    _setFilteredTableData(invoices);
  }, [invoices]);

  const handleOnTableRowClicked = (invoice: KInvoice) => {
    onRowClicked(invoice);
  };

  const getInvoiceLength = (status: KInvoiceStatus) =>
    invoices.filter((item) => item.status === status).length;

  const statusTabs = [
    {
      value: null,
      label: translate("invoice_status_title_all"),
      color: "default",
      count: invoices.length,
    },
    ...Object.values(KInvoiceStatus).map((status) => ({
      value: status,
      label: translate(`invoice_status_title_${status.toLowerCase()}`),
      color: getColorByStatus(status),
      count: getInvoiceLength(status),
    })),
  ] as RichTableStatusTab[];

  const TABLE_HEAD: TableHeadItem[] = [
    {
      id: "invoiceNumber",
      label: `${translate("invoice_table_head_title_number")}`,
    },
    {
      id: "invoiceFromName",
      label: `${translate("invoice_table_head_title_from_name")}`,
    },
    {
      id: "type",
      label: `${translate("invoice_type_title")}`,
    },
    {
      id: "issuedDate",
      label: `${translate("invoice_table_head_title_issued_date")}`,
    },
    {
      id: "dueDate",
      label: `${translate("invoice_table_head_title_due_date")}`,
    },
    {
      id: "totalAmount",
      label: `${translate("invoice_table_head_title_amount")}`,
    },
    { id: "status", label: `${translate("invoice_table_head_title_status")}` },
  ];

  const typeFilterOptions = new Map(
    Object.values(KInvoiceType).map((type) => [
      type,
      `${translate(`invoice_type_title_${type.toLowerCase()}`)}`,
    ])
  );

  const handleOnApplyFilters = (
    filters: RichTableFilters,
    dateError: boolean
  ) => {
    _setFilteredTableData(applyFilter(invoices, filters, dateError));
  };

  return (
    <RichTableView
      showEndDate
      translate={translate}
      isLoadingData={isLoadingData}
      titleKey={titleKey}
      filteredTableData={filteredTableData}
      onApplyFilters={handleOnApplyFilters}
      analyticsView={
        <InvoiceStatisticsView
          getInvoiceLength={getInvoiceLength}
          translate={translate}
          data={invoices}
        />
      }
      tableHeads={TABLE_HEAD}
      statusTabs={statusTabs}
      typeFilterOptions={typeFilterOptions}
      randerRow={(item: KInvoice) => (
        <InvoiceTableRow
          translate={translate}
          key={item.id || ""}
          row={item}
          onViewRow={() => handleOnTableRowClicked(item)}
        />
      )}
      onActionButtonClicked={onActionButtonClicked}
      actionButtonLabelKey={actionButtonLabelKey}
      actionButtonIcon={actionButtonIcon}
    />
  );
}

import * as katzbatApi from '../../../api';
import { API_URL_BASE, getAxionsInstance } from '../../../global/repositories/RepositoryBase';

import { CreateOrUpdateVehicleIncident, IncidentEntry, VehicleIncident, VehicleIncidentHeader, AddVehicleIncidentImgsRequestBody } from '../../../api';

export interface IRepositoryVehicleIncidents {
  // Incidents:
  createVehicleIncident: (_clientId: string, _licenseNumber: string, _relatedDriverId: string, _createIncidentObject: CreateOrUpdateVehicleIncident) => Promise<any | undefined>;
  getVehicleIncident: (_clientId: string, _licenseNumber: string, _incidentId: string) => Promise<VehicleIncident | undefined>;
  getVehicleIncidentsHeaders: (_clientId: string, _licenseNumber: string) => Promise<VehicleIncidentHeader[] | undefined>;
  updateVehicleIncident: (_clientId: string, _licenseNumber: string, _incidentId: string, _updateIncidentObject: CreateOrUpdateVehicleIncident) => Promise<IncidentEntry | undefined>;
  addVehicleIncidentImages: (_clientId: string, _licenseNumber: string, _incidentId: string, _request: AddVehicleIncidentImgsRequestBody) => Promise<string[] | undefined>;
}

export default function RepositoryVehicleIncidents(): IRepositoryVehicleIncidents {

  const apiVehicleIncidents = (clientId?: string) => katzbatApi.VehicleIncidentsApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  // Incidents:
  const createVehicleIncident = async (clientId: string, licenseNumber: string, relatedDriverId: string, createIncidentObject: CreateOrUpdateVehicleIncident): Promise<VehicleIncidentHeader | undefined> => {
    const resutls = await apiVehicleIncidents(clientId).vehicleIncidentsCreateIncidentPost(clientId, licenseNumber, relatedDriverId, createIncidentObject);
    return resutls.data;
  }

  const getVehicleIncident = async (clientId: string, licenseNumber: string, incidentId: string): Promise<VehicleIncident | undefined> => {
    const resutls = await apiVehicleIncidents(clientId).vehicleIncidentsGetIncidentGet(clientId, licenseNumber, incidentId);
    return resutls.data;
  }

  const getVehicleIncidentsHeaders = async (clientId: string, licenseNumber: string): Promise<VehicleIncidentHeader[] | undefined> => {
    const resutls = await apiVehicleIncidents(clientId).vehicleIncidentsGetIncidentsHeadersGet(clientId, licenseNumber);
    return resutls.data;
  }

  const updateVehicleIncident = async (clientId: string, licenseNumber: string, incidentId: string, updateIncidentObject: CreateOrUpdateVehicleIncident): Promise<IncidentEntry | undefined> => {
    const resutls = await apiVehicleIncidents(clientId).vehicleIncidentsUpdateIncidentPatch(clientId, licenseNumber, incidentId, updateIncidentObject);
    return resutls.data;
  }

  const addVehicleIncidentImages = async (clientId: string, licenseNumber: string, incidentId: string, request: AddVehicleIncidentImgsRequestBody): Promise<string[] | undefined> => {
    const resutls = await apiVehicleIncidents(clientId).vehicleIncidentsAddIncidentPhotosPost(clientId, licenseNumber, incidentId, request);
    return resutls.data;
  }


  return {
    createVehicleIncident,
    getVehicleIncident,
    getVehicleIncidentsHeaders,
    updateVehicleIncident,
    addVehicleIncidentImages
  };
}

import { ChangeEvent, useState } from "react";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { Add } from "@mui/icons-material";
import { DriverDocumentUploadType, DriverUser } from "../../../../api";
import FullWithDataView, {
  DataViewEntry,
} from "../../common/views/FullWithDataView";
import { KDatePicker } from "../../../../global/components/hook-form/RHFDatePicker";

type DriverDocumentsSelectItem = {
  title: string;
  value: string;
};

export type DriverDocumentUploadData = {
  type: DriverDocumentUploadType;
  title: string | null;
  expireDate: string | null;
  isHiddenFile: boolean;
  selectedFile: File | null;
};

type Props = {
  driver?: DriverUser | null;
  translate: Function;
  isLoading: boolean;
  handleUploadDoc: (_driverDocumentCreateReq: DriverDocumentUploadData) => void;
};

export default function UploadDriverDocuments({
  driver,
  translate,
  isLoading,
  handleUploadDoc,
}: Props) {
  const VEHILCE_TYPE_OTHER = "OTHER";

  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [documentDate, setDocumentDate] = useState<string | null>(null);
  const [isHiddenFile, setIsHiddenFile] = useState<boolean>(false);
  const [documentName, setDocumentName] = useState<string | null>(null);

  const DriverDocumentsSelect = ({
    id,
    labelKey,
    currentValue,
    options,
    showOtherOption,
    onSelect,
  }: {
    id: string;
    labelKey: string;
    currentValue: string;
    showOtherOption?: boolean;
    options: DriverDocumentsSelectItem[];
    onSelect: (_value: string) => void;
  }) => (
    <FormControl fullWidth>
      <InputLabel id={labelKey}>{`${translate(labelKey)}`}</InputLabel>
      <Select
        labelId={labelKey}
        id={id}
        value={currentValue}
        label={`${translate(labelKey)}`}
        onChange={(event) => {
          onSelect(event.target.value as string);
        }}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}

        {/** Other option */}
        {showOtherOption && (
          <MenuItem value={VEHILCE_TYPE_OTHER}>{`${translate(
            "global_other"
          )}`}</MenuItem>
        )}
      </Select>
    </FormControl>
  );

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showMissingFileErorr, setShowMissingFileError] = useState(false);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      setSelectedFile(fileInput.files[0]);
      setShowMissingFileError(false);
    }
  };


  const onUploadClicked = () => {
    handleUploadDoc({
        type: Object.values(DriverDocumentUploadType)?.find(
          (type) => type === selectedType
        ) ?? DriverDocumentUploadType.OTHER,
        title: documentName,
        expireDate: documentDate,
        isHiddenFile,
        selectedFile,
      } as DriverDocumentUploadData);
  };

  // Update documents:
  const driverDocumentUploadForm: DataViewEntry[] = [
    // TYPE:
    {
      overrideElement: (
        <DriverDocumentsSelect
          id="driver_documents_upload_type"
          labelKey="driver_documents_upload_type"
          currentValue={selectedType || ""}
          options={
            Object.values(DriverDocumentUploadType)?.map((type) => ({
              title: `${translate(
                `driver_document_upload_title_${type.toLowerCase()}`
              )}`,
              value: type,
            })) || []
          }
          onSelect={setSelectedType}
        />
      ),
    },

    // TITLE:
    {
      overrideElement: (
        <TextField
          id="driver_documents_upload_name_title"
          label={`${translate("driver_documents_upload_name_title")}`}
          fullWidth
          onChange={(event) => {
            setDocumentName(event.target.value);
          }}
        />
      ),
    },

    // DATE:
    {
      overrideElement: (
        <KDatePicker
          sx={{ width: "100%" }}
          label={`${translate("driver_documents_expire_date_title")}`}
          value={documentDate || ""}
          onValueChanged={(newValue) => {
            setDocumentDate(newValue);
          }}
        />
      ),
    },

    // HIDDEN FILE CHECKBOX:
    {
      overrideElement: (
        <FormGroup sx={{padding: 2}}>
          <FormControlLabel
            control={
              <Switch
                checked={isHiddenFile}
                onChange={() => setIsHiddenFile(!isHiddenFile)}
                name="isHiddenFile"
              />
            }
            label={`${translate("driver_documents_hide_file_title")}`}
          />
        </FormGroup>
      ),
    },

    // UPLOAD BUTTON:
    {
      overrideElement: (
        <>
          <input
            accept=".jpg,.jpeg,.png,.pdf"
            id="contained-button-file"
            type="file"
            name="pdfFile"
            hidden
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button fullWidth size="large" variant="contained" component="span">
              {`${translate("driver_documents_page_title_upload_file")}`}
            </Button>
          </label>

          {selectedFile && (
            <Typography sx={{ mt: 2 }} textAlign="center">
              {`${translate("driver_documents_page_title_file_selected")}: ${
                selectedFile.name
              }`}
            </Typography>
          )}

          {showMissingFileErorr && (
            <Typography sx={{ mt: 2 }} color="error" textAlign="center">
              {`${translate("error_driver_document_add_no_file")}`}
            </Typography>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      {driver && (
        <Stack spacing={3}>
          <FullWithDataView
            title={`${translate("driver_documents_upload_title")}`}
            subheader={`${translate("driver_documents_upload_subtitle")}`}
            itemsPerRow={1}
            data={driverDocumentUploadForm}
            translate={translate}
            isLoading={isLoading}
            actions={[
              {
                title: `${translate("driver_documents_upload_new_document")}`,
                onClick: onUploadClicked,
                color: "success",
                icon: <Add />,
                isDisabled: selectedFile === null,
              },
            ]}
          />
        </Stack>
      )}
    </>
  );
}

import { Helmet } from "react-helmet-async";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Groups, Pages } from "@mui/icons-material";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../../../hooks/useRedux";
import { useLocales } from "../../../../../global/locales";
import { PATH_DASHBOARD } from "../../../../routes/paths";

import { DashboardRootState } from "../../../../app/dashboardAppStore";
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";

import CommonLayout from "../../../common/layout/CommonLayout";
import LoadingView from "../../../../components/loading-view/LoadingView";
import {
  CommonLayoutPageCover,
  CommonLayoutTab,
} from "../../../common/layout/types";
import { getIncidentData, onResetCurrentSelectedIncidentVehicle } from "../vehicleIncidentsPageSlice";
import IncidentDetailsPage from "./IncidentDetailsPage";
import InvolvedPartiesPage from "./involvedParties/InvolvedPartiesPage";
import { CLIENT_PAGE_TABS_IDS } from "../../../clientPage/main/ClientPage";
import { VEHICLE_PAGE_TABS_IDS } from "../../VehiclePage";

export const INCIDENT_PAGE_TABS_IDS = {
  info: "info",
  involvedParties: "involvedParties",
};

export default function IncidentPage() {
  const { clientId, licenseNumber, incidentId } = useParams<string>();
  const dispatch = useDashboardAppDispatch();

  const { translate } = useLocales();

  // const isLoadingData = useDashboardAppSelector(
  //   (state: DashboardRootState) => state.clientCoursesPageSlice.isLoading
  // );

  const incidentData = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.vehicleIncidentsPageSlice.selectedIncident
  );

  // Get the data:
  const getData = async () => {
    if (licenseNumber && clientId && incidentId) {
      dispatch(getIncidentData({ clientId, licenseNumber, incidentId }));
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(onResetCurrentSelectedIncidentVehicle());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseNumber]);

  const TABS: CommonLayoutTab[] = [
    {
      value: INCIDENT_PAGE_TABS_IDS.info,
      label: `${translate("incident_page_tab_info")}`,
      icon: <Pages />,
      component: (
        <>
          <IncidentDetailsPage
            clientId={clientId}
            licenseNumber={licenseNumber}
            translate={translate}
            incidentData={incidentData}
          />
        </>
      ),
    },

    {
      value: INCIDENT_PAGE_TABS_IDS.involvedParties,
      label: `${translate("incident_details_involved_parties_title")}`,
      icon: <Groups />,
      component: (
        <>
          <InvolvedPartiesPage
            translate={translate}
            involvedParties={incidentData?.involvedParties}
          />
        </>
      ),
    },
  ];

  const coverData: CommonLayoutPageCover = {
    title: `${incidentData?.title}`
  };

  return incidentData ? (
    <>
      <Helmet>
        <title>
          {`${translate("incident_page_title")}`} | {`${incidentData?.title || ""}`}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("incident_page_title")}`}
          links={[
            {
              name: `${translate("global_back_to_client")}`,
              href: `${PATH_DASHBOARD.management.clients.client(
                clientId || "",
                CLIENT_PAGE_TABS_IDS.profile
              )}`,
            },
            {
              name: `${licenseNumber}`,
              href: `${PATH_DASHBOARD.management.clients.vheicle.path(
                clientId || "",
                licenseNumber || "",
                VEHICLE_PAGE_TABS_IDS.incidents
              )}`,
            },
            {
              name: `${incidentData?.title || ""}`,
            },
          ]}
        />
        <CommonLayout tabs={TABS} converData={coverData} />
      </Container>
    </>
  ) : (
    <LoadingView showLogo />
  );
}

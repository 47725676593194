// @mui

import {
  Card,
  Typography,
  Stack,
  Link,
  Button,
} from "@mui/material";
import { Phone, LocationOn, Email } from "@mui/icons-material";
// components
import { Address } from "../../../../api";
import { generateGoogleMapsLink } from "../../../utils/linksUtils";

// ----------------------------------------------------------------------

const iconStype = {
  width: 20,
  height: 20,
  flexShrink: 0,
  marginRight: 1,
};

// ----------------------------------------------------------------------

type Contact = {
  name?: string;
  email?: string;
  phoneNumber?: string;
  address?: Address;
};

export type ProfileAboutProps = {
  translate: Function;
  data: Contact;
  onEditClick?: Function;
};
export default function ProfileAbout({
  translate,
  data,
  onEditClick,
}: ProfileAboutProps) {
  return data ? (
    <Card>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack direction="row" justifyContent='space-between'>
          <Typography variant="h5">
            {data.name ? `${data.name}` : `${translate("client_page_about")}`}
          </Typography>
          {onEditClick && (
            <Button
              variant="contained"
              onClick={() => onEditClick()}
              key={"edit"}
            >
              {`${translate("global_edit")}`}
            </Button>
          )}
        </Stack>

        {data.address &&
          (data.address.street ||
            data.address.houseNumber ||
            data.address.city) && (
            <Stack direction="row">
              <LocationOn sx={iconStype} />
              <Link
                target="_blank"
                rel="noreferrer"
                href={`${generateGoogleMapsLink(data.address)}`}
              >
                <Typography variant="body2">
                  {`${data.address.street} ${data.address.houseNumber}, ${data.address.city} - ${data.address.zipCode}`}
                </Typography>
              </Link>
            </Stack>
          )}

        {data.email && (
          <Stack direction="row">
            <Email sx={iconStype} />
            <Link href={`mailto:${data.email}`}>
              <Typography variant="body2">{data.email}</Typography>
            </Link>
          </Stack>
        )}

        {/* <Stack direction="row">
          <PhoneAndroid sx={iconStype} />
          <Link href={`tel:${data.phoneMobile}`}>
            <Typography variant="body2">{data.phoneMobile}</Typography>
          </Link>
        </Stack> */}

        {data.phoneNumber && (
          <Stack direction="row">
            <Phone sx={iconStype} />
            <Link href={`tel:${data.phoneNumber}`}>
              <Typography variant="body2">{data.phoneNumber}</Typography>
            </Link>
          </Stack>
        )}
      </Stack>
    </Card>
  ) : (
    <></>
  );
}

import { Helmet } from "react-helmet-async";
import { Container, Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Pages,
  Rule,
  InsertDriveFile,
  Receipt,
  CarCrash,
  PunchClock,
} from "@mui/icons-material";
import { VehicleType, WebAppFeature } from "src/api";
import { useSnackbar } from "../../../global/components/snackbar";
import RepositoryVehicle from "../../repositories/vehicle/RepositoryVehicle";
import {
  useDashboardAppDispatch,
  useDashboardAppSelector,
} from "../../hooks/useRedux";
import { useLocales } from "../../../global/locales";
import { PATH_DASHBOARD } from "../../routes/paths";

import { DashboardRootState } from "../../app/dashboardAppStore";
import CustomBreadcrumbs from "../../components/custom-breadcrumbs";
import {
  getVehicleData,
  removeDriverFromVehicleFromApi,
  resetVehicleData,
} from "./vehiclePageSlice";
import CommonLayout from "../common/layout/CommonLayout";
import { CLIENT_PAGE_TABS_IDS } from "../clientPage/main/ClientPage";
import ViewLicensePlate from "../common/views/ViewLicensePlate";
import { formatLicensePlateNumber } from "../../utils/models/vehicleUtils";
import VehiclePageReports from "./reportsList/VehiclePageReports";
import VehicleDetailsPage from "./details/VehicleDetailsPage";
import ManufacturerOrModelLabel from "../common/views/ManufacturerOrModelLabel";
import LoadingView from "../../components/loading-view/LoadingView";
import { getVehicleAvilableReportTypes } from "../settings/config/settingsReportConfigSlice";
import { CommonLayoutPageCover, CommonLayoutTab } from "../common/layout/types";
import VehiclePageDocuments from "./documentsList/VehiclePageDocuments";
import VehiclePageInvoices from "./invoicesList/VehiclePageInvoices";
import DashboardAppFeaturesGuard from "../../app/featuresConfig/DashboardAppFeaturesGuard";
import { getClientDriversFromApi } from "../clientPage/main/clientPageSlice";
import VehicleIncidentsPage from "./incidants/VehicleIncidentsPage";
import VehicleTachographsPage from "./tachographs/VehicleTachographsPage";
import { DRIVER_PAGE_TABS_IDS } from "../drivers/DriverPage";

export const VEHICLE_PAGE_TABS_IDS = {
  info: "info",
  documents: "documents",
  invoices: "invoices",
  incidents: "incidents",
  tachographs: "tachographs",
  reports: "reports",
};

export default function VehiclePage() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { clientId, licenseNumber } = useParams<string>();
  const dispatch = useDashboardAppDispatch();

  const { translate } = useLocales();

  const isLoadingData = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageSlice.isLoading
  );

  const appConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig
  );

  const vehicleData = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageSlice.data
  );

  const onRemoveDriver = (driverId: string) => {
    if (clientId && vehicleData?.licenseNumber !== undefined) {
      dispatch(
        removeDriverFromVehicleFromApi({
          clientId,
          driverId,
          licenseNumber: vehicleData.licenseNumber,
        })
      );
    }
  };

  const updateIsArchive = async (): Promise<void> => {
    if (clientId && vehicleData) {
      const currentlyIsArchived = vehicleData.isArchived;
      const modifiedVehicleData = {
        ...vehicleData,
        isArchived: !currentlyIsArchived,
      };
      const result = await RepositoryVehicle().updateVehicle(
        clientId,
        modifiedVehicleData
      );
      if (result && licenseNumber) {
        dispatch(getVehicleData({ clientId, licenseNumber }));
      } else {
        enqueueSnackbar(`${translate("error_global_subtitle")}`);
      }
    }
  };

  const onLinkedDriverClicked = (driverId: string) => {
    if (clientId) {
      navigate(
        `${PATH_DASHBOARD.management.clients.driver.path(clientId, driverId, DRIVER_PAGE_TABS_IDS.info)}`
      );
    }
  };

  // Get the data:
  const getData = async () => {
    if (licenseNumber && clientId) {
      dispatch(getVehicleData({ clientId, licenseNumber }));
      dispatch(getClientDriversFromApi(clientId));
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(resetVehicleData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseNumber]);

  useEffect(() => {
    if (vehicleData?.type !== undefined) {
      dispatch(
        getVehicleAvilableReportTypes({
          langCode: appConfig.locale ?? "en",
          vehicleType: vehicleData.type,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleData]);

  const getLayoutTabs = () => {
    const defaultTabs: CommonLayoutTab[] = [
      {
        value: VEHICLE_PAGE_TABS_IDS.info,
        label: `${translate("vehicle_page_tab_info")}`,
        icon: <Pages />,
        component: (
          <>
            {vehicleData && clientId ? (
              <VehicleDetailsPage
                clientId={clientId}
                translate={translate}
                vehicle={vehicleData}
                onDataChanged={getData}
                onRemoveDriver={onRemoveDriver}
                onUpdateArchive={updateIsArchive}
                onDriverItemClicked={onLinkedDriverClicked}
              />
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        value: VEHICLE_PAGE_TABS_IDS.documents,
        label: `${translate("vehicle_page_tab_documents")}`,
        icon: <InsertDriveFile />,
        component: (
          <DashboardAppFeaturesGuard
            showError
            appFeature={WebAppFeature.VEHICLE_DOCUMENTS}
          >
            <VehiclePageDocuments
              translate={translate}
              navigate={navigate}
              clientId={clientId}
              licenseNumber={licenseNumber}
            />
          </DashboardAppFeaturesGuard>
        ),
      },
      {
        value: VEHICLE_PAGE_TABS_IDS.invoices,
        label: `${translate("vehicle_page_tab_invoices")}`,
        icon: <Receipt />,
        component: (
          <DashboardAppFeaturesGuard
            showError
            appFeature={WebAppFeature.VEHICLE_INVOICES}
          >
            <VehiclePageInvoices
              translate={translate}
              clientId={clientId}
              licenseNumber={licenseNumber}
            />
          </DashboardAppFeaturesGuard>
        ),
      },
      {
        value: VEHICLE_PAGE_TABS_IDS.incidents,
        label: `${translate("vehicle_page_tab_incidents")}`,
        icon: <CarCrash />,
        component: (
          <DashboardAppFeaturesGuard
            showError
            appFeature={WebAppFeature.VEHICLE_INCIDENTS}
          >
            <VehicleIncidentsPage
              translate={translate}
              clientId={clientId}
              licenseNumber={licenseNumber}
            />
          </DashboardAppFeaturesGuard>
        ),
      },
      {
        value: VEHICLE_PAGE_TABS_IDS.reports,
        label: `${translate("vehicle_page_tab_reports")}`,
        icon: <Rule />,
        component: (
          <VehiclePageReports
            isLoading={isLoadingData.reports}
            translate={translate}
            navigate={navigate}
            clientId={clientId}
            vehicle={vehicleData}
          />
        ),
      }
    ];

    // Add tachographs tab if the vehicle is a bus, minibus, truck or small truck
    if (
      vehicleData?.type === VehicleType.BUS ||
      vehicleData?.type === VehicleType.MINIBUS ||
      vehicleData?.type === VehicleType.TRUCK ||
      vehicleData?.type === VehicleType.TRUCK_SMALL ||
      vehicleData?.type === VehicleType.TEAYOULIT
    ) {
      defaultTabs.push({
        value: VEHICLE_PAGE_TABS_IDS.tachographs,
        label: `${translate("vehicle_page_tab_tachographs")}`,
        icon: <PunchClock />,
        component: (
          <VehicleTachographsPage
            translate={translate}
            clientId={clientId}
            licenseNumber={licenseNumber}
          />
        ),
      });
    }

    return defaultTabs;
  };

  const RenderVehicleFullTitle = () =>
    vehicleData && (
      <Stack direction="row" spacing={1}>
        <ManufacturerOrModelLabel
          labelType="manufacturer"
          variant="h4"
          makeId={vehicleData.makeId}
          modelId={vehicleData.modelId}
          type={vehicleData.type}
        />
        <ManufacturerOrModelLabel
          labelType="model"
          variant="h4"
          makeId={vehicleData.makeId}
          modelId={vehicleData.modelId}
          type={vehicleData.type}
        />
      </Stack>
    );

  const coverData: CommonLayoutPageCover = {
    title: <RenderVehicleFullTitle />,
    subtitle: `${vehicleData?.vin}`,
    icon: (
      <ViewLicensePlate
        size="medium"
        number={vehicleData?.licenseNumber || ""}
        sx={{ marginTop: 6, marginBottom: 6 }}
      />
    ),
  };

  const formatedLicensePlateNumber = formatLicensePlateNumber(
    vehicleData?.licenseNumber || ""
  );

  return vehicleData && vehicleData.relatedClientHeader ? (
    <>
      <Helmet>
        <title>
          {`${translate("vehicle_page_title")}`} |{" "}
          {`${formatedLicensePlateNumber || ""}`}
        </title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("vehicle_page_title")}`}
          links={[
            {
              name: `${vehicleData.relatedClientHeader.companyName}`,
              href: PATH_DASHBOARD.management.clients.client(
                vehicleData.relatedClientHeader.id,
                CLIENT_PAGE_TABS_IDS.profile
              ),
            },
            {
              name: `${translate("client_page_tab_vehicles")}`,
              href: `${PATH_DASHBOARD.management.clients.client(
                vehicleData.relatedClientHeader.id,
                CLIENT_PAGE_TABS_IDS.vehicles
              )}`,
            },
            {
              name: `${formatedLicensePlateNumber}`,
            },
          ]}
        />
        <CommonLayout tabs={getLayoutTabs()} converData={coverData} />
      </Container>
    </>
  ) : (
    <LoadingView showLogo />
  );
}

import { Table, TableBody, TableContainer } from "@mui/material";
import { useEffect, useState } from "react";
import SearchableTableToolbar from "./SearchableTableToolbar";
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../../components/table";
import { useLocales } from "../../../../../global/locales";

// repository

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  searchTerm,
}: {
  inputData: any[];
  comparator: (_a: any, _b: any) => number;
  searchTerm: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (searchTerm) {
    // Find any value from inputData:
    inputData = inputData.filter((row) =>
      Object.keys(row).some((key) =>
        row[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }
  return inputData;
}

// ----------------------------------------------------------------------

export type CustomTableHeadProps = {
  id: string;
  label?: string;
  align?: "left" | "center" | "right" | "justify";
  width?: number;
  hidden?: boolean;
};

export type CustomTableProps<T> = {
  tableHead: CustomTableHeadProps[];
  data: Array<T>;
  // eslint-disable-next-line no-unused-vars
  renderRow: (row: T) => JSX.Element;
  showSearchBar: boolean;
  searchValue?: string;
  hidePagination?: boolean;
};

export default function SearchableTable<T>({
  tableHead,
  data,
  renderRow,
  searchValue,
  showSearchBar,
  hidePagination,
}: CustomTableProps<T>) {
  const { translate } = useLocales();

  const {
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate", defaultRowsPerPage: 25 });

  // Unlimited rows per page if hidePagination is true:
  const updatedRowsPerPage = hidePagination ? data.length : rowsPerPage;

  const [searchTerm, setSearchTerm] = useState("");

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
    searchTerm,
  });

  const isFiltered = searchTerm !== "";
  const isNotFound = !dataFiltered.length && !!searchTerm;

  const handleFilterName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0);
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (searchValue) {
      setSearchTerm(searchValue);
    } else {
      setSearchTerm("");
    }
  }, [searchValue]);

  const handleResetFilter = () => {
    setSearchTerm("");
  };

  return (
    <>
      {showSearchBar && (
        <SearchableTableToolbar
          searchHint={`${translate("global_search")}`}
          isFiltered={isFiltered}
          filterName={searchTerm}
          onFilterName={handleFilterName}
          onResetFilter={handleResetFilter}
        />
      )}

      <TableContainer sx={{ position: "relative", overflow: "unset" }}>
        <Table size={"medium"} >
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabel={tableHead}
            rowCount={dataFiltered.length}
            numSelected={selected.length}
            onSort={onSort}
          />

          <TableBody>
            {dataFiltered
              .slice(
                page * updatedRowsPerPage,
                page * updatedRowsPerPage + updatedRowsPerPage
              )
              .map((row) => renderRow(row))}

            <TableEmptyRows
              emptyRows={emptyRows(
                page,
                updatedRowsPerPage,
                dataFiltered.length
              )}
            />

            <TableNoData isNotFound={isNotFound} />
          </TableBody>
        </Table>
      </TableContainer>

      {!hidePagination && (
        <TablePaginationCustom
          labelRowsPerPage={`${translate("global_rows_per_page")}`}
          count={dataFiltered.length}
          page={page}
          rowsPerPage={updatedRowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      )}
    </>
  );
}

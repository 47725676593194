// @mui
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

import { KInvoice, KInvoiceStatus } from "src/api";

import { fDate } from "../../../utils/format/formatTime";
import { fCurrency } from "../../../utils/format/formatNumber";

import Label from "../../../../global/components/label";
import { getColorByStatus } from "./invoice-list-view";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  row: KInvoice;
  onViewRow: VoidFunction;
};

export default function InvoiceTableRow({
  translate,
  row,
  onViewRow,
}: Props) {
  const {
    invoiceNumber,
    issuedDate,
    dueDate,
    status,
    invoiceFromName,
    totalAmount,
    type,
  } = row;

  const globalNoData = `${translate("global_no_data")}`;

  return (
    <>
      <TableRow
        hover
        onClick={onViewRow}
        sx={{ cursor: "pointer" }}
      >

        {/** invoiceNumber */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {invoiceNumber}
              </Typography>
            }
          />
        </TableCell>

        {/** invoiceFromName */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {invoiceFromName ?? globalNoData}
              </Typography>
            }
          />
        </TableCell>

        {/** type */}
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {type ? translate(`invoice_type_title_${type.toLowerCase()}`) : globalNoData}
              </Typography>
            }
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={fDate(issuedDate) ?? globalNoData}
            primaryTypographyProps={{ typography: "body2", noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: "span",
              typography: "caption",
            }}
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={fDate(dueDate) ?? globalNoData}
            primaryTypographyProps={{ typography: "body2", noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: "span",
              typography: "caption",
            }}
          />
        </TableCell>

        <TableCell>{fCurrency(totalAmount || 0)}</TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={getColorByStatus(status as KInvoiceStatus)}
          >
            {status ? translate(`invoice_status_title_${status.toLowerCase()}`) : globalNoData}
          </Label>
        </TableCell>
      </TableRow>
    </>
  );
}

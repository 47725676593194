// @mui
import { TableRow, TableCell, SxProps, Theme } from '@mui/material';
//
import EmptyContent from '../empty-content';
import useLocales from '../../../global/locales/useLocales';

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
  sx?: SxProps<Theme>;
};

export default function TableNoData({ isNotFound, sx }: Props) {
  const { translate } = useLocales();
  
  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={`${translate('global_no_data')}`}
            sx={{
              '& span.MuiBox-root': { height: 160 },
              ...sx,
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}

import { Add } from "@mui/icons-material";
import { useState } from "react";
import { Dialog } from "@mui/material";
import { DriverUser, Vehicle } from "../../../../api";
import FullWithDataView from "../../common/views/FullWithDataView";
import { ClientDriversListItem } from "../../drivers/list/ClientDriversListItem";
import VehicleDetailsPageLinkDriver from "./VehicleDetailsPageLinkDriver";
import LoadingView from "../../../components/loading-view/LoadingView";
import GenericMessageView from '../../common/views/GenericMessageView';

type Props = {
  clientDriversData?: DriverUser[];
  translate: Function;
  isLoading: boolean;
  vehicle: Vehicle | null;
  onDriverItemClicked: (_driverId: string) => void;
  onLinkDriver?: Function;
  onRemoveDriver?: Function;
};

export default function VehicleDetailsPageRelatedDrivers({
  clientDriversData,
  translate,
  vehicle,
  isLoading,
  onDriverItemClicked,
  onLinkDriver,
  onRemoveDriver,
}: Props
) {
  // ------------------- LinkDriver Dialog -------------------
  const [isLinkDriverDialogOpen, setIsLinkDriverDialogOpen] = useState(false);
  const handleLinkDriverClicked = () => {
    setIsLinkDriverDialogOpen(true);
  };
  const handleClose = () => {
    setIsLinkDriverDialogOpen(false);
  };

  const handleOnLinkDriver = () => {
    if (onLinkDriver) {
      onLinkDriver();
    }
    handleClose();
  };

  // ------------------- Delete driver Dialog -------------------
  const onRemoveDriverClicked = (driverId: string) => {
    if (onRemoveDriver) {
      onRemoveDriver(driverId);
    }
  };

  type RanderDriverItemProps = {
    driver?: DriverUser;
  };
  const RanderDriverItem = ({ driver }: RanderDriverItemProps) => (
    <>
      {driver && vehicle?.relatedClientHeader?.id && (
        <ClientDriversListItem
          key={driver.licenseNumber}
          driver={driver}
          onDeleteClicked={onRemoveDriverClicked}
          onDriverItemClicked={onDriverItemClicked}
          translate={translate}
          sx={{ px: 2, py: 1 }}
        />
      )}
    </>
  );

  const filteredDrivers = clientDriversData?.filter((driver) =>
    // eslint-disable-next-line react/prop-types
    vehicle?.relatedDrivers?.includes(driver.id || "")
  );

  const data = filteredDrivers?.map((driver) => ({
    overrideElement: <RanderDriverItem driver={driver} />,
  }));

  const getAllDriversThatAreNotRelatedToVehicle = () => {
    if (!vehicle || !clientDriversData) {
      return [];
    }

    return clientDriversData.filter(
      // eslint-disable-next-line react/prop-types
      (driver: DriverUser) => !vehicle.relatedDrivers?.includes(driver.id || "")
    );
  };

  return (
    <>
      {!isLoading && data ? (
        data.length > 0 ? (
          <FullWithDataView
            sx={{ padding: 3 }}
            title={`${translate("vehicle_page_related_drivers")}`}
            itemsPerRow={2}
            actions={[
              {
                title: `${translate("client_page_driver_link")}`,
                onClick: handleLinkDriverClicked,
                icon: <Add />,
              },
            ]}
            translate={translate}
            data={data}
          />
        ) : (
          <GenericMessageView
            title={`${translate("error_linked_drivers_list_empty_title")}`}
            subtitle={`${translate("error_linked_drivers_list_empty_subtitle")}`}
            buttonTitle={`${translate("client_page_driver_link")}`}
            onAction={handleLinkDriverClicked}
          />
        )
      ) : (
        <LoadingView />
      )}

      {clientDriversData &&
        vehicle?.licenseNumber &&
        vehicle?.relatedClientHeader?.id && (
          <Dialog open={isLinkDriverDialogOpen} onClose={handleClose}>
            <VehicleDetailsPageLinkDriver
              clientId={vehicle?.relatedClientHeader?.id}
              vehicleLicenseNumber={vehicle.licenseNumber}
              clientDrivers={getAllDriversThatAreNotRelatedToVehicle()}
              translate={translate}
              isLoading={isLoading}
              onComplete={handleOnLinkDriver}
            />
          </Dialog>
        )}
    </>
  );
}

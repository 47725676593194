// @mui
import { Card, TableRow, TableCell, Typography } from "@mui/material";

// components
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import { PATH_DASHBOARD } from "../../../routes/paths";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../../common/views/tableView/ViewGenericDataTable";
import { ExpiredVehicleHeader } from "../../../../api";
import ManufacturerOrModelLabel from "../../common/views/ManufacturerOrModelLabel";
import GenericMessageView from "../../common/views/GenericMessageView";
import ViewLicensePlate from "../../common/views/ViewLicensePlate";
import {
  fDateFromSeconds,
  fToNow,
} from "../../../utils/format/formatTime";
import { COOKIE_NAME_RELATED_USER_ID, setCookie } from "../../../utils/cookieService";
import { VEHICLE_PAGE_TABS_IDS } from "../VehiclePage";

// ----------------------------------------------------------------------

type ClientVehiclesExpiredTestsListRowProps = {
  row: ExpiredVehicleHeader;
  showRelatedClient: boolean;
  onRowClick?: (_data: ExpiredVehicleHeader) => void;
};

function ClientVehiclesExpiredTestsListRow({
  row,
  showRelatedClient,
  onRowClick,
}: ClientVehiclesExpiredTestsListRowProps) {
  const { relatedClientName, licenseNumber, buildYear, lastReportId } = row;

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  return (
    <TableRow hover onClick={handleRowClick} sx={{ cursor: "pointer" }}>
      {/* license Number */}
      <TableCell align="left">
        <ViewLicensePlate size="small" number={licenseNumber || ""} />
      </TableCell>

      {/* Make */}
      <TableCell align="left">
        <ManufacturerOrModelLabel
          labelType="manufacturer"
          makeId={row.makeId}
          modelId={row.modelId}
          type={row.type}
        />
      </TableCell>

      {/* Model */}
      <TableCell align="left">
        <ManufacturerOrModelLabel
          labelType="model"
          makeId={row.makeId}
          modelId={row.modelId}
          type={row.type}
        />
      </TableCell>

      {/* buildYear */}
      <TableCell align="left">{buildYear}</TableCell>

      {/* Last report date */}
      <TableCell align="left">
        <Typography variant="body2">
          {`${fDateFromSeconds(Number(lastReportId))}`}
        </Typography>
        <Typography
          variant="subtitle2"
          color={"error"}
        >
          {`(${fToNow(Number(lastReportId) * 1000)})`}
        </Typography>
      </TableCell>

      {/* client name */}
      <TableCell align="left" hidden={!showRelatedClient}>
        {relatedClientName}
      </TableCell>
    </TableRow>
  );
}

// ----------------------------------------------------------------------

type Props = {
  currentClientId?: string;
  translate: Function;
  navigate: Function;
  isLoading: boolean;
  vehiclesData?: ExpiredVehicleHeader[] | null;
  showRelatedClient?: boolean;
};

export default function ClientVehiclesExpiredTests({
  currentClientId,
  translate,
  navigate,
  isLoading,
  vehiclesData,
  showRelatedClient,
}: Props) {
  const listOfHeaders: CustomTableHeadProps[] = [
    {
      id: "licenseNumber",
      label: `${translate("client_page_vehicle_id")}`,
      align: "left",
    },
    {
      id: "vehicleMake",
      label: `${translate("client_page_vehicle_make")}`,
      align: "left",
    },
    {
      id: "vehicleModel",
      label: `${translate("client_page_vehicle_model")}`,
      align: "left",
    },
    {
      id: "vehicleYear",
      label: `${translate("client_page_vehicle_year")}`,
      align: "left",
    },
    {
      id: "lastTestDate",
      label: `${translate("vehicle_exipred_tests_last_date")}`,
      align: "left",
    },
    {
      id: "relatedClientName",
      label: `${translate("clients_list_table_title_name")}`,
      align: "left",
      hidden: !showRelatedClient,
    }
  ];

  const handleRowClick = (data: ExpiredVehicleHeader) => {
    setCookie({
      name: COOKIE_NAME_RELATED_USER_ID,
      id: data.relatedClientId,
      value: data.relatedUserId,
    });
    navigate(
      PATH_DASHBOARD.management.clients.vheicle.path(
        data.relatedClientId || currentClientId || "",
        data.licenseNumber,
        VEHICLE_PAGE_TABS_IDS.reports
      )
    );
  };

  const renderRow = (row: ExpiredVehicleHeader) => (
    <ClientVehiclesExpiredTestsListRow
      showRelatedClient
      key={row.licenseNumber}
      row={row}
      onRowClick={handleRowClick}
    />
  );

  const genericDataTableViewProps: ViewGenericDataTableData<ExpiredVehicleHeader> =
    {
      isLoading,
      translate,
      titleKey: "vehicle_exipred_tests_list_title",
      subtitleKey: "vehicle_exipred_tests_list_subtitle",
      data: vehiclesData || [],
      renderRow,
      tableHead: listOfHeaders,
      searchable: false,
      searchLabelKey: "client_page_search_vehicles",
      filterTitle: `${translate("vehicle_exipred_tests_filter_company_name")}`,
      emptyStateComponent: (
        <GenericMessageView
          title={`${translate("vehicle_exipred_tests_no_vehicle")}`}
          subtitle={""}
        />
      ),
    };

  return (
    <>
      <Card sx={{ px: 3 }}>
        <ViewGenericDataTable {...genericDataTableViewProps} />
      </Card>
    </>
  );
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryVehicle from '../../repositories/vehicle/RepositoryVehicle';
import { DocumentConfig, Vehicle, VehicleReportResponse, VehicleReportHeader, VehicleReportType, DocumentInputOptionKeys } from '../../../api';
import RepositoryReports from '../../repositories/RepositoryReports';
import RepositoryGeneratePdf, { DefauleQueryParams } from '../../repositories/RepositoryGeneratePdf';
import RepositoryConfig from '../../repositories/RepositoryConfig';
import { downloadFile } from '../../../global/repositories/RepositoryBase';


export type VehiclePageState = {
  data: Vehicle | null;
  reportsHeaders: VehicleReportHeader[];
  selectedReport: VehicleReportResponse | null;
  selectedReportConfig: DocumentConfig | null;
  isLoading : {
    details: boolean;
    reports: boolean;
    reportData: boolean;
    generateReport: boolean;
  }
};

const initialState: VehiclePageState = {
  data: null,
  reportsHeaders: [],
  selectedReport: null,
  selectedReportConfig: null,
  isLoading: {
    details: false,
    reports: false,
    reportData: false,
    generateReport: false,
  }
};


type getCodumentConfigParams = {
  langCode: string;
  reportType: VehicleReportType;
}

export const getDocumentConfig = createAsyncThunk('vehiclePageSlice/getDocumentConfig', async (
  { langCode, reportType }: getCodumentConfigParams
) => {
  const data = await RepositoryConfig().getDocumentConfig(langCode, reportType);
  return data;
});


type GetVehicleDataDefaultParams = {
  clientId: string;
  licenseNumber: string;
}
export const getVehicleData = createAsyncThunk('vehiclePageSlice/getVehicleData', async (
  { clientId, licenseNumber }: GetVehicleDataDefaultParams
) => {
  const data = await RepositoryVehicle().getVehicleData(clientId, licenseNumber);
  return data;
});

export const getVehicleReportsHeaders = createAsyncThunk('vehiclePageSlice/getVehicleReportsHeaders', async (
  { clientId, licenseNumber }: GetVehicleDataDefaultParams
) => {
  const data = await RepositoryReports().getVehicleReportsHeaders(clientId, licenseNumber);
  return data;
});

type VehicleReportDataParams = GetVehicleDataDefaultParams & {
  reportId: string;
}
export const getVehicleReportData = createAsyncThunk('vehiclePageSlice/getVehicleReportData', async (
  { clientId, licenseNumber, reportId }: VehicleReportDataParams
) => {
  const data = await RepositoryReports().getFullVehicleReport(clientId, licenseNumber, reportId);
  return data;
});


type VehicleReportUpdateDataParams = GetVehicleDataDefaultParams & {
  reportId: string;
  data: any;
}
export const updateVehicleReportData = createAsyncThunk('vehiclePageSlice/updateVehicleReportData', async (
  { clientId, licenseNumber, reportId, data }: VehicleReportUpdateDataParams
) => {
  const result = await RepositoryReports().updateReportData(clientId, licenseNumber, reportId, data);
  return result;
});


export const generateReport = createAsyncThunk('vehiclePageSlice/generateReport', async ({
  clientId,
  licenseNumber,
  reportId,
  includePictures: includePhotos,
  includeNotes,
  includeDriver
}: DefauleQueryParams) => {

  const data = await RepositoryGeneratePdf().generateReportPdf({
    clientId,
    licenseNumber,
    reportId,
    langCode: 'he',
    includePictures: includePhotos,
    includeNotes,
    includeDriver
  });

  return data;
});

export const emailReport = createAsyncThunk('vehiclePageSlice/emailReport', async ({
  clientId,
  licenseNumber,
  reportId,
  includePictures: includePhotos,
  includeNotes,
  includeDriver
}: DefauleQueryParams) => {

  const data = await RepositoryGeneratePdf().emailReportPdf({
    clientId,
    licenseNumber,
    reportId,
    langCode: 'he',
    includePictures: includePhotos,
    includeNotes,
    includeDriver
  });

  return data;
});


// Remove driver from vehicle:
export const removeDriverFromVehicleFromApi = createAsyncThunk('clientPageSlice/removeDriverFromVehicleFromApi', async ({ clientId, driverId, licenseNumber }: { clientId: string; driverId: string; licenseNumber: string }) => {
  const data = await RepositoryVehicle().removeDriverFromVehicle(clientId, driverId, licenseNumber);
  return data;
});

export const vehiclePageSlice = createSlice({
  name: 'vehiclePageSlice',
  initialState,
  reducers: {

    // Reset current report:
    onResetCurrentReportVehicle: (state: VehiclePageState) => {
      state.selectedReport = null;
    },

    resetVehicleData: (state: VehiclePageState) => {
      state.data = null;
      state.reportsHeaders = [];
      state.selectedReport = null;
      state.selectedReportConfig = null;
    },

  },
  extraReducers: (builder) => {

    builder.addCase(getDocumentConfig.pending, (state: VehiclePageState, _action) => {
      state.selectedReportConfig = null;
    });

    // Get document config:
    builder.addCase(getDocumentConfig.fulfilled, (state: VehiclePageState, action) => {
      if (action.payload) {
        state.selectedReportConfig = action.payload;
      }
    });

    builder.addCase(getDocumentConfig.rejected, (state: VehiclePageState, _action) => {
      state.selectedReportConfig = null;
    });


    // Vehicle Data:
    builder.addCase(getVehicleData.pending, (state: VehiclePageState, _action) => {
      state.isLoading.details = true;
    });

    builder.addCase(getVehicleData.fulfilled, (state: VehiclePageState, action) => {
      if (action.payload) {
        state.data = action.payload;
      }
      state.isLoading.details = false;
    });
    
    builder.addCase(getVehicleData.rejected, (state: VehiclePageState, _action) => {
      state.data = null;
      state.isLoading.details = false;
    });


    // Vehicle Reports Headers:
    builder.addCase(getVehicleReportsHeaders.pending, (state: VehiclePageState, _action) => {
      state.isLoading.reports = true;
    });

    builder.addCase(getVehicleReportsHeaders.fulfilled, (state: VehiclePageState, action) => {
      if (action.payload) {
        // Decending order by test id:
        state.reportsHeaders = action.payload.sort((a, b) => {
          if (a.id && b.id) {
            return parseInt(b.id, 10) - parseInt(a.id, 10);
          }
          return 0;
        });
      }
      state.isLoading.reports = false;
    });

    builder.addCase(getVehicleReportsHeaders.rejected, (state: VehiclePageState, _action) => {
      state.reportsHeaders = [];
      state.isLoading.reports = false;
    });


    // Vehicle Report Data:
    builder.addCase(getVehicleReportData.pending, (state: VehiclePageState, _action) => {
      state.isLoading.reportData = true;
    });

    builder.addCase(getVehicleReportData.fulfilled, (state: VehiclePageState, action) => {
      if (action.payload) {
        const data = action.payload;
        // remove unneeded data:
        data.documentInputResults = data.documentInputResults?.filter((inputResult) => inputResult.dbKey !== DocumentInputOptionKeys.DRIVER_ID);
        state.selectedReport = data;
      }
      state.isLoading.reportData = false;
    });

    builder.addCase(getVehicleReportData.rejected, (state: VehiclePageState, _action) => {
      state.selectedReport = null;
      state.isLoading.reportData = false;
    });

    
    builder.addCase(removeDriverFromVehicleFromApi.fulfilled, (state: VehiclePageState, action) => {
      if (action.payload) {
        const related = state.data?.relatedDrivers?.find((driver) => driver === action.payload?.toString());
        if (related) {
          state.data?.relatedDrivers?.splice(state.data?.relatedDrivers?.indexOf(related), 1);
        }
      }
    });


    // Update Report Data
    builder.addCase(updateVehicleReportData.pending, (state: VehiclePageState, _action) => {
      state.isLoading.reportData = true;
    });

    builder.addCase(updateVehicleReportData.fulfilled, (state: VehiclePageState, action) => {
      if (action.payload) {
        state.selectedReport = action.payload;
      }
      state.isLoading.reportData = false;
    });

    builder.addCase(updateVehicleReportData.rejected, (state: VehiclePageState, _action) => {
      state.isLoading.reportData = false;
    });



    // Generate report:
    builder.addCase(generateReport.pending, (state: VehiclePageState, _action) => {
      state.isLoading.generateReport = true;
    });

    builder.addCase(generateReport.fulfilled, (state: VehiclePageState, action) => {
      state.isLoading.generateReport = false;
      downloadFile(action.payload, `${state.selectedReport?.vehicle?.licenseNumber}/${state.selectedReport?.id}`, 'text/plain')
    });

    builder.addCase(generateReport.rejected, (state: VehiclePageState, _action) => {
      state.isLoading.generateReport = false;
    });


    // email report:
    builder.addCase(emailReport.pending, (state: VehiclePageState, _action) => {
      state.isLoading.generateReport = true;
    });

    builder.addCase(emailReport.fulfilled, (state: VehiclePageState, _action) => {
      state.isLoading.generateReport = false;
    });

    builder.addCase(emailReport.rejected, (state: VehiclePageState, _action) => {
      state.isLoading.generateReport = false;
    });
  },
});

// Export the actions and reducer
export default vehiclePageSlice.reducer;
export const {
  onResetCurrentReportVehicle,
  resetVehicleData,
} = vehiclePageSlice.actions;

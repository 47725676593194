import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddVehicleIncidentImgsRequestBody, CreateOrUpdateVehicleIncident, VehicleIncident, VehicleIncidentHeader } from '../../../../api';
import RepositoryVehicleIncidents from '../../../repositories/vehicle/RepositoryVehicleIncidents';


export type VehicleIncidentPageState = {
  headers: VehicleIncidentHeader[] | undefined;
  selectedIncident: VehicleIncident | undefined;
  isLoading: {
    details: boolean;
    create: boolean;
    download: boolean;
    upload: boolean;
  }
};

const initialState: VehicleIncidentPageState = {
  headers: undefined,
  selectedIncident: undefined,
  isLoading: {
    details: false,
    create: false,
    download: false,
    upload: false
  }
};



// getIncidentsHeaders:
type GetIncidentsHeadersParams = {
  clientId: string;
  licenseNumber: string;
}
export const getIncidentsHeaders = createAsyncThunk('vehicleIncidentsPageSlice/getIncidentsHeaders', async (
  { clientId, licenseNumber }: GetIncidentsHeadersParams
) => {
  const data = await RepositoryVehicleIncidents().getVehicleIncidentsHeaders(clientId, licenseNumber);
  return data;
});


// getIncidentData:
type GetIncidentDataParams = GetIncidentsHeadersParams & {
  incidentId: string;
}
export const getIncidentData = createAsyncThunk('vehicleIncidentsPageSlice/getIncidentData', async (
  { clientId, licenseNumber, incidentId }: GetIncidentDataParams
) => {
  const data = await RepositoryVehicleIncidents().getVehicleIncident(clientId, licenseNumber, incidentId);
  return data;
});


// createVehicleIncident:
type CreateVehicleIncidentParams = GetIncidentsHeadersParams & {
  relatedDriverId: string;
  createIncidentObject: CreateOrUpdateVehicleIncident,
  onCompleted?: (_isSuccess: boolean) => void;
}
export const createVehicleIncident = createAsyncThunk('vehicleIncidentsPageSlice/createVehicleIncident', async (
  { clientId, licenseNumber, relatedDriverId, createIncidentObject }: CreateVehicleIncidentParams
) => {
  const data = await RepositoryVehicleIncidents().createVehicleIncident(clientId, licenseNumber, relatedDriverId, createIncidentObject);
  return data;
});


// updateVehicleIncident: 
type UpdateVehicleIncidentParams = GetIncidentDataParams & {
  updateIncidentObject: CreateOrUpdateVehicleIncident,
  onCompleted?: (_isSuccess: boolean) => void;
}
export const updateVehicleIncident = createAsyncThunk('vehicleIncidentsPageSlice/updateVehicleIncident', async (
  { clientId, licenseNumber, incidentId, updateIncidentObject }: UpdateVehicleIncidentParams
) => {
  const data = await RepositoryVehicleIncidents().updateVehicleIncident(clientId, licenseNumber, incidentId, updateIncidentObject);
  return data;
});


// uploadIncidentImages:
type UploadIncidentImagesParams = GetIncidentDataParams & {
  request: AddVehicleIncidentImgsRequestBody,
  onCompleted?: (_isSuccess: boolean) => void;
}

export const uploadIncidentImages = createAsyncThunk('vehicleIncidentsPageSlice/uploadIncidentImages', async (
  { clientId, licenseNumber, incidentId, request }: UploadIncidentImagesParams
) => {
  const data = await RepositoryVehicleIncidents().addVehicleIncidentImages(clientId, licenseNumber, incidentId, request);
  return data;
});

export const vehicleIncidentsPageSlice = createSlice({
  name: 'vehicleIncidentsPageSlice',
  initialState,
  reducers: {

    onResetCurrentSelectedIncidentVehicle: (state: VehicleIncidentPageState) => {
      state.selectedIncident = undefined;
    },
  },

  extraReducers: (builder) => {

    // getIncidentsHeaders
    builder.addCase(getIncidentsHeaders.pending, (state) => {
      state.isLoading.details = true;
    });

    builder.addCase(getIncidentsHeaders.fulfilled, (state, action) => {
      state.headers = action.payload;
      state.isLoading.details = false;
    });

    builder.addCase(getIncidentsHeaders.rejected, (state) => {
      state.isLoading.details = false;
    });

    // getIncidentData
    builder.addCase(getIncidentData.pending, (state) => {
      state.isLoading.details = true;
    });

    builder.addCase(getIncidentData.fulfilled, (state, action) => {
      state.selectedIncident = action.payload;
      state.isLoading.details = false;
    });

    builder.addCase(getIncidentData.rejected, (state) => {
      state.isLoading.details = false;
    });

    // createVehicleIncident:
    builder.addCase(createVehicleIncident.pending, (state) => {
      state.isLoading.create = true;
    });

    builder.addCase(createVehicleIncident.fulfilled, (state, action) => {
      if (action.payload) {
        if (!state.headers) {
          state.headers = [];
        }
        state.headers.push(action.payload);
        action.meta.arg.onCompleted?.(true);
      }
      state.isLoading.create = false;
    });

    builder.addCase(createVehicleIncident.rejected, (state, action) => {
      state.isLoading.create = false;
      action.meta.arg.onCompleted?.(false);
    });

    // updateVehicleIncident:
    builder.addCase(updateVehicleIncident.pending, (state) => {
      state.isLoading.create = true;
    });

    builder.addCase(updateVehicleIncident.fulfilled, (state, action) => {
      if (action.payload) {
        state.selectedIncident = {
          ...action.payload,
          photosUrls: state.selectedIncident?.photosUrls
        };

        // update the list of incidents headers:
        if (state.headers) {
          const index = state.headers.findIndex((header) => header.id === action.payload?.id);
          if (index >= 0) {
            state.headers[index] = {
              id: action.payload.id,
              dateTime: action.payload.dateTime,
              address: action.payload.address,
              relatedDriver: action.payload.relatedDriver?.licenseNumber,
              relatedVehicleLicensePlate: action.payload.relatedVehicleLicensePlate
            };
          }
          action.meta.arg.onCompleted?.(true);
        }
      }
      state.isLoading.create = false;
    });

    builder.addCase(updateVehicleIncident.rejected, (state, action) => {
      state.isLoading.create = false;
      action.meta.arg.onCompleted?.(false);
    });

    // uploadIncidentImages:
    builder.addCase(uploadIncidentImages.pending, (state) => {
      state.isLoading.upload = true;
    });

    builder.addCase(uploadIncidentImages.fulfilled, (state, action) => {
      if (state.selectedIncident !== undefined) {
        const currentImgs = state.selectedIncident?.photosUrls || [];
        const newImgs = action.payload || [];
  
        state.selectedIncident = {
          ...state.selectedIncident,
          photosUrls: [...currentImgs, ...newImgs]
        };
      }
      state.isLoading.upload = false;
      action.meta.arg.onCompleted?.(true);
    });

    builder.addCase(uploadIncidentImages.rejected, (state, action) => {
      state.isLoading.upload = false;
      action.meta.arg.onCompleted?.(false);
    });

  },
});

// Export the actions and reducer
export default vehicleIncidentsPageSlice.reducer;
export const {
  onResetCurrentSelectedIncidentVehicle,
} = vehicleIncidentsPageSlice.actions;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CourseHeader, CreateNewCourseBody } from '../../../api';
import RepositoryCourses from '../../repositories/RepositoryCourses';
import { AddCourseDocumentQueryProps, CourseAtendee, CourseAtendeeStatus, CourseData, CourseDocumentBase, GetCourseDocumentQueryProps, GetCourseDocumentsQueryProps, UpdateCourseDataBody } from '../../../api/api';
import { downloadFile } from '../../../global/repositories/RepositoryBase';


export type ClientCoursesPageState = {
  coursesHeaders?: CourseHeader[];
  selectedCourseData?: CourseData;
  selectedCourseDocuments?: CourseDocumentBase[] | null;
  allClientAvilableAttendees?: CourseAtendee[];
  selectedAttendeeData?: CourseAtendee;

  isLoading: {
    generic: boolean;
    download: boolean;
    getAllClientAvilableAttendees: boolean;
    documentsData: boolean;
    documentsAdd: boolean;
    documentsDownload: boolean;
    documentsDelete: boolean;
    atendeesStatusIds: string[];
    atendeesNotify: boolean;
  };
};

const initialState: ClientCoursesPageState = {
  isLoading: {
    generic: false,
    download: false,
    documentsData: false,
    documentsAdd: false,
    documentsDownload: false,
    documentsDelete: false,
    getAllClientAvilableAttendees: false,
    atendeesStatusIds: [],
    atendeesNotify: false,
  },
};


// Courses:

// createNewCourse
export const createNewCourse = createAsyncThunk('clientCoursesPageSlice/createNewCourse', async ({ clientId, course }: { clientId: string, course: CreateNewCourseBody }) => {
  const newCourse = await RepositoryCourses().createNewCourse(clientId, course);
  return newCourse;
});

// updateCourseData
export const updateCourseData = createAsyncThunk('clientCoursesPageSlice/updateCourseData', async ({ clientId, courseId, course }: { clientId: string, courseId: string, course: UpdateCourseDataBody }) => {
  await RepositoryCourses().updateCourseData(clientId, courseId, course);
  return true;
});

// getCoursesHeaders
export const getCoursesHeaders = createAsyncThunk('clientCoursesPageSlice/getCoursesHeaders', async (clientId: string) => {
  const coursesHeaders = await RepositoryCourses().getCoursesHeaders(clientId);
  return coursesHeaders;
});

// getCourseData
export const getCourseData = createAsyncThunk('clientCoursesPageSlice/getCourseData', async ({ clientId, courseId }: { clientId: string, courseId: string }) => {
  const courseData = await RepositoryCourses().getCourseData(clientId, courseId);
  return courseData;
});

export const downloadCourseSummery = createAsyncThunk('clientCoursesPageSlice/downloadCourseSummery', async ({ clientId, courseId }: { clientId: string, courseId: string }) => {
  const courseSummery = await RepositoryCourses().downloadCourseSummery(clientId, courseId);
  return courseSummery;
});

export const downloadAllCoursesSummery = createAsyncThunk('clientCoursesPageSlice/downloadAllCoursesSummery', async ({ clientId, from, courseType, to }: { clientId: string, from?: string, courseType?: string, to?: string }) => {
  const courseSummery = await RepositoryCourses().downloadAllCoursesSummery(clientId, from, courseType, to);
  return courseSummery;
});



// Attendees:
// getAllClientAvilableAttendees
export const getAllClientAvilableAttendees = createAsyncThunk('clientCoursesPageSlice/getAllClientAvilableAttendees', async (clientId: string) => {
  const allClientAvilableAttendees = await RepositoryCourses().getAllClientAvilableAttendees(clientId);
  return allClientAvilableAttendees;
});

// getAttendeeData
export const getAttendeeData = createAsyncThunk('clientCoursesPageSlice/getAttendeeData', async ({ clientId, courseId, attendeeId }: { clientId: string, courseId: string, attendeeId: string }) => {
  const attendeeData = await RepositoryCourses().getAttendeeData(clientId, courseId, attendeeId);
  return attendeeData;
});

// updateAttendeeStatus
export const updateAttendeeStatus = createAsyncThunk('clientCoursesPageSlice/updateAttendeeStatus', async ({ clientId, courseId, attendeeId, status }: { clientId: string, courseId: string, attendeeId: string, status: CourseAtendeeStatus }) => {
  await RepositoryCourses().updateAttendeeStatus(clientId, courseId, attendeeId, status);
  return true;
});

// notifyAttendees:
export const notifyAttendees = createAsyncThunk('clientCoursesPageSlice/notifyAttendees', async ({ clientId, courseId }: { clientId: string, courseId: string }) => {
  await RepositoryCourses().notifyAttendees(clientId, courseId);
});


// Documents:
// addCourseDocumentFromPDF
export const addCourseDocument = createAsyncThunk('clientCoursesPageSlice/addCourseDocument', async ({ file, docProps, }: { file: File | null, docProps: AddCourseDocumentQueryProps, onComplete: (_isSuccess: boolean) => void }) => {
  const documentId = await RepositoryCourses().addCourseDocument(file, docProps);
  return documentId;
});

// getCourseDocumentsListHeaders
export const getCourseDocumentsListHeaders = createAsyncThunk('clientCoursesPageSlice/getCourseDocumentsListHeaders', async (props: GetCourseDocumentsQueryProps) => {
  const documentsListHeaders = await RepositoryCourses().getCourseDocumentsListHeaders(props);
  return documentsListHeaders;
});

// getCourseDocumentUrl:
export const downloadCourseDocument = createAsyncThunk('clientCoursesPageSlice/downloadCourseDocument', async (props: GetCourseDocumentQueryProps) => {
  const documentUrl = await RepositoryCourses().getCourseDocumentUrl(props);
  return documentUrl;
});

// deleteCourseDocument:
export const deleteCourseDocument = createAsyncThunk('clientCoursesPageSlice/deleteCourseDocument', async (props: GetCourseDocumentQueryProps) => {
  const isDeleted = await RepositoryCourses().deleteCourseDocument(props);
  return isDeleted;
});




export const clientCoursesPageSlice = createSlice({
  name: 'clientCoursesPageSlice',
  initialState,
  reducers: {

    // resetCourseData:
    resetCourseData: (state) => {
      state.selectedCourseData = undefined;
      state.allClientAvilableAttendees = undefined;
      state.selectedCourseDocuments = null;
    },

  },

  extraReducers: (builder) => {

    // ==== createNewCourse ==== //
    builder.addCase(createNewCourse.pending, (state, _action) => {
      state.isLoading.generic = true;
    });

    builder.addCase(createNewCourse.fulfilled, (state, action) => {
      if (action.payload) {
        state.coursesHeaders?.push({
          id: action.payload.id,
          title: action.payload.title,
          courseType: action.payload.courseType,
          status: action.payload.status,
          totalRegistered: action.payload.totalRegistered,
          startedAt: action.payload.startedAt,
        });
      }
      state.isLoading.generic = false;
    });

    builder.addCase(createNewCourse.rejected, (state, _action) => {
      state.isLoading.generic = false;
    });


    // ==== updateCourseData ==== //
    builder.addCase(updateCourseData.pending, (state, _action) => {
      state.isLoading.generic = true;
    });

    builder.addCase(updateCourseData.fulfilled, (state, _action) => {
      state.isLoading.generic = false;
    });

    builder.addCase(updateCourseData.rejected, (state, _action) => {
      state.isLoading.generic = false;
    });


    // ==== getCoursesHeaders ==== //
    builder.addCase(getCoursesHeaders.pending, (state, _action) => {
      state.isLoading.generic = true;
    });

    builder.addCase(getCoursesHeaders.fulfilled, (state, action) => {
      state.coursesHeaders = action.payload;
      state.isLoading.generic = false;
    });

    builder.addCase(getCoursesHeaders.rejected, (state, _action) => {
      state.isLoading.generic = false;
    });


    // ==== getCourseData ==== //
    builder.addCase(getCourseData.pending, (state, _action) => {
      state.selectedCourseData = undefined;
      state.allClientAvilableAttendees = undefined;
      state.selectedCourseDocuments = null;
      state.isLoading.generic = true;
    });

    builder.addCase(getCourseData.fulfilled, (state, action) => {
      state.selectedCourseData = action.payload;
      state.isLoading.generic = false;
    });

    builder.addCase(getCourseData.rejected, (state, _action) => {
      state.selectedCourseData = undefined;
      state.isLoading.generic = false;
    });



    // ==== downloadCourseSummery ==== //
    builder.addCase(downloadCourseSummery.pending, (state, _action) => {
      state.isLoading.download = true;
    });

    builder.addCase(downloadCourseSummery.fulfilled, (state, action) => {
      downloadFile(action.payload, `${action.meta.arg.courseId}`, 'application/pdf')
      state.isLoading.download = false;
    });

    builder.addCase(downloadCourseSummery.rejected, (state, _action) => {
      state.isLoading.download = false;
    });

    // ==== downloadAllCoursesSummery ==== //
    builder.addCase(downloadAllCoursesSummery.pending, (state, _action) => {
      state.isLoading.download = true;
    });

    builder.addCase(downloadAllCoursesSummery.fulfilled, (state, action) => {
      downloadFile(action.payload, `all_courses`, 'application/pdf')
      state.isLoading.download = false;
    });

    builder.addCase(downloadAllCoursesSummery.rejected, (state, _action) => {
      state.isLoading.download = false;
    });



    // ==== getAllClientAvilableAttendees ==== //
    builder.addCase(getAllClientAvilableAttendees.pending, (state, _action) => {
      state.allClientAvilableAttendees = undefined;
      state.isLoading.getAllClientAvilableAttendees = true;
    });

    builder.addCase(getAllClientAvilableAttendees.fulfilled, (state, action) => {
      state.allClientAvilableAttendees = action.payload;
      state.isLoading.getAllClientAvilableAttendees = false;
    });

    builder.addCase(getAllClientAvilableAttendees.rejected, (state, _action) => {
      state.allClientAvilableAttendees = undefined;
      state.isLoading.getAllClientAvilableAttendees = false;
    });


    // ==== getAttendeeData ==== //
    builder.addCase(getAttendeeData.pending, (state, _action) => {
      state.selectedAttendeeData = undefined;
      state.isLoading.generic = true;
    });

    builder.addCase(getAttendeeData.fulfilled, (state, action) => {
      state.selectedAttendeeData = action.payload;
      state.isLoading.generic = false;
    });

    builder.addCase(getAttendeeData.rejected, (state, _action) => {
      state.selectedAttendeeData = undefined;
      state.isLoading.generic = false;
    });


    // ==== updateAttendeeStatus ==== //
    builder.addCase(updateAttendeeStatus.pending, (state, _action) => {
      state.isLoading.atendeesStatusIds.push(_action.meta.arg.attendeeId);
    });

    builder.addCase(updateAttendeeStatus.fulfilled, (state, action) => {
      if (action.payload && state.selectedCourseData?.atendees) {
        const updatedAtendees = state.selectedCourseData.atendees.map((atendee) => {
          if (atendee.id === action.meta.arg.attendeeId) {
            return {
              ...atendee,
              status: action.meta.arg.status
            };
          }
          return atendee;
        });

        const updatedTotalPassed = updatedAtendees.filter((atendee) => atendee.status === CourseAtendeeStatus.PASSED).length;
        const updatedTotalFailed = updatedAtendees.filter((atendee) => atendee.status === CourseAtendeeStatus.FAILED).length;

        state.selectedCourseData = {
          ...state.selectedCourseData,
          totalPassed: updatedTotalPassed,
          totalFailed: updatedTotalFailed,
          atendees: updatedAtendees
        };
      }
      state.isLoading.atendeesStatusIds.splice(state.isLoading.atendeesStatusIds.indexOf(action.meta.arg.attendeeId), 1);
    });

    builder.addCase(updateAttendeeStatus.rejected, (state, _action) => {
      state.isLoading.atendeesStatusIds.splice(state.isLoading.atendeesStatusIds.indexOf(_action.meta.arg.attendeeId), 1);
    });


    // ==== notifyAttendees ==== //
    builder.addCase(notifyAttendees.pending, (state, _action) => {
      state.isLoading.atendeesNotify = true;
    });

    builder.addCase(notifyAttendees.fulfilled, (state, _action) => {
      state.isLoading.atendeesNotify = false;
    });

    builder.addCase(notifyAttendees.rejected, (state, _action) => {
      state.isLoading.atendeesNotify = false;
    });



    // ==== addCourseDocument ==== //
    builder.addCase(addCourseDocument.pending, (state, _action) => {
      state.isLoading.documentsAdd = true;
    });

    builder.addCase(addCourseDocument.fulfilled, (state, action) => {
      if (action.payload) {
        state.selectedCourseDocuments?.push(action.payload);
        action.meta.arg.onComplete(true);
      }
      state.isLoading.documentsAdd = false;
    });

    builder.addCase(addCourseDocument.rejected, (state, action) => {
      state.isLoading.documentsAdd = false;
      action.meta.arg.onComplete(false);
    });


    // getCourseDocumentsListHeaders
    builder.addCase(getCourseDocumentsListHeaders.pending, (state, _action) => {
      state.selectedCourseDocuments = null;
      state.isLoading.documentsData = true;
    });

    builder.addCase(getCourseDocumentsListHeaders.fulfilled, (state, action) => {
      state.selectedCourseDocuments = action.payload;
      state.isLoading.documentsData = false;
    });

    builder.addCase(getCourseDocumentsListHeaders.rejected, (state, _action) => {
      state.selectedCourseDocuments = null;
      state.isLoading.documentsData = false;
    });


    // getCourseDocumentUrl
    builder.addCase(downloadCourseDocument.pending, (state, _action) => {
      state.isLoading.documentsDownload = true;
    });

    builder.addCase(downloadCourseDocument.fulfilled, (state, action) => {
      // Open the document in a new tab:
      if (action.payload) {
        window.open(action.payload, '_blank');
      }
      state.isLoading.documentsDownload = false;
    });

    builder.addCase(downloadCourseDocument.rejected, (state, _action) => {
      state.isLoading.documentsDownload = false;
    });

    // deleteCourseDocument:
    builder.addCase(deleteCourseDocument.pending, (state, _action) => {
      state.isLoading.documentsDelete = true;
    });

    builder.addCase(deleteCourseDocument.fulfilled, (state, action) => {
      if (action.payload) {
        state.selectedCourseDocuments = state.selectedCourseDocuments?.filter((doc) => doc.id !== action.meta.arg.documentId);
      }
      state.isLoading.documentsDelete = false;
    });

    builder.addCase(deleteCourseDocument.rejected, (state, _action) => {
      state.isLoading.documentsDelete = false;
    });

  }
});

export default clientCoursesPageSlice.reducer;
export const { resetCourseData } = clientCoursesPageSlice.actions;

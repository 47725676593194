import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Stack, Box, Typography, Grid, Button } from "@mui/material";
import { AddressInputFieldProps } from "../../../../utils/formsUtils";
import {
  Address,
  IncidentInvolvedParty,
  IncidentInvolvedPartyType,
  IncidentInvolvedPartyVehicle,
  VehicleManufacturer,
  VehicleType,
} from "../../../../../api";

import FormProvider, {
  RHFTextField,
  RHFSelect,
  RHFCheckbox,
} from "../../../../../global/components/hook-form";
import {
  emailSchema,
  genericAnySchema,
  genericRequiredStringSchema,
  numberOnlyStringSchema,
  numberOnlyStringSchemaRequired,
  textOnlySchema,
  textOnlySchemaRequired,
} from "../../../../../global/utils/formValidators";
import { DashboardRootState } from "../../../../app/dashboardAppStore";
import { useDashboardAppSelector } from "../../../../hooks/useRedux";
import { getVehicleTypeLabelKey } from "../../../../utils/models/typesUtils";
import {
  getManufacturersBasedOnVehicleType,
  getModelsBasedOnManufacturer,
} from "../../../../utils/models/vehicleUtils";

export type IncidentInvolvedPartyAddProps = {
  currentData?: IncidentInvolvedParty;
  translate: Function;
  onAddEditInvolvedParty?: (_newInvolvedParty: IncidentInvolvedParty) => void;
  onCloseClicked?: Function;
};

export default function IncidentInvolvedPartyAdd({
  currentData,
  translate,
  onAddEditInvolvedParty,
  onCloseClicked,
}: IncidentInvolvedPartyAddProps) {
  const vehicleConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig.vehicleConfig
  );

  const getPersonAddressInputFields: AddressInputFieldProps[] = [
    { name: "personAddressStreet", lablel_key: "form_input_street" },
    {
      name: "personAddressHouseNumber",
      lablel_key: "form_input_house_number",
    },
    { name: "personAddressCity", lablel_key: "form_input_city" },
    { name: "personAddressZipCode", lablel_key: "form_input_zip_code" },
  ];

  const getVehicleAddressInputFields: AddressInputFieldProps[] = [
    { name: "vehicleOwnerAddressStreet", lablel_key: "form_input_street" },
    {
      name: "vehicleOwnerAddressHouseNumber",
      lablel_key: "form_input_house_number",
    },
    { name: "vehicleOwnerAddressCity", lablel_key: "form_input_city" },
    { name: "vehicleOwnerAddressZipCode", lablel_key: "form_input_zip_code" },
  ];

  const NewInvolvedPartySchema = Yup.object().shape({
    type: genericRequiredStringSchema(translate),
    personName: textOnlySchemaRequired(translate),
    personGovId: numberOnlyStringSchemaRequired(translate),
    personlicenseNumber: numberOnlyStringSchema(translate),
    personAddressStreet: genericAnySchema(),
    personAddressHouseNumber: genericAnySchema(),
    personAddressCity: genericAnySchema(),
    personAddressZipCode: genericAnySchema(),
    personPhoneNumber: numberOnlyStringSchema(translate),
    personEmail: emailSchema(translate),
    insuranceCompany: genericAnySchema(),
    insurancePolicyNumber: genericAnySchema(),
    insuranceAgentName: textOnlySchema(translate),
    usePersonDetailsForVehicleOwner: Yup.boolean(),
    vehicleLicenseNumber: numberOnlyStringSchema(translate),
    vehicleMake: genericAnySchema(),
    vehicleModel: genericAnySchema(),
    vehicleYear: numberOnlyStringSchema(translate),
    vehicleType: genericAnySchema(),
    vehicleOwnerName: textOnlySchema(translate),
    vehicleOwnerId: numberOnlyStringSchema(translate),
    vehicleOwnerPhoneNumber: numberOnlyStringSchema(translate),
    vehicleOwnerAddressStreet: genericAnySchema(),
    vehicleOwnerAddressHouseNumber: genericAnySchema(),
    vehicleOwnerAddressCity: genericAnySchema(),
    vehicleOwnerAddressZipCode: genericAnySchema(),
  });

  const defaultValues = useMemo(
    () => ({
      type: currentData?.type || IncidentInvolvedPartyType.OTHER,
      personName: currentData?.personName || "",
      personGovId: currentData?.personGovId || "",
      personlicenseNumber: currentData?.personlicenseNumber || "",
      personAddressStreet: currentData?.personAddress?.street || "",
      personAddressHouseNumber: currentData?.personAddress?.houseNumber || "",
      personAddressCity: currentData?.personAddress?.city || "",
      personAddressZipCode: currentData?.personAddress?.zipCode || "",
      personPhoneNumber: currentData?.personPhoneNumber || "",
      personEmail: currentData?.personEmail || "",
      insuranceCompany: currentData?.insuranceCompany || "",
      insurancePolicyNumber: currentData?.insurancePolicyNumber || "",
      insuranceAgentName: currentData?.insuranceAgentName || "",
      usePersonDetailsForVehicleOwner: false,
      vehicleLicenseNumber: currentData?.vehicle?.vehicleLicenseNumber || "",
      vehicleMake: currentData?.vehicle?.vehicleMake || "",
      vehicleModel: currentData?.vehicle?.vehicleModel || "",
      vehicleYear: currentData?.vehicle?.vehicleYear || "",
      vehicleType: currentData?.vehicle?.vehicleType || "",
      vehicleOwnerName: currentData?.vehicle?.vehicleOwnerName || "",
      vehicleOwnerId: currentData?.vehicle?.vehicleOwnerId || "",
      vehicleOwnerPhoneNumber:
        currentData?.vehicle?.vehicleOwnerPhoneNumber || "",
      vehicleOwnerAddressStreet:
        currentData?.vehicle?.vehicleOwnerAddress?.street || "",
      vehicleOwnerAddressHouseNumber:
        currentData?.vehicle?.vehicleOwnerAddress?.houseNumber || "",
      vehicleOwnerAddressCity:
        currentData?.vehicle?.vehicleOwnerAddress?.city || "",
      vehicleOwnerAddressZipCode:
        currentData?.vehicle?.vehicleOwnerAddress?.zipCode || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentData]
  );

  const methods = useForm({
    resolver: yupResolver(NewInvolvedPartySchema),
    defaultValues,
  });

  // List of manufacturers:
  const [manufacturers, setManufacturers] = useState<VehicleManufacturer[]>([]);
  const [models, setModels] = useState<VehicleManufacturer[]>([]);
  const make = methods.watch("vehicleMake");

  // All VehicleType:
  const selectedVehicleType = methods.watch("vehicleType");

  useEffect(() => {
    if (selectedVehicleType && vehicleConfig) {
      setModels([]);
      setManufacturers(
        getManufacturersBasedOnVehicleType(
          vehicleConfig,
          selectedVehicleType.toString()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVehicleType]);

  useEffect(() => {
    if (make && vehicleConfig && selectedVehicleType) {
      setModels(
        getModelsBasedOnManufacturer(
          vehicleConfig,
          selectedVehicleType.toString(),
          make.toString()
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [make]);

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const getAddressFromData = (data: any, vehicle: boolean): Address => ({
    street: vehicle
      ? data.vehicleOwnerAddressStreet
      : data.personAddressStreet || "",
    houseNumber: vehicle
      ? data.vehicleOwnerAddressHouseNumber
      : data.personAddressHouseNumber || "",
    city: vehicle ? data.vehicleOwnerAddressCity : data.personAddressCity || "",
    zipCode: vehicle
      ? data.vehicleOwnerAddressZipCode
      : data.personAddressZipCode || "",
  });

  const usePersonDetailsForVehicleOwner = methods.watch(
    "usePersonDetailsForVehicleOwner"
  );

  if (usePersonDetailsForVehicleOwner) {
    methods.setValue("vehicleOwnerName", methods.watch("personName"));
    methods.setValue("vehicleOwnerId", methods.watch("personGovId"));
    methods.setValue(
      "vehicleOwnerPhoneNumber",
      methods.watch("personPhoneNumber")
    );

    // Address:
    methods.setValue(
      "vehicleOwnerAddressStreet",
      methods.watch("personAddressStreet")
    );
    methods.setValue(
      "vehicleOwnerAddressHouseNumber",
      methods.watch("personAddressHouseNumber")
    );
    methods.setValue(
      "vehicleOwnerAddressCity",
      methods.watch("personAddressCity")
    );
    methods.setValue(
      "vehicleOwnerAddressZipCode",
      methods.watch("personAddressZipCode")
    );
  }
  
  const onSubmit = async (data: any) => {
    const newInvolvedParty: IncidentInvolvedParty = {
      type: data.type,
      personName: data.personName,
      personGovId: data.personGovId,
      personlicenseNumber: data.personlicenseNumber,
      personAddress: getAddressFromData(data, false),
      personPhoneNumber: data.personPhoneNumber,
      personEmail: data.personEmail,
      vehicle: {
        vehicleType: data.vehicleType,
        vehicleMake: data.vehicleMake,
        vehicleModel: data.vehicleModel,
        vehicleYear: data.vehicleYear,
        vehicleLicenseNumber: data.vehicleLicenseNumber,
        vehicleOwnerName: usePersonDetailsForVehicleOwner
          ? data.personName
          : data.vehicleOwnerName,
        vehicleOwnerId: usePersonDetailsForVehicleOwner
          ? data.personGovId
          : data.vehicleOwnerId,
        vehicleOwnerAddress: getAddressFromData(
          data,
          !usePersonDetailsForVehicleOwner
        ),
        vehicleOwnerPhoneNumber: usePersonDetailsForVehicleOwner
          ? data.personPhoneNumber
          : data.vehicleOwnerPhoneNumber,
      } as IncidentInvolvedPartyVehicle,
      insuranceCompany: data.insuranceCompany,
      insurancePolicyNumber: data.insurancePolicyNumber,
      insuranceAgentName: data.insuranceAgentName,
    };

    if (onAddEditInvolvedParty) {
      onAddEditInvolvedParty(newInvolvedParty);
      reset();
    }
  };

  const renderTitleAndActions = (
    <Stack direction={"row"} justifyContent="space-between">
      <Stack>
        <Typography variant="h4">{`${translate("vehicle_incident_add_edit_involved_parties_add")}`}</Typography>
      </Stack>

      <Stack direction={"row"} spacing={1}>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ minWidth: 120 }}
        >
          {`${translate("global_save")}`}
        </LoadingButton>

        <Button
          onClick={() => {
            if (onCloseClicked) {
              onCloseClicked();
            }
          }}
          variant="outlined"
          color="error"
        >
          {`${translate("global_cancel_and_close")}`}
        </Button>
      </Stack>
    </Stack>
  );

  const renderPersonDetails = (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_involved_party_person_details")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <RHFSelect
          native
          name="type"
          label={`${translate("vehicle_incident_add_edit_involved_party_type")}`}
        >
          <option />
          {Object.values(IncidentInvolvedPartyType).map((partyType) => (
            <option key={partyType} value={partyType}>
              {`${translate(`incident_details_involved_parties_type_${partyType.toLowerCase()}`)}`}
            </option>
          ))}
        </RHFSelect>

        <RHFTextField
          name="personName"
          label={`${translate("vehicle_incident_add_edit_involved_party_person_name")}`}
        />

        <RHFTextField
          disabled={currentData !== undefined}
          name="personGovId"
          label={`${translate("vehicle_incident_add_edit_involved_party_person_gov_id")}`}
        />

        <RHFTextField
          name="personlicenseNumber"
          label={`${translate("vehicle_incident_add_edit_involved_party_person_license_number")}`}
        />

        <RHFTextField
          name="personEmail"
          label={`${translate("vehicle_incident_add_edit_involved_party_person_email")}`}
        />

        <RHFTextField
          name="personPhoneNumber"
          label={`${translate("vehicle_incident_add_edit_involved_party_person_phone_number")}`}
        />
      </Box>
    </Stack>
  );

  const renderPersonAddress = (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_involved_party_person_address")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        {getPersonAddressInputFields.map((field) => (
          <RHFTextField
            key={field.name}
            name={field.name}
            label={`${translate(field.lablel_key)}`}
          />
        ))}
      </Box>
    </Stack>
  );

  const renderInsuranceDetails = (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_involved_parties_insurance_details")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <RHFTextField
          name="insuranceCompany"
          label={`${translate("vehicle_incident_add_edit_involved_parties_insurance_company")}`}
        />
        <RHFTextField
          name="insurancePolicyNumber"
          label={`${translate("vehicle_incident_add_edit_involved_parties_insurance_policy_number")}`}
        />
        <RHFTextField
          name="insuranceAgentName"
          label={`${translate("vehicle_incident_add_edit_involved_parties_insurance_agent_name")}`}
        />
      </Box>
    </Stack>
  );

  const renderVehicleDetails = (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_involved_parties_vehicle_details")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        <RHFTextField
          name="vehicleLicenseNumber"
          label={`${translate("client_page_vehicle_id")}`}
          type="number"
        />
        <RHFTextField
          name="vehicleYear"
          label={`${translate("client_page_vehicle_year")}`}
          type="number"
        />

        <RHFSelect
          native
          name="vehicleType"
          label={`${translate("client_page_vehicle_type")}`}
        >
          <option />
          {vehicleConfig?.vehicleTypes?.map(
            (type) =>
              type && (
                <option key={type} value={type}>
                  {`${translate(getVehicleTypeLabelKey(type))}`}
                </option>
              )
          )}
        </RHFSelect>

        <RHFSelect
          native
          name="vehicleMake"
          label={`${translate("client_page_vehicle_make")}`}
        >
          <option value="" />
          {manufacturers.map((car) => (
            <option key={car.value} value={car.value}>
              {car.text}
            </option>
          ))}
        </RHFSelect>

        {selectedVehicleType === VehicleType.CAR ||
        selectedVehicleType === VehicleType.TAXI ||
        selectedVehicleType === VehicleType.COMMERCIAL ? (
          <RHFSelect
            native
            name="vehicleModel"
            label={`${translate("client_page_vehicle_model")}`}
          >
            <option value="" />
            {models.map((model) => (
              <option key={model.value} value={model.value}>
                {model.text}
              </option>
            ))}
          </RHFSelect>
        ) : (
          <RHFTextField
            name="vehicleModel"
            label={`${translate("client_page_vehicle_model")}`}
          />
        )}

        <RHFCheckbox
          name="usePersonDetailsForVehicleOwner"
          label={`${translate("vehicle_incident_add_edit_involved_parties_use_person_details_for_vehicle_owner")}`}
        />
        <RHFTextField
          name="vehicleOwnerName"
          label={`${translate("vehicle_incident_add_edit_involved_parties_vehicle_owner_name")}`}
        />
        <RHFTextField
          name="vehicleOwnerId"
          label={`${translate("vehicle_incident_add_edit_involved_parties_vehicle_owner_gov_id")}`}
        />
        <RHFTextField
          name="vehicleOwnerPhoneNumber"
          label={`${translate("vehicle_incident_add_edit_involved_parties_vehicle_owner_phone_number")}`}
        />
      </Box>
    </Stack>
  );

  const renderVehicleOwnerAddress = (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        {`${translate("vehicle_incident_add_edit_involved_parties_vehicle_owner_address")}`}
      </Typography>
      <Box
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        {getVehicleAddressInputFields.map((field) => (
          <RHFTextField
            key={field.name}
            name={field.name}
            label={`${translate(field.lablel_key)}`}
          />
        ))}
      </Box>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack padding={4}>
        {renderTitleAndActions}

        {/* Content */}
        <Grid container spacing={3}>
          {/** Person & Address */}
          <Grid item xs>
            <Stack spacing={1}>
              {renderPersonDetails}
              {renderPersonAddress}
              {renderInsuranceDetails}
            </Stack>
          </Grid>

          {/* Vehicle & Insurance */}
          <Grid item xs>
            <Stack spacing={1}>
              {renderVehicleDetails}
              {renderVehicleOwnerAddress}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </FormProvider>
  );
}

// @mui
import { Dialog, Stack } from "@mui/material";
// import { Edit } from "@mui/icons-material";
import { useState } from "react";
import { Edit, Archive, Restore } from "@mui/icons-material";
import { Vehicle } from "../../../../api";
import { getVehicleOwnerTypeLabelKey, getVehicleTypeLabelKey } from "../../../utils/models/typesUtils";
import FullWithDataView from "../../common/views/FullWithDataView";
import ManufacturerOrModelLabel from "../../common/views/ManufacturerOrModelLabel";
import VehicleDetailsPageRelatedDrivers from "./VehicleDetailsPageRelatedDrivers";
import ClientVehiclesAddEdit from "../list/ClientVehiclesAddEdit";
import DeleteConfimDialog from "../../common/views/DeleteConfimDialog";
import { useDashboardAppSelector } from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { formatKmString } from "../../../utils/format/formatUnits";

type Props = {
  clientId: string;
  translate: Function;
  vehicle: Vehicle | null;
  onDataChanged?: Function;
  onRemoveDriver?: Function;
  onUpdateArchive?: () => Promise<void> | null;
  onDriverItemClicked: (_driverId: string) => void;
};

export default function VehicleDetailsPage({
  clientId,
  translate,
  vehicle,
  onDataChanged,
  onRemoveDriver,
  onUpdateArchive,
  onDriverItemClicked,
}: Props) {

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.isLoading
  );

  const clientDriversData = useDashboardAppSelector(
    (state: DashboardRootState) => state.clientPageSlice.drivers
  );

  // ------------------- Delete vehicle Dialog -------------------
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const handleOpenDeleteConfirm = () => {
    setOpenDeleteConfirm(true);
  };

  const handleCloseDeleteConfirm = () => {
    setOpenDeleteConfirm(false);
  };

  const handleDeleteClick = () => {
    if (onUpdateArchive) {
      onUpdateArchive();
    }
    handleCloseDeleteConfirm();
  };

  // ------------------- Edit vehicle Dialog -------------------
  const [isEditVehicleDialogOpen, setIsEditVehicleDialogOpen] = useState(false);
  const handleEditVehicleClicked = () => {
    setIsEditVehicleDialogOpen(true);
  };

  const handleEditVehicleClose = () => {
    setIsEditVehicleDialogOpen(false);
  };

  const handleOnEditVehicle = () => {
    if (onDataChanged) {
      onDataChanged();
    }

    handleEditVehicleClose();
  };

  const VehicleDetails = () => {
    if (!vehicle) {
      return <></>;
    }

    const data = [
      {
        title: `${translate("client_page_vehicle_make")}`,
        overrideElement: (
          <ManufacturerOrModelLabel
            labelType="manufacturer"
            variant="body1"
            sx={{ fontWeight: "bold" }}
            makeId={vehicle.makeId}
            modelId={vehicle.modelId}
            type={vehicle.type}
          />
        ),
      },
      {
        title: `${translate("client_page_vehicle_model")}`,
        overrideElement: (
          <ManufacturerOrModelLabel
            labelType="model"
            variant="body1"
            sx={{ fontWeight: "bold" }}
            makeId={vehicle.makeId}
            modelId={vehicle.modelId}
            type={vehicle.type}
          />
        ),
      },
      {
        title: `${translate("client_page_vehicle_year")}`,
        value: vehicle.buildYear.toString(),
      },
      {
        title: `${translate("client_page_vehicle_type")}`,
        value: `${translate(getVehicleTypeLabelKey(vehicle.type))}`,
      },
      {
        title: `${translate("client_page_vehicle_owner_type")}`,
        value: `${translate(getVehicleOwnerTypeLabelKey(vehicle.ownerType))}`,
      },
      {
        title: `${translate("client_page_vehicle_vin")}`,
        value: vehicle.vin,
      },
      {
        title: `${translate("client_page_vehicle_last_odometer")}`,
        value: `${formatKmString(vehicle.lastOdometer, translate)}`,
      },
      {
        title: `${translate("client_page_vehicle_color")}`,
        value: `${vehicle.color ?? translate("global_no_data")}`,
      },
      {
        title: `${translate("client_page_vehicle_internal_id")}`,
        value: `${vehicle.internalId ?? translate("global_no_data")}`,
      },
    ];

    return (
      <FullWithDataView
        title={`${translate("report_page_title_car_details")}`}
        itemsPerRow={data.length / 3}
        data={data}
        translate={translate}
        actions={
          [
            {
              title: `${translate("global_edit")}`,
              onClick: handleEditVehicleClicked,
              icon: <Edit />,
            },
            {
              title: `${translate(vehicle.isArchived ? "vehicle_page_move_out_from_archive" : "vehicle_page_move_to_archive")}`,
              onClick: handleOpenDeleteConfirm,
              icon: vehicle.isArchived ? <Restore /> : <Archive />,
              color: vehicle.isArchived ? "success" : "error",
            },
          ]
        }
      />
    );
  };

  return vehicle ? (
    <>
      <Stack spacing={3}>
        {/** Vehicle details */}
        <VehicleDetails />

        {/** Related drivers */}
        <VehicleDetailsPageRelatedDrivers
          clientDriversData={clientDriversData}
          isLoading={isLoading.drivers}
          translate={translate}
          vehicle={vehicle}
          onLinkDriver={onDataChanged}
          onRemoveDriver={onRemoveDriver}
          onDriverItemClicked={onDriverItemClicked}
        />
      </Stack>

      {/* Delete comfirm dialog: */}
      <DeleteConfimDialog
        translate={translate}
        titleKey={vehicle.isArchived ? "vehicle_page_move_out_from_archive" : "vehicle_page_move_to_archive"}
        descriptionKey={vehicle.isArchived ? "vehicle_page_move_out_from_archive_description" : "vehicle_page_move_to_archive_description"}
        actionLabelKey={vehicle.isArchived ? "vehicle_page_move_out_from_archive" : "vehicle_page_move_to_archive"}
        isOpen={openDeleteConfirm}
        onClose={handleCloseDeleteConfirm}
        onAction={handleDeleteClick}
      />

      {/* Edit vehicle dialog: */}
      <Dialog open={isEditVehicleDialogOpen} onClose={handleEditVehicleClose}>
        <ClientVehiclesAddEdit
          isEdit
          currentVehicle={vehicle}
          clientId={clientId}
          translate={translate}
          drivers={clientDriversData}
          onComplete={handleOnEditVehicle}
        />
      </Dialog>
    </>
  ) : (
    <></>
  );
}

import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { Button, Card, Stack } from "@mui/material";
import { DriverUser } from "../../../../api";
import FormProvider, {
  RHFDatePicker,
  RHFSelect,
} from "../../../../global/components/hook-form";

export default function VehicleTachographsPageToolbar({
  translate,
  selectedDate,
  driversList,
  selectedDriverId,
  handleOnDateChanged,
  setSelectedDriverId,
}: {
  translate: Function;
  selectedDate: Date;
  driversList?: DriverUser[];
  selectedDriverId?: string;
  handleOnDateChanged: (_newDate: Date) => void;
  setSelectedDriverId: (_driverId: string) => void;
}) {
  const defaultValues = useMemo(
    () => ({
      relatedDriverId: selectedDriverId || undefined,
      year: selectedDate,
      month: selectedDate,
    }),
    [selectedDate, selectedDriverId]
  );

  const methods = useForm({
    defaultValues,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods, selectedDate]);

  return (
    <>
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods}>
          <Stack direction="row" spacing={2}>
            {/* Driver Select */}
            <RHFSelect
              native
              name="relatedDriverId"
              label={`${translate("vehicle_tachograph_page_calendar_select_driver")}`}
              onSelected={(selected) => setSelectedDriverId(selected)}
            >
              <option key={"all"} value={""}>
                {`${translate("vehicle_tachograph_page_calendar_all_drivers")}`}
              </option>
              {driversList?.map(
                (driverData) =>
                  driverData && (
                    <option key={driverData.govId} value={driverData.id}>
                      {`${driverData.fullName} | ${driverData.govId}`}
                    </option>
                  )
              )}
            </RHFSelect>

            {/* Year Select */}
            <RHFDatePicker
              name="year"
              label={`${translate("vehicle_tachograph_page_calendar_title_year")}`}
              openTo="year"
              views={["year"]}
              onValueChanged={handleOnDateChanged}
            />

            {/* Month Select */}
            <RHFDatePicker
              name="month"
              label={`${translate("vehicle_tachograph_page_calendar_title_month")}`}
              openTo="month"
              views={["month"]}
              onValueChanged={handleOnDateChanged}
            />

            {/* Jump to today button */}
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleOnDateChanged(new Date())}
            >
              {`${translate("global_today")}`}
            </Button>
          </Stack>
        </FormProvider>
      </Card>
    </>
  );
}

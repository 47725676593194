
export const convertFileToBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

/**
 * Reduces the size of the image to the specified maximum size.
 * @param file The image file to reduce.
 * @param maxSize The maximum size of the image.
 * @returns A promise that resolves with the reduced image file.
 */
export const reduceImageSize = (file: File, maxSize: number): Promise<File> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
            const elem = document.createElement('canvas');
            const {width} = img;
            const {height} = img;
            const scale = Math.min(maxSize / width, maxSize / height);
            elem.width = width * scale;
            elem.height = height * scale;
            const ctx = elem.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height, 0, 0, width * scale, height * scale);
            ctx?.canvas.toBlob((blob) => {
                if (blob) {
                    const reducedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: Date.now()
                    });
                    resolve(reducedFile);
                }
            }, file.type);
        };
    };
    reader.onerror = error => reject(error);
});
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryVehicleInvoices, { AddVehicleInvoiceParams, DownloadVehicleInvoicesParams, GetVehicleInvoicesParams, UpdateVehicleInvoiceParams } from '../../../repositories/vehicle/RepositoryVehicleInvoices';
import { KInvoice } from '../../../../api';

export type VehiclePageInvoicesState = {
  data: KInvoice[];
  isLoading: {
    data: boolean;
    invoicesUpdate: boolean;
  }
};

const initialState: VehiclePageInvoicesState = {
  data: [],
  isLoading: {
    data: false,
    invoicesUpdate: false
  }
};

// Vehicle invoices:
export const clearSlice = createAsyncThunk('vehiclePageSlice/clearSlice', async (_params, _thunkAPI) => initialState);

export const getVehicleInvoices = createAsyncThunk('vehiclePageSlice/getVehicleInvoices', async (params: GetVehicleInvoicesParams) => {
  const data = await RepositoryVehicleInvoices().getVehicleInvoices(params);
  return data;
});


export const downloadVehicleInvoice = createAsyncThunk('vehiclePageSlice/downloadVehicleInvoice', async ({
  params
}: {
  params: DownloadVehicleInvoicesParams,
  onRespose: (_data: KInvoice) => void
}) => {
  const data = await RepositoryVehicleInvoices().getVehicleInvoiceById(params);
  return data;
});


export const addVehicleInvoice = createAsyncThunk('vehiclePageSlice/addVehicleInvoice', async ({
  params
}: {
  params: AddVehicleInvoiceParams,
  onRespose: (_isSuccess: boolean) => void
}) => {
  try {
    const isSuccess = await RepositoryVehicleInvoices().addVehicleInvoice(params);
    return isSuccess;
  } catch (error) {
    return false;
  }
});

export const updateVehicleInvoice = createAsyncThunk('vehiclePageSlice/updateVehicleInvoice', async ({
  params }: {
    params: UpdateVehicleInvoiceParams,
    onRespose: (_isSuccess: boolean) => void
  }) => {
  const data = await RepositoryVehicleInvoices().updateVehicleInvoice(params);
  return data;
});

// Slice:
export const vehiclePageInvoicesSlice = createSlice({
  name: 'vehiclePageInvoicesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    // clear slice:
    builder.addCase(clearSlice.fulfilled, (_state: VehiclePageInvoicesState, _action) => initialState);

    // vehicle invoices:
    builder.addCase(getVehicleInvoices.pending, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.data = true;
    });

    builder.addCase(getVehicleInvoices.fulfilled, (state: VehiclePageInvoicesState, action) => {
      if (action.payload) {
        // Arragne the invoices by date:
        action.payload.sort((a, b) => {
          if (a.issuedDate && b.issuedDate) {
            return new Date(a.issuedDate).getTime() - new Date(b.issuedDate).getTime();
          }
          return 0;
        });
        state.data = action.payload;
      }
      state.isLoading.data = false;
    });

    builder.addCase(getVehicleInvoices.rejected, (state: VehiclePageInvoicesState, _action) => {
      state.data = [];
      state.isLoading.data = false;
    });


    // download vehicle invoice:
    builder.addCase(downloadVehicleInvoice.pending, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.data = true;
    });

    builder.addCase(downloadVehicleInvoice.fulfilled, (state: VehiclePageInvoicesState, action) => {
      if (action.payload != null) {
        action.meta.arg.onRespose(action.payload);
      }
      state.isLoading.data = false;

    });

    builder.addCase(downloadVehicleInvoice.rejected, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.data = false;
    });


    // add vehicle invoice:
    builder.addCase(addVehicleInvoice.pending, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.invoicesUpdate = true;
    });

    builder.addCase(addVehicleInvoice.fulfilled, (state: VehiclePageInvoicesState, action) => {
      if (action.payload) {
        state.data.push(action.payload);
        action.meta.arg.onRespose(true);
      }

      action.meta.arg.onRespose(false);
      state.isLoading.invoicesUpdate = false;
    });

    builder.addCase(addVehicleInvoice.rejected, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.invoicesUpdate = false;
    });

    // update vehicle invoice:
    builder.addCase(updateVehicleInvoice.pending, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.invoicesUpdate = true;
    });

    builder.addCase(updateVehicleInvoice.fulfilled, (state: VehiclePageInvoicesState, action) => {
      if (action.payload) {
        const updatedInvoice = action.payload;
        const index = state.data.findIndex((invoice) => invoice.id === updatedInvoice.id);
        if (index !== -1) {
          state.data[index] = updatedInvoice;
        }
        action.meta.arg.onRespose(true);
      }
      action.meta.arg.onRespose(false);
      state.isLoading.invoicesUpdate = false;
    });

    builder.addCase(updateVehicleInvoice.rejected, (state: VehiclePageInvoicesState, _action) => {
      state.isLoading.invoicesUpdate = false;
    });
  },
});

// Export the actions and reducer
export default vehiclePageInvoicesSlice.reducer;
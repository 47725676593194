// @mui
import { Delete, Search } from '@mui/icons-material';
import { Stack, InputAdornment, TextField, Button } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  searchHint: string;
  filterName: string;
  isFiltered: boolean;
  onResetFilter: VoidFunction;
  onFilterName: (_event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function SearchableTableToolbar({
  searchHint,
  filterName,
  isFiltered,
  onFilterName,
  onResetFilter
}: Props) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
    
      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder={searchHint}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Delete />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
}

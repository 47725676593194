import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CompleteDriverOnboardingReq, DriverCourseData, DriverCourseHeader, DriverOnboardingData } from "../../api";
import RepositoryDriver from "../../dashboard/repositories/RepositoryDriver";

export type DriverOnboardingPageState = {
    isLoading: {
        data: boolean;
        coursesHeaders: boolean;
        courseData: boolean;
        courseComplpete: boolean;
        downloadUrl: { [key: string]: boolean };
    };
    driverOnboardingData: DriverOnboardingData | undefined;
    coursesHeaders: DriverCourseHeader[] | null;
    selectedCourseData: DriverCourseData | null;
};

const initialState: DriverOnboardingPageState = {
    isLoading: {
        data: false,
        coursesHeaders: false,
        courseData: false,
        courseComplpete: false,
        downloadUrl: {}
    },
    driverOnboardingData: undefined,
    coursesHeaders: null,
    selectedCourseData: null
};

const NAME = "driverAppSlice";

export const getDriverOnboarding = createAsyncThunk(`${NAME}/getDriverOnboarding`, async () => {
    const data = await RepositoryDriver().getDriverOnboarding();
    return data;
});

export const completeOnboarding = createAsyncThunk(`${NAME}/completeOnboarding`, async ({ completeDriverOnboardingReq }: { completeDriverOnboardingReq: CompleteDriverOnboardingReq, onComplete: (_result: boolean) => void }) => {
    const data = await RepositoryDriver().completeOnboarding(completeDriverOnboardingReq);
    return data;
});

export const getDriverCoursesHeaders = createAsyncThunk(`${NAME}/getDriverCoursesHeaders`, async (driverId: string) => {
    const data = await RepositoryDriver().getDriverCoursesHeaders(driverId);
    return data;
});

export const getDriverCourseData = createAsyncThunk(`${NAME}/getDriverCourseData`, async ({ driverId, courseId }: { driverId: string, courseId: string }) => {
    const data = await RepositoryDriver().getDriverCourseData(driverId, courseId);
    return data;
});

export const completeDriverCourse = createAsyncThunk(`${NAME}/completeDriverCourse`, async ({ driverId, courseId }: { driverId: string, courseId: string }) => {
    const data = await RepositoryDriver().completeDriverCourse(driverId, courseId);
    return data;
});

export const getCourseDocumentUrl = createAsyncThunk(`${NAME}/getCourseDocumentUrl`, async ({ driverId, courseId, docId }: { driverId: string, courseId: string, docId: string, onComplete: (_result: string) => void }) => {
    const data = await RepositoryDriver().getCourseDocumentUrl(driverId, courseId, docId);
    return data;
});


export const driverAppSlice = createSlice({
    name: NAME,
    initialState,
    reducers: {
        // reset selected course data:
        resetSelectedCourseData: (state) => {
            state.selectedCourseData = null;
        }
    },
    extraReducers: (builder) => {

        // === GET: Get driver ===
        builder.addCase(getDriverOnboarding.pending, (state) => {
            state.isLoading.data = true;
            state.driverOnboardingData = undefined;
        });

        builder.addCase(getDriverOnboarding.fulfilled, (state, action) => {
            state.driverOnboardingData = action.payload;
            state.isLoading.data = false;
        });

        builder.addCase(getDriverOnboarding.rejected, (state) => {
            state.isLoading.data = false;
            state.driverOnboardingData = undefined;
        });

        // === POST: Complete onboarding ===
        builder.addCase(completeOnboarding.pending, (state) => {
            state.isLoading.data = true;
        });

        builder.addCase(completeOnboarding.fulfilled, (state, action) => {
            if (state.driverOnboardingData) {
                state.driverOnboardingData.isOnboardingCompleted = action.payload || false;
            }
            action.meta.arg.onComplete(action.payload || false);
            state.isLoading.data = false;
        });

        builder.addCase(completeOnboarding.rejected, (state, action) => {
            state.isLoading.data = false;
            action.meta.arg.onComplete(false);
        });

        // getDriverCoursesHeaders:
        builder.addCase(getDriverCoursesHeaders.pending, (state) => {
            state.isLoading.coursesHeaders = true;
            state.selectedCourseData = null;
            state.coursesHeaders = null;
        });

        builder.addCase(getDriverCoursesHeaders.fulfilled, (state, action: PayloadAction<DriverCourseHeader[] | null>) => {
            state.coursesHeaders = action.payload;
            state.isLoading.coursesHeaders = false;
        });

        builder.addCase(getDriverCoursesHeaders.rejected, (state) => {
            state.isLoading.coursesHeaders = false;
            state.coursesHeaders = null;
        });

        // getDriverCourseData:
        builder.addCase(getDriverCourseData.pending, (state) => {
            state.isLoading.courseData = true;
            state.selectedCourseData = null;
        });

        builder.addCase(getDriverCourseData.fulfilled, (state, action: PayloadAction<DriverCourseData | null>) => {
            state.selectedCourseData = action.payload;
            state.isLoading.courseData = false;
        });

        builder.addCase(getDriverCourseData.rejected, (state) => {
            state.isLoading.courseData = false;
            state.selectedCourseData = null;
        });

        // completeDriverCourse:
        builder.addCase(completeDriverCourse.pending, (state) => {
            state.isLoading.courseComplpete = true;
        });

        builder.addCase(completeDriverCourse.fulfilled, (state) => {
            state.isLoading.courseComplpete = false;
        });

        builder.addCase(completeDriverCourse.rejected, (state) => {
            state.isLoading.courseComplpete = false;
        });

        // getCourseDocumentUrl:
        builder.addCase(getCourseDocumentUrl.pending, (state, action) => {
            state.isLoading.downloadUrl[action.meta.arg.docId] = true;
        });

        builder.addCase(getCourseDocumentUrl.fulfilled, (state, action) => {
            if (action.payload) {
                action.meta.arg.onComplete(action.payload);
            }
            state.isLoading.downloadUrl[action.meta.arg.docId] = false;
        });

        builder.addCase(getCourseDocumentUrl.rejected, (state, action) => {
            state.isLoading.downloadUrl[action.meta.arg.docId] = false;
        });
    },
});

export default driverAppSlice.reducer;
export const { resetSelectedCourseData } = driverAppSlice.actions;
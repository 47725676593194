// @mui
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Stack, { StackProps } from "@mui/material/Stack";

// components
import { KInvoiceStatus } from "src/api";
import { useLocales } from "../../../../../global/locales";
import { shortDateLabel } from "../../../../components/custom-date-range-picker";
import Iconify from "../../../../../global/components/iconify";
import { RichTableStatusTab } from "./rich-table-view";

// ----------------------------------------------------------------------
export type RichTableFilterValue = string | string[] | Date | null;
export type RichTableFilters = {
  globalSearch: string;
  types: string[];
  status: KInvoiceStatus | null;
  startDate: Date | null;
  endDate: Date | null;
};

type RichTableFiltersResultProps = StackProps & {
  filters: RichTableFilters;
  typesMap: Map<string, string>;
  statusTabs?: RichTableStatusTab[];
  onFilters: (_name: string, _value: RichTableFilterValue) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
};

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: "hidden",
        borderStyle: "dashed",
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: "subtitle2" }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

export default function RichTableFiltersResult({
  filters,
  onFilters,
  typesMap,
  statusTabs,
  //
  onResetFilters,
  //
  results,
  ...other
}: RichTableFiltersResultProps) {
  const { translate } = useLocales();

  const shortLabel = shortDateLabel(filters.startDate, filters.endDate);

  const handleRemoveType = (inputValue: any) => {
    const newValue = filters.types.filter((item) => item !== inputValue);
    onFilters("types", newValue);
  };

  const handleRemoveStatus = () => {
    onFilters("status", "all");
  };

  const handleRemoveDate = () => {
    onFilters("startDate", null);
    onFilters("endDate", null);
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: "body2" }}>
        <Box component="span" sx={{ color: "text.secondary", ml: 0.25 }}>
          {`${translate(`rich_table_filter_title_found`)} `}
        </Box>
        <strong>{results}</strong>
      </Box>

      <Stack
        flexGrow={1}
        spacing={1}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
      >
        {!!filters.types.length && (
          <Block label={`${translate(`rich_table_filter_type_title`)}`}>
            {filters.types.map((item) => (
              <Chip
                key={item}
                label={typesMap.get(item)}
                size="small"
                onDelete={() => handleRemoveType(item)}
              />
            ))}
          </Block>
        )}

        {filters.status !== null && (
          <Block label={`${translate(`rich_table_filter_status_title`)}:`}>
            <Chip
              size="small"
              label={
                statusTabs?.find((tab) => tab.value === filters.status)
                  ?.label || ""
              }
              onDelete={handleRemoveStatus}
            />
          </Block>
        )}

        {filters.startDate && filters.endDate && (
          <Block label={`${translate(`global_date`)}:`}>
            <Chip size="small" label={shortLabel} onDelete={handleRemoveDate} />
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          {`${translate(`global_clear`)} `}
        </Button>
      </Stack>
    </Stack>
  );
}

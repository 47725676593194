import { useEffect } from "react";
// @mui
import {
  Card,
  Chip,
  TableRow as MuiTableRow,
  TableCell,
  Typography,
} from "@mui/material";

// components
import { CustomTableHeadProps } from "../../common/views/tableView/SearchableTable";
import { PATH_DASHBOARD } from "../../../routes/paths";
import ViewGenericDataTable, {
  ViewGenericDataTableData,
} from "../../common/views/tableView/ViewGenericDataTable";
import {
  DocumentConfigOption,
  Vehicle,
  VehicleReportHeader,
  VehicleReportStatus,
} from "../../../../api";
import GenericMessageView from "../../common/views/GenericMessageView";
import { fDate } from "../../../utils/format/formatTime";
import { useDashboardAppDispatch, useDashboardAppSelector } from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { getVehicleReportsHeaders } from "../vehiclePageSlice";
import { getVehicleAvilableReportTypes } from "../../settings/config/settingsReportConfigSlice";
import { getWebAppConfig } from "../../../app/dashboardAppStateSlice";

// ----------------------------------------------------------------------

type VehiclePageReportsRowProps = {
  reportHeader: VehicleReportHeader;
  translate: Function;
  onRowClick?: Function;
  avilableReportTypes: DocumentConfigOption[] 
};

function VehiclePageReportsRow({
  reportHeader,
  translate,
  onRowClick,
  avilableReportTypes,
}: VehiclePageReportsRowProps) {
  const { id, createdAt, reportStatus, reportType } = reportHeader;

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(reportHeader);
    }
  };

  return (
    <>
      <MuiTableRow hover onClick={handleRowClick} sx={{ cursor: "pointer" }}>
        {/* ID */}
        <TableCell>
          <Typography variant="subtitle2" noWrap>
            {id}
          </Typography>
        </TableCell>

        {/* type */}
        <TableCell align="left">
          <Typography variant="subtitle2" noWrap>
            {avilableReportTypes.find((item) => item.type === reportType)
              ?.label ?? "-"}
          </Typography>
        </TableCell>

        {/* createdAt */}
        <TableCell align="center">
          {createdAt ? fDate(createdAt!) : "-"}
        </TableCell>

        {/* Status */}
        <TableCell align="center">
          <Typography variant="subtitle2" noWrap>
            {reportStatus === VehicleReportStatus.OPEN ? (
              <Chip
                size="small"
                label={translate("report_page_title_report_status_open")}
              />
            ) : (
              <Chip
                size="small"
                label={translate("report_page_title_report_status_closed")}
              />
            )}
          </Typography>
        </TableCell>
      </MuiTableRow>
    </>
  );
}

// ----------------------------------------------------------------------

type Props = {
  isLoading: boolean;
  translate: Function;
  navigate: Function;
  clientId?: string;
  vehicle: Vehicle | null;
};

export default function VehiclePageReports({
  isLoading,
  translate,
  navigate,
  clientId,
  vehicle
}: Props) {
  const dispatch = useDashboardAppDispatch();
  
  const appConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig
  );

  const avilableReportTypes: DocumentConfigOption[] = useDashboardAppSelector(
    (state: DashboardRootState) =>
      state.settingsReportConfigSlice.avilableReportTypes
  );

  const vehicleReportsHeaders = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehiclePageSlice.reportsHeaders
  );

  useEffect(() => {
    if (vehicle && vehicle.licenseNumber && avilableReportTypes.length === 0) {
      dispatch(
        getVehicleAvilableReportTypes({
          langCode: appConfig.locale ?? "en",
          vehicleType: vehicle.type,
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle]);

  useEffect(() => {
    if (vehicle && vehicle.licenseNumber && clientId && vehicleReportsHeaders.length === 0) {
      dispatch(getVehicleReportsHeaders({ clientId, licenseNumber: vehicle.licenseNumber }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicle, clientId]);


  useEffect(() => {
    if (clientId) {
      dispatch(getWebAppConfig({ clientId }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  

  const TABLE_HEAD: CustomTableHeadProps[] = [
    {
      id: "reportId",
      label: `${translate("report_page_title_report_number")}`,
      align: "left",
    },
    {
      id: "reportType",
      label: `${translate("report_page_title_test_type")}`,
      align: "left",
    },
    {
      id: "createdAt",
      label: `${translate("global_created")}`,
      align: "center",
    },
    {
      id: "reportStatus",
      label: `${translate("report_page_title_report_status")}`,
      align: "center",
    },
  ];

  const handleRowClick = (reportHeader: VehicleReportHeader) => {
    if (clientId) {
      navigate(
        PATH_DASHBOARD.management.clients.report.path(
          clientId,
          reportHeader.vehicleLicensePlate,
          reportHeader.id
        )
      );
    }
  };

  const renderRow = (reportHeader: VehicleReportHeader) => (
    <VehiclePageReportsRow
      key={reportHeader.id}
      reportHeader={reportHeader}
      translate={translate}
      onRowClick={handleRowClick}
      avilableReportTypes={avilableReportTypes}
    />
  );

  const genericDataTableViewProps: ViewGenericDataTableData<VehicleReportHeader> =
    {
      isLoading,
      translate,
      titleKey: "vehicle_page_tab_reports",
      data: vehicleReportsHeaders,
      renderRow,
      tableHead: TABLE_HEAD,
      searchable: true,
      searchLabelKey: "vehicle_page_search_reports",
      emptyStateComponent: (
        <GenericMessageView
          title={`${translate("error_vehicle_reports_list_is_empty_title")}`}
          subtitle={`${translate(
            "error_vehicle_reports_list_is_empty_subtitle"
          )}`}
        />
      ),
    };

  return (
    <Card sx={{ px: 3 }}>
      <ViewGenericDataTable {...genericDataTableViewProps} />
    </Card>
  );
}

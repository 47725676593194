// @mui
import {
  Stack,
  Typography,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { Search } from "@mui/icons-material";

// _mock
import { useState } from "react";
// components
import { CustomTextField } from "../../../../components/custom-input";
import SearchableTable, { CustomTableHeadProps } from "./SearchableTable";
import GenericMessageView from "../GenericMessageView";

import LoadingView from "../../../../components/loading-view";

// ----------------------------------------------------------------------

export type ViewGenericDataTableFilterData = {
  label: string;
  key: string;
  payloadObject: any;
};

export type ViewGenericDataTableData<T> = {
  isLoading: boolean;
  translate: Function;
  titleKey?: string;
  subtitleKey?: string;

  // Table:
  data: Array<T>;

  // eslint-disable-next-line no-unused-vars
  renderRow: (row: T) => JSX.Element;
  tableHead: CustomTableHeadProps[];

  // Action Button:
  onActionButtonClicked?: Function;
  actionButtonLabelKey?: string;
  actionButtonIcon?: JSX.Element;
  overrideActionButtons?: JSX.Element;

  // Search Bar:
  searchable?: boolean;
  searchLabelKey?: string;
  hidePagination?: boolean;

  // Filters:
  filterTitle?: string;
  listOfFilters?: Array<ViewGenericDataTableFilterData> | null;
  currentSelectedFilterKey?: string | null;
  onFilterChange?: (_: string) => void | null;

  // Empty State Component:
  emptyStateComponent?: JSX.Element;
  ovverideTableContent?: JSX.Element;
};

export default function ViewGenericDataTable<T>({
  isLoading,
  translate,
  titleKey,
  subtitleKey,
  data,
  renderRow,
  tableHead,
  onActionButtonClicked,
  actionButtonLabelKey,
  actionButtonIcon,
  overrideActionButtons,
  searchable,
  searchLabelKey,
  hidePagination,
  filterTitle,
  listOfFilters,
  currentSelectedFilterKey,
  onFilterChange,
  emptyStateComponent,
  ovverideTableContent,
}: ViewGenericDataTableData<T>) {
  const [searchDataValue, setSearchDataValue] = useState("");

  return (
    <>
      <Stack
        spacing={1}
        justifyContent="space-between"
        direction={{ xs: "column", sm: "row" }}
        sx={{ my: 4 }}
      >
        {/* Title */}
        <Stack>
          {titleKey ? (
            <Typography variant="h5">{`${translate(titleKey)}`}</Typography>
          ) : (
            <Typography variant="h5"> </Typography>
          )}

          {subtitleKey && (
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {`${translate(subtitleKey)}`}
            </Typography>
          )}
        </Stack>

        {/* Action Button + Search Bar */}
        <Stack direction="row" alignItems="center" spacing={3}>
          {/* Action Button */}

          {overrideActionButtons === undefined &&
          onActionButtonClicked &&
          actionButtonLabelKey ? (
            <Button
              onClick={() => onActionButtonClicked()}
              variant="contained"
              startIcon={actionButtonIcon}
            >
              {`${translate(actionButtonLabelKey)}`}
            </Button>
          ) : (
            overrideActionButtons && overrideActionButtons
          )}

          {/* Search Bar */}
          {searchable && (
            <CustomTextField
              width={220}
              size="small"
              value={searchDataValue}
              onChange={(e) => setSearchDataValue(e.target.value)}
              placeholder={
                searchLabelKey
                  ? `${translate(searchLabelKey)}...`
                  : `${translate("global_search")}...`
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: "text.disabled" }} />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Stack>
      </Stack>

      {/* Filters  */}
      <Stack direction={"row"} sx={{ pb: 2 }} justifyContent={"start"}>
        {listOfFilters && onFilterChange && (
          <FormControl sx={{ width: 250 }}>
            <InputLabel id="filter-label">
              {filterTitle || `${translate("global_filter")}`}
            </InputLabel>
            <Select
              value={currentSelectedFilterKey}
              onChange={(event) => onFilterChange(event.target.value as string)}
            >
              <MenuItem
                value={undefined}
              >{`${translate("global_all")}`}</MenuItem>
              {listOfFilters.map((filter) => (
                <MenuItem key={filter.key} value={filter.key}>
                  {filter.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      {/* Table */}
      {ovverideTableContent ||
        (isLoading ? (
          <LoadingView showLogo={false} />
        ) : data.length > 0 ? (
          <SearchableTable
            tableHead={tableHead}
            data={data}
            renderRow={renderRow}
            searchValue={searchDataValue}
            showSearchBar={false}
            hidePagination={hidePagination}
          />
        ) : emptyStateComponent !== undefined ? (
          emptyStateComponent
        ) : (
          <GenericMessageView
            title={""}
            subtitle={`${translate("error_global_empty")}`}
          />
        ))}
    </>
  );
}

import { configureStore } from '@reduxjs/toolkit';

import { driverAppSlice } from './driverAppSlice';

export const driversAppStore = configureStore({
  reducer: {
    driverAppSlice: driverAppSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type DriversRootState = ReturnType<typeof driversAppStore.getState>;
export type DriversAppDispatch = typeof driversAppStore.dispatch;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryClient from '../../../repositories/RepositoryClient';
import { Alert, AlertType, AlertTypeEmailContact, Client, ClientWebAppConfig, DriverUser, ExpiredVehicleHeader, VehicleHeader } from '../../../../api';
import RepositoryVehicle from '../../../repositories/vehicle/RepositoryVehicle';
import RepositoryDriver from '../../../repositories/RepositoryDriver';
import RepositoryAlerts from '../../../repositories/RepositoryAlerts';

// Define a type for the slice state
export type ClientState = {
  data: Client | null;
  clientWebAppConfig: ClientWebAppConfig | null;
  vehicles: VehicleHeader[];
  expiredTestVehicleHeaders?: ExpiredVehicleHeader[];
  alerts?: Alert[];
  drivers: DriverUser[];
  isLoading: {
    clientData: boolean;
    vehicles: boolean;
    drivers: boolean;
    alerts: boolean;
    settings: boolean;
    alertConfig: boolean;
    expiredVehicles: boolean;
  };
};

// Define the initial state using that type
const initialState: ClientState = {
  data: null,
  clientWebAppConfig: null,
  vehicles: [],
  expiredTestVehicleHeaders: [],
  alerts: undefined,
  drivers: [],
  isLoading: {
    clientData: false,
    vehicles: false,
    drivers: false,
    alerts: false,
    settings: false,
    alertConfig: false,
    expiredVehicles: false
  },
};

// Create a createAsyncThunk function to get the clients list from the repository
export const getClientDataFromApi = createAsyncThunk('clientPageSlice/getClientDataFromApi', async (clientId: string) => {
  const data = await RepositoryClient().getData(clientId);
  return data;
});

export const getSingleClientDataFromApi = createAsyncThunk('clientPageSlice/getSingleClientDataFromApi', async () => {
  const data = await RepositoryClient().getSingleClientData();
  return data;
});

export const getClientVehiclesFromApi = createAsyncThunk('clientPageSlice/getClientVehiclesFromApi', async ({ clientId, fromArchive }: { clientId: string; fromArchive: boolean }) => {
  const data = await RepositoryVehicle().getVehicles(clientId, fromArchive);
  return data;
});

export const getClientDriversFromApi = createAsyncThunk('clientPageSlice/getClientDriversFromApi', async (clientId: string) => {
  const data = await RepositoryDriver().getDrivers(clientId);
  return data;
});

// Get Client Web App Config:
export const getClientWebAppConfig = createAsyncThunk('clientPageSlice/getClientWebAppConfig', async (clientId: string) => {
  const data = await RepositoryClient().getWebAppConfig(clientId);
  return data;
});

// Get Alerts:
export const getAlertsFromApi = createAsyncThunk('clientPageSlice/getAlertsFromApi', async (clientId: string) => {
  const data = await RepositoryAlerts().getUnreadAlertsForClient(clientId);
  return data;
});

// Mark alert as read:
export const markAlertAsRead = createAsyncThunk('clientPageSlice/markAlertAsRead', async ({ clientId, alertId }: { clientId: string; alertId: string }) => {
  const data = await RepositoryAlerts().markAlertAsRead(clientId, alertId);
  return data;
});

// Add Alert Email Contact:
export const addAlertEmailContact = createAsyncThunk('clientPageSlice/addAlertEmailContact', async ({ clientId, alertType, contactInfo, _onResponse }: { clientId: string; alertType: AlertType; contactInfo: AlertTypeEmailContact, _onResponse: (_success: boolean) => void }) => {
  const data = await RepositoryClient().addAlertEmailContact(clientId, alertType, contactInfo);
  return data;
});

// Delete Alert Email Contact:
export const deleteAlertEmailContact = createAsyncThunk('clientPageSlice/deleteAlertEmailContact', async ({ clientId, alertType, email, _onResponse }: { clientId: string; alertType: AlertType; email: string, _onResponse: (_success: boolean) => void }) => {
  const data = await RepositoryClient().deleteAlertEmailContact(clientId, alertType, email);
  return data;
});

// getExpiredTestVehicles:
export const getExpiredTestVehicles = createAsyncThunk('clientPageSlice/getExpiredTestVehicles', async (clientId: string) => {
  const data = await RepositoryVehicle().getExpiredTestVehicles(clientId);
  return data;
});

export const updateWebAppAlertsConfig = createAsyncThunk('clientPageSlice/updateWebAppAlertsConfig', async ({ 
  clientId, 
  config 
}: { 
  clientId: string; 
  config: { [key: string]: boolean };
  onCompleted?: (_isSuccess: boolean) => void;
}) => {
  const data = await RepositoryClient().updateWebAppAlertsConfig(clientId, config);
  return data;
});


// Define the clients list slice
export const clientPageSlice = createSlice({
  name: 'clientsPageSlice',
  initialState,
  reducers: {
    // Clear when logout:
    clearClientData: () => initialState,

    // Clear Vehicles data:
    clearVehiclesData: (state: ClientState) => {
      state.vehicles = [];
    },
  },

  extraReducers: (builder) => {
    
    // Get clients list cases
    builder.addCase(getClientDataFromApi.pending, (state: ClientState, _action) => {
      state.isLoading.clientData = true;
    });

    builder.addCase(getClientDataFromApi.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.data = action.payload;
      }
      state.isLoading.clientData = false;
    });
    builder.addCase(getClientDataFromApi.rejected, (state: ClientState, _action) => {
      state.data = null;
      state.isLoading.clientData = false;
    });

    builder.addCase(getSingleClientDataFromApi.pending, (state: ClientState, _action) => {
      state.isLoading.clientData = true;
    });

    builder.addCase(getSingleClientDataFromApi.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.data = action.payload;
      }
      state.isLoading.clientData = false;
    });

    builder.addCase(getSingleClientDataFromApi.rejected, (state: ClientState, _action) => {
      state.data = null;
      state.isLoading.clientData = false;
    });

    builder.addCase(getClientVehiclesFromApi.pending, (state: ClientState, _action) => {
      state.isLoading.vehicles = true;
    });

    builder.addCase(getClientVehiclesFromApi.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.vehicles = action.payload;
      }
      state.isLoading.vehicles = false;
    });

    builder.addCase(getClientVehiclesFromApi.rejected, (state: ClientState, _action) => {
      state.vehicles = [];
      state.isLoading.vehicles = false;
    });


    builder.addCase(getClientDriversFromApi.pending, (state: ClientState, _action) => {
      state.isLoading.drivers = true;
    });

    builder.addCase(getClientDriversFromApi.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.drivers = action.payload;
      }
      state.isLoading.drivers = false;
    });

    builder.addCase(getClientDriversFromApi.rejected, (state: ClientState, _action) => {
      state.drivers = [];
      state.isLoading.drivers = false;
    });


    // Alerts:
    builder.addCase(getAlertsFromApi.pending, (state: ClientState, _action) => {
      state.isLoading.alerts = true;
    });

    builder.addCase(getAlertsFromApi.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.alerts = action.payload;
      }
      state.isLoading.alerts = false;
    });

    builder.addCase(getAlertsFromApi.rejected, (state: ClientState, _action) => {
      state.alerts = [];
      state.isLoading.alerts = false;
    });

    // Mark alert as read:
    builder.addCase(markAlertAsRead.pending, (state: ClientState, _action) => {
      state.isLoading.alerts = true;
    });

    builder.addCase(markAlertAsRead.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.alerts = state.alerts?.filter((alert) => alert.id !== action.payload?.id);
      }
      state.isLoading.alerts = false;
    });

    builder.addCase(markAlertAsRead.rejected, (state: ClientState, _action) => {
      state.isLoading.alerts = false;
    });

    // Get Client Web App Config:
    builder.addCase(getClientWebAppConfig.pending, (state: ClientState, _action) => {
      state.isLoading.settings = true;
    });

    builder.addCase(getClientWebAppConfig.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.clientWebAppConfig = action.payload;
      }
      state.isLoading.settings = false;
    });

    builder.addCase(getClientWebAppConfig.rejected, (state: ClientState, _action) => {
      state.clientWebAppConfig = null;
      state.isLoading.settings = false;
    });

    // Add Alert Email Contact:
    builder.addCase(addAlertEmailContact.pending, (state: ClientState, _action) => {
      state.isLoading.settings = true;
    });

    builder.addCase(addAlertEmailContact.fulfilled, (state: ClientState, action) => {
      state.isLoading.settings = false;
      if (action.payload) {
        if (state.clientWebAppConfig?.alertEmailsByType && state.clientWebAppConfig.alertEmailsByType[action.meta.arg.alertType] !== undefined) {
          state.clientWebAppConfig.alertEmailsByType[action.meta.arg.alertType].push(action.meta.arg.contactInfo);
        } else {
          state.clientWebAppConfig = {
            ...state.clientWebAppConfig,
            alertEmailsByType: {
              ...state.clientWebAppConfig?.alertEmailsByType,
              [action.meta.arg.alertType]: [action.meta.arg.contactInfo]
            }
          };
        }
      }
      action.meta.arg._onResponse(action.payload);
    });

    builder.addCase(addAlertEmailContact.rejected, (state: ClientState, action) => {
      state.isLoading.settings = false;
      action.meta.arg._onResponse(false);
    });

    // Delete Alert Email Contact:
    builder.addCase(deleteAlertEmailContact.pending, (state: ClientState, _action) => {
      state.isLoading.settings = true;
    });

    builder.addCase(deleteAlertEmailContact.fulfilled, (state: ClientState, action) => {
      state.isLoading.settings = false;
      if (action.payload) {
        if (state.clientWebAppConfig?.alertEmailsByType && state.clientWebAppConfig.alertEmailsByType[action.meta.arg.alertType] !== undefined) {
          state.clientWebAppConfig.alertEmailsByType[action.meta.arg.alertType] = state.clientWebAppConfig.alertEmailsByType[action.meta.arg.alertType].filter((contact) => contact.email !== action.meta.arg.email);
        }
      }
      action.meta.arg._onResponse(action.payload);
    });

    builder.addCase(deleteAlertEmailContact.rejected, (state: ClientState, action) => {
      state.isLoading.settings = false;
      action.meta.arg._onResponse(false);
    });

    // Update Web App Alerts Config:
    builder.addCase(updateWebAppAlertsConfig.pending, (state: ClientState) => {
      state.isLoading.alertConfig = true;
    });

    builder.addCase(updateWebAppAlertsConfig.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.clientWebAppConfig = action.payload;
        action.meta.arg.onCompleted?.(true);
      }
      state.isLoading.alertConfig = false;
    });

    builder.addCase(updateWebAppAlertsConfig.rejected, (state: ClientState, action) => {
      state.isLoading.alertConfig = false;
      action.meta.arg.onCompleted?.(false);
    });

    // getExpiredTestVehicles:
    builder.addCase(getExpiredTestVehicles.pending, (state: ClientState) => {
      state.isLoading.expiredVehicles = true;
    });

    builder.addCase(getExpiredTestVehicles.fulfilled, (state: ClientState, action) => {
      if (action.payload) {
        state.expiredTestVehicleHeaders = action.payload;
      }
      state.isLoading.expiredVehicles = false;
    });

    builder.addCase(getExpiredTestVehicles.rejected, (state: ClientState) => {
      state.expiredTestVehicleHeaders = [];
      state.isLoading.expiredVehicles = false;
    });

  },
});

// Export the actions and reducer
export default clientPageSlice.reducer;
export const { clearClientData, clearVehiclesData } = clientPageSlice.actions;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { dashboardAppStateSlice } from './dashboardAppStateSlice';
import { dashboardSlice } from '../features/main/dashboardSlice';
import { clientPageSlice } from '../features/clientPage/main/clientPageSlice';
import { vehiclePageSlice } from '../features/vehicles/vehiclePageSlice';
import { userSettingsPageSlice } from '../features/settings/user/userSettingsPageSlice';
import { settingsReportConfigSlice } from '../features/settings/config/settingsReportConfigSlice';
import { driverPageSlice } from '../features/drivers/driverPageSlice';
import { vehiclePageDocumentsSlice } from '../features/vehicles/documentsList/vehiclePageDocumentsSlice';
import { vehiclePageInvoicesSlice } from '../features/vehicles/invoicesList/vehiclePageInvoicesSlice';
import { clientsListSlice } from '../features/clientsList/clientsListSlice';
import { clientCoursesPageSlice } from '../features/courses/clientCoursesPageSlice';
import { vehicleIncidentsPageSlice } from '../features/vehicles/incidants/vehicleIncidentsPageSlice';
import { vehicleTachographsPageSlice } from '../features/vehicles/tachographs/vehicleTachographsPageSlice';

const appReducer = combineReducers({
    appStateSlice: dashboardAppStateSlice.reducer,
    dashboardSlice: dashboardSlice.reducer,
    clientsListSlice: clientsListSlice.reducer,
    clientPageSlice: clientPageSlice.reducer,
    vehiclePageSlice: vehiclePageSlice.reducer,
    vehiclePageDocumentsSlice: vehiclePageDocumentsSlice.reducer,
    vehiclePageInvoicesSlice: vehiclePageInvoicesSlice.reducer,
    settingsPageSlice: userSettingsPageSlice.reducer,
    settingsReportConfigSlice: settingsReportConfigSlice.reducer,
    driverPageSlice: driverPageSlice.reducer,
    clientCoursesPageSlice: clientCoursesPageSlice.reducer,
    vehicleIncidentsPageSlice: vehicleIncidentsPageSlice.reducer,
    vehicleTachographsPageSlice: vehicleTachographsPageSlice.reducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const dashboardAppStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type DashboardRootState = ReturnType<typeof dashboardAppStore.getState>;
export type DashboardAppDispatch = typeof dashboardAppStore.dispatch;

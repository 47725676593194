// @mui

import { Button, Collapse, Divider, Grid, Stack, SxProps } from "@mui/material";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

import {
  IncidentInvolvedParty,
  IncidentInvolvedPartyVehicle,
  VehicleConfig,
} from "../../../../../../api";
import Label from "../../../../../../global/components/label/Label";
import { useBoolean } from "../../../../../../global/hooks/use-boolean";
import { DataViewEntry } from "../../../../common/views/FullWithDataView";
import { getManufacturerOrModelLabel } from "../../../../common/views/ManufacturerOrModelLabel";
import { getVehicleTypeLabelKey } from "../../../../../utils/models/typesUtils";
import { formatAddress } from "../../../../../utils/format/formatAddress";

// import { fDate } from "../../../../../utils/format/formatTime";

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  vehicleConfig?: VehicleConfig;
  involvedParty: IncidentInvolvedParty;
  onEditClicked?: (_involvedParty: IncidentInvolvedParty) => void;
};

export default function InvolvedPartiesListViewRowItem({
  translate,
  vehicleConfig,
  involvedParty,
  onEditClicked,
}: Props) {
  const globalNoData = `${translate("global_no_data")}`;

  const collapse = useBoolean();

  const mainView = (
    <TableRow
      hover
      onClick={collapse.onToggle}
      sx={{
        cursor: "pointer",
      }}
    >
      {/** personName */}
      <TableCell>
        <ListItemText
          primary={
            <Typography variant="body2" noWrap>
              {involvedParty.personName || globalNoData}
            </Typography>
          }
        />
      </TableCell>

      {/** personGovId */}
      <TableCell>
        <ListItemText
          primary={
            <Typography variant="body2" noWrap>
              {involvedParty.personGovId || globalNoData}
            </Typography>
          }
        />
      </TableCell>

      {/** personPhoneNumber */}
      {!onEditClicked && (
        <TableCell>
          <ListItemText
            primary={
              <Typography variant="body2" noWrap>
                {involvedParty.personPhoneNumber || globalNoData}
              </Typography>
            }
          />
        </TableCell>
      )}

      {/** type */}
      <TableCell>
        <Label variant="soft">
          {involvedParty.type
            ? `${translate(`incident_details_involved_parties_type_${involvedParty.type.toLowerCase()}`)}`
            : globalNoData}
        </Label>
      </TableCell>

      {/** Edit */}
      {onEditClicked && (
        <TableCell>
          <Button
            variant="outlined"
            onClick={() => onEditClicked(involvedParty)}
          >
            {translate("global_edit")}
          </Button>
        </TableCell>
      )}
    </TableRow>
  );

  const itemsPerRow = 4;

  const DataViewGrid = ({
    sx,
    title,
    data,
  }: {
    sx?: SxProps;
    title?: String;
    data: DataViewEntry[];
  }) => (
    <Stack
      spacing={1}
      sx={{
        pb: 2,
        backgroundColor: "#fafafa",
        paddingInline: 2,
        ...sx,
      }}
    >
      <Divider />
      {title && (
        <Typography variant="h6" sx={{ fontWeight: "bold", pt: 1 }}>
          {title}
        </Typography>
      )}
      <Grid container rowSpacing={1}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} sm={12 / itemsPerRow}>
            <ListItemText
              primary={
                <Typography variant="subtitle2" noWrap>
                  {item.title}
                </Typography>
              }
              secondary={
                item.overrideElement || (
                  <Typography variant="body2" noWrap>
                    {item.value || globalNoData}
                  </Typography>
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );

  const personDetails = (
    <DataViewGrid
      title={`${translate("vehicle_incident_add_edit_involved_party_person_details")}`}
      data={[
        {
          title: `${translate("vehicle_incident_add_edit_involved_party_person_name")}`,
          value: involvedParty.personName,
        },
        {
          title: `${translate("vehicle_incident_add_edit_involved_party_person_gov_id")}`,
          value: involvedParty.personGovId,
        },
        {
          title: `${translate("vehicle_incident_add_edit_involved_party_person_license_number")}`,
          value: involvedParty.personlicenseNumber,
        },
        {
          title: `${translate("vehicle_incident_add_edit_involved_party_person_email")}`,
          value: involvedParty.personEmail,
        },
        {
          title: `${translate("vehicle_incident_add_edit_involved_party_person_phone_number")}`,
          value: involvedParty.personPhoneNumber,
        },
        {
          title: `${translate("vehicle_incident_add_edit_involved_party_person_address")}`,
          value: formatAddress(involvedParty.personAddress),
        },
      ]}
    />
  );

  const VehicleInfo = ({
    incidentVehicle,
  }: {
    incidentVehicle: IncidentInvolvedPartyVehicle;
  }) => (
    <DataViewGrid
      title={`${translate("incident_details_involved_parties_table_vehicle_info")}`}
      data={
        [
          {
            title: `${translate("incident_details_involved_parties_table_license_number")}`,
            value: incidentVehicle.vehicleLicenseNumber,
          },
          {
            title: `${translate("incident_details_involved_parties_table_manufacturer")}`,
            value: getManufacturerOrModelLabel({
              labelType: "manufacturer",
              makeId: incidentVehicle.vehicleMake,
              modelId: incidentVehicle.vehicleModel,
              type: incidentVehicle.vehicleType,
              vehicleConfig,
            }),
          },
          {
            title: `${translate("incident_details_involved_parties_table_model")}`,
            value: getManufacturerOrModelLabel({
              labelType: "model",
              makeId: incidentVehicle.vehicleMake,
              modelId: incidentVehicle.vehicleModel,
              type: incidentVehicle.vehicleType,
              vehicleConfig,
            }),
          },
          {
            title: `${translate("incident_details_involved_parties_table_manufacturer_date")}`,
            value: incidentVehicle.vehicleYear,
          },
          {
            title: `${translate("incident_details_involved_parties_table_vehicle_type")}`,
            value: `${translate(getVehicleTypeLabelKey(incidentVehicle.vehicleType))}`,
          },
          {
            title: `${translate("incident_details_involved_parties_table_vehicle_owner_name")}`,
            value: incidentVehicle.vehicleOwnerName,
          },
          {
            title: `${translate("incident_details_involved_parties_table_vehicle_owner_id")}`,
            value: incidentVehicle.vehicleOwnerId,
          },
          {
            title: `${translate("incident_details_involved_parties_table_vehicle_owner_phone_number")}`,
            value: incidentVehicle.vehicleOwnerPhoneNumber,
          },
          {
            title: `${translate("incident_details_involved_parties_table_vehicle_owner_address")}`,
            value: formatAddress(incidentVehicle.vehicleOwnerAddress),
          },
        ] as DataViewEntry[]
      }
    />
  );

  const insuranceDetails = (
    <DataViewGrid
      sx={{ pb: 3 }}
      title={`${translate("incident_details_involved_parties_table_insurance_info")}`}
      data={[
        {
          title: `${translate("incident_details_involved_parties_table_insurance_company")}`,
          value: involvedParty.insuranceCompany,
        },
        {
          title: `${translate("incident_details_involved_parties_table_insurance_policy_number")}`,
          value: involvedParty.insurancePolicyNumber,
        },
        {
          title: `${translate("incident_details_involved_parties_table_insurance_agent_name")}`,
          value: involvedParty.insuranceAgentName,
        },
      ]}
    />
  );

  const collapseView = (
    <TableRow>
      <TableCell sx={{ p: 0, border: "none" }} colSpan={8}>
        <Collapse
          in={collapse.value}
          timeout="auto"
          unmountOnExit
          // sx={{ bgcolor: "background.neutral" }}
        >
          <Stack>
            {/** Person Info */}
            {personDetails}

            {/** vehicle Info */}
            {involvedParty.vehicle && (
              <VehicleInfo incidentVehicle={involvedParty.vehicle} />
            )}

            {/** insurance Info */}
            {insuranceDetails}

            <Divider />
          </Stack>
        </Collapse>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {mainView}
      {collapseView}
    </>
  );
}

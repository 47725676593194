// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { CommonLayoutPageCover } from "./types";
import { COMMON_LAYOUT_COVER_SPECS } from "./CommonLayout";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  "&:before": {
    ...bgBlur({
      color: theme.palette.primary.darker,
    }),
    top: 0,
    zIndex: 9,
    content: "''",
    width: "100%",
    height: "100%",
    position: "absolute",
  },
}));

const StyledInfo = styled("div")(({ theme }) => ({
  // left: 0,
  // right: 0,
  zIndex: 99,
  position: "absolute",
  marginTop: theme.spacing(5),
  right: "auto",
  display: "flex",
  alignItems: "center",
  left: theme.spacing(3),
  bottom: theme.spacing(3),
}));

type CommonLayoutCoverProps = {
  data: CommonLayoutPageCover;
};

export default function CommonLayoutCover({ data }: CommonLayoutCoverProps) {
  return (
    <StyledRoot>
      <StyledInfo>
        {data.icon ? (
          data.icon
        ) : (
          <>
            {(data.title as string) ? (
              <CustomAvatar
                color="primary"
                name={data.title as string}
                sx={{
                  mx: "auto",
                  borderWidth: 2,
                  borderStyle: "solid",
                  borderColor: "common.white",
                  width: { xs: 80, md: COMMON_LAYOUT_COVER_SPECS.iconSize },
                  height: { xs: 80, md: COMMON_LAYOUT_COVER_SPECS.iconSize },
                }}
              />
            ) : (
              data.title
            )}
          </>
        )}

        <Box
          sx={{
            ml: { xs: 1, md: 3 },
            mt: { xs: 1, md: 0 },
            color: "common.white",
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h4">{data.title}</Typography>
          {data.subtitle && (
            <Typography sx={{ opacity: 0.72 }}>{data.subtitle}</Typography>
          )}
        </Box>
      </StyledInfo>
    </StyledRoot>
  );
}

import { useState, useEffect } from "react";

import {
  IncidentInvolvedParty,
  IncidentInvolvedPartyType,
} from "../../../../../../api";

import RichTableView from "../../../../common/views/richTableView/rich-table-view";
import { TableHeadItem } from "../../../../../components/table/TableHeadCustom";
import { RichTableFilters } from "../../../../common/views/richTableView/rich-table-filters-result";
import InvolvedPartiesListViewRowItem from "./InvolvedPartiesListViewRowItem";
import { DashboardRootState } from "../../../../../app/dashboardAppStore";
import { useDashboardAppSelector } from "../../../../../hooks/useRedux";

const applyFilter = (
  involvedParties: IncidentInvolvedParty[],
  filters: RichTableFilters
): IncidentInvolvedParty[] => {
  const { globalSearch, types } = filters;

  let filteredData: IncidentInvolvedParty[] = [...involvedParties];

  if (globalSearch !== "") {
    filteredData = involvedParties.filter((itemObject: any) =>
      Object.keys(itemObject).some((key) => {
        const value = itemObject[key];
        return (
          value &&
          typeof value === "string" &&
          value.toLowerCase().includes(globalSearch.toLowerCase())
        );
      })
    );
  }

  if (types && types.length > 0) {
    filteredData = involvedParties.filter(
      (incident) => incident.type && types.includes(incident.type)
    );
  }

  return filteredData;
};

// ----------------------------------------------------------------------

type Props = {
  translate: Function;
  involvedParties: IncidentInvolvedParty[] | undefined;
  onEditClicked?: (_involvedParty: IncidentInvolvedParty) => void;
  tableHeadOverride?: TableHeadItem[];

  // Action Button:
  onActionButtonClicked?: () => void;
  actionButtonLabelKey?: string;
  actionButtonIcon?: JSX.Element;
  hideNoDataView?: boolean;
};

export default function InvolvedPartiesPage({
  translate,
  involvedParties,
  onEditClicked,
  tableHeadOverride,

  onActionButtonClicked,
  actionButtonLabelKey,
  actionButtonIcon,
  hideNoDataView,
}: Props) {
  // const dispatch = useDashboardAppDispatch();

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.vehicleIncidentsPageSlice.isLoading
  );

  const vehicleConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig.vehicleConfig
  );

  const [filteredTableData, _setFilteredTableData] = useState(involvedParties);

  useEffect(() => {
    _setFilteredTableData(involvedParties);
  }, [involvedParties]);

  const TABLE_HEAD: TableHeadItem[] = tableHeadOverride ?? [
    {
      id: "personName",
      label: `${translate("incident_details_involved_parties_table_head_person_name")}`,
    },
    {
      id: "personGovId",
      label: `${translate("incident_details_involved_parties_table_head_person_gov_id")}`,
    },
    {
      id: "personPhoneNumber",
      label: `${translate("incident_details_involved_parties_table_head_person_phone_number")}`,
    },
    {
      id: "type",
      label: `${translate("incident_details_involved_parties_table_head_type")}`,
    },
  ];

  const handleOnApplyFilters = (filters: RichTableFilters) => {
    _setFilteredTableData(applyFilter(involvedParties || [], filters));
  };

  const typeFilterOptions = new Map(
    Object.values(IncidentInvolvedPartyType).map((type) => [
      type,
      `${translate(`incident_details_involved_parties_type_${type.toLowerCase()}`)}`,
    ])
  );

  return (
    <RichTableView
      hideNoDataView={hideNoDataView}
      translate={translate}
      isLoadingData={isLoading.details}
      titleKey={"incident_details_involved_parties_title"}
      filteredTableData={filteredTableData || []}
      tableHeads={TABLE_HEAD}
      onApplyFilters={handleOnApplyFilters}
      typeFilterOptions={typeFilterOptions}
      randerRow={(item: IncidentInvolvedParty) => (
        <InvolvedPartiesListViewRowItem
          translate={translate}
          vehicleConfig={vehicleConfig}
          key={item.personGovId || ""}
          involvedParty={item}
          onEditClicked={onEditClicked}
        />
      )}
      onActionButtonClicked={onActionButtonClicked}
      actionButtonLabelKey={actionButtonLabelKey}
      actionButtonIcon={actionButtonIcon}
    />
  );
}
